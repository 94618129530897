@import "../../../styles/main.scss";

.emp-checkbox {
  .emp-checkbox-input {
    background-color: transparent;
    display: none;
    width: fit-content;
  }

  label {
    width: fit-content;
    cursor: pointer;
    display: flex;

    span {
      font-weight: 400;
      color: $color-scale-gray-300;
      display: block;
      position: relative;
      font-size: 1em;
      top: 1px;
      margin-left: 8px;
      line-height: 1.4em;

      &.checked {
        color:  $color-scale-gray-100;
        font-weight: 500;
      }
    }
  }

  .check-wrapper {
    position: relative;
    top: 1px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    border-radius: 20px;

    // z-index: 0;
    .check-hover {
      position: absolute;
      top: calc(-50% + 0.5px);
      left: calc(-50% + 0.5px);
      width: 35px;
      height: 35px;
      border-radius: 30px;
      background-color: rgba(9, 97, 229, 0.3);
    }
  }

  .check-box {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: map-get($color-scale-gray, 500) 3px solid;
    // background-color: map-get($color-scale-gray , 750);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
  }
}
