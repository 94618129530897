@import "../../styles/main.scss";



.emp-campaign-guide-modal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;


  .guide-content-section {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }


  .guide-item {
    cursor: pointer;
    padding: 8px 10px;
    background-color: $color-scale-gray-900;
    border: 1px solid $color-scale-gray-800;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: all 200ms;

    &.bordered {
      border: 1px solid $color-scale-gray-800;
    }

    &:hover {
      background-color: $color-scale-gray-850;

      @include mobile-view {
        transform: scale(0.95);
      }
    }

    &:active {
      transform: scale(0.95);
    }

    .guide-content-wrapper {
      flex: 1;

      .header-wrapper {
        display: flex;
        gap: 6px;

        h3 {
          margin: 0;
          font-weight: 600;
          color: $color-scale-gray-100;
          font-size: 1.1em;
        }
      }
    }

  }


  .guide-complete-view,
  .guide-view {
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }


  .guide-view {
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;

    .added-drop-shadow {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .guide-view-header {
      padding: 0 20px 8px 20px;
      display: flex;
      align-items: center;
      gap: 10px;


      .guide-view-header-wrapper {
        flex: 1;

        .guide-title {
          display: block;
          font-weight: 600;
          color: $color-scale-gray-400;
        }

        .slide-title {
          margin: 0;
          font-weight: 600;
          color: $color-scale-gray-100;
          font-size: 1.2em;
        }
      }

      .guide-view-step-wrapper {
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-weight: 600;
        color: $color-scale-gray-300;

        .done {
          color: map-get($color-scale-green, 500);
        }
      }

    }

    .guide-section {
      overflow: auto;
      padding: 0 20px;
      flex: 1;

      .guide-view-visuals-wrapper {
        position: relative;
        overflow: hidden;
        margin-top: 8px;
        aspect-ratio: 450/240;
        width: 100%;
        border-radius: 12px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;

        .guide-progress-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          height: 5px;
          width: 100%;
          background: $color-scale-primary-800;
          display: flex;
          justify-content: flex-start;

          .progress {
            height: 100%;
            width: 100%;
            transform-origin: left;
            background: $color-scale-primary-400;

          }
        }
      }

      .slide-header {
        margin: 0;
        margin-top: 16px;
        width: 100%;
        font-size: 1.2em;
        color: $color-scale-gray-100;
        font-weight: 600;
      }

      .guide-view-text-section {
        margin-top: 16px;
        width: 100%;
      }

      .desc-ul {
        padding-left: 24px;
        color: $color-scale-gray-500 !important;
        line-height: 1.4em;

        li {
          margin-top: 6px;
        }
      }

    }


    .guide-complete-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 40px;
      margin-top: 20px;

      .checkmark-img {
        width: 100px;
      }

      .guide-complete-header {

        margin: 0;
        margin-top: 16px;
        text-align: center;
        font-size: 1.5em;
        color: $color-scale-gray-100;
        font-weight: 700;
      }

      .guide-complete-paragraph {
        margin-top: 12px;
        text-align: center;
      }

      .recommended-guide-section {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 20px;
      }
    }

    .guide-view-btn-section {
      padding: 12px 20px 0 20px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}



.info-alert {
  padding: 8px 10px;
  border-radius: 6px;
  background: $color-scale-primary-100;
  color: $color-scale-primary-400;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;
}

.guide-visual-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.centered-kv {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  &.anchored-top-kv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start
  }

  &.anchored-bottom-kv {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .campaign-kv {
    width: 80%;
  }

  .task-kv {
    position: relative;
    width: 80%;
    height: 100%;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    gap: 10px;

    .task-img {
      border-radius: 12px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .task-option-img-wrapper {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;

      .task-option-img {
        width: 160px;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }


  }

  .recruiting-counteroffer-kv,
  .deliverable-kv {
    width: 95%;
    margin-bottom: -10px;
  }

  .payment-bank-kv,
  .payment-cs-kv,
  .payment-payout-kv,
  .payment-process-kv {
    width: 90%;
  }


  .recruiting-application-kv,
  .brand-pay-first-kv {
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;


  }


  .recruiting-application-kv {
    .recruiting-application-img {
      position: relative;
      border-radius: 8px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      position: relative;
      bottom: -20px;
      width: 80%;
      height: fit-content;
    }

    .recruiting-application-bottom-wrapper {
      position: absolute;
      width: 100%;
      height: 70px;
      left: 0px;
      bottom: 0px;
      display: flex;
      align-items: flex-end;
      gap: 10px;
      padding-bottom: 10px;
      justify-content: center;
      background: #000000;
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    }
  }

  .brand-pay-first-kv {
    .brand-pay-first-img {
      position: relative;
      border-radius: 8px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      position: relative;
      bottom: -20px;
      width: 80%;
      height: fit-content;
    }

    .brand-pay-first-bottom-wrapper {
      position: absolute;
      width: 100%;
      height: 70px;
      left: 0px;
      bottom: 0px;
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      justify-content: center;
      background: #000000;
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    }
  }

  .payment-bank-kv,
  .payment-cs-kv,
  .payment-payout-kv {
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .recruiting-creator-card-kv {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .creator-card-img {
      position: relative;
      right: -20px;
      width: 100%;
      height: fit-content;
      border-radius: 12px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }

  .recruiting-start-task-kv {
    width: 95%;
    margin-top: 20px;
  }

  .ongoing-draft-kv {
    width: 95%;
    margin-top: 5px;
  }


  .ongoing-draft-card-kv {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 20px;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;

    .ongoing-draft-card {
      border-radius: 12px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: 0.5px solid $color-scale-gray-150;

      &.left-card,
      &.right-card {
        height: 90%;
      }

      &.right-card {

        z-index: 0;
      }

      &.left-card {}

      &.center-card {
        height: 100%;
      }
    }
  }

  .ongoing-proof-of-work-card-kv {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 20px;
    align-items: flex-end;
    justify-content: center;

    .ongoing-proof-of-work-card {
      border-radius: 8px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: 0.5px solid $color-scale-gray-150;

      &.left-card,
      &.right-card {
        width: 32%;
      }

      &.right-card {

        margin-left: -20px;
        z-index: 0;
      }

      &.left-card {
        margin-right: -20px;
        z-index: 0;
      }

      &.center-card {
        position: relative;
        top: 20px;
        width: 40%;
        z-index: 2;
      }
    }
  }


  .pow-options-kv {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .pow-img {
      width: 95%;
      border-radius: 12px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

  }


  .ongoing-progress-wall-kv {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    gap: 10px;

    .ongoing-col {
      border-radius: 4px;
      width: 40%;
      height: fit-content;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border: 0.5px solid $color-scale-gray-150;
    }
  }
}