@import "../../../styles/main.scss";

.emp-evidence-record-modal {
  .modal-shine-border {
    border: 1px solid $color-scale-gray-800;
    border-radius: 12px;
    margin: 6px;
  }


  .evidence-details-view {
    .header-section {
      display: flex;

      .info-wrapper {
        flex: 1;

        .section-title {
          display: block;
          font-weight: 600;
          color: $color-scale-gray-100;
          font-size: 1.2em;
          margin: 0;
        }
      }
    }

    .sm-top-section {
      width: 100%;
      padding: 8px 20px;
      border-top: 1px solid $color-scale-gray-800;
      border-bottom: 1px solid $color-scale-gray-800;
      display: flex;
      gap: 6px;
      align-items: center;


      .avatar-wrapper {
        width: 28px;
        height: 28px;
        position: relative;

        .social-media-platform-badge {
          position: absolute;
          bottom: -2px;
          right: -4px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: $color-scale-gray-1000;
          border: 1px solid $color-scale-gray-800;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .social-media-avatar {
        width: 28px;
        height: 28px;
        border-radius: 30px;
        object-fit: cover;
      }

      .info-section {
        flex: 1;

        .name-lbl {
          font-weight: 600;
          color: $color-scale-gray-100;
        }
      }
    }

    .sm-content {
      position: relative;
      padding: 0 20px 5px 20px;
      border-bottom: 1px solid $color-scale-gray-800;
      display: flex;
      justify-content: center;
      background-color: $color-scale-gray-800;

      &.slider-enabled {
        display: block;
      }

      &.text-bg {
        background-color: $color-scale-gray-900;
      }

      .sm-right-arrow-wrapper,
      .sm-left-arrow-wrapper {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 200ms;

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      .sm-left-arrow-wrapper {
        left: 20px;
      }

      .sm-right-arrow-wrapper {
        right: 20px;
      }

      .sm-pagination-dots-wrapper {
        padding: 5px 10px 8px 5px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        gap: 0;

        .sm-dot-clickable-area {
          width: 18px;
          height: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            .sm-dot {
              background-color: $color-scale-gray-400;

              &.active {
                background-color: $color-scale-primary-600;
              }
            }
          }

          .sm-dot {
            border-radius: 50%;
            background-color: $color-scale-gray-200;
            width: 8px;
            height: 8px;
            transition: 100ms all;

            &.active {
              background-color: $color-scale-primary-500;
            }
          }

        }


      }

      .attachment-image,
      .attachment-video {
        max-height: 300px;
        overflow: hidden;
        position: relative;
        border-radius: 12px;

        .attachment-overlay {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          width: 100%;
          height: 60px;
          padding: 0 20px;
          padding-top: 10px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
          opacity: 0;
          transition: all 200ms;

          &.hovered {
            opacity: 1;
          }

          .overlay-content-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .overlay-filename {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 200px;
              width: 100%;
              font-weight: 600;
              color: $color-scale-gray-0;
            }

            .download-wrapper {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }

        video {
          width: 100%;
        }
      }

      .attachment-video {
        display: flex;
        justify-content: center;
        background-color: $color-scale-gray-850;
      }

      .attachment-file {
        border: 1px solid $color-scale-gray-800;
        border-radius: 12px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 6px;

        .filename {
          color: $color-scale-gray-800;
          font-size: 1.05em;
          font-weight: 600;

          .size {
            color: $color-scale-gray-500;
            font-weight: 500;
          }
        }

      }

      .sm-video-wrapper {
        width: 100%;
        aspect-ratio: 1;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .attachment-wrapper {
        width: 100%;
        max-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-scale-gray-850;
      }


      .sm-image {
        max-height: 300px;
        height: 100%;
        width: auto;
        max-width: 100%;
      }

      .sm-video {
        height: 100%;
        width: auto;
        object-fit: cover;
      }

      .sm-paragraph {
        min-height: 200px;
        margin: 0;
        padding: 10px 20px;
        background-color: $color-scale-gray-50;
        color: $color-scale-gray-700;
        font-weight: 500;
        font-size: 1.2em;
      }

      .x-tweet-wrapper {
        .x-tweet-text {
          color: $color-scale-gray-300;
          font-weight: 500;
          font-size: 1.2em;
        }

        .x-media-grid-wrapper {
          padding: 0 5px;

          .x-media-grid {
            display: grid;
            grid-gap: 4px;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 12px;
            overflow: hidden;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            border: 1px solid $color-scale-gray-800;

            .x-media-wrapper {
              position: relative;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .play-video-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-circle {
                  cursor: pointer;
                  background-color: rgba(255, 255, 255, 0.1);
                  width: 50px;
                  height: 50px;
                  border-radius: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.items-1 {
                &:nth-child(1) {
                  grid-column: span 2;
                  grid-row: span 2;
                }
              }

              &.items-2 {

                &:nth-child(1),
                &:nth-child(2) {
                  grid-row: span 2;
                }
              }

              &.items-3 {
                &:nth-child(1) {
                  grid-row: span 2;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .x-poll-wrapper {
          padding: 0 5px;
        }
      }


    }

    .sm-metrics {
      display: flex;
      padding: 8px 20px;
      border-bottom: 1px solid $color-scale-gray-800;
      border-top: 1px solid $color-scale-gray-800;
      gap: 16px;

      .metric-wrapper {
        cursor: pointer;
        padding: 2px;
        border: 4px;
        display: flex;
        gap: 4px;

        &:hover {
          background-color: $color-scale-gray-800;
        }

        span {
          color: $color-scale-gray-300;
          font-weight: 600;
        }
      }
    }

    .details-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* 2 columns with equal width */
      column-gap: 12px;
      row-gap: 20px;
      padding: 0 20px;
    }

    /* Adjust for smaller screens */
    @include mobile-view {

      /* You can adjust the max-width as needed */
      .details-section {
        grid-template-columns: 1fr;
        /* Switch to 1 column */
      }
    }
  }
}