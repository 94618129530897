@import "../../styles/main.scss";

.emp-thirdweb-pay-embed-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  width: 100dvw;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .embed-wrapper {
    position: relative;

    .close-button {
      position: absolute;
      padding: 10px;
      z-index: 1;
      background-color: transparent;
      top: 4px;
      right: 4px;
      cursor: pointer;
      border-radius: 12px 17px 12px 12px;
      transition: all 100ms;

      &:hover {
        background-color: $color-scale-gray-800;
      }
    }
  }
}