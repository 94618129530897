@import "../../styles/main.scss";


.emp-rate-privacy-request-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px 10px 20px;


        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .demo-wrapper {
        padding: 10px 0;
        display: flex;

        .slide {
            position: relative;
            flex: 1;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 200ms all;
            background-image: url('../../assets/images/light-mesh-bg.png');
            background-size: cover;

            img {
                transition: 200ms all;
                height: 90%;
            }

            .absolute-wrapper {
                position: absolute;
                top: 10px;
                left: 10px;
            }
        }
    }

    .info-wrapper {
        border-top: 1px solid $color-scale-gray-800;
        padding: 10px 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .modal-setting-item {
            display: flex;
            gap: 12px;
            align-items: center;

            .info-header {
                flex: 1;
                font-size: 1.1em;
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .switch-wrapper {
                min-width: fit-content;
                min-height: fit-content;
            }

        }

    }




}