@import "../../styles/main.scss";




.bg-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0.00) 100%);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f9 100%);
}

.emp-seller-campaign-details-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .page-header {
        font-size: 1.4em !important;
        color: $color-scale-gray-100;
    }

    .campaign-details-section {
        display: flex;
        gap: 20px;

        @include mobile-view {
            flex-direction: column;
            gap: 10px;
        }

        .campaign-image {
            aspect-ratio: 1.667;
            height: 140px;
            border-radius: 12px;

        }

        .campaign-image-mobile-wrapper {
            position: relative;
            height: fit-content;
            width: 100%;
            height: 140px;

            .full-banner-anchor {
                position: absolute;
                left: -10px;
                top: 0;
                width: 100dvw;
                height: 140px;
                background-color: black;

                .full-banner-wrapper {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .campaign-image-mobile {
                        height: 140px;
                        width: 100%;
                        object-fit: cover;
                    }

                    .banner-shading {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        bottom: 0;
                        background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, rgba(10, 10, 10, 0.9) 85%);
                        height: 100px;
                        padding: 10px 10px;
                        display: flex;
                        align-items: flex-end;

                        .campaign-name-lbl {
                            margin: 0;
                            color: $color-scale-gray-0;
                        }
                    }
                }
            }




        }

        .brand-wrapper {
            display: flex;
            align-items: center;
            gap: 4px;

            .logo {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                border: 1px solid $color-scale-gray-800;
                object-fit: cover;
            }

            .company-name {
                color: $color-scale-gray-100;
                font-weight: 500;
            }
        }

        .details-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .campaign-name-lbl {
                margin: 0;
                font-size: 1.4em;
                font-weight: 700;
                color: $color-scale-gray-100
            }

            .campaign-description {
                line-break: anywhere;
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300
            }

            .campaign-details-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;

                .uploaded-file-card {
                    border: 1px solid map-get($color-scale-gray, 800);
                    background-color: map-get($color-scale-gray, 900);
                    padding: 6px 30px 6px 6px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: fit-content;
                    cursor: pointer;
                    transition: all 200ms;

                    &:hover {
                        transform: translateY(-4px);
                    }

                    .image-section {
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        background-color: map-get($color-scale-primary, 500);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .text-section {
                        .file-title {
                            display: block;
                            font-weight: 600;
                            color: $color-scale-gray-300;
                        }

                        .file-name {
                            display: block;
                            margin-top: 3px;
                            color: map-get($color-scale-gray, 500);
                        }
                    }
                }
            }

        }
    }

    .tasks-section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(500px, 100%), 1fr));
        grid-gap: 10px;
        position: relative;


        .blank-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(7, 7, 47, 0.3) 10%, $color-scale-primary-1000 100%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 2;

            .info-wrapper {
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                img {
                    height: 120px;
                }

                .header {
                    margin-top: 8px;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                    font-size: 1.6em;
                }

                .description {
                    margin-top: 0px;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 500);
                }
            }
        }

    }
}