@import "../../styles/main.scss";


.emp-task-invitation-application-response-modal {
    position: relative;
    display: flex;
    flex-direction: column;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .view-header-wrapper {
        display: flex;
        align-items: center;
    }

    .back-btn {
        position: relative;
        left: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        gap: 3px;
        cursor: pointer;
        padding: 5px;
        box-sizing: border-box;
        width: fit-content;
        transition: all 300ms;

        &.with-text {
            padding-right: 12px;
        }

        &:hover {
            background-color: $color-scale-gray-850;
        }

        .btn-text {
            font-weight: 600;
            color: $color-scale-gray-300;
        }
    }

    .view-header {
        font-size: 1.1em;
        font-weight: 600;
        color: $color-scale-gray-100;

        &.shift-left {
            position: relative;
            left: -5px;
        }
    }

    .view-description {
        margin: 0;
        line-height: 1.4em;
        font-weight: 500;
        color: $color-scale-gray-300;
    }

    .loading-view {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        .emp-loader {
            padding: 12px 12px;
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border: 1px solid #EFF0F1;
            display: flex;
            flex-direction: column;
            align-items: center;

            .label {
                font-weight: 600;
                font-size: 1.2em;
            }
        }
    }





    .content-section {
        margin-top: 20px;

        .heading,
        .task-name-lbl {
            display: block;

            font-weight: 600;
            text-align: center;
            line-height: 1.4em;
        }

        .task-name-lbl {
            font-size: 1.3em;
            color: $color-scale-gray-100;
        }

        .heading {
            font-size: 1em;
            color: $color-scale-gray-300;
            margin-top: 6px;
        }

        .description {
            text-align: center;
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }

        .alert {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            background-color: #fff6db;
            padding: 10px 15px;
            border-radius: 12px;
            width: 100%;

            .intro-wrapper {
                display: flex;
                gap: 8px;

                .description-text {
                    color: $color-scale-gray-300;
                    font-weight: 500;
                }
            }
        }
    }

    .single-response-view {
        .profile-photo-section {
            padding-top: 20px;
            display: flex;
            justify-content: center;

            .relative-wrapper {
                width: 60px;
                height: 60px;
                position: relative;

                .absolute-wrapper {
                    position: absolute;
                    bottom: -4px;
                    right: -4px;
                    width: 30px;
                    height: 30px;

                    .profile-photo {
                        width: 30px;
                        height: 30px;
                        border: 1px solid map-get($color-scale-gray, 150);
                    }
                }
            }

            .creator-rep-wrapper {
                display: flex;
                align-items: center;
            }

            .profile-photo-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .overlap {
                    margin-left: -20px;
                }

                .profile-photo {
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    object-fit: cover;
                    border: 2px solid map-get($color-scale-gray, 150);

                    &.org-photo {
                        border-radius: 12px;
                    }
                }

                .plus-label {
                    top: -6px;
                    position: relative;
                    display: block;
                    color: $color-scale-gray-200;
                    font-weight: 500;
                    font-size: 4em;
                }

                .empty-div {
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    display: flex;
                    background-color: map-get($color-scale-gray , 50);
                    box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
                    align-items: center;
                    justify-content: center;
                    color: map-get($color-scale-gray , 500);
                    font-weight: 600;
                }


                .avatar {
                    display: flex;
                    width: 60px;
                    height: 60px;
                    border-radius: 120px;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 1.8em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }
            }
        }

        .creator-card {
            padding: 8px 8px 12px 8px;
            border-radius: 8px;
            border: $color-scale-gray-800 solid 1px;
            display: flex;
            width: 100%;
            gap: 8px;
            cursor: pointer;
            transition: 200ms all;
            background-color: $color-scale-gray-900;

            &:hover {
                transform: translateY(-3px);
            }

            .profile-img,
            .profile-avatar {
                width: 42px;
                height: 42px;
                min-width: 42px;
                min-height: 42px;
                border: 1px solid $color-scale-gray-800;
                border-radius: 80px;
            }

            .profile-img {
                object-fit: cover;
            }

            .profile-avatar {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 1.1em;
                    font-weight: 700;
                    color: $color-scale-gray-0
                }
            }

            .bio-wrapper {
                flex: 1;

                .email,
                .name {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                .name-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

                .icon-wrapper {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    min-width: 20px;
                }

                .name {
                    font-size: 1.05em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .email {
                    width: 100%;
                    margin-top: 2px;
                    font-size: 0.9em;
                    font-weight: 500;
                    color: map-get($color-scale-gray, 300);
                }

                .metrics-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }

        }
    }

    .multi-response-view {
        padding-bottom: 80px;

        .response-section {
            display: flex;
            flex-direction: column;

            .response-form-row {
                padding: 12px 5px 10px 5px;
                position: relative;
                cursor: pointer;
                border-top: 1px solid $color-scale-gray-800;
                display: flex;
                transition: all 200ms;
                gap: 12px;

                &:hover {
                    background-color: $color-scale-gray-900;
                }

                &.selected {
                    background-color: $color-scale-gray-900;

                    &:hover {
                        background-color: $color-scale-gray-900;
                    }
                }

                .relative-wrapper {
                    position: relative;
                    width: 45px;
                    height: 45px;

                    .absolute-wrapper {
                        position: absolute;
                        bottom: 0px;
                        right: -4px;
                        width: 25px;
                        height: 25px;

                        .profile-photo {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }

                .profile-photo {
                    width: 45px;
                    height: 45px;
                    border-radius: 100px;
                    object-fit: cover;
                    border: 1px solid map-get($color-scale-gray, 150);
                    box-shadow: 0 0 0 0px transparent;
                    transition: 400ms all;

                    &.org-photo {
                        border-radius: 8px;
                    }

                    &.selected {
                        box-shadow: 0 0 0 8px $color-scale-primary-100;
                    }
                }

                .avatar {
                    display: flex;
                    width: 45px;
                    height: 45px;
                    border-radius: 120px;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 1.8em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }

                .details-section {
                    flex: 1;

                    .rep-name-section {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .rep-name-lbl {
                            font-weight: 600;
                            color: $color-scale-gray-100;
                            display: block;
                        }
                    }

                    .rate-section {
                        display: flex;

                        .rate-lbl {
                            font-weight: 600;
                            color: $color-scale-gray-300;
                            display: block;
                        }

                    }

                    .rep-remarks {
                        word-break: break-word;
                    }


                }

                .form-section {
                    display: flex;
                    flex-direction: row-reverse;
                    width: fit-content;
                    position: relative;
                    top: -4px;

                    .remarks-btn {}
                }
            }
        }

    }

    .acknowledgement-wrapper {
        display: flex;

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            flex-direction: column;
            background-color: $color-scale-primary-100;
            padding: 15px;
            border-radius: 12px;
            width: 100%;

            .intro-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                span {
                    color: $color-scale-primary-600;
                    font-weight: 700;
                }
            }

            .control-wrapper {
                display: flex;

                .checkbox-text-wrapper {
                    cursor: pointer;
                    flex: 1;

                    span {
                        line-height: 1.4em;
                        font-weight: 400;
                        color: $color-scale-gray-100;

                        &.checked {
                            font-weight: 500;
                        }
                    }
                }
            }

        }
    }

}