@import "../../../styles/main.scss";


.emp-task-card {

    .skeletal-card,
    .loading-card,
    .task-card {
        position: relative;
        border-radius: 12px;
        transition: all 200ms;
        display: flex;
        gap: 16px;
        padding: 10px;
        box-sizing: border-box;
        height: 100%;

        @include mobile-view {
            padding: 0;
        }

        .task-img {
            width: 48px;
            border-radius: 8px;
        }

        .task-img-skeleton {
            height: 100px;
            width: 138px;
            border-radius: 8px;

            @include mobile-view {
                flex: 1;
            }
        }

    }

    .task-card {
        position: relative;
        background: $cbz-card-gradient-md;
        border: $color-scale-gray-800 solid 1px;
        cursor: pointer;

        &:hover {
            transform: translateY(-3px);
        }

        .participation-badge {
            position: absolute;
            z-index: 2;
            top: -4px;
            left: -4px;
            padding: 3px 6px 5px 8px;
            border-radius: 10px 4px 4px 4px;
            background: #d50006;
            background: -webkit-linear-gradient(0deg, #d50006 0%, #ff1d23 100%);
            background: linear-gradient(0deg, #d50006 0%, #ff1d23 100%);
            display: flex;

            span {
                font-size: 0.9em;
                font-weight: 600;
                color: $color-scale-gray-0;
            }
        }

        .actions-badge {
            padding: 3px 8px;
            border-radius: 8px;
            background-color: map-get($color-scale-red, 500);
            display: flex;
            align-items: center;
            justify-content: center;

            &.absolute-positioning {
                position: absolute;
                top: 5px;
                right: 5px;
            }

            .value {
                color: $color-scale-gray-0;
                font-weight: 700;
            }
        }

        .task-wrapper {
            width: 100%;
            display: flex;
            overflow: hidden;
            gap: 12px;

            @include mobile-view {
                flex-direction: column;
            }

            .campaign-img-wrapper {
                position: relative;
                display: flex;
                height: 90px;

                @include mobile-view {
                    height: fit-content;
                }

                .task-img {
                    height: 100%;
                    background: linear-gradient(318deg, #000 0%, #4B4B4B 100%);
                    display: flex;
                    align-items: center;
                    border-radius: 12px 0 0 12px;
                    justify-content: center;
                    aspect-ratio: 1;

                    @include mobile-view {
                        border-radius: 0;
                        border-top-left-radius: 12px;
                    }
                }

                .campaign-img-div {
                    width: 90px;
                    height: 90px;
                    border-radius: 0 12px 12px 0;
                    overflow: hidden;

                    @include mobile-view {
                        aspect-ratio: initial;
                        width: initial;
                        flex: 1;
                        border-radius: 0;
                        border-top-right-radius: 12px;
                    }
                }

                .campaign-img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }


            .task-details-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                @include mobile-view {
                    padding: 0 10px 10px 10px;
                }

                .task-header-wrapper {
                    display: flex;
                    flex: 1;
                    flex-direction: column;


                    .pipe {
                        color: $color-scale-gray-200;
                    }

                    .task-description {
                        margin: 0;
                        overflow: hidden;
                        color: $color-scale-gray-300;
                        font-weight: 500;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .task-name-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .details-wrapper {
                            display: flex;
                            flex: 1;
                            align-items: center;
                            gap: 8px;

                            @include mobile-view {
                                flex-direction: column;
                                align-items: flex-start;
                            }


                            .task-name-lbl {
                                display: block;
                                font-size: 1.1em;
                                font-weight: 700;
                                color: $color-scale-gray-100;
                            }

                            .task-status-wrapper {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                            }
                        }

                    }

                    .task-mid-section-wrapper {
                        display: flex;
                        gap: 4px;
                        align-items: center;
                    }

                }

                .task-info-wrapper {
                    margin-top: 10px;
                    display: flex;
                    gap: 12px;
                }

                // .organisation-wrapper {
                //     display: flex;
                //     gap: 6px;
                //     height: fit-content;
                //     align-items: center;
                //     max-width: 160px;
                //     overflow: hidden;

                //     .organisation-img {
                //         width: 32px;
                //         height: 32px;
                //         border-radius: 45px;
                //         aspect-ratio: 1;
                //         object-fit: cover;
                //         border: 1px solid $color-scale-gray-800;
                //         z-index: 3;
                //     }

                //     .organisation-details-wrapper {
                //         display: flex;
                //         flex-direction: column;
                //         gap: 4px;
                //         overflow: hidden;

                //         .organisation-name-lbl {
                //             white-space: nowrap;
                //             font-weight: 600;
                //             display: block;
                //             overflow: hidden;
                //             text-overflow: ellipsis;
                //             color: $color-scale-gray-100;
                //         }

                //         .created-date-lbl {
                //             font-weight: 500;
                //             display: block;
                //             color: #2C2F34
                //         }

                //     }
                // }
                .agency-wrapper,
                .organisation-wrapper,
                .country-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                }

                .agency-wrapper {
                    gap: 6px;
                }

                .organisation-wrapper {
                    gap: 4px !important;
                    max-width: 120px;
                    overflow: hidden;

                    span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }



                .country {
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-scale-gray-800;
                    border-radius: 20px;
                }

                .profile {
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-scale-gray-800;
                    border-radius: 20px;
                    object-fit: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .initials {
                        font-size: 0.5em;
                        color: $color-scale-gray-0;
                    }
                }

                .extra {
                    height: 18px;
                    padding: 0 5px;
                    border: 1px solid $color-scale-gray-800;
                    border-radius: 18px;
                    background-color: $color-scale-gray-900;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 0.9em;
                    color: $color-scale-gray-300;
                }
            }
        }

    }

    .skeletal-card {
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        padding: 15px;
        height: 121.5px;

        @include mobile-view {
            flex-direction: column;
        }


        .task-details-wrapper-skeleton {
            display: flex;
            flex-direction: column;
            flex: 1;

            .task-top-section {
                flex: 1;

                .task-name-skeleton {
                    height: 20px;
                    border-radius: 4px;
                    width: 180px;
                }

                .task-status-skeleton {
                    height: 16px;
                    border-radius: 4px;
                    width: 100px;
                }
            }

            .task-bottom-section {
                display: flex;
                gap: 12px;

                .rounded-img-wrapper {
                    display: flex;
                    gap: 4px;

                    .rounded-img {
                        width: 18px;
                        height: 18px;
                        border-radius: 18px;
                    }
                }

                .date-title-skeleton,
                .country-title-skeleton,
                .hired-creators-title-skeleton {
                    height: 14px;
                    border-radius: 4px;
                }

                .date-title-skeleton {
                    width: 160px;
                }

                .date-value-skeleton {
                    height: 16px;
                    border-radius: 4px;
                    width: 180px;
                }

                .country-title-skeleton {
                    width: 60px;
                }

                .hired-creators-title-skeleton {
                    width: 80px;
                }
            }

        }

        .shimmer-color {
            background-color: $color-scale-gray-850;
        }
    }


}