@import "../../../styles/main.scss";



.emp-home-social-media-card {
    @include card-border-conic-gradient-mixin(110deg, 10px, 48%, 52%);

    .platform-card {
        display: flex;
        border-radius: 12px;
        border: 1px solid $color-scale-gray-800;
        background: $cbz-card-gradient-sm;
        padding: 8px;


        .left-elem {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .platform-logo {
            background-color: $color-scale-gray-800;
            width: 45px;
            min-width: 45px;
            min-height: 45px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.empty-state {
                background-color: $color-scale-gray-800;
            }
        }

        .social-media-img-wrapper {
            position: relative;
            display: flex;

            .social-media-logo-placeholder {
                position: absolute;
                right: -2px;
                bottom: -2px;
                width: 20px;
                height: 20px;
                border: 1.5px solid map-get($color-scale-gray, 100);
                border-radius: 50px;
                background-color: #1778F2;
                display: flex;
                align-items: center;
                justify-content: center;
            }


            .social-media-image {
                border: 1px solid $color-scale-gray-800;
                object-fit: contain;
                width: 45px;
                height: 45px;
                border-radius: 8px;
            }

        }


        .content {
            margin-left: 12px;

            .status-lbl {
                display: block;
                font-weight: 600;
                color: $color-scale-gray-150;
            }

            .action-lbl {
                margin-top: 4px;
                display: block;
                color: $color-scale-gray-300;

            }
        }
    }
}