@import "../../../../styles/main.scss";

.brand-event-post-modal {
  &.tiktok-config-post-modal {
    .emp-modal-body {
      padding: 0 0 20px 0;
    }
    .event-post-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 1em;
        font-weight: 600;
      }
    }
    .checkbox-wrapper {
      &.disable {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .selected-connect-wrapper {
      background: linear-gradient(
        to right,
        #f6f6f6 0%,
        rgba(214, 230, 255, 0.01) 100%
      );
      margin-bottom: 12px;
      border: 1px solid map-get($color-scale-gray, 100);
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
    }

    .tiktok-config-post-video-content-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 16px;
      .media-wrapper {
        width: 80px;
        min-width: 80px;
        height: 125px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid map-get($color-scale-gray, 100);
        position: relative;
        > div {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          font-size: 1.2em;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content-disclosure-wrapper {
      margin-bottom: 16px;
    }
    .content-disclosure {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      label {
        margin: 0;
      }
      &.gap-0 {
        gap: 0;
      }

      &.w-fit {
        width: fit-content;
      }

      .emp-dropdown-menu-btn {
        justify-content: flex-end;
      }
    }
    .disclosure-warning-wrapper {
      margin-top: 20px;
      width: 100%;
      .emp-pill {
        padding: 10px;
      }
    }
    .interaction-checkbox-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      margin-bottom: 16px;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        label {
          margin: 0;
        }
      }
      .check-wrapper {
        border-radius: 6px;

        .check-hover {
          border-radius: 8px;
        }
      }

      .check-box {
        border-radius: 6px;
      }
    }
  }
}
