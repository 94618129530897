@import "../../styles/main.scss";


.emp-edit-name-confirmation-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;

            .highlighted {
                color: map-get($color-scale-primary, 600);
                font-weight: 600;
            }
        }

        .error-message-wrapper {
            border-radius: 8px;
            background-color: #fff1f1;
            border: 1px solid map-get($color-scale-red, 200);
            color: $color-scale-gray-100;
            padding: 12px;
        }
    }


}