@import "../../styles/main.scss";


.emp-configure-payouts-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .bank-img {
        width: 80px;
    }


    .content-section {
        margin-top: 20px;

        .heading,
        .invitation-failed-lbl {
            display: block;

            font-weight: 600;
            text-align: center;
            line-height: 1.4em;
        }

        .invitation-failed-lbl {
            font-size: 1.3em;
            color: $color-scale-primary-500;
        }

        .heading {
            font-size: 1em;
            color: $color-scale-gray-300;
            margin-top: 6px;
        }

        .description {
            text-align: center;
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }
    }

    .button-wrapper {
        display: flex;
        flex-direction: column;
    }

}