@import "../../../styles/main.scss";

.creator-active-task-card {

    flex: 1;
    overflow-y: auto;
    margin-top: 12px;
    @include card-border-conic-gradient-mixin(100deg, 10px, 48%, 52%);

    @include mobile-view {
        margin-top: 0;
    }

    .header-section {
        padding: 15px 0 20px 20px;

        .card-header {
            font-size: 1.2em;
            margin: 0;
        }

        @include mobile-view {
            padding: 0 5px 10px 5px;
        }
    }

    .table-overlay-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
            height: 60px;
            width: auto;
        }

        .header {
            margin-top: 8px;
            margin-bottom: 0px;
            font-weight: 700;
            color: $color-scale-gray-100;
            font-size: 1.4em;
        }

        .description {
            margin-top: 8px;
            line-height: 1.4em;
            color: $color-scale-gray-300;
        }
    }

    .task-name-cell {
        display: flex;
        align-items: center;
        gap: 6px;

        .campaign-img {
            height: 30px;
            width: auto;
            border-radius: 6px;
            border: 1px solid $color-scale-gray-900;
        }

        .task-platform-wrapper {
            display: flex;
            align-items: center;
            gap: 2.5px;


            .task-type-small {
                font-size: 0.9em;
                color: $color-scale-gray-400;
                font-weight: 600;
                display: block;
            }
        }

    }

    .raw-text {
        color: $color-scale-gray-300;
        font-weight: 500;
    }

    .creators-cell {
        display: flex;
        align-items: center;

        .profile-img {
            width: 30px;
            margin-left: -10px;
            height: 30px;
            border-radius: 30px;
            border: 1px solid $color-scale-gray-800;
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;

            &.org {
                border-radius: 4px;
            }

            .initials {
                font-size: 0.9em;
                font-weight: 600;
                color: $color-scale-gray-0;
            }
        }
    }
}