@import "../main.scss";


.emp-draft-modal {
    position: relative;

    .section-padding {
        padding-left: 20px;
        padding-right: 20px;

        @include mobile-view {
            padding-left: 10px;
            padding-right: 10px;
        }
    }


    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: map-get($color-scale-gray, 600);
        }
    }

    .brand-remarks-section {
        margin-top: 12px;
        padding: 10px 15px;
        background-color: rgba(255, 74, 79, 0.2);
        border-radius: 12px;

        .header-wrapper {
            display: block;
            font-weight: 600;
            color: map-get($color-scale-gray, 100);
            font-size: 1em;
            margin: 0;
        }
    }

    .content-section {
        .upload-hidden {
            display: none;
        }

        .upload-section-label-wrapper {
            display: flex;
            gap: 10px;

            .upload-section-label {
                flex: 1;
                display: flex;
                align-items: center;
                font-size: 1em;
                font-weight: 500;
                color:$color-scale-gray-100;
                margin-bottom: 4px;

                .required {
                    display: inline;
                    color: red;
                }
            }
        }


        .attachment-section {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            gap: 8px;

            .attachment-item {
                position: relative;
                aspect-ratio: 1;
                border-radius: 12px;
                border: 1px solid $color-scale-gray-800;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &.upload-btn {
                    cursor: pointer;
                    transition: all 100ms;

                    .upload-text {
                        display: block;
                        margin-top: 4px;
                        font-weight: 600;
                        color: $color-scale-gray-400;
                    }

                    &:hover {
                        background-color: $color-scale-primary-900;
                    }
                }

                .media-wrapper {
                    position: relative;
                    width: 100%;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-scale-gray-900;
                    overflow: hidden;

                    video,
                    img {
                        width: 100%;
                        object-fit: cover;
                    }

                    .file-info {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 15px 15px;
                        width: 100%;
                        height: 100%;
                        background-color: $color-scale-gray-900;
                        overflow: hidden;

                        .file-extension {
                            font-size: 2em;
                            text-transform: uppercase;
                            font-weight: 800;
                            color: $color-scale-gray-500;
                        }

                        .file-name {
                            font-size: 0.9em;
                            color: $color-scale-gray-300;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                        }
                    }


                    .delete-button {
                        position: absolute;
                        cursor: pointer;
                        right: 4px;
                        top: 4px;
                        border-radius: 100px;
                        height: 22px;
                        width: 22px;
                        background-color: $color-scale-gray-800;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 100ms;

                        &:hover {
                            background-color: $color-scale-gray-150;
                            transform: scale(1.05);
                        }
                    }

                    .processing-state {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(255, 255, 255, 0.3);
                    }

                    .loader {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: $color-scale-gray-100;

                        .bar {
                            background-color: $color-scale-primary-500;
                            height: 100%;
                            border-radius: 4px;
                            transition: width 100ms linear;

                            &.max {
                                background-color: $color-scale-green-400;
                                width: 100% !important;
                            }
                        }
                    }
                }


                .info-section {
                    width: 100%;
                    background-color: $color-scale-gray-900;
                    padding: 4px 6px;


                    .context-section {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        color: $color-scale-gray-300;
                        font-weight: 600;
                        font-size: 0.8em;
                    }

                    >.download-link {
                        font-size: 0.9em;
                    }
                }
            }
        }

        .uploaded-attachment-wrapper {
            border-radius: 12px;
            width: 100%;
            max-height: 300px;

            .file-upload-zone {
                text-align: center;
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid $color-scale-gray-150;
                box-sizing: border-box;
                flex-direction: column;
                cursor: pointer;
                transition: all 200ms;

                &:hover {
                    background-color: $color-scale-gray-50;

                    &>.title {
                        color: map-get($color-scale-primary, 600);
                    }
                }

                .title {
                    display: block;
                    margin-top: 4px;
                    font-weight: 600;
                    color: $color-scale-gray-800;
                    transition: all 200ms;
                }

                .specs {
                    margin: 0;
                    margin-top: 8px;
                    color: $color-scale-gray-500;
                }
            }

            .video-wrapper {
                width: 100%;
                position: relative;
                max-height: 300px;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                background-color: $color-scale-gray-850;


                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
                    display: flex;
                    padding: 10px;
                    border-radius: 12px;

                    .video-name {
                        white-space: nowrap;
                        color: $color-scale-gray-0;
                        font-weight: 600;
                    }
                }

                .uploaded-video {
                    width: 100%;
                }
            }

            .image-wrapper {
                position: relative;
                width: 100%;
                max-height: 300px;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                background-color: $color-scale-gray-850;
                border-radius: 12px;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
                    display: flex;
                    padding: 10px;
                    border-radius: 12px;

                    .image-name {
                        white-space: nowrap;
                        color: $color-scale-gray-0;
                        font-weight: 600;
                    }
                }

                img {
                    max-height: 300px;
                    max-width: 100%;
                    background-color: $color-scale-gray-100;
                    box-sizing: border-box;
                    object-fit: cover;
                }
            }
        }
    }

}