@import "../../styles/main.scss";


.emp-upload-chat-attachment-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .content-section {
        padding: 0 20px;

        .uploader-section {
            .upload-label {
                font-size: 1em;
                font-weight: 500;
                color: $color-scale-gray-300;
                margin-bottom: 8px;

                .required {
                    display: inline;
                    color: red;
                }
            }

            .uploaded-attachment-wrapper {
                border-radius: 12px;

                .video-wrapper {
                    position: relative;

                    overflow: hidden;
                    height: fit-content;

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 80px;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
                        display: flex;
                        padding: 10px;
                        border-radius: 12px;

                        .video-name {
                            white-space: nowrap;
                            color: $color-scale-gray-0;
                            font-weight: 600;
                        }
                    }

                    .uploaded-video {
                        border: 1px solid $color-scale-gray-800;
                        width: 100%;
                        border-radius: 12px;
                    }
                }

                .images-wrapper {
                    max-width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    img {
                        width: calc(25% - 10px);
                        aspect-ratio: 1;
                        border-radius: 12px;
                        background-color: $color-scale-gray-900;
                        border: 1px solid $color-scale-gray-800;
                        /* 50% width with spacing accounted for */
                        box-sizing: border-box;
                        object-fit: contain;
                    }
                }

            }



            .file-upload-zone {
                text-align: center;
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid $color-scale-gray-800;
                background-color: $color-scale-gray-900;
                box-sizing: border-box;
                flex-direction: column;
                cursor: pointer;
                transition: all 200ms;



                &:hover {
                    background-color: $color-scale-gray-850;

                    &>.title {
                        color: map-get($color-scale-primary, 100);
                    }
                }

                .title {
                    display: block;
                    margin-top: 4px;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                    transition: all 200ms;
                }

                .specs {
                    margin: 0;
                    margin-top: 8px;
                    color: $color-scale-gray-300;
                }
            }

            .upload-hidden {
                display: none;
            }
        }

        .form-section {}
    }

}