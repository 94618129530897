@import "../../../styles/main.scss";

.emp-button-tab {
  display: flex;

  .emp-tab-button {
    padding: 8px 16px;
    font-size: 1em;
    color: map-get($color-scale-gray, 500);
    background-color: map-get($color-scale-gray, 100);
    border: 1px solid map-get($color-scale-gray, 200);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover {
      background-color: map-get($color-scale-primary, 100);
      color: map-get($color-scale-primary, 500);
    }
    &.active {
      background-color: map-get($color-scale-primary, 100);
      color: map-get($color-scale-primary, 500);
      border: 2px solid map-get($color-scale-primary, 500);
      font-weight: 600;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
