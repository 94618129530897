@import "../../../styles/main.scss";

.emp-instagram-account-modal {
    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.1em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 6px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .pages-wrapper {
        margin-top: 20px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid $color-scale-gray-800;
        padding: 8px;
        box-sizing: border-box;

        .page-name-wrapper {
            display: flex;
            gap: 4px;
            align-items: center;

            .page-name-lbl {
                font-size: 0.95em;
                font-weight: 600;
                color: $color-scale-gray-200;
            }
        }

        .page-item {
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            padding: 8px;
            border: 1px solid $color-scale-gray-700;

            &.active {
                background-color: $color-scale-gray-850;
            }

            &:not(:first-child) {
                margin-top: 10px;
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }


            .left-elem {
                display: flex;
                flex: 1;

                .picture {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    object-fit: cover;
                    border: 1px solid $color-scale-gray-800;
                }

                .text-section {
                    margin-left: 12px;

                    .name {
                        display: block;
                        font-size: 1em;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }

                    .about {
                        display: block;
                        margin-top: 3px;
                        font-size: 1em;
                        color: $color-scale-gray-300;
                    }
                }
            }

            .right-elem {
                display: flex;
                align-items: center;
                justify-content: center;

                .placeholder {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                    border-radius: 30px;
                    background-color: $color-scale-gray-800;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.active {
                        box-shadow: 0px 0px 0px 2px $color-scale-primary-300;
                    }

                    .knob {
                        width: 14px;
                        height: 14px;
                        border-radius: 25px;
                        background-color: $color-scale-primary-300;
                    }
                }
            }
        }
    }

    .footer-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}