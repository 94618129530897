@import "../../../styles/main.scss";


.emp-manage-wallet-modal {

  .form-view {

    .form-section,
    .header-section {
      padding: 10px 20px;
    }

    .header-section {
      padding: 20px 20px;

      h2 {
        margin: 0;
        font-size: 1.2em;
        font-weight: 600;
        color: $color-scale-gray-100;
      }
    }

    .banner-section {
      width: 100%;
      height: 100px;
      border-top: 1px solid $color-scale-gray-800;
      border-bottom: 1px solid $color-scale-gray-800;
    }

    .form-section {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;

    }

    .footer-section {
      padding: 10px 20px;
      border-top: 1px solid $color-scale-gray-800;
      display: flex;
      gap: 10px;
      flex-direction: row-reverse;
    }
  }

  .completed-view {

    .token-img {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      border: 1px solid $color-scale-gray-800;
      z-index: 1;
    }

    .banner-section {
      width: 100%;
      padding: 20px 0;
      overflow: hidden;
      border-bottom: 1px solid $color-scale-gray-800;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px 12px 0 0;
    }

    .success-section {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 30px;

      .heading {
        font-size: 1.4em;
        color: $color-scale-gray-0;
        margin: 0;
      }
    }

  }
}

.emp-manage-wallet-animation {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $cbz-card-gradient-lg;



  .abs-section {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;



    .left-section,
    .right-section {
      display: flex;
      flex: 1;
      overflow: hidden;
      position: relative;
    }

    .left-section {
      .coin {
        right: 0;
      }
    }

    .right-section {
      .coin {
        left: 0;
      }
    }

    .coin {
      position: absolute;
      top: 40%;
      width: 20px;
      height: 20px;
      opacity: 0.5;

      &.first-coin {
        top: 30%;
      }

      &.second-coin {
        top: 40%;
      }

      &.third-coin {
        top: 50%;
      }
    }

  }

  .wallet-img {
    width: 100px;
    height: auto;
  }
}

.purple-grid-background {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 1px;
  border-left: 1px solid $color-scale-gray-800;
  border-right: 1px solid $color-scale-gray-800;
  top: 0;
  left: 0;

  svg {
    width: 100%;
    height: auto;
  }
}