@import "../../styles/main.scss";


.referral-page {
    position: relative;

    .coming-soon-overlay {
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        .overlay-content-wrapper {
            margin-top: 150px;
            max-width: 400px;
            text-align: center;

            .construction-img {
                width: 150px;
            }

            h1 {
                font-size: 1.6em;
                color: $color-scale-gray-100;
                font-weight: 700;
            }
        }


    }

    .header-wrapper {
        border-bottom: 1px solid map-get($color-scale-gray, 150);

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .referral-intro-card {
            position: relative;
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            background: linear-gradient(268.9deg, #B9D9F8 4%, #FFFFFF 67.72%);
            border: 1px solid $color-scale-gray-800;
            border-radius: 12px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            @include mobile-view {
                padding: 18px;
            }

            .grid-h {
                position: absolute;
                right: 0;
                width: 400px;
                height: 2px;
                background: rgba(255, 255, 255, 0.2);

                &.one {
                    top: 25%;
                }

                &.two {
                    top: 50%;
                }

                &.three {
                    top: 75%;
                }
            }

            .grid-v {
                position: absolute;
                top: 0px;
                width: 2px;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);

                &.one {
                    right: 80px;
                }

                &.two {
                    right: 160px;
                }

                &.three {
                    right: 240px;
                }
            }


            .image-wrapper {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 10px;

                img {
                    position: relative;
                    height: 100%;

                    @include tablet-view {
                        display: none;
                    }
                }
            }


            .title-lbl {
                margin: 0;
                font-size: 1.8em;
                color: $color-scale-gray-100;
                font-weight: 700;

                @include mobile-view {
                    font-size: 1.4em;
                }
            }

            .description-lbl {
                max-width: 600px;
                color: $color-scale-gray-300;
                line-height: 1.4em;
                margin: 0;
            }

            .form-wrapper {
                max-width: 700px;
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }



        .platform-referral-card {
            padding-bottom: 60px !important;

            .display-pic-wrapper {
                display: flex;
                gap: 8px;
                align-items: center;


                .profile-pic,
                .profile-avatar {
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    min-height: 32px;
                    border: 1px solid map-get($color-scale-gray, 100);
                    border-radius: 80px;
                }

                .profile-pic {
                    object-fit: cover;
                }

                .profile-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 1.1em;
                        font-weight: 700;
                        color: $color-scale-gray-0
                    }
                }
            }
        }

    }
}