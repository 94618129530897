// Font imports
@import "colors";

// Font variables
$font-family-base: 'Inter', sans-serif;

// Base typography

body {
    font-size: 0.85em;
    font-family: $font-family-base;
}

.text-light {
    font-family: $font-family-base;
    font-weight: 300;
}

.text-normal {
    font-family: $font-family-base;
    font-weight: 400;
    font-size: 1em;
    color: $color-scale-gray-300;
}
a:-webkit-any-link {
    text-decoration: none;
    color: map-get($color-scale-primary, 700);
}
.text-link {
    font-family: $font-family-base;
    color: map-get($color-scale-primary, 600);
    font-weight: 500;
    text-decoration: underline;

    &:active {
        text-decoration: none;
    }

    &:link {
        text-decoration: none;
    }


    &:visited {
        text-decoration: none;
    }


}

.text-bold {
    font-family: $font-family-base;
    font-weight: 600;
}

.text-bolder {
    font-family: $font-family-base;
    font-weight: 900;
}

.subtitle {
    font-size: 13px
}