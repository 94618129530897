@import "../../styles/main.scss";


.emp-auth-error-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-msg-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;


        h2 {
            margin: 0;
            font-size: 1.3em;
            color: $color-scale-gray-100;
        }
    }
}