@import "../../styles/main.scss";


.emp-invite-task-modal {
    position: relative;
    display: flex;
    flex-direction: column;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .view-header-wrapper {
        display: flex;
        align-items: center;
    }

    .back-btn {
        position: relative;
        left: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        gap: 3px;
        cursor: pointer;
        padding: 5px;
        box-sizing: border-box;
        width: fit-content;
        transition: all 300ms;

        &.with-text {
            padding-right: 12px;
        }

        &:hover {
            background-color: $color-scale-gray-100;
        }

        .btn-text {
            font-weight: 600;
            color: $color-scale-gray-300;
        }
    }

    .view-header {
        font-size: 1.1em;
        font-weight: 600;
        color: $color-scale-gray-100;

        &.shift-left {
            position: relative;
            left: -5px;
        }
    }

    .view-description {
        margin: 0;
        line-height: 1.4em;
        font-weight: 500;
        color: $color-scale-gray-300;
    }

    .loading-view {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        .emp-loader {
            padding: 12px 12px;
            background-color: $color-scale-gray-850;
            border-radius: 8px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            flex-direction: column;
            align-items: center;

            .label {
                font-weight: 600;
                font-size: 1.2em;
            }
        }
    }

    .task-rep-selection-view {
        .task-rep-section {
            display: flex;
            // gap: 10px;
            flex-wrap: wrap;

            .rep-card {
                position: relative;
                cursor: pointer;
                border-left: 1px solid $color-scale-gray-800;
                border-top: 1px solid $color-scale-gray-800;
                aspect-ratio: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: all 200ms;

                &.rep-card-border-bottom {
                    border-bottom: 1px solid $color-scale-gray-800;
                }

                &.rep-card-border-right {
                    border-right: 1px solid $color-scale-gray-800;
                }

                &:hover {
                    background-color: $color-scale-gray-900;
                }

                &.selected {
                    background-color: $color-scale-gray-900;

                    &:hover {
                        background-color: $color-scale-gray-800;
                    }
                }

                .knob {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    border: 2px solid $color-scale-gray-100;
                    transition: all 200ms;

                    &.selected {
                        border: 2px solid $color-scale-gray-200;
                    }

                    .knob-ellipsis {
                        width: 12px;
                        height: 12px;
                        border-radius: 10px;
                        background-color: $color-scale-primary-500;
                        opacity: 0;

                        &.selected {
                            opacity: 1;
                        }
                    }
                }

                .rep-name-lbl {
                    font-weight: 600;
                    color: $color-scale-gray-100;
                    display: block;
                }

                .negotiation-wrapper {
                    color: $color-scale-gray-300;
                    font-weight: 500;
                }

                .profile-photo {
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                    object-fit: cover;
                    border: 1px solid map-get($color-scale-gray, 800);
                    box-shadow: 0 0 0 0px transparent;
                    transition: 400ms all;

                    &.org-photo {
                        border-radius: 12px;
                    }

                    &.selected {
                        box-shadow: 0 0 0 8px $color-scale-primary-600;
                    }
                }

                .avatar {
                    display: flex;
                    width: 95px;
                    height: 95px;
                    border-radius: 120px;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 3em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }
            }
        }

    }

    .task-invitation-varied-offer-view {
        .rep-offer-section {
            display: flex;
            flex-direction: column;

            .rep-form-row {
                padding: 12px 5px 10px 5px;
                position: relative;
                cursor: pointer;
                border-top: 1px solid $color-scale-gray-800;
                display: flex;
                transition: all 200ms;
                gap: 8px;


                &:hover {
                    background-color: $color-scale-gray-850;
                }

                &.selected {
                    background-color: $color-scale-gray-850;

                    &:hover {
                        background-color: $color-scale-gray-800;
                    }
                }



                .profile-photo {
                    width: 45px;
                    height: 45px;
                    border-radius: 100px;
                    object-fit: cover;
                    border: 1px solid map-get($color-scale-gray, 800);
                    box-shadow: 0 0 0 0px transparent;
                    transition: 400ms all;

                    &.org-photo {
                        border-radius: 8px;
                    }

                    &.selected {
                        box-shadow: 0 0 0 8px $color-scale-primary-600;
                    }
                }

                .avatar {
                    display: flex;
                    width: 45px;
                    height: 45px;
                    border-radius: 120px;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 3em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }

                .details-section {
                    flex: 1;

                    .rep-name-section {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .rep-name-lbl {
                            font-weight: 600;
                            color: $color-scale-gray-100;
                            display: block;
                        }
                    }

                    .rate-section {
                        display: flex;

                        .rate-lbl {
                            font-weight: 600;
                            color: $color-scale-gray-300;
                            display: block;
                        }
                    }
                }

                .form-section {
                    width: 180px;
                    position: relative;
                    top: -4px;
                }
            }
        }

    }


    .profle-photo-section {
        padding-top: 20px;
        display: flex;
        justify-content: center;

        .creator-rep-wrapper {
            display: flex;
            align-items: center;
        }

        .profile-photo-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            .overlap {
                margin-left: -20px;
            }

            .profile-photo {
                width: 60px;
                height: 60px;
                border-radius: 100px;
                object-fit: cover;
                border: 2px solid map-get($color-scale-gray, 800);

                &.org-photo {
                    border-radius: 12px;
                }
            }

            .plus-label {
                top: -6px;
                position: relative;
                display: block;
                color: $color-scale-gray-200;
                font-weight: 500;
                font-size: 4em;
            }

            .empty-div {
                width: 60px;
                height: 60px;
                border-radius: 100px;
                display: flex;
                background-color: map-get($color-scale-gray , 850);
                box-shadow: 0 0 0 1px map-get($color-scale-gray , 800);
                align-items: center;
                justify-content: center;
                color: map-get($color-scale-gray , 500);
                font-weight: 600;
            }


            .avatar {
                display: flex;
                width: 95px;
                height: 95px;
                border-radius: 120px;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 3em;
                    color: map-get($color-scale-gray, 0);
                    font-weight: 700;
                }
            }
        }
    }




    .content-section {
        margin-top: 20px;

        .heading,
        .task-name-lbl {
            display: block;
            font-weight: 600;
            text-align: center;
            line-height: 1.4em;
            color: $color-scale-gray-100;
        }

        .task-name-lbl {
            font-size: 1.3em;
            color: $color-scale-gray-100;
        }

        .heading {
            font-size: 1em;
            color: $color-scale-gray-300;
            margin-top: 6px;
        }

        .description {
            text-align: center;
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }
    }

}