@import "../../styles/main.scss";

.emp-notification-record {
    position: relative;
    padding: 15px;
    border-bottom: 1px solid $color-scale-gray-800;
    background-color: $color-scale-gray-900;
    display: flex;
    cursor: pointer;
    transition: 200ms all;
    gap: 10px;

    &:hover {
        background-color: $color-scale-gray-850;
    }


    .loader {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .organisation-img-wrapper {
        position: relative;
        width: fit-content;
        height: fit-content;

        .small-profile-badge {
            position: absolute;
            border: 1px solid $color-scale-gray-0;
            bottom: 0;
            right: -5px;
            border-radius: 100px;

            &.badge-img {
                width: 24px;
                height: 24px;
                object-fit: cover;
            }

            &.badge-avatar {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                .initials {
                    font-size: 1em;
                    color: $color-scale-gray-0;
                    font-weight: 600;
                }
            }

        }
    }

    .user-img,
    .user-avatar,
    .organisation-img {
        width: 42x;
        height: 42px;
        min-width: 42px;
        min-height: 42px;
        border: 1px solid $color-scale-gray-800;
        object-fit: cover;
    }

    .user-img,
    .user-avatar {
        border-radius: 100px;
    }

    .user-avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        .initials {
            font-size: 1.2em;
            color: $color-scale-gray-0;
            font-weight: 600;
        }
    }

    .organisation-img {
        border-radius: 8px;
    }

    .notification-content {
        flex: 1;

        .content-wrapper {
            display: flex;
            gap: 6px;

            .notification-text-wrapper {
                display: flex;
                flex-direction: column;
            }

            .info-text {
                display: block;
                flex: 1;
                color: $color-scale-gray-300;
                line-height: 1.4em;
                word-break: break-word;
            }

            .content-label {
                display: block;
                font-size: 0.85em;
                font-weight: 700;
                flex: 1;
                color: $color-scale-gray-300;
            }

            .badge {
                width: 8px;
                max-width: 8px;
                min-width: 8px;
                height: 8px;
                max-height: 8px;
                min-height: 8px;
                border-radius: 5px;
                background-color: $color-scale-primary-200;
            }
        }

        .footer {
            display: flex;
            align-items: center;
            gap: 10px;

            .small-text {
                display: block;
                flex: 1;
                font-weight: 600;
                font-size: 0.9em;
                color: $color-scale-gray-400;
                line-height: 1.4em;
            }

            .button-wrapper {
                display: flex;
                gap: 10px;

                .disabled-text {
                    font-size: 0.9em;
                    font-weight: 600;
                    color: $color-scale-gray-300;
                }
            }
        }

    }


}