@import "../../styles/main.scss";


.emp-tiktok-view {
    .social-media-label {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 4px;

        h1 {
            font-size: 1.4em;
            color: $color-scale-gray-200;
        }
    }

    .social-media-info-section {
        display: flex;
        gap: 12px;

        .metrics-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            .metric-card-empty {
                flex: 1;
                height: 81px;
                border-radius: 12px;
            }

            .metric-card {
                @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);
                position: relative;
                flex: 1;
                align-items: center;
                padding: 14px 14px 18px 14px;

                .icon-wrapper {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                }

                .context-lbl {
                    display: block;
                    font-size: 0.9em;
                    font-weight: 700;
                    color: $color-scale-gray-300;
                    text-transform: uppercase;
                }

                .value-lbl {
                    display: block;
                    margin-top: 4px;
                    font-weight: 700;
                    font-size: 1.8em;
                    color: $color-scale-gray-100;
                }
            }
        }

    }

    .rates-section {
        @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);

        &.empty {
            padding: 20px;
        }

        .empty-state {
            display: flex;
            align-items: center;
            gap: 10px;

            .empty-img {
                height: 120px;
            }
        }


        .header-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            .title-wrapper {
                display: flex;
                gap: 8px;
            }

            @include mobile-view {
                flex-direction: column;
            }

            .rates-btn-wrapper {
                display: flex;
                gap: 6px;
            }
        }

        .rates-wrapper {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;


            .rate {
                display: flex;
                gap: 10px;

                @include mobile-view {
                    min-width: 150px;
                }
            }

            .divider {
                height: 40px;
                background-color: $color-scale-gray-800;
                width: 1px;
            }
        }
    }

    .external-rates-section {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .external-rate-card {
            padding: 12px 0;
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            flex-direction: column;

            .header-section {
                display: flex;
                gap: 8px;
                padding: 0 12px;

                .avatar,
                .pic {
                    width: 42px;
                    height: 42px;
                    border-radius: 36px;
                    border: $color-scale-gray-800 solid 1px;
                }

                .pic {
                    object-fit: cover;
                }

                .avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-weight: 700;
                        color: $color-scale-gray-100;
                        font-size: 1.2em;
                    }
                }

                .details-section {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    .name-lbl {
                        display: block;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }
                }
            }

            .rates-breakdown-section {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 0 12px;

                &.hidden {
                    padding: 20px 12px;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 1px solid $color-scale-gray-800;
                    border-top: 1px solid $color-scale-gray-800;
                    background-color: $color-scale-gray-900;

                    span {
                        text-align: center;
                        font-weight: 600;
                        color: $color-scale-gray-300;
                    }
                }
            }

            .btn-section {
                padding: 0 12px;
            }
        }

        .external-empty-rate-card {
            padding: 12px;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px inset;

            .label {
                display: block;
                font-weight: 700;
                color: $color-scale-gray-400;
            }
        }
    }

    .social-media-post-section {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;

        .empty-card {
            height: 316px;
            border-radius: 12px;
            box-sizing: border-box;

            &.mr-10px {
                margin-right: 10px;
            }

        }

        .ig-card {
            cursor: pointer;
            background: $cbz-card-gradient-md;
            border: 1px solid $color-scale-gray-800;
            border-radius: 12px;
            box-sizing: border-box;
            overflow: hidden;

            &.mr-10px {
                margin-right: 10px;
            }

            .img-wrapper {
                aspect-ratio: 1 / 1;
                width: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 400ms;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }



            .info-section {
                padding: 10px 12px 14px 10px;
                width: 100%;
                box-sizing: border-box;
                background: $cbz-card-gradient-sm;

                .metrics-wrapper {
                    display: flex;
                    gap: 20px;

                    .metric {
                        display: flex;
                        gap: 4px;

                        .metric-value {
                            color: $color-scale-gray-300;
                        }
                    }
                }

                .caption {
                    margin: 0;
                    margin-top: 10px;
                    color: $color-scale-gray-300;
                    line-height: 1.4em;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
            }
        }

    }
}