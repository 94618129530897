@import "../../../styles/main.scss";

.emp-creator-info-modal {
    position: relative;
    overflow: hidden;

    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.1em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 6px;
            display: block;
            color: $color-scale-gray-300;
        }
    }


    .description {
        margin: 0;
        margin-top: 6px;
        display: block;
        color: $color-scale-gray-300;

        .highlighted {
            font-weight: 600;
            color: $color-scale-primary-300;
        }
    }

    .error-message {
        margin: 0;
        margin-top: 6px;
        display: block;
        color: map-get($color-scale-red, 400);
    }

    .pill-wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 6px;
        column-gap: 6px;

        .pill-btn {
            cursor: pointer;
        }
    }


    .footer-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }


    .content-body {
        position: relative;
        display: flex;

        .swipe-slide {
            flex: 1;
            height: fit-content;

        }
    }
}