@import "../../../styles/main.scss";

.emp-agency-account-view {
    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    @include mobile-view {
        padding-left: 0px;
    }

    .view-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
            color: $color-scale-gray-100;

            @include mobile-view {
                font-size: 1.4em;
                font-weight: 600;
                margin: 0;
            }
        }

        .page-description {
            line-height: 1.4em;
            color: $color-scale-gray-300;
            margin-bottom: 0;

            @include mobile-view {
                margin-top: 6px;
            }
        }
    }



    .card-wrapper {
        margin-top: 20px;
        max-width: 800px;
        display: flex;
        gap: 20px;

        @include mobile-view {
            flex-direction: column;
            width: 100%;
        }

        .image-card {
            display: flex;
            flex-direction: column;
            align-items: center;

            img,
            .avatar {
                width: 120px;
                height: 120px;
                border-radius: 120px;

                @include mobile-view {
                    width: 80px;
                    height: 80px;
                    border-radius: 80px;
                }
            }

            img {
                border: 1px solid $color-scale-gray-800;
            }

            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 4em;
                    color: map-get($color-scale-gray, 0);
                    font-weight: 700;

                    @include mobile-view {
                        font-size: 3em;
                    }
                }
            }

            .logo-upload {
                color: $color-scale-primary-300;
                font-weight: 600;
                cursor: pointer;
                margin-top: 8px;
                transition: color 200ms;

                &:hover {
                    color: $color-scale-primary-400;
                }
            }

            .upload-hidden {
                display: none;
            }
        }

        .account-card-wrapper {
            flex: 1;

            .account-card {
                border-radius: 14px;
                padding: 14px;
                border: 1px solid $color-scale-gray-800;
                background-color: $color-scale-gray-950;
                display: flex;

                .info-wrapper {
                    margin-right: 12px;
                    flex: 1
                }

                &:not(:first-child) {
                    margin-top: 12px;
                }
            }
        }

    }

}