@import "../main.scss";

.emp-campaign-analytics-view {
  padding-top: 20px;

  .task-details-section {
    .task-name-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;

      @include tablet-view {
        flex-direction: column;
        align-items: flex-start;
      }

      .info-wrapper {
        flex: 1;
        display: flex;
        gap: 10px;
        justify-content: center;
        flex-direction: column;
      }
    }


  }

  .tabbed-option-wrapper {
    padding: 5px;
    width: fit-content;
    border-radius: 10px;
    background-color: $color-scale-gray-100;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px inset;
    display: flex;
    gap: 4px;

    .tabbed-option {
      cursor: pointer;
      height: 24px;
      padding: 0 10px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      &.active {
        background-color: $color-scale-gray-0;
      }


      span {
        font-weight: 600;
        color: $color-scale-gray-400;
        transition: color 200ms;

        &:hover {
          color: $color-scale-gray-600;
        }
      }

      &.active {
        span {
          color: $color-scale-gray-600;
        }
      }

    }
  }

  h2 {
    margin: 0;
    font-size: 1.5em;
    font-weight: 700;
    color: $color-scale-gray-100;
  }

  .metrics-section {
    .metrics-tile-wrapper {
      width: 100%;
      gap: 10px;
      display: flex;
      flex-wrap: wrap;



      .metric-tile {
        flex: 1;
        min-width: 190px;
        min-height: 70px;
        display: flex;
        gap: 6px;
        flex-direction: column;
        padding: 10px 15px;
        border-radius: 10px;
        border: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;
        align-items: flex-start;

        .details-wrapper {
          flex: 1;
          display: flex;
          width: 100%;
          gap: 10px;
          justify-content: space-between;

          .metric-value {
            font-size: 2em;
            font-weight: 700;
            color: $color-scale-gray-100;
          }

          .empty-metric-value {
            height: 34px;
            border-radius: 8px;
            width: 60%;
          }
        }

        .metric-header {
          font-weight: 700;
          text-transform: uppercase;
          color: $color-scale-gray-400;
        }

      }



    }
  }

  .table-details-section {

    .form-section {
      display: flex;
      gap: 8px;
      align-items: flex-end;

      .button-control {
        margin-bottom: 10px;
      }
    }
  }

}