@import "../../styles/main.scss";


.emp-x-view {
  .social-media-label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 4px;

    h1 {
      font-size: 1.4em;
      color: $color-scale-gray-200;
    }
  }

  .social-media-info-section {
    display: flex;
    gap: 12px;

    .metrics-section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      .metric-card-empty {
        flex: 1;
        height: 81px;
        border-radius: 12px;
        min-width: 160px;
      }

      .metric-card {
        @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);
        position: relative;
        flex: 1;
        align-items: center;
        padding: 14px 14px 18px 14px;
        min-width: 160px;

        .icon-wrapper {
          position: absolute;
          top: 15px;
          right: 15px;
        }

        .context-lbl {
          display: block;
          font-size: 0.9em;
          font-weight: 700;
          color: $color-scale-gray-300;
          text-transform: uppercase;
        }

        .value-lbl {
          display: block;
          margin-top: 4px;
          font-weight: 700;
          font-size: 1.8em;
          color: $color-scale-gray-100;

          &.small {
            font-size: 1.4em !important;
          }
        }
      }
    }

  }

  .rates-section {
    @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);

    &.empty {
      padding: 20px;
    }

    .empty-state {
      display: flex;
      align-items: center;
      gap: 10px;

      .empty-img {
        height: 120px;
      }
    }


    .header-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .title-wrapper {
        display: flex;
        gap: 8px;
      }

      @include mobile-view {
        flex-direction: column;
      }

      .rates-btn-wrapper {
        display: flex;
        gap: 6px;
      }
    }

    .rates-wrapper {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;


      .rate {
        display: flex;
        gap: 10px;

        @include mobile-view {
          min-width: 150px;
        }
      }

      .divider {
        height: 40px;
        background-color: $color-scale-gray-800;
        width: 1px;
      }
    }
  }

  .external-rates-section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 10px;

    .external-rate-card {
      padding: 12px 0;
      background-color: $color-scale-gray-900;
      border: 1px solid $color-scale-gray-800;
      display: flex;
      flex-direction: column;

      .header-section {
        display: flex;
        gap: 8px;
        padding: 0 12px;

        .avatar,
        .pic {
          width: 42px;
          height: 42px;
          border-radius: 36px;
          border: $color-scale-gray-800 solid 1px;
        }

        .pic {
          object-fit: cover;
        }

        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-weight: 700;
            color: $color-scale-gray-100;
            font-size: 1.2em;
          }
        }

        .details-section {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .name-lbl {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
          }
        }
      }

      .rates-breakdown-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 12px;

        &.hidden {
          padding: 20px 12px;
          flex: 1;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid $color-scale-gray-800;
          border-top: 1px solid $color-scale-gray-800;
          background-color: $color-scale-gray-900;

          span {
            text-align: center;
            font-weight: 600;
            color: $color-scale-gray-300;
          }
        }
      }

      .btn-section {
        padding: 0 12px;
      }
    }

    .external-empty-rate-card {
      padding: 12px;
      box-sizing: border-box;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-scale-gray-900;
      border: 1px solid $color-scale-gray-800;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px inset;

      .label {
        display: block;
        font-weight: 700;
        color: $color-scale-gray-400;
      }
    }
  }


  .social-media-post-section {
    display: flex;
    gap: 10px;

    @include tablet-view {
      flex-direction: column-reverse;
    }

    .empty-tweet-column,
    .tweets-column {
      max-width: 600px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include tablet-view {
        max-width: initial;
      }
    }

    .empty-tweet-column {
      position: relative;

      .not-updated-placeholder,
      .no-tweets-placeholder {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $color-scale-gray-900 100%);
        border-radius: 12px;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 20px;

        .placeholder-content {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          max-width: 350px;
          width: 100%;

          
          img {
            width: 100px;
            height: auto;
          }

          .overlay-header {
            font-size: 1.2em;
            color: $color-scale-gray-100;
            font-weight: 600;
          }

        }
      }

      .not-updated-placeholder {}

      .empty-tweet {
        border: 1px solid $color-scale-gray-800;
        border-radius: 12px;
        height: 140px;
        width: 100%;
      }
    }

    .empty-profile-column {
      height: 400px;
    }

    .profile-column {
      @include card-border-conic-gradient-mixin(95deg, 12px, 47%, 53%);

      height: fit-content;
      padding-bottom: 30px;
      position: sticky;
      top: 10px;
      overflow: hidden;
      background: $cbz-card-gradient-md;
      border-radius: 12px;

      .banner {
        inset: 1px;
        height: 160px;
        width: 100%;
        background-color: $color-scale-gray-800;
        position: relative;
        background-size: cover;
        background-position: center;

        .profile-details {
          inset: 1px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 15px;
          display: flex;
          align-items: flex-end;
          gap: 8px;

          .profile-picture {
            width: 46px;
            height: 46px;
            border-radius: 50px;
            border: 1px solid $color-scale-gray-800;
          }

          .profile-info {
            .name-wrapper {
              display: flex;
              align-items: center;
              gap: 4px;

              .name-lbl {
                display: block;
                font-weight: 700;
                font-size: 1.3em;
                color: $color-scale-gray-100;
              }

              .verified-mark {
                width: 18px;
                height: auto;
              }
            }



            .handle-lbl {
              display: block;
              margin-top: 4px;
              font-weight: 500;
              font-size: 1.1em;
              color: $color-scale-gray-300;
            }
          }
        }
      }

      .profile-description {
        padding: 0 15px;
        margin: 0;
        margin-top: 16px;
        color: $color-scale-gray-300;
        line-height: 1.4em;
      }

      .profile-metadata {
        margin-top: 10px;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .metadata {
          display: flex;
          align-items: center;
          gap: 2px;
        }

        .metadata-text {
          color: $color-scale-gray-300;
          font-weight: 500;
        }

        .metadata-link {
          color: $color-scale-primary-500;
          text-decoration: underline;
          font-weight: 600;
        }
      }
    }


    .profile-column,
    .empty-profile-column {
      border-radius: 12px;
      width: 350px;

      @include tablet-view {
        width: 100%;
        position: static
      }
    }


    .x-card {
      background-color: $color-scale-gray-800;
      border: 1px solid $color-scale-gray-800;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .profile-wrapper {
        padding: 15px;
        display: flex;
        gap: 10px;

        .picture {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }

        .tweet-details-wrapper {
          flex: 1;
          overflow: hidden;

          .profile-name-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 6px;

            .profile-name-wrapper-2 {
              display: flex;
              gap: 3px;
              align-items: center;

              .verified-mark {
                width: 14px;
                height: auto;
              }

              .profile-name {
                color: $color-scale-gray-100;
                font-weight: 700;
              }
            }




            .profile-handle {
              font-weight: 500;
              color: $color-scale-gray-300;
            }

            .date-wrapper {
              flex: 1;
              display: flex;
              justify-content: flex-end;

              span {
                font-weight: 500;
                color: $color-scale-gray-300;
              }
            }
          }

          .caption {
            margin: 0;
            margin-top: 10px;
            color: $color-scale-gray-300;
            line-height: 1.4em;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }

          .image-grid {
            margin-top: 12px;
            display: grid;
            grid-gap: 4px;
            width: 100%;
            aspect-ratio: 2;
            border-radius: 12px;
            overflow: hidden;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            border: 1px solid $color-scale-gray-800;

            .media-wrapper {
              position: relative;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .play-video-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-circle {
                  cursor: pointer;
                  background-color: rgba(255, 255, 255, 0.1);
                  width: 50px;
                  height: 50px;
                  border-radius: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.items-1 {
                &:nth-child(1) {
                  grid-column: span 2;
                  grid-row: span 2;
                }
              }

              &.items-2 {

                &:nth-child(1),
                &:nth-child(2) {
                  grid-row: span 2;
                }
              }

              &.items-3 {
                &:nth-child(1) {
                  grid-row: span 2;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

          }


        }
      }

      .info-section {
        padding: 10px 12px 14px 60px;
        width: 100%;
        box-sizing: border-box;
        border-top: 1px solid $color-scale-gray-800;
        background-color: map-get($color-scale-gray, 900);

        .metrics-wrapper {
          width: 100%;
          display: flex;
          gap: 20px;

          .metric {
            display: flex;
            gap: 4px;

            .metric-value {
              color: $color-scale-gray-100;
            }
          }

          .link-to-post {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            .link {
              cursor: pointer;
              display: flex;
              gap: 6px;
              align-items: center;

            }
          }

        }
      }
    }
  }

}