@import "../../styles/main.scss";

.emp-create-campaign-modal {

    .campaign-modal-body {
        padding: 0 20px;
    }

    .stepper-section {
        width: 100%;
        display: flex;
        gap: 12px;

        .step {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .step-header {
                display: block;
                font-size: 1.2em;
                font-weight: 700;
            }

            .step-description {
                display: block;
                font-weight: 500;
            }

            .bar {
                width: 100%;
                height: 4px;
                border-radius: 20px;
            }

            .stepper-elem {
                background-color: $color-scale-gray-200;

                &.active {
                    background-color: $color-scale-primary-400;
                }
            }

            .stepper-text-elem {
                color: $color-scale-gray-200;

                &.active {
                    color: $color-scale-primary-400;
                }
            }
        }
    }

    .form-section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .split-wrapper {
            display: flex;
            gap: 10px;

            .split-control {
                flex: 1;
            }

            .date-range-wrapper {
                max-width: 230px;
            }
        }
    }

    .step-2 {
        .uploader-section {
            .upload-label {
                font-size: 1em;
                font-weight: 500;
                color: map-get($color-scale-gray, 100);
                margin-bottom: 8px;

                .required {
                    display: inline;
                    color: red;
                }
            }

            .image-upload-zone,
            .file-upload-zone {
                text-align: center;
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid $color-scale-gray-800;
                box-sizing: border-box;
                flex-direction: column;
                cursor: pointer;
                transition: all 200ms;

                &:hover {
                    background-color: $color-scale-gray-850;

                    &>.title {
                        color: map-get($color-scale-primary, 400);
                    }
                }

                .title {
                    display: block;
                    margin-top: 4px;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                    transition: all 200ms;
                }

                .specs {
                    margin: 0;
                    margin-top: 8px;
                    color: $color-scale-gray-300;
                }
            }

            .image-upload-zone {


                .image-description {
                    display: block;
                    margin: 0;
                    color: $color-scale-gray-300;
                }

                .image-upload-wrapper {
                    position: relative;
                    overflow: hidden;
                    border-radius: 12px;
                    height: 140px;

                    &:hover {
                        .image-hover-box {
                            opacity: 1;
                        }

                    }

                    .image-hover-box {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        transition: opacity 200ms;

                        gap: 6px;

                        span {
                            color: white;
                            font-weight: 600;
                        }
                    }

                    .campaign-image,
                    .image-upload {
                        aspect-ratio: 1.667;
                        height: 100%;


                    }

                    .campaign-image {
                        object-fit: cover;
                    }

                    .image-upload {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 75px;
                            height: 75px;
                            border-radius: 80px;
                        }
                    }

                }


            }

            .upload-hidden {
                display: none;
            }
        }
    }

    .button-wrapper {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: flex-end;
    }
}


.picker-label {
    font-size: 1em;
    font-weight: 500;
    color: map-get($color-scale-gray, 750);
    margin-bottom: 8px;

    .required {
        display: inline;
        color: red;
    }
}