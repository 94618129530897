@import "../../styles/main.scss";


.emp-rate-card-accept-confirmation-modal {
    display: flex;
    flex-direction: column;


    .profile-photo {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        object-fit: cover;
        border: 1px solid map-get($color-scale-gray, 150)
    }


    .content-section {

        .heading {
            font-size: 1.3em;
            color: $color-scale-gray-100;
        }

        .description {
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;

            .highlighted {
                font-weight: 600;
                color:  $color-scale-primary-500;
            }
        }

        .button-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
        }
    }

}