@import "../../styles/main.scss";

.emp-external-role-selection-page {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile-view {
        height: 100%;
        box-sizing: border-box;
    }


    .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-info-section {
            position: relative;

            .menu-wrapper {
                display: flex;
                background: $cbz-card-gradient-md;
                border: 1px solid $color-scale-gray-800;
                flex-direction: column;
                position: absolute;
                bottom: -6px;
                transform: translateY(100%);
                right: 0px;
                border-radius: 8px;
                max-width: 100%;
                width: fit-content;
                overflow: hidden;

                ul {
                    background: $cbz-card-gradient-md;
                    list-style-type: none;
                    padding: 4px 0;
                    margin: 0;
                    width: 100%;

                    .option {
                        cursor: pointer;
                        display: block;
                        padding: 12px 18px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        color: $color-scale-red-500;
                        transition: all 150ms;

                        &:hover {
                            background-color: $color-scale-gray-850;
                        }
                    }
                }

            }

            .user-info-wrapper {
                position: relative;
                display: flex;
                border-radius: 8px;
                border: 1px solid $color-scale-gray-800;
                padding: 8px 12px;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                transition: all 150ms;

                &:hover {
                    background-color: $color-scale-gray-900;
                }



                .profile-pic {
                    display: flex;
                    align-items: center;
                    object-fit: cover;
                    height: 34px;
                    width: 34px;
                    border: 1px solid $color-scale-gray-750;
                    border-radius: 50px;

                    .initials {
                        font-size: 2em;
                        color: $color-scale-gray-0;
                        font-weight: 700;
                    }
                }

                .profile-name {
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }
            }
        }

        img {
            height: 32px;
        }

        h1 {
            margin-top: 25px;
            font-size: 1.3em;
            color: $color-scale-gray-100;
            font-weight: 600;
        }
    }


    .platform-role-wrapper {
        margin: 0;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;

        @include mobile-view {
            padding: 0 20px;
        }


        .platform-role-card {
            @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
            width: 240px;
            background: $cbz-card-gradient-md;
            border-radius: 12px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            transition: all 200ms;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
            cursor: pointer;


            @include mobile-view {
                flex-direction: row;
                text-align: left;
                gap: 12px;
                width: 100%;
            }

            &:hover {
                transform: translateY(-5px);
            }

            .platform-role-img {
                border-radius: 8px;
                width: 80px;
                height: 80px;
                object-fit: cover;
                background-color: $color-scale-gray-900;
                border: 1px solid $color-scale-gray-850;
            }

            .platform-role-lbl {
                margin: 0;
                color: $color-scale-gray-150;
                font-weight: 700;
                margin-top: 12px;

                @include mobile-view {
                    margin-top: 0;
                }
            }

            .platform-role-description {
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300;
            }
        }
    }
}