@import "../../styles/main.scss";



.emp-brand-creator-invitation-page {
    position: relative;
    flex: 1;
    background: linear-gradient(132.49deg, rgba(232, 237, 255, 0.192)7.07%, #FFFFFF 100.39%);
    display: flex;
    align-items: center;


    .content {
        width: 100%;
        max-width: 1240px;
        padding: 15px 20px 30px 20px;
        box-sizing: border-box;

        @include tablet-view {
            padding: 15px 10px;
        }

        .header-wrapper-flex {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 15px;

            .page-header {
                margin: 0;
                font-size: 1.6em !important;
                color: $color-scale-gray-100;
            }
        }


        .brand-invitation-panel {
            @include card-border-conic-gradient-mixin(80deg, 20px, 49%, 51%);
            margin-bottom: 16px;
            padding: 15px;
            display: flex;
            align-items: center;

            .profile-photo-wrapper {
                display: flex;

                .creator-profile {
                    position: relative;
                    left: -12px;
                }

                .profile-photo {
                    width: 50px;
                    height: 50px;
                    border-radius: 100px;
                    object-fit: cover;
                    border: 1px solid map-get($color-scale-gray, 800);

                    &.org-photo {
                        border-radius: 12px;
                    }
                }


                .empty-div {
                    width: 50px;
                    height: 50px;
                    border-radius: 100px;
                    display: flex;
                    background-color: map-get($color-scale-gray , 50);
                    box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
                    align-items: center;
                    justify-content: center;
                    color: map-get($color-scale-gray , 500);
                    font-weight: 600;
                }


                .avatar {
                    display: flex;
                    width: 50px;
                    height: 50px;
                    border-radius: 120px;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 3em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }
            }

            .invitation-section {
                flex: 1;

                .card-header {
                    display: block;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                    font-size: 1.2em;
                }

                .metrics-section {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .pipe {
                        height: 18px;
                        width: 1px;
                        background-color: $color-scale-gray-200;
                    }

                    .horizontal-content {
                        display: flex;
                        gap: 4px;

                        .content-header {
                            font-weight: 600;
                            color: $color-scale-gray-400;
                        }

                        .content-value {
                            font-weight: 500;
                            color: $color-scale-gray-100;
                        }
                    }
                }

            }
        }

        .profile-info-wrapper {
            display: flex;
            gap: 16px;

            @include tablet-view {
                flex-direction: column;
            }


            .profile-section {
                width: 240px;
                position: sticky;
                top: 10px;
                height: fit-content;
                background: $cbz-card-gradient-sm;
                @include card-border-conic-gradient-mixin(90deg, 20px, 48%, 54%);

                @include tablet-view {
                    width: 100%;
                }

                .banner {
                    height: 80px;
                    width: 100%;
                    background-color: #212121;
                }

                .pic-section {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -30px;
                    padding: 0 20px 30px 20px;
                    box-sizing: border-box;
                    text-align: center;

                    @include tablet-view {
                        align-items: flex-start;
                    }

                    @include mobile-view {
                        margin-top: -42.5px;
                    }

                    .avatar,
                    .profile-pic {
                        width: 65px;
                        height: 65px;
                        border-radius: 70px;
                        border: 1px solid map-get($color-scale-gray, 150);

                        @include mobile-view {
                            width: 85px;
                            height: 85px;
                            border-radius: 85px;
                        }
                    }

                    .avatar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.8em;
                        font-weight: 700;
                        color: map-get($color-scale-gray, 0);

                        @include mobile-view {
                            font-size: 2.2em;
                        }
                    }

                    .profile-img {
                        object-fit: cover;
                    }

                    .creator-name-lbl {
                        display: block;
                        font-size: 1.2em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }

                    .creator-email-lbl {
                        display: block;
                        font-size: 1em;
                        font-weight: 500;
                        color: $color-scale-gray-300;
                    }
                }

                .info-section {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    width: 100%;
                    padding: 0 20px;
                    box-sizing: border-box;

                    @include tablet-view {
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 12px;
                    }

                    .creator-info-wrapper {
                        width: 100%;
                        display: flex;
                        gap: 8px;

                        @include tablet-view {
                            width: fit-content;
                            flex: initial;
                        }

                        .creator-gender,
                        .creator-age {
                            flex: 1;
                        }
                    }

                    .interest-wrapper,
                    .service-wrapper {
                        @include tablet-view {
                            flex-basis: 100%;
                        }

                    }

                    .country-wrapper {
                        display: flex;
                        gap: 4px;

                        .img-flag {
                            height: 16px;
                            border: solid 1px map-get($color-scale-gray, 150);
                        }
                    }

                    .platform-wrapper {
                        display: flex;
                        gap: 8px;
                    }

                    .agency-wrapper {
                        margin-top: 12px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .agency-card {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        .agency-logo {
                            border-radius: 8px;
                            width: 32px;
                            height: 32px;
                            object-fit: cover;
                            border: 1px solid map-get($color-scale-gray, 100);
                        }

                        .agency-content {
                            flex: 1;

                            .name-wrapper {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .name-lbl {
                                    flex: 1;
                                    display: block;
                                    font-weight: 600;
                                    color: $color-scale-gray-300;
                                }
                            }
                        }
                    }
                }

                .pills-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 6px;
                }
            }




            .social-media-section {
                flex: 1;


                // choose social media section
                .social-media-selector-card {
                    display: flex;
                    overflow-x: auto;
                    align-items: center;
                    padding: 8px;


                    .social-media-option {
                        display: flex;
                        align-items: center;
                        padding: 8px 14px 8px 10px;
                        border-radius: 8px;
                        cursor: pointer;

                        &.active {
                            background-color: $color-scale-gray-850;
                        }

                        .picture-section {
                            position: relative;
                            width: 42px;
                            height: 42px;

                            img {
                                width: 42px;
                                height: 42px;
                                border-radius: 45px;
                                border: 1px solid $color-scale-gray-800;
                            }

                            .social-media-bubble {
                                position: absolute;
                                bottom: -1px;
                                right: -1px;
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                background-color: $color-scale-gray-750;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0px 0px 0px 1px $color-scale-gray-800;
                            }
                        }

                        .info-section {
                            flex: 1;
                            margin-left: 10px;

                            .handle-name-lbl {
                                display: block;
                                white-space: nowrap;
                                font-weight: 600;
                                color: $color-scale-gray-100;
                            }

                            .metric-lbl {
                                margin-top: 2px;
                                display: block;
                                white-space: nowrap;
                                font-size: 0.9em;
                                color: $color-scale-gray-300;
                            }
                        }
                    }

                    .empty-option {
                        display: flex;
                        align-items: center;
                        padding: 8px 14px 8px 10px;
                        border-radius: 8px;

                        .picture-section {
                            position: relative;
                            width: 42px;
                            height: 42px;

                            .placeholder {
                                width: 42px;
                                height: 42px;
                                border-radius: 45px;
                                background-color: map-get($color-scale-gray, 150);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                        }

                        .info-section {
                            flex: 1;
                            margin-left: 10px;

                            .handle-name-lbl {
                                display: block;
                                font-weight: 600;
                                color: map-get($color-scale-gray, 250);
                            }

                            .metric-lbl {
                                margin-top: 2px;
                                display: block;
                                white-space: nowrap;
                                font-size: 0.9em;
                                color: map-get($color-scale-gray, 250);
                            }
                        }
                    }
                }
            }
        }
    }


}