@import "../../../styles/main.scss";

.emp-creator-social-media-step {

    .header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            height: 30px;
        }

        .description {
            text-align: center;
            color: $color-scale-gray-300;
            line-height: 1.4em;
            margin: 0;
        }
    }

    .platform-card {
        display: flex;
        border-radius: 12px;
        border: 1px solid $color-scale-gray-800;
        background: $cbz-card-gradient-sm;
        padding: 8px;


        .left-elem {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .platform-logo {
            background-color: $color-scale-gray-800;
            width: 45px;
            min-width: 45px;
            min-height: 45px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.empty-state {
                background-color: $color-scale-gray-800;
            }
        }



        .social-media-img-wrapper {
            position: relative;
            display: flex;

            .social-media-logo-placeholder {
                position: absolute;
                right: -2px;
                bottom: -2px;
                width: 20px;
                height: 20px;
                border: 1.5px solid map-get($color-scale-gray, 100);
                border-radius: 50px;
                background-color: #1778F2;
                display: flex;
                align-items: center;
                justify-content: center;
            }


            .social-media-image {
                border: 1px solid $color-scale-gray-800;
                object-fit: contain;
                width: 45px;
                height: 45px;
                border-radius: 8px;
            }

        }


        .content {
            margin-left: 12px;

            .status-lbl {
                display: block;
                font-weight: 600;
                color: $color-scale-gray-150;
            }

            .action-lbl {
                margin-top: 4px;
                display: block;
                color: $color-scale-gray-300;
            }
        }
    }

    .intro-para {
        margin: 0;
        color: $color-scale-gray-300;
    }

    .form-wrapper {
        width: 400px;
        padding: 12px 0 20px 0;
        border-radius: 20px;
        box-sizing: border-box;

        @include tablet-view {
            width: 100%;
        }

        .body-section {
            padding: 20px 20px 0px 20px;

        }

        .header-section {
            padding: 0 20px 8px 20px;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            gap: 12px;
            align-items: center;

            .step-lbl {
                font-weight: 600;
                font-size: 1.1em;
                color: $color-scale-gray-100;
                display: block;
            }
        }

        .btn-section {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btn-wrapper {
                display: flex;
                width: fit-content;
                justify-content: flex-end;
                gap: 10px;

                @include mobile-view {
                    width: 100%;
                }
            }
        }

    }
}