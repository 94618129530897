@import "../../styles/main.scss";


.emp-home-page {
    width: 100%;

    .card-header-lbl {
        font-size: 1.2em;
        display: block;
        font-weight: 600;
        color: $color-scale-gray-100;
        margin: 0;
    }

    .emp-home-page-intro-banner {
        @include card-border-conic-gradient-mixin(95deg, 0 0 12px 12px, 49%, 51%, false);
        position: relative;
        padding: 0 20px;
        background-color: #000000;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        aspect-ratio: 9/1;
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: flex-end;


        .banner-shading {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, rgba(10, 10, 10, 0.7) 100%);
            height: 50px;
            border-radius: 0 0 12px 12px;

        }

        .company-info-wrapper {
            position: relative;
            bottom: -24px;
            display: flex;
            gap: 10px;

            .organistion-logo {
                border: 2px solid $color-scale-gray-100;
                border-radius: 12px;
                width: 80px;
                height: 80px;
            }

            .intro-lbl {
                display: block;
                font-size: 1.8em;
                font-weight: 700;
                color: $color-scale-gray-100;
            }
        }

    }

    .content-wrapper {
        width: 100%;

    }

    .dashboard-section {
        margin-top: 40px;
        display: flex;
        gap: 10px;

        @include tablet-view {
            flex-direction: column;
        }

        .left-col {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .metrics-tile-wrapper {
                width: 100%;
                gap: 10px;
                display: flex;
                flex-wrap: wrap;

                .metric-tile {
                    @include card-border-conic-gradient-mixin(95deg, 10px, 47%, 53%);
                    flex: 1;
                    min-width: 190px;
                    min-height: 70px;
                    height: fit-content;
                    display: flex;
                    gap: 6px;
                    flex-direction: column;
                    padding: 10px 15px;
                    border-radius: 10px;
                    background: $cbz-card-gradient-md;
                    align-items: flex-start;

                    .details-wrapper {
                        flex: 1;
                        display: flex;
                        width: 100%;
                        gap: 10px;
                        justify-content: space-between;

                        .metric-value {
                            font-size: 1.7em;
                            font-weight: 700;
                            color: $color-scale-gray-100;
                        }
                    }

                    .metric-header {
                        font-weight: 700;
                        text-transform: uppercase;
                        color: $color-scale-gray-300;
                    }

                }
            }

            .agency-map-chart {
                @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);

                min-width: 1px;
                overflow: hidden;
                width: 100%;
            }

            .active-tasks-card {
                @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);

                .header-section {
                    padding: 15px;
                }

                .table-overlay-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    img {
                        height: 60px;
                    }

                    .header {
                        margin-top: 8px;
                        margin-bottom: 0px;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                        font-size: 1.4em;
                    }

                    .description {
                        margin-top: 8px;
                        line-height: 1.4em;
                        color: $color-scale-gray-400;
                    }
                }

                .task-name-cell {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .campaign-img {
                        height: 30px;
                        width: auto;
                        border-radius: 6px;
                        border: 1px solid $color-scale-gray-800;
                    }

                    .task-platform-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 2.5px;


                        .task-type-small {
                            font-size: 0.9em;
                            color: $color-scale-gray-400;
                            font-weight: 600;
                            display: block;
                        }
                    }

                }

                .raw-text {
                    color: $color-scale-gray-300;
                    font-weight: 500;
                }

                .creators-cell {
                    display: flex;
                    align-items: center;

                    .profile-img {
                        width: 30px;
                        margin-left: -10px;
                        height: 30px;
                        border-radius: 30px;
                        border: 1px solid $color-scale-gray-800;
                        object-fit: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .initials {
                            font-size: 0.9em;
                            font-weight: 600;
                            color: $color-scale-gray-0;
                        }
                    }
                }
            }
        }

        .right-col {
            .completion-cards-card {
                @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
                width: 320px;
                overflow: hidden;

                .completion-section {
                    padding: 15px 12px 12px 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .details-section {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .progress-wrapper {
                            width: fit-content;
                            position: relative;

                            .progress-value-wrapper {
                                position: absolute;
                                top: -2px;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .value-lbl {
                                    font-weight: 800;
                                    font-size: 1em;
                                    color: $color-scale-gray-300;
                                }
                            }

                            .circle-container {
                                width: 60px;
                                height: 60px;
                                transform: rotate(-90deg);
                            }

                            .circle-container__background {
                                fill: none;
                                stroke: gainsboro;
                                strokeWidth: 5px;
                            }

                            .circle-container__progress {
                                fill: none;
                                stroke-linecap: round;
                                stroke: map-get($color-scale-primary, 500);
                                stroke-dasharray: 100 100;
                                strokeWidth: 5px;
                                // For animations...
                                transition: stroke-dashoffset 1s ease-in-out;
                                will-change: transform;
                            }
                        }

                        .info-wrapper {
                            position: relative;
                            top: -4px;



                            .description-lbl {
                                display: block;
                                color: $color-scale-gray-300;
                            }
                        }
                    }

                }

                .completion-cards-section {
                    width: 100%;
                    display: flex;
                    flex-direction: column;


                    .completion-card {
                        position: relative;
                        background: $color-scale-gray-900;
                        border-top: 1px solid $color-scale-gray-800;
                        box-sizing: border-box;
                        padding: 10px 15px;
                        display: flex;
                        flex-direction: column;
                        transition: all 200ms;
                        cursor: pointer;

                        .muted {
                            position: absolute;
                            background-color: rgba(0, 0, 0, 0.6);
                            top: 0;
                            left: 0;

                            height: 100%;
                            width: 100%;
                        }

                        .task-wrapper {
                            display: flex;
                            gap: 12px;

                            .icon-section {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: linear-gradient(180deg, #000000 0%, #2E3255 100%);
                                width: 40px;
                                height: 40px;
                                min-width: 40px;
                                min-height: 40px;
                                border-radius: 4px;

                                img {
                                    height: 35px;
                                    aspect-ratio: 1;
                                }

                                span {
                                    font-size: 20px;
                                }
                            }

                            .info-section {
                                .header-lbl {
                                    display: block;
                                    font-weight: 600;
                                    color: $color-scale-gray-100;
                                }

                                .description-lbl {
                                    margin-top: 2px;
                                    display: block;
                                    color: $color-scale-gray-300;
                                }

                                .completed-lbl {
                                    display: block;
                                    font-weight: 600;
                                    color: $color-scale-gray-300;
                                }
                            }
                        }

                        .actions-wrapper {
                            margin-left: 52px;
                        }
                    }
                }
            }


            .recent-creators-card {
                width: 320px;
                @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);

                .header-section {
                    padding: 15px;
                }

                .creator-record-row {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .profile {
                        width: 28px;
                        height: 28px;
                        border-radius: 40px;
                        border: 1px solid $color-scale-gray-800;

                        &img {
                            object-fit: cover;
                        }

                        &.avatar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .initials {
                            font-size: 1em;
                            font-weight: 700;
                            color: $color-scale-gray-0;
                        }
                    }
                }
            }
        }
    }
}