@import "../../../styles/main.scss";

.emp-seller-exclusive-task-summary-view {
    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }

    .task-details-section {
        padding-bottom: 25px;
        border-bottom: 1px solid $color-scale-gray-800;

        .deliverable-section {
            padding: 25px 0;
            border-bottom: 1px solid $color-scale-gray-800;


            .title-wrapper {
                display: flex;
                gap: 10px;
                align-items: center;

                .link-wrapper {
                    position: relative;
                    top: -2px;
                }
            }

            .collapsible-section {
                overflow: hidden;
                transition: height 300ms;

                .deliverable-wrapper {

                    padding: 20px;
                    box-sizing: border-box;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    background-color: $color-scale-gray-900;
                    border-radius: 12px;
                    margin-top: 10px;

                    .deliverable-indicator {
                        display: block;
                        font-size: 0.9em;
                        font-weight: 700;
                        color: #7E8591;
                    }

                    .deliverable-name-lbl {
                        margin: 0;
                        display: block;
                        padding-top: 8px;
                        box-sizing: border-box;
                        font-size: 1em;
                        font-weight: 700;
                        color: $color-scale-gray-100
                    }

                    .deliverable-description {
                        display: block;
                        padding-top: 8px;
                        box-sizing: border-box;
                        margin: 0;
                        line-height: 1.4em;
                        color: $color-scale-gray-300
                    }
                }

            }


        }

        .task-name-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .info-wrapper {
                flex: 1;
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .task-description {
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300;
            word-break: break-word;
        }

        .task-details {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .profile-photo {
                width: 20px;
                height: 20px;
                border-radius: 100px;
                object-fit: cover;
                border: 1px solid map-get($color-scale-gray, 150);

                &.org-photo {
                    border-radius: 12px;
                }
            }

            .avatar {
                display: flex;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 0.8em;
                    color: map-get($color-scale-gray, 0);
                    font-weight: 700;
                }
            }

            .country-wrapper {
                display: flex;
                gap: 2px;
            }

            .extra,
            .country {
                width: 18px;
                height: 18px;
                border: 1px solid $color-scale-gray-800;
                border-radius: 18px;
            }

            .extra {
                background-color: $color-scale-gray-900;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                color: $color-scale-gray-300;
            }
        }
    }

    .deliverable-section {
        padding: 25px 0;
        border-bottom: 1px solid $color-scale-gray-800;


        .title-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .link-wrapper {
                position: relative;
                top: -2px;
            }
        }

        .collapsible-section {
            overflow: hidden;
            transition: height 300ms;

            .deliverable-wrapper {

                padding: 20px;
                box-sizing: border-box;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                background-color: $color-scale-gray-900;
                border-radius: 12px;
                margin-top: 10px;

                .deliverable-indicator {
                    display: block;
                    font-size: 0.9em;
                    font-weight: 700;
                    color: #7E8591;
                }

                .deliverable-name-lbl {
                    margin: 0;
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    font-size: 1em;
                    font-weight: 700;
                    color: $color-scale-gray-100
                }

                .deliverable-description {
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    margin: 0;
                    line-height: 1.4em;
                    color: $color-scale-gray-300
                }
            }

        }


    }

    .events-section {
        padding: 25px 0;

        .table-wrapper {
            border: 1px solid $color-scale-gray-800;
            border-top: none;
        }
    }
}