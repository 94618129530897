@import "../../../styles/main.scss";

.emp-subscription-view {

    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    @include mobile-view {
        padding-left: 0px;
    }

    .view-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
            color: $color-scale-gray-100;

            @include mobile-view {
                font-size: 1.4em;
                font-weight: 600;
                margin: 0;
            }
        }

        .page-description {
            line-height: 1.4em;
            color: $color-scale-gray-300;
            margin-bottom: 0;

            @include mobile-view {
                margin-top: 6px;
            }
        }
    }


    .info-panel {
        margin-top: 12px;
        padding: 20px;
        border-radius: 12px;
        box-sizing: border-box;
        width: 100%;
        background-color: $color-scale-primary-100;
        display: flex;
        gap: 10px;

        .circle-icon-wrapper {
            position: relative;
            top: -2px;
        }

        .change-request-description {
            margin: 0;
            color: $color-scale-gray-100
        }
    }

    .card-wrapper {
        margin-top: 20px;
        display: flex;
        gap: 20px;

        .plan-name {
            color: $color-scale-gray-200;
        }

        @include mobile-view {
            flex-direction: column;
            width: 100%;
        }

        .split-cards-wrapper {
            width: 100%;
            display: flex;
            gap: 10px;


            .billing-card {
                position: relative;
                overflow: hidden;
                background: $cbz-card-gradient-md;

                .svg-wrapper {
                    opacity: 0.1;
                    position: absolute;
                    right: 0;
                    top: -200px;
                    width: 100%
                }
            }

            .skeletal-card,
            .card {
                flex: 1;
                padding: 10px 10px 20px 10px !important;
            }

            .card {
                @include card-border-conic-gradient-mixin(95deg, 12px, 47%, 53%);

                .card-header-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    justify-content: space-between;

                    h2 {
                        margin: 0;
                        font-size: 1.2em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }
                }


                .title-lbl {
                    display: block;
                    margin-top: 8px;
                    font-weight: 600;
                    color: $color-scale-gray-300;
                }

                .card-number-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;

                    .card-number-lbl {
                        font-family: 'Inconsolata', monospace;
                        font-weight: 600;
                        color: $color-scale-gray-200;
                        letter-spacing: 0.2em;
                        font-size: 1.25em;
                    }

                    .card-brand-img {
                        height: 30px;
                    }
                }

                .name-lbl {
                    display: block;
                    margin-top: 8px;
                    font-weight: 700;
                    color: $color-scale-gray-300;
                }

                .details-wrapper {
                    display: flex;
                    gap: 16px;
                }
            }

            .skeletal-card {
                background: $color-scale-gray-0;
                border: 1px solid $color-scale-gray-800;
                border-radius: 12px;

                .header-skeleton {
                    border-radius: 6px;
                    height: 24px;
                    width: 200px;
                }

                .title-skeleton {
                    border-radius: 4px;
                    height: 18px;
                    width: 160px;
                }

                .details-skeleton-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
            }
        }
    }

    .invoice-section {
        h2 {
            font-size: 1.5em;
            font-weight: 700;
            color: $color-scale-gray-100;
            margin: 0;
        }
    }
}