@import "../../styles/main.scss";

.emp-agency-sign-up-page {
    width: 100%;
    height: 100dvh;
    background: $cbz-background-gradient;

    .emp-onboarding-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;


        .onboarding-wrapper {
            max-width: 1000px;
            width: 100%;
            background-color: black;
            @include card-border-conic-gradient-mixin(90deg, 20px, 47%, 53%);
            border-radius: 20px;
            display: flex;
            overflow: hidden;

            .form-section {
                width: 450px;
                box-sizing: border-box;
                padding: 40px 55px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;


                &.hidden {
                    position: absolute;
                    z-index: -999;
                }

                .header-wrapper {
                    display: flex;
                    align-items: center;

                    .header {
                        font-size: 1.2em;
                        font-weight: 600;

                    }
                }

                .loader-wrapper {
                    display: flex;
                    align-items: center;
                }



                .loader-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0.75;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cbz-card-gradient-md;

                }


                .inner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .description {
                    color: $color-scale-gray-300;
                    line-height: 1.4em;
                    margin: 0;
                }

                .highlight {
                    font-weight: 600;
                    color: $color-scale-primary-400;
                    cursor: pointer;
                }

                .logo {
                    height: 24px;
                }


                .form-wrapper {
                    width: 100%;
                }

                .password-card {
                    border-radius: 8px;
                    padding: 10px;

                }
            }

            .info-section {
                flex: 1;
                padding: 30px;
                background-color: black;
                background: url("https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/opal-prismatic-1.png");
                object-fit: cover;
                background-size: cover;
                background-position: 0 170px;
                background-repeat: no-repeat;
                border-left: 1px solid $color-scale-gray-800;

                .title {
                    display: block;
                    margin-top: 60px;
                    font-size: 1.8em;
                    font-weight: 700;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 0);
                }

                .description {
                    color: map-get($color-scale-gray, 300);
                    line-height: 1.4em;
                    margin: 0;
                }
            }
        }
    }
}