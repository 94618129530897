@import "../../styles/main.scss";


.emp-select-tiktok-post-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px 10px 20px;


        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }


    .post-section {
        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;

        .post-wrapper {
            width: 95%;
            box-sizing: border-box;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }
    }


    .ig-card {
        cursor: pointer;
        background-color: map-get($color-scale-gray, 900);
        // border: 1px solid map-get($color-scale-gray, 100);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        &:hover {
            outline: 3px solid $color-scale-primary-300;
        }

        &.mr-10px {
            margin-right: 10px;
        }

        .img-wrapper {
            aspect-ratio: 1 / 1;
            width: 100%;
            overflow: hidden;

            video,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 400ms;
                pointer-events: none;
            }

            img {
                &:hover {
                    transform: scale(1.2);
                }
            }
        }



        .info-section {
            padding: 10px 12px 14px 10px;
            width: 100%;
            box-sizing: border-box;
            background-color: map-get($color-scale-gray, 900);

            .metrics-wrapper {
                display: flex;
                gap: 6px;

                .metric {
                    display: flex;
                    gap: 4px;

                    .metric-value {
                        color: $color-scale-gray-300;
                    }
                }
            }

            .caption {
                margin: 0;
                margin-top: 10px;
                color: $color-scale-gray-300;
                line-height: 1.4em;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }
        }
    }

    .empty-card {
        height: 240px;
        border-radius: 12px;
        box-sizing: border-box;
    }
}