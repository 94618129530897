@import "../../styles/main.scss";

.emp-update-negotiation-changelog-modal {
  position: relative;
  display: flex;
  flex-direction: column;

  .dismiss-icon-wrapper {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .modal-header-wrapper {
    padding: 0 20px 12px 20px;
    border-bottom: 1px solid $color-scale-gray-800;

    .intro-subtitle {
      display: block;
      font-weight: 700;
      color: #D50006;
    }

    .title {
      margin: 0;
      margin-top: 6px;
      display: block;
      font-weight: 600;
      font-size: 1.4em;
      color: $color-scale-gray-100;
    }
  }

  .representatives-wrapper {

    .representative-column,
    .status-column,
    .amount-column {
      align-items: center;
    }

    .representative-column {
      display: flex;
      flex: 1;
    }

    .status-column,
    .amount-column {
      padding-left: 10px;

    }

    .status-column {
      display: flex;
      width: 220px;
    }

    .amount-column {
      justify-content: flex-end;
      width: 200px;
    }

    .header-wrapper {
      width: 100%;
      padding: 5px 20px;
      background-color: $color-scale-gray-100;
      display: flex;


      .header-text {
        font-weight: 600;
        color: $color-scale-gray-750
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;

      .record-row {
        display: flex;
        width: 100%;
        padding: 8px 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .profile-wrapper {
          position: relative;

          .profile {
            width: 45px;
            height: 45px;
            border-radius: 50px;
            border: 1px solid $color-scale-gray-800;
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;

            &.profile-badge {
              position: absolute;
              bottom: 0;
              right: -4px;
              width: 24px;
              height: 24px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              object-fit: cover;
            }

            .initials {
              color: $color-scale-gray-0;
              font-size: 1.4em;
              font-weight: 600;
            }
          }

        }



        .info-wrapper {
          .creator-name {
            display: block;
            font-size: 1.1em;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          .creator-name-skeleton {
            border: 1px solid $color-scale-gray-800;
            height: 18px;
            width: 140px;
            border-radius: 6px;
          }

          .representative-lbl {

            display: block;
            color: $color-scale-gray-300;
          }

          .representative-lbl-skeleton {
            border: 1px solid $color-scale-gray-800;
            margin-top: 4px;
            height: 18px;
            width: 70px;
            border-radius: 6px;
          }
        }
      }



    }
  }

  .acknowledgement-wrapper {
    display: flex;
    padding: 0 20px;

    .card {
      display: flex;
      flex-direction: column;
      background-color: map-get($color-scale-red, 100);
      padding: 15px;
      border-radius: 12px;
      width: 100%;

      border: 1px solid map-get($color-scale-red, 100);
      background: #e9f2fe;
      background: -webkit-linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);
      background: linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);



      .intro-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: map-get($color-scale-red, 500);
          font-weight: 700;
        }
      }

      .control-wrapper {
        display: flex;

        .checkbox-text-wrapper {
          cursor: pointer;
          flex: 1;

          span {
            line-height: 1.4em;
            font-weight: 400;
            color: $color-scale-gray-100;

            &.checked {
              font-weight: 500;
            }
          }
        }
      }

    }
  }

  .button-wrapper {
    padding: 0 20px;
    display: flex;
    flex-direction: row-reverse;
  }



}