@import "../../../styles/main.scss";

.emp-evidence-card {
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: all 200ms;
    background-color: $color-scale-gray-900;
    border: $color-scale-gray-800 1px solid;

    &.skeleton-wrapper {
        .image-wrapper {
            width: 100%;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;

            .top-section {
                background-color: $color-scale-gray-900;
                width: 100%;
                padding: 10px;
                display: flex;
                gap: 4px;

                .profile-image {
                    background-color: $color-scale-gray-100;
                    width: 28px;
                    height: 28px;
                    border-radius: 28px;
                }

                .name {
                    background-color: $color-scale-gray-100;
                    border-radius: 8px;
                    height: 24px;
                    width: 90px;
                }
            }

            .middle-section {
                background-color: $color-scale-gray-100;
                width: 100%;
                flex: 1;
            }
        }

        .details-section {
            padding: 10px;

            .details-wrapper-skeleton {
                display: flex;
                gap: 6px;

                .profile-image-2 {
                    background-color: $color-scale-gray-100;
                    width: 38px;
                    height: 38px;
                    border-radius: 28px;
                }

                .name-2 {
                    background-color: $color-scale-gray-100;
                    border-radius: 4px;
                    height: 18px;
                    width: 90px;
                }

                .status {
                    margin-top: 4px;
                    background-color: $color-scale-gray-100;
                    border-radius: 3px;
                    height: 14px;
                    width: 60px;
                }
            }

            .fake-button {
                height: 30px;
                width: 100%;
                background-color: $color-scale-gray-100;
                border-radius: 8px;
            }
        }


    }

    .image-wrapper {
        aspect-ratio: 1;
        background-color: $color-scale-gray-900;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        &.padded {
            padding: 0 15px;
        }

        .sm-top-section {
            width: 100%;
            padding: 8px 10px;
            display: flex;
            gap: 6px;
            align-items: center;
            background-color: $color-scale-gray-900;

            .attachment-info-wrapper {
                width: 100%;
                text-align: left;
                display: flex;
                flex-direction: column;
                gap: 3px;

                .attachment-header {
                    font-weight: 600;
                    color: $color-scale-gray-300;
                }

                .attachment-filename {
                    font-size: 0.95em;
                    display: inline-block;
                    color: $color-scale-gray-100;
                    max-width: 60%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .avatar-wrapper {
                width: 28px;
                height: 28px;
                position: relative;

                .social-media-platform-badge {
                    position: absolute;
                    bottom: -2px;
                    right: -4px;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    background-color: $color-scale-gray-900;
                    border: 1px solid $color-scale-gray-800;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .social-media-avatar {
                width: 28px;
                height: 28px;
                border-radius: 30px;
                object-fit: cover;
            }

            .info-section {
                flex: 1;

                .name-lbl {
                    display: block;
                    width: fit-content;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }
            }
        }

        .post-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex: 1;
            object-fit: cover;
            overflow: hidden;


            .additional-deliverable-wrapper-badge {
                position: absolute;
                top: 4px;
                right: 4px;
                padding: 2px 4px;
                border-radius: 6px;
                background-color: rgba(0, 0, 0, 0.7);
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3px;

                &.anchor-bottom {
                    top: unset !important;
                    bottom: 4px !important;
                }

                .badge-text {
                    font-weight: 700;
                    color: $color-scale-gray-300;
                    font-size: 0.9em;
                }
            }

            .empty-submission,
            .preview-file {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;

            }

            .empty-submission {
                background: linear-gradient($color-scale-gray-50 0%, $color-scale-gray-150 100%);
                border-top: 1px solid $color-scale-gray-800;
                padding: 10px;
                overflow: hidden;
                text-align: left;

                p {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                }
            }

            .preview-file {
                background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
                justify-content: center;
                align-items: center;

                .preview-filename {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: 600;
                    color: $color-scale-gray-300;
                }

                .preview-size {
                    font-weight: 500;
                    color: $color-scale-gray-100;
                }
            }

            .preview-image {
                width: 100%;
                height: 100%;
                border-bottom: 1px solid $color-scale-gray-800;
                object-fit: cover;
            }

            .x-tweet-wrapper {
                height: 100%;
                width: 100%;
                padding: 10px;
                background-color: $color-scale-gray-900;
                border-top: 1px solid $color-scale-gray-800;

                .x-tweet-text {
                    margin: 0;
                    color: $color-scale-gray-300;
                    font-weight: 500;
                    font-size: 1.05em;
                    text-align: left;

                    &.clamp {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .x-media-grid-wrapper {
                    padding: 0 5px;

                    .x-media-grid {
                        display: grid;
                        grid-gap: 4px;
                        width: 100%;
                        aspect-ratio: 1;
                        border-radius: 12px;
                        overflow: hidden;
                        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
                        border: 1px solid $color-scale-gray-800;

                        .x-media-wrapper {
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            .play-video-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .play-circle {
                                    cursor: pointer;
                                    background-color: rgba(255, 255, 255, 0.1);
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            &.items-1 {
                                &:nth-child(1) {
                                    grid-column: span 2;
                                    grid-row: span 2;
                                }
                            }

                            &.items-2 {

                                &:nth-child(1),
                                &:nth-child(2) {
                                    grid-row: span 2;
                                }
                            }

                            &.items-3 {
                                &:nth-child(1) {
                                    grid-row: span 2;
                                }
                            }
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .x-poll-wrapper {
                    padding: 0 5px;
                }
            }

        }


        .sm-metrics-section {
            width: 100%;
            padding: 6px 10px;
            display: flex;
            flex-direction: column;
            border-top: 1px solid $color-scale-gray-800;
            border-bottom: 1px solid $color-scale-gray-800;
            background-color: $color-scale-gray-900;
            gap: 6px;

            .metrics-bar {
                display: flex;
                align-items: center;
                gap: 12px;

                .metric-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                        font-weight: 600;
                        display: block;
                        color: $color-scale-gray-300;
                    }
                }
            }
        }

        .remarks-section {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            padding: 30px 10px 10px 10px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%);

            p {
                text-align: left;
                margin: 0;
                color: $color-scale-gray-0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .attachment-text {
            flex: 1;
            object-fit: cover;
            pointer-events: none;
            width: 100%;
            overflow: hidden;
            background-color: $color-scale-gray-900;
            border-top: 1px solid $color-scale-gray-800;
            padding: 10px;
            text-align: start;
        }

        .attachment-img {
            flex: 1;
            object-fit: contain;
            pointer-events: none;
            width: 100%;
            overflow: hidden;
            background-color: $color-scale-gray-50;
        }

        .attachment-video {
            flex: 1;
            object-fit: cover;
            width: 100%;
            overflow: hidden;
            background-color: $color-scale-gray-50;
        }

        .attachment-file-wrapper {
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
            flex-direction: column;

            .title {
                margin-top: 3px;
                font-weight: 600;
                color: $color-scale-gray-100;
                text-align: center;
            }

            .specs {
                margin-top: 4px;
                font-weight: 600;
                color: $color-scale-gray-500;
                text-align: center;
            }
        }

        .empty-draft-img {
            object-fit: cover;
            aspect-ratio: 1;
            width: 20%;
        }

        .empty-draft-header {
            font-weight: 700;
            color: $color-scale-gray-300;
        }

        .empty-draft-description {
            margin: 0;
            font-weight: 500;
            color: $color-scale-gray-300;
            font-size: 0.9em;
        }


    }

    .details-wrapper {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 8px;

        .profile {
            width: 38px;
            height: 38px;
            border-radius: 36px;
            border: 1px solid $color-scale-gray-100;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            .initials {
                color: $color-scale-gray-0;
                font-size: 1.2em;
                font-weight: 600;
            }
        }

        .info-wrapper {
            .name-lbl {
                font-size: 1em;
                color: $color-scale-gray-100;
                font-weight: 700;
            }

            .pill-wrapper {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }
    }

    .revision-pill {
        border-radius: 5px;
        font-size: 0.85em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        white-space: nowrap;
        line-height: initial;
        background-color: $color-scale-gray-800;
        color: $color-scale-gray-500;
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 0.75em;
        padding: 3px 4px;
        font-weight: 700;
    }

    .button-wrapper {
        padding: 0 10px 10px 10px;
    }
}