@import "../../../styles/main.scss";

.emp-button {
  all: unset;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 0 14px;
  color: map-get($color-scale-gray, 0);
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  background: radial-gradient(circle at 50% 600%, #865BFF, #4B23BA);
  border: 1px solid #764CE7;
  transition: all 200ms ease;
  text-align: center;

  border-radius: 10px;
  mask-image: paint(squircle);
  --squircle-radius: 30px;
  --squircle-smooth: 4;

  &.height-sm {
    height: 30px;

    @include mobile-view {
      height: 35px;
    }
  }

  &.height-md {
    height: 35px;

    @include mobile-view {
      height: 40px;
    }
  }

  &.height-lg {
    height: 40px;

    @include mobile-view {
      height: 45px;
    }
  }

  &.secondary {
    border: 1px solid $color-scale-gray-800;
    background: radial-gradient(circle at 50% 600%, #1D1D26, #171720);
    color: $color-scale-gray-150;

    &:hover {
      border: 1px solid $color-scale-gray-750 !important;
      color: $color-scale-gray-100;
      background: radial-gradient(circle at 50% 600%, #272733, #1e1e2a) !important;
    }
  }

  &.outline-primary {
    background-color: map-get($color-scale-primary, 100) !important;
    color: map-get($color-scale-primary, 500);
    border: 1px solid map-get($color-scale-primary, 500);

    &:hover {
      color: map-get($color-scale-primary, 500) !important;
      background-color: map-get($color-scale-primary, 150) !important;
    }
  }

  &.outline-secondary {
    background-color: map-get($color-scale-gray, 0) !important;
    color: $color-scale-gray-100;
    border: 1px solid map-get($color-scale-gray, 150);

    &:hover {
      border: 1px solid map-get($color-scale-primary, 500) !important;
    }
  }

  &.danger {
    background-color: map-get($color-scale-red, 600) !important;
    color: map-get($color-scale-gray, 0);

    &:hover {
      background-color: map-get($color-scale-red, 700) !important;
      color: map-get($color-scale-gray, 0);
    }
  }

  &.success {
    background-color: map-get($color-scale-green, 500) !important;
    color: map-get($color-scale-gray, 0);

    &:hover {
      background-color: map-get($color-scale-green, 700) !important;
      color: map-get($color-scale-gray, 0);
    }
  }

  &:disabled,
  &[disabled] {
    border: 1px solid $color-scale-gray-800 !important;
    background: $color-scale-gray-800 !important;
    color: $color-scale-gray-300 !important;
    cursor: not-allowed;

    &:hover {
      border: 1px solid $color-scale-gray-800 !important;
      background: $color-scale-gray-800 !important;
      color: $color-scale-gray-300 !important;
    }
  }

  &:not(:first-child) {
    @include breakpoint(mobile) {
      margin-top: 12px;
    }
  }

  &.loading {
    background-color: map-get($color-scale-gray, 200);

    &:hover {
      background-color: map-get($color-scale-gray, 200);
    }
  }

  &:hover {
    background-color: map-get($color-scale-primary, 700);
  }

  .emp-button-content {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }

  .emp-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.full-width {
  width: 100%;
}

.fit-content {
  width: fit-content;
}