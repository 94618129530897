@import "../../../styles/main.scss";

.emp-brand-task-ongoing-progress-wall-view {
    padding-top: 20px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }

    .task-details-section {
        padding-bottom: 6px;

        .info-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    .description-lbl {
        margin: 0;
        line-height: 1.4em;
        color: $color-scale-gray-300;
    }

    .tabbed-option-wrapper {
        padding: 5px;
        width: fit-content;
        border-radius: 10px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        display: flex;
        gap: 4px;

        .tabbed-option {
            cursor: pointer;
            height: 24px;
            padding: 0 10px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            &.active {
                background-color: $color-scale-gray-850;
                border: 1px solid $color-scale-gray-800;
                cursor: not-allowed;
            }


            span {
                font-weight: 600;
                color: $color-scale-gray-400;
                transition: color 200ms;

                &:hover {
                    color: $color-scale-gray-300;
                }
            }

            &.active {
                span {
                    color: $color-scale-gray-300;
                }
            }

        }
    }

    .kanban-section {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

}