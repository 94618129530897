@import "../../../styles/main.scss";

.img-ref {
    position: absolute;
    z-index: 999px;
    max-width: 400px;
    visibility: hidden;
}


.emp-cropper {
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;

    img {
        pointer-events: none;
    }

    .cropper-card {
        background-color: $color-scale-gray-900;
        border-radius: 12px;
        padding: 15px 0;
        border: 1px solid map-get($color-scale-gray, 800);
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        height: 80%;
        max-height: 80vh;

        .header-section,
        .footer-section {
            padding: 0 20px;
        }

        .header-section {
            padding-bottom: 15px;
            border-bottom: 1px solid $color-scale-gray-800;

            .header {
                font-size: 1.2em;
                color: $color-scale-gray-100;
                margin: 0;
                font-weight: 600;
            }
        }

        .footer-section {
            padding-top: 15px;
            border-top: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn-wrapper {
                width: fit-content;

                &:last-child {
                    margin-left: 8px;
                }
            }
        }



        .cropper-section {
            background-color: black;
            display: flex;
            justify-content: center;
            height: 100%;

            .cropper-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
            }
        }

    }

}