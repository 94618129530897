@import "../../styles/main.scss";

.emp-creator-access-page {
    width: 100%;
    height: 100dvh;
    background-color: map-get($color-scale-gray, 900);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;


    .emplifive-logo {
        height: 30px;
    }

    .onboarding-wrapper {
        max-width: 1000px;
        box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
        border: 1px solid map-get($color-scale-gray, 100);
        border-radius: 20px;
        display: flex;
        overflow: hidden;

        .form-section {
            box-sizing: border-box;
            padding: 20px 30px 30px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .language-picker-wrapper {
                display: flex;
                justify-content: flex-end;
            }

            .centralized-div {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            .avatar,
            .profile-pic {
                width: 52px;
                height: 52px;
                border-radius: 80px;
            }

            .avatar {

                display: flex;
                align-items: center;
                justify-content: center;

                .initials {
                    color: $color-scale-gray-0;
                    font-size: 1.8em;
                    font-weight: 700;
                }
            }

            .secondary-lbl {
                display: block;
                color: $color-scale-gray-300;
            }

            .header-text {
                font-size: 1.2em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }



            .header-wrapper {
                display: flex;
                align-items: center;

                .header {
                    font-size: 1.2em;
                    font-weight: 600;

                }
            }


            .inner {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .intro-wrapper {
                display: flex;
                align-items: center;
                margin-top: 32px;
            }

            .logo {
                height: 40px;
                width: 40px;
                border-radius: 10px;
            }

            .description {
                color: map-get($color-scale-gray, 500);
                line-height: 1.4em;
                margin: 0;
            }

            .highlight {
                font-weight: 600;
                color: map-get($color-scale-primary, 600);
                cursor: pointer;
            }

            .form-wrapper {
                width: 100%;
            }

            .password-card {
                border-radius: 8px;
                padding: 10px;

            }


            .disclaimer-section {
                display: flex;
                flex-direction: column;
                flex: 1;

                .picture-wrapper {

                    display: flex;
                    gap: 20px;
                    align-items: center;

                    .plus {
                        display: block;
                        position: relative;
                        font-weight: 700;
                        font-size: 2.5em;
                        color: $color-scale-gray-300;
                    }

                    .avatar,
                    .org-image {
                        width: 60px;
                        height: 60px;
                    }

                    .avatar {
                        border-radius: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .initials {
                            color: $color-scale-gray-0;
                            font-size: 1.8em;
                            font-weight: 700;
                        }
                    }

                    .org-image {
                        border-radius: 8px;
                    }
                }

                .heading {
                    margin-top: 40px;
                    text-align: center;
                    font-size: 1.4em;
                    line-height: 1.4em;
                    font-weight: 600;
                    color: $color-scale-gray-100;

                    .highlighted {
                        color: $color-scale-primary-500 !important;
                    }
                }

                .disclaimer-description {
                    margin-top: 30px;
                    text-align: center;
                    line-height: 1.4em;
                    font-weight: 500;
                    color: $color-scale-gray-300;
                }

                .btn-wrapper {
                    flex: 1;
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }

    }
}