@import "../../../styles/main.scss";

.emp-role-selection-view {
    position: relative;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile-view {
        height: 100%;
        padding-top: 60px;
        box-sizing: border-box;
    }

    .back-btn-wrapper {
        position: absolute;
        top: 10px;
        left: 0px;

        @include mobile-view {
            top: 20px;
            left: 20px;
        }

    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            height: 32px;
        }

        h1 {
            margin-top: 25px;
            font-size: 1.3em;
            color: $color-scale-gray-100;
            font-weight: 600;
        }
    }


    .platform-role-wrapper {
        margin: 0;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;

        @include mobile-view {
            padding: 0 20px;
        }


        .platform-role-card {
            @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
            width: 240px;
            background: $cbz-card-gradient-md;
            border-radius: 12px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            transition: all 200ms;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
            cursor: pointer;


            @include mobile-view {
                flex-direction: row;
                text-align: left;
                gap: 12px;
                width: 100%;
            }

            &:hover {
                transform: translateY(-5px);
            }

            .platform-role-img {
                border-radius: 8px;
                width: 80px;
                height: 80px;
                object-fit: cover;
                background-color: $color-scale-gray-900;
                border: 1px solid $color-scale-gray-850;
            }

            .platform-role-lbl {
                margin: 0;
                color: $color-scale-gray-150;
                font-weight: 700;
                margin-top: 12px;

                @include mobile-view {
                    margin-top: 0;
                }
            }

            .platform-role-description {
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300;
            }
        }
    }
}