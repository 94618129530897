@import "../../styles/main.scss";



.emp-agency-creator-details-page {
    flex: 1;
    background: linear-gradient(132.49deg, rgba(232, 237, 255, 0.192)7.07%, #FFFFFF 100.39%);
    display: flex;
    align-items: center;

    .content {
        width: 100%;
        max-width: 1240px;
        padding: 15px 20px 30px 20px;
        box-sizing: border-box;

        @include tablet-view {
            padding: 15px 10px;
        }

        .header-wrapper-flex {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 15px;
            color: $color-scale-gray-100;

            .page-header {
                margin: 0;
                font-size: 1.6em !important;
            }
        }




        .profile-info-wrapper {
            display: flex;
            gap: 16px;

            @include tablet-view {
                flex-direction: column;
            }


            .profile-section {
                width: 240px;
                position: sticky;
                top: 10px;
                height: fit-content;
                background: $cbz-card-gradient-sm;

                @include tablet-view {
                    width: 100%;
                    position: static;
                }

                .profile-card-border {
                    @include card-border-conic-gradient-mixin(140deg, 0 0 30px 30px, 48%, 52%, false);
                }

                .banner {
                    height: 80px;
                    width: 100%;
                    background-color: #181030;
                }

                .pic-section {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -30px;
                    padding: 0 20px 30px 20px;
                    box-sizing: border-box;
                    text-align: center;

                    @include tablet-view {
                        align-items: flex-start;
                    }

                    @include mobile-view {
                        margin-top: -42.5px;
                    }

                    .avatar,
                    .profile-pic {
                        width: 65px;
                        height: 65px;
                        border-radius: 70px;
                        border: 1px solid $color-scale-gray-800;

                        @include mobile-view {
                            width: 85px;
                            height: 85px;
                            border-radius: 85px;
                        }
                    }

                    .avatar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.8em;
                        font-weight: 700;
                        color: map-get($color-scale-gray, 0);

                        @include mobile-view {
                            font-size: 2.2em;
                        }
                    }

                    .profile-img {
                        object-fit: cover;
                    }

                    .creator-name-lbl {
                        display: block;
                        font-size: 1.2em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }

                    .creator-email-lbl {
                        display: block;
                        font-size: 1em;
                        font-weight: 500;
                        color: $color-scale-gray-300;
                    }
                }

                .info-section {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    width: 100%;
                    padding: 0 20px;
                    box-sizing: border-box;

                    @include tablet-view {
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 12px;
                    }

                    .creator-info-wrapper {
                        width: 100%;
                        display: flex;
                        gap: 8px;

                        @include tablet-view {
                            width: fit-content;
                            flex: initial;
                        }

                        .creator-gender,
                        .creator-age {
                            flex: 1;
                        }
                    }

                    .interest-wrapper,
                    .service-wrapper {
                        @include tablet-view {
                            flex-basis: 100%;
                        }

                    }

                    .country-wrapper {
                        display: flex;
                        gap: 4px;

                        .img-flag {
                            height: 16px;
                            border: solid 1px map-get($color-scale-gray, 150);
                        }
                    }

                    .platform-wrapper {
                        display: flex;
                        gap: 8px;
                    }

                    .agency-wrapper {
                        margin-top: 12px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .agency-card {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        .agency-logo {
                            border-radius: 8px;
                            width: 32px;
                            height: 32px;
                            object-fit: cover;
                            border: 1px solid map-get($color-scale-gray, 100);
                        }

                        .agency-content {
                            flex: 1;

                            .name-wrapper {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .name-lbl {
                                    flex: 1;
                                    display: block;
                                    font-weight: 600;
                                    color: $color-scale-gray-300;
                                }
                            }
                        }
                    }
                }

                .pills-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 6px;
                }
            }




            .social-media-section {
                flex: 1;


                // choose social media section
                .social-media-selector-card {
                    display: flex;
                    overflow-x: auto;
                    align-items: center;
                    padding: 8px;

                    &.social-card-border {
                        @include card-border-conic-gradient-mixin(90deg, 12px, 49.5%, 50.5%);
                    }

                    .social-media-option {
                        display: flex;
                        align-items: center;
                        padding: 8px 14px 8px 10px;
                        border-radius: 8px;
                        cursor: pointer;

                        &.active {
                            background-color: $color-scale-gray-850;
                        }

                        .picture-section {
                            position: relative;
                            width: 42px;
                            height: 42px;

                            img {
                                width: 42px;
                                height: 42px;
                                border-radius: 45px;
                                border: 1px solid $color-scale-gray-800;
                            }

                            .social-media-bubble {
                                position: absolute;
                                bottom: -1px;
                                right: -1px;
                                width: 20px;
                                height: 20px;
                                border-radius: 10px;
                                background-color: $color-scale-gray-750;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: 0px 0px 0px 1px $color-scale-gray-800;
                            }
                        }

                        .info-section {
                            flex: 1;
                            margin-left: 10px;

                            .handle-name-lbl {
                                display: block;
                                white-space: nowrap;
                                font-weight: 600;
                                color: $color-scale-gray-100;
                            }

                            .metric-lbl {
                                margin-top: 2px;
                                display: block;
                                white-space: nowrap;
                                font-size: 0.9em;
                                color: $color-scale-gray-300;
                            }
                        }
                    }

                    .empty-option {
                        display: flex;
                        align-items: center;
                        padding: 8px 14px 8px 10px;
                        border-radius: 8px;

                        .picture-section {
                            position: relative;
                            width: 42px;
                            height: 42px;

                            .placeholder {
                                width: 42px;
                                height: 42px;
                                border-radius: 45px;
                                background-color: $color-scale-gray-800;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                        }

                        .info-section {
                            flex: 1;
                            margin-left: 10px;

                            .handle-name-lbl {
                                display: block;
                                font-weight: 600;
                                color: $color-scale-gray-300;
                            }

                            .metric-lbl {
                                margin-top: 2px;
                                display: block;
                                white-space: nowrap;
                                font-size: 0.9em;
                                color: $color-scale-gray-300;
                            }
                        }
                    }
                }
            }
        }
    }

    // External
    .agency-invitation-card {
        margin-bottom: 16px;
        padding-bottom: 20px;
    }

}