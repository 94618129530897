@import "../../styles/main.scss";


.emp-x-tweet-poll-widget {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .poll-bar-article {
    display: flex;
    gap: 6px;

    .poll-bar-wrapper {
      position: relative;
      width: 100%;

      .poll-label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px;
        z-index: 1;

        .label {
          font-weight: 600;
          color: $color-scale-gray-100;

          &.highest-result {
            color: $color-scale-primary-100;
            font-weight: 700;
          }
        }
      }

      .poll-bar {
        min-width: 5px;
        border-radius: 8px;
        height: 30px;
        background-color: $color-scale-gray-100;

        &.highest-result {
          background-color: $color-scale-primary-500;
        }
      }
    }

    .poll-percentage {
      width: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $color-scale-gray-100;
      font-weight: 500;

      &.highest-result {
        color: $color-scale-gray-100;
        font-weight: 700;
      }
    }
  }

}