@import "../../../styles/main.scss";

.emp-instagram-connect-info-modal {
    display: flex;
    flex-direction: column;

    .modal-header {
        font-weight: 600;
        font-size: 1.1em;
        color: $color-scale-gray-100;
    }

    .content-section {
        margin-top: 40px;

        .info-section-wrapper {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .info {
                display: flex;
                align-items: center;
                gap: 10px;

                .left-elem {
                    width: 50px;
                    min-width: 50px;

                    .circle {
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.facebook {
                            background-color: #1776F6;
                        }

                        &.instagram {
                            background-color: #DA3174;
                        }

                        &.emplifive {
                            background-color: $color-scale-gray-700
                        }

                        img {
                            height: 30px;
                            width: 30px;
                            object-fit: cover;
                        }
                    }

                    .fb-page {
                        position: relative;
                        top: -5px;
                    }
                }

                .right-elem {
                    flex: 1;

                    .title {
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }

                    .description {
                        display: block;
                        margin: 0;
                        margin-top: 8px;
                        color: $color-scale-gray-300;
                    }
                }
            }
        }

        .footer-description {
            margin-top: 30px;
            display: block;
            color: $color-scale-gray-300;
        }

        .button-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
        }
    }

}