@import "../../../styles/main.scss";

.emp-kebab-menu {
    position: relative;

    .emp-kebab-button {
        all: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        z-index: 1;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            opacity: 0;
            border-radius: 30px;
            background-color: map-get($color-scale-gray, 800);
            width: 100%;
            height: 100%;
            z-index: -1;
            transform: scale(0.5);
            transition: 200ms all;
        }

        &:hover:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}


.emp-kebab-menu-wrapper {
    position: absolute;
    padding: 10px 0;
    background-color: map-get($color-scale-gray, 50);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    border: 1px solid map-get($color-scale-gray, 100);
    z-index: 3;

    &.hidden {
        background-color: green;
        visibility: hidden;
        z-index: -100;
    }

    .emp-kebab-menu-btn {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        cursor: pointer;
        white-space: nowrap;
        background-color: map-get($color-scale-gray, 50);
        font-size: 1em;
        font-weight: 600;
        display: flex;
        align-items: center;
        transition: all 200ms;

        &.default {
            color: $color-scale-gray-100;
        }

        &.danger {
            color: map-get($color-scale-red, 500);
        }

        &:hover {
            background-color: map-get($color-scale-gray, 100);
        }
    }
}