@import "../../styles/main.scss";

.emp-confirm-modal {
  position: relative;

  .dismiss-icon-wrapper {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: 0;
  }

  .confirm-modal-text-content-section {
    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .title {
        font-weight: 600;
        color: $color-scale-gray-100;
        font-size: 1.2em;
        margin: 0;
      }
    }

    .description {
      margin: 12px 0 0;
      color: $color-scale-gray-300;

      .highlighted {
        font-weight: 500;
        color: map-get($color-scale-primary, 600);
      }
    }
  }
}
