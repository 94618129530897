@import "../../styles/main.scss";

.emp-subscription-payment-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .intro-section {
        padding: 0 15px;
        padding-bottom: 20px;

        .section-title {
            font-size: 1.2em;
            font-weight: 600;
            color: $color-scale-gray-100;
            margin: 0;
        }
    }

    .payment-method-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 5px 15px 0 15px;
        overflow-y: auto;

        .payment-method-card {
            position: relative;
            background: -webkit-linear-gradient(224deg, #dadce2 0%, #ffffff 100%);
            background: linear-gradient(224deg, #dadce2 0%, #ffffff 100%);
            padding: 15px 15px 15px 15px;
            background-color: $color-scale-gray-900;
            border-radius: 12px;
            width: 100%;
            box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
            border: 2px solid $color-scale-gray-100;
            cursor: pointer;
            transition: all 200ms;


            &.selected {
                border: 2px solid $color-scale-primary-500;
                background-color: $color-scale-primary-200 !important;
            }

            &:hover {
                transform: translateY(-4px);
            }

            .svg-wrapper {
                opacity: 0.1;
                position: absolute;
                overflow: hidden;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &.selected {
                    opacity: 0;
                }
            }

            .card-header-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;

                h2 {
                    margin: 0;
                    font-size: 1.2em;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                }
            }


            .title-lbl {
                display: block;
                margin-top: 8px;
                font-weight: 600;
                color: $color-scale-gray-300;
            }

            .card-number-wrapper {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                .card-number-lbl {
                    font-family: 'Inconsolata', monospace;
                    font-weight: 600;
                    letter-spacing: 0.2em;
                    font-size: 1.25em;
                }

                .card-brand-img {
                    height: 30px;
                }
            }

            .name-lbl {
                display: block;
                margin-top: 8px;
                font-weight: 700;
                color: $color-scale-gray-300;
            }
        }
    }


    .button-section {
        display: flex;
        height: fit-content;
        padding: 10px 15px 0 15px;
        gap: 10px;
        align-items: flex-end;
        justify-content: flex-end;

    }

}