@import "../../../styles/main.scss";

.emp-content {
    display: flex;
    gap: 6px;

    &__label {
        font-size: 0.9em;
        font-weight: 700;
        color: $color-scale-gray-300;
        display: flex;
        align-items: center;
        gap: 4px;

        &.skeleton {
            height: 14px;
            border-radius: 4px;
        }

        &.skeleton-default-color {
            background-color: $color-scale-gray-100;
        }
    }

    &__value {
        display: block;
        font-size: 1em;
        font-weight: 500;
        color: $color-scale-gray-250;

        &.skeleton {
            height: 16px;
            border-radius: 4px;
        }

        &.skeleton-default-color {
            background-color: $color-scale-gray-100;
        }
    }
}