@import "../../../styles/main.scss";

.emp-rounded-pill-wrapper {
    display: inline-block;

    .emp-rounded-pill {
        padding: 4px 8px;
        border-radius: 20px;
        font-weight: 600;
        font-size: 0.85em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        white-space: nowrap;
        border: 1px solid $color-scale-gray-700;
        background-color: $color-scale-primary-900;
        color: $color-scale-gray-0;
        transition: all 100ms;

        @include mobile-view {
            padding: 5px 10px;
            font-size: 0.9em;
        }

        &:hover {
            background-color: $color-scale-primary-800 !important;
        }

        &.selected {
            background-color: $color-scale-primary-500 !important;
            border: 1px solid $color-scale-primary-300;
        }

    }

    &.clickable {
        cursor: pointer;
    }
}