@import "../../styles/main.scss";

.emp-delete-campaign-modal {
  position: relative;
  display: flex;
  flex-direction: column;

  .dismiss-icon-wrapper {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .modal-header-wrapper {
    padding: 0 20px 12px 20px;
    border-bottom: 1px solid $color-scale-gray-800;

    .intro-subtitle {
      display: block;
      font-weight: 700;
      color: #D50006;
    }

    .title {
      margin: 0;
      margin-top: 6px;
      display: block;
      font-weight: 600;
      font-size: 1.4em;
      color: $color-scale-gray-100;
    }
  }

  .participants-wrapper {
    .reason-wrapper {
      padding: 6px 20px;

      .reason-header {
        font-weight: 600;
        color: map-get($color-scale-red, 600);
      }
    }

    .header-wrapper {
      padding: 5px 20px;
      background-color: $color-scale-gray-100;
      display: flex;

      .participants-header {
        flex: 1;
      }

      .status-header {
        display: flex;
        justify-content: flex-end;
        width: 160px;
      }

      .header-text {
        font-weight: 600;
        color: $color-scale-gray-750
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;

      .header-text {
        font-weight: 600;
        color: $color-scale-gray-750
      }
    }
  }

  .acknowledgement-wrapper {
    display: flex;
    padding: 0 20px;

    .card {
      display: flex;
      flex-direction: column;
      background-color: map-get($color-scale-red, 100);
      padding: 15px;
      border-radius: 12px;
      width: 100%;

      border: 1px solid map-get($color-scale-red, 100);
      background: #e9f2fe;
      background: -webkit-linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);
      background: linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);



      .intro-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: map-get($color-scale-red, 500);
          font-weight: 700;
        }
      }

      .control-wrapper {
        display: flex;

        .checkbox-text-wrapper {
          cursor: pointer;
          flex: 1;

          span {
            line-height: 1.4em;
            font-weight: 400;
            color: $color-scale-gray-100;

            &.checked {
              font-weight: 500;
            }
          }
        }
      }

    }
  }

  .button-wrapper {
    padding: 0 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  .task-record {
    cursor: pointer;
    border-bottom: 1px solid $color-scale-gray-800;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    transition: 150ms ease-out;

    &:hover {
      background-color: $color-scale-primary-100;
      border: 1px solid $color-scale-primary-500;
    }

    .row-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 10px 20px;


      .task-wrapper {
        flex: 1;
        display: flex;
        gap: 10px;

        .task-pic-wrapper {
          display: flex;
          border-radius: 9px;
          box-shadow: 0 0 0 1px $color-scale-gray-200;

          .task-platform-section {
            width: 38px;
            height: 80px;
            border-radius: 8px 0 0 8px;
            border-right: 1px solid $color-scale-gray-700;
            background: linear-gradient(318deg, #000 0%, #4B4B4B 100%);
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .task-campaign-img-section {
            height: 80px;
            border-radius: 0 8px 8px 0;
            object-fit: cover;
            aspect-ratio: 1;
          }
        }


        .info-wrapper {
          .creator-name {
            display: block;
            font-size: 1.1em;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          .task-description-lbl {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.4em;
            max-height: 4.2em;
            margin: 0;
          }

          .creator-name-skeleton {
            border: 1px solid $color-scale-gray-800;
            height: 18px;
            width: 140px;
            border-radius: 6px;
          }

          .representative-lbl {

            display: block;
            color: $color-scale-gray-300;
          }

          .representative-lbl-skeleton {
            margin-top: 4px;
            height: 18px;
            width: 70px;
            border-radius: 6px;
          }
        }


      }

      .amount-wrapper {
        width: 160px;
        display: flex;
        justify-content: flex-end;

        .amount-lbl {
          display: block;
          font-size: 1em;
          font-weight: 600;
          color: $color-scale-gray-100;
        }

        .amount-lbl-skeleton {
          border: 1px solid $color-scale-gray-800;
          height: 20px;
          width: 65px;
          border-radius: 6px;
        }
      }
    }

  }

}