@import "../main.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.emp-evidence-modal {
  position: relative;
  min-height: 300px;

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .dismiss-icon-wrapper {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: 20px;
  }

  .form-section {
    padding: 0 20px;
  }

  .text-content-section {
    padding: 0 20px;

    .title {
      display: block;
      font-weight: 600;
      color: $color-scale-gray-100;
      font-size: 1.2em;
      margin: 0;
    }

    .description {
      margin: 0;
      margin-top: 8px;
      display: block;
      color: $color-scale-gray-300;
    }
  }

  .select-method-view {
    padding: 0 20px;

    .method-card {
      border: 1px solid $color-scale-gray-800;
      border-radius: 12px;
      background-color: $color-scale-gray-900;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items: center;
      transition: all 300ms;
      cursor: pointer;

      &:hover {
        transform: translateY(-3px);
      }

      .method-image {
        height: 40px;
        width: auto;
      }

      .method-title {
        font-size: 1.1em;
        font-weight: 700;
        color: $color-scale-gray-100;
        margin: 0;
      }

    }
  }

  .view-form-section {
    margin-top: 20px;
    padding: 15px 20px 0 20px;
    border-top: 1px solid $color-scale-gray-800;

    .empty-form-card-wrapper,
    .form-card-wrapper {
      display: flex;
      padding: 10px;
      width: 100%;
      border-radius: 12px;
    }

    .empty-form-card-wrapper {
      border: 1px solid $color-scale-gray-800;
      gap: 8px;

      .empty-profile-pic-wrapper {
        display: flex;
        align-items: center;

        .empty-sm-pic,
        .empty-profile-pic {
          min-width: 40px;
          min-height: 40px;
          border-radius: 30px;
          border: 1px solid $color-scale-gray-800;
        }
      }

      .empty-description-wrapper {
        flex: 1;

        .empty-description,
        .empty-description-2 {
          height: 16px;
          border-radius: 4px;
          border: 1px solid $color-scale-gray-100;
        }

        .empty-description {
          width: 50%;
        }

        .empty-description-2 {
          width: 20%;
        }
      }

    }

    .form-card-wrapper {
      background: $color-scale-gray-900;
      padding: 10px;
      border: 1px solid $color-scale-gray-800;
      align-items: center;

      &.warn {
        background: linear-gradient(90deg, rgba(255, 235, 235, 1) 0%, rgba(255, 255, 255, 1) 73%);
      }

      .profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        border: 1px solid $color-scale-gray-100;

        &div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &img {
          object-fit: cover;
        }

        .initials {
          font-weight: 600;
          color: $color-scale-gray-100;
        }
      }

      .creator-picture {
        z-index: 2;
      }

      .sm-picture-wrapper {
        width: fit-content;
        height: fit-content;
        position: relative;
        top: 2px;

        .social-badge {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-scale-gray-800;
          border: $color-scale-gray-0 1px solid;
        }
      }

      .text-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 4px;

        .title-lbl {
          font-weight: 600;
          color: $color-scale-gray-100;
        }

        .follower-lbl {
          color: $color-scale-gray-300;
        }

        .verified-mark {
          width: 18px;
          height: auto;
        }
      }
    }

    &.sm-link-view,
    &.sm-image-view {
      .section-title {
        display: block;
        font-weight: 600;
        color: $color-scale-gray-100;
        font-size: 1.2em;
        margin: 0;
      }

      .split-form {
        display: flex;
        gap: 10px;

        .split-form-control {
          flex: 1;
        }

        .btn-wrapper {
          margin-top: 25px;
        }
      }
    }

    &.sm-link-view {
      .fetched-media-section {
        padding: 20px;
        border-radius: 12px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;

        .media-header {
          font-weight: 700;
          color: $color-scale-gray-300
        }

        .social-media-card {
          border-radius: 8px;
          border: 1px solid $color-scale-gray-800;
          background-color: $color-scale-gray-900;
          width: 220px;

          .top-section {
            padding: 8px 10px;
            display: flex;
            gap: 6px;
            align-items: center;

            .social-media-avatar,
            .empty-avatar {
              width: 28px;
              height: 28px;
              border-radius: 30px;
            }

            .social-media-avatar {
              object-fit: cover;
            }

            .info-section {
              .empty-name {
                border-radius: 6px;
                height: 20px;
                width: 80px;
              }

              .name-lbl {
                font-weight: 600;
                color: $color-scale-gray-100;
              }
            }

          }


          .media-section {
            box-sizing: border-box;

            .media-wrapper {
              padding: 0 10px;

              .empty-media {
                width: 100%;
                border-radius: 8px;
                aspect-ratio: 1;
              }

              img {
                aspect-ratio: 1;
                border-radius: 8px;
                width: 100%;
                object-fit: cover;
              }

              video {
                aspect-ratio: 1;
                border-radius: 8px;
                width: 100%;
                object-fit: cover;
              }
            }

            .x-media-grid-wrapper {
              padding: 0 5px;

              .x-media-grid {
                display: grid;
                grid-gap: 4px;
                width: 100%;
                aspect-ratio: 1;
                border-radius: 12px;
                overflow: hidden;
                grid-template: repeat(2, 1fr) / repeat(2, 1fr);
                border: 1px solid $color-scale-gray-100;

                .x-media-wrapper {
                  position: relative;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .play-video-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .play-circle {
                      cursor: pointer;
                      background-color: rgba(255, 255, 255, 0.1);
                      width: 50px;
                      height: 50px;
                      border-radius: 50px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }

                  &.items-1 {
                    &:nth-child(1) {
                      grid-column: span 2;
                      grid-row: span 2;
                    }
                  }

                  &.items-2 {

                    &:nth-child(1),
                    &:nth-child(2) {
                      grid-row: span 2;
                    }
                  }

                  &.items-3 {
                    &:nth-child(1) {
                      grid-row: span 2;
                    }
                  }
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .x-poll-wrapper {
              padding: 0 5px;
            }


            .text-wrapper {
              border-top: 1px solid $color-scale-gray-800;
              border-bottom: 1px solid $color-scale-gray-800;
              padding: 10px;

              &.x {
                border-bottom: none;
              }
            }
          }

          .bottom-section {
            padding: 6px 10px;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .empty-metrics {
              border-radius: 6px;
              height: 20px;
              width: 20px;
            }

            .metrics-bar {
              display: flex;
              align-items: center;

              .file-metadata {
                display: block;
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $color-scale-gray-100;
                font-weight: 500;
              }

              .metric-wrapper {
                cursor: pointer;
                padding: 3px 6px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                gap: 2px;

                &:hover {
                  background-color: $color-scale-gray-800;
                }

                span {
                  font-weight: 600;
                  display: block;
                  color: $color-scale-gray-300;
                }
              }
            }

            .empty-description {
              border-radius: 6px;
              height: 20px;
              width: 100%;
            }

            .description {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* Limit to 2 lines */
              -webkit-box-orient: vertical;
            }
          }

        }
      }
    }

    &.sm-image-view {
      border-top: 1px solid $color-scale-gray-100;
      padding: 20px 20px 0 20px;

      .uploader-section {
        .upload-label {
          font-size: 1em;
          font-weight: 500;
          color: map-get($color-scale-gray, 750);
          margin-bottom: 8px;

          .required {
            display: inline;
            color: red;
          }
        }

        .uploaded-attachment-wrapper {
          border-radius: 12px;
          max-height: 300px;
          overflow: hidden;

          .video-wrapper {
            position: relative;
            max-height: 300px;
            height: 100%;
            display: flex;
            justify-content: center;
            background-color: $color-scale-gray-1000;

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 80px;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
              display: flex;
              padding: 10px;
              border-radius: 12px;

              .video-name {
                white-space: nowrap;
                color: $color-scale-gray-850;
                font-weight: 600;
              }
            }

            .uploaded-video {
              width: 100%;
            }
          }

          .images-wrapper {
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            img {
              width: calc(25% - 10px);
              aspect-ratio: 1;
              border-radius: 12px;
              background-color: $color-scale-gray-100;
              /* 50% width with spacing accounted for */
              box-sizing: border-box;
              object-fit: contain;
            }
          }
        }



        .file-upload-zone {
          text-align: center;
          padding: 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          border: 1px solid $color-scale-gray-150;
          box-sizing: border-box;
          flex-direction: column;
          cursor: pointer;
          transition: all 200ms;



          &:hover {
            background-color: $color-scale-gray-50;

            &>.title {
              color: map-get($color-scale-primary, 600);
            }
          }

          .title {
            display: block;
            margin-top: 4px;
            font-weight: 600;
            color: $color-scale-gray-100;
            transition: all 200ms;
          }

          .specs {
            margin: 0;
            margin-top: 8px;
            color: $color-scale-gray-500;
          }
        }

        .upload-hidden {
          display: none;
        }
      }
    }
  }

  .listing-view {
    margin-top: 20px;
    border-top: 1px solid $color-scale-gray-800;

    .header-wrapper {
      padding: 15px 20px 0 20px;
      display: flex;
      align-items: flex-end;
      gap: 10px;
      width: 100%;
      justify-content: space-between;

      .header {
        color: $color-scale-gray-100;
        font-size: 1.1em;
        font-weight: 600;
        margin: 0;
      }

    }

    .card-section {
      padding: 15px 20px 30px 20px;
      background-color: $color-scale-gray-900;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .listing-card {
        cursor: pointer;
        width: 100%;
        padding: 8px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        border-radius: 12px;
        box-sizing: border-box;
        display: flex;
        gap: 12px;
        transition: all 200ms;

        &:hover {
          transform: scale(1.02);
        }

        @include mobile-view {
          &:hover {
            background-color: $color-scale-gray-0; // Reset to default background
            transform: none; // Remove transformation
          }

          &:active {
            background-color: $color-scale-gray-50;
            transform: scale(0.95);
          }
        }

        @include mobile-view {
          flex-direction: column;
        }

        .image-relative-wrapper {
          position: relative;

          .additional-deliverable-wrapper-badge {
            position: absolute;
            top: 4px;
            right: 4px;
            padding: 2px 4px;
            border-radius: 6px;
            background-color: rgba(0, 0, 0, 0.7);
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;

            &.anchor-bottom {
              top: unset !important;
              bottom: 4px !important;
            }

            .badge-text {
              font-weight: 700;
              color: $color-scale-gray-300;
              font-size: 0.9em;
            }
          }
        }

        .preview-image {
          height: 75px;
          aspect-ratio: 1;
          border-radius: 8px;
          object-fit: cover;
          border: $color-scale-gray-100 solid 1px;

          &.attachment {
            background-color: $color-scale-gray-100;
            object-fit: contain !important;
          }

          @include mobile-view {
            aspect-ratio: initial;
            width: 100%;
          }
        }

        .preview-file-attachment {
          height: 75px;
          width: 75px;
          overflow: hidden;
          border-radius: 8px;
          border: $color-scale-gray-100 solid 1px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px;
          box-sizing: border-box;
          gap: 4px;

          @include mobile-view {
            aspect-ratio: initial;
            width: 100%;
          }

          .preview-file-name {
            display: block;
            width: 100%;
            font-size: 0.9em;
            color: $color-scale-gray-600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .preview-text-attachment {
          height: 75px;
          aspect-ratio: 1;
          border-radius: 8px;
          border: $color-scale-gray-100 solid 1px;
          background: #474bff;
          background: -webkit-linear-gradient(0deg, #f9f9ff 100%, #f0f0f0 0%);
          background: linear-gradient(0deg, #f9f9ff 100%, #f0f0f0 0%);
          display: flex;
          justify-content: center;
          padding: 5px;
          overflow: hidden;

          .text {
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 0.6em;
            font-weight: 600;
            color: $color-scale-gray-600;
          }
        }

        .info-section {
          flex: 1;

          .remarks-span {
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            text-wrap: pretty;
            word-break: break-word;
          }

          .top-section-wrapper {
            display: flex;

            .top-section-info-wrapper {
              flex: 1;

              .card-title {
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-100;
              }

              .metrics-bar {
                display: flex;
                align-items: center;

                .remarks-paragraph {
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1.4em;
                  max-height: 2.8em;
                  margin: 0;
                }

                .file-metadata {
                  display: block;
                  max-width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  color: $color-scale-gray-100;
                  font-weight: 500;
                }

                .metric-wrapper {
                  cursor: pointer;
                  padding: 3px 6px;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  &:hover {
                    background-color: $color-scale-gray-800;
                  }

                  span {
                    font-weight: 600;
                    display: block;
                    color: $color-scale-gray-300;
                  }
                }
              }
            }

            .top-section-btn-wrapper {
              display: flex;
              gap: 8px;

              @include mobile-view {
                display: none;
              }
            }
          }

          .listing-pills-wrapper {
            margin-top: 4px;
            display: flex;
            gap: 4px;
            align-items: center;
          }

          .content-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
          }

        }

        .chevron-button-section {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile-view {
            display: none;
          }
        }

      }
    }

  }

  .evidence-details-view {
    border-top: 1px solid $color-scale-gray-800;

    .header-section {
      display: flex;

      .info-wrapper {
        flex: 1;

        .section-title {
          display: block;
          font-weight: 600;
          color: $color-scale-gray-100;
          font-size: 1.2em;
          margin: 0;
        }
      }
    }

    .sm-top-section {
      width: 100%;
      padding: 8px 20px;
      border-top: 1px solid $color-scale-gray-800;
      border-bottom: 1px solid $color-scale-gray-800;
      display: flex;
      gap: 6px;
      align-items: center;


      .avatar-wrapper {
        width: 28px;
        height: 28px;
        position: relative;

        .social-media-platform-badge {
          position: absolute;
          bottom: -2px;
          right: -4px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: $color-scale-gray-1000;
          border: 1px solid $color-scale-gray-100;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .social-media-avatar {
        width: 28px;
        height: 28px;
        border-radius: 30px;
        object-fit: cover;
      }

      .info-section {
        flex: 1;

        .name-lbl {
          font-weight: 600;
          color: $color-scale-gray-100;
        }
      }
    }

    .sm-content {
      position: relative;
      padding: 0 20px 5px 20px;
      border-bottom: 1px solid $color-scale-gray-800;
      display: flex;
      justify-content: center;
      background-color: $color-scale-gray-800;

      &.slider-enabled {
        display: block;
      }

      &.text-bg {
        background-color: $color-scale-gray-900;
      }

      .sm-right-arrow-wrapper,
      .sm-left-arrow-wrapper {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 200ms;

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      .sm-left-arrow-wrapper {
        left: 20px;
      }

      .sm-right-arrow-wrapper {
        right: 20px;
      }

      .sm-pagination-dots-wrapper {
        padding: 5px 10px 8px 5px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        gap: 0;

        .sm-dot-clickable-area {
          width: 18px;
          height: 20px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            .sm-dot {
              background-color: $color-scale-gray-400;

              &.active {
                background-color: $color-scale-primary-600;
              }
            }
          }

          .sm-dot {
            border-radius: 50%;
            background-color: $color-scale-gray-200;
            width: 8px;
            height: 8px;
            transition: 100ms all;

            &.active {
              background-color: $color-scale-primary-500;
            }
          }

        }


      }

      .attachment-image,
      .attachment-video {
        max-height: 300px;
        overflow: hidden;
        position: relative;
        border-radius: 12px;

        .attachment-overlay {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          width: 100%;
          height: 60px;
          padding: 0 20px;
          padding-top: 10px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 20%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
          opacity: 0;
          transition: all 200ms;

          &.hovered {
            opacity: 1;
          }

          .overlay-content-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .overlay-filename {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 200px;
              width: 100%;
              font-weight: 600;
              color: $color-scale-gray-0;
            }

            .download-wrapper {
              flex: 1;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }

        video {
          width: 100%;
        }
      }

      .attachment-video {
        display: flex;
        justify-content: center;
        background-color: $color-scale-gray-850;
      }

      .attachment-file {
        border: 1px solid $color-scale-gray-100;
        border-radius: 12px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 6px;

        .filename {
          color: $color-scale-gray-100;
          font-size: 1.05em;
          font-weight: 600;

          .size {
            color: $color-scale-gray-500;
            font-weight: 500;
          }
        }

      }

      .sm-video-wrapper {
        width: 100%;
        aspect-ratio: 1;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .attachment-wrapper {
        width: 100%;
        max-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-scale-gray-850;
      }


      .sm-image {
        max-height: 300px;
        height: 100%;
        width: auto;
        max-width: 100%;
      }

      .sm-video {
        height: 100%;
        width: auto;
        object-fit: cover;
      }


      .sm-paragraph {
        min-height: 200px;
        margin: 0;
        padding: 10px 20px;
        background-color: $color-scale-gray-50;
        color: $color-scale-gray-700;
        font-weight: 500;
        font-size: 1.2em;
      }

      .x-tweet-wrapper {
        .x-tweet-text {
          color: $color-scale-gray-300;
          font-weight: 500;
          font-size: 1.2em;
        }

        .x-media-grid-wrapper {
          padding: 0 5px;

          .x-media-grid {
            display: grid;
            grid-gap: 4px;
            width: 100%;
            aspect-ratio: 1;
            border-radius: 12px;
            overflow: hidden;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            border: 1px solid $color-scale-gray-100;

            .x-media-wrapper {
              position: relative;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              .play-video-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-circle {
                  cursor: pointer;
                  background-color: rgba(255, 255, 255, 0.1);
                  width: 50px;
                  height: 50px;
                  border-radius: 50px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

              &.items-1 {
                &:nth-child(1) {
                  grid-column: span 2;
                  grid-row: span 2;
                }
              }

              &.items-2 {

                &:nth-child(1),
                &:nth-child(2) {
                  grid-row: span 2;
                }
              }

              &.items-3 {
                &:nth-child(1) {
                  grid-row: span 2;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .x-poll-wrapper {
          padding: 0 5px;
        }
      }


    }

    .sm-metrics {
      display: flex;
      padding: 8px 17px;
      border-bottom: 1px solid $color-scale-gray-800;
      border-top: 1px solid $color-scale-gray-800;
      gap: 4px;

      .metric-wrapper {
        cursor: pointer;
        padding: 3px 6px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 2px;

        &:hover {
          background-color: $color-scale-gray-800;
        }

        span {
          color: $color-scale-gray-300;
          font-weight: 600;
        }
      }
    }

    .details-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      /* 2 columns with equal width */
      column-gap: 12px;
      row-gap: 20px;
      padding: 0 20px;
    }
  }


  .content-section {
    padding: 0 20px;

    .uploader-section {
      .upload-label {
        font-size: 1em;
        font-weight: 500;
        color: map-get($color-scale-gray, 750);
        margin-bottom: 8px;

        .required {
          display: inline;
          color: red;
        }
      }

      .uploaded-attachment-wrapper {
        border-radius: 12px;
        max-height: 300px;
        overflow: hidden;

        .video-wrapper {
          position: relative;
          max-height: 300px;
          height: 100%;
          display: flex;
          justify-content: center;
          background-color: $color-scale-gray-1000;

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
            display: flex;
            padding: 10px;
            border-radius: 12px;

            .video-name {
              white-space: nowrap;
              color: $color-scale-gray-850;
              font-weight: 600;
            }
          }

          .uploaded-video {
            width: 100%
          }
        }

        .image-wrapper {
          position: relative;

          overflow: hidden;
          height: fit-content;

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
            display: flex;
            padding: 10px;
            border-radius: 12px;

            .image-name {
              white-space: nowrap;
              color: $color-scale-gray-0;
              font-weight: 600;
            }
          }

          img {
            aspect-ratio: 1;
            border-radius: 12px;
            width: 100%;
            background-color: $color-scale-gray-100;
            box-sizing: border-box;
            object-fit: contain;
          }
        }
      }




      .file-upload-zone {
        text-align: center;
        padding: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid $color-scale-gray-150;
        box-sizing: border-box;
        flex-direction: column;
        cursor: pointer;
        transition: all 200ms;



        &:hover {
          background-color: $color-scale-gray-50;

          &>.title {
            color: map-get($color-scale-primary, 600);
          }
        }

        .title {
          display: block;
          margin-top: 4px;
          font-weight: 600;
          color: $color-scale-gray-100;
          transition: all 200ms;
        }

        .specs {
          margin: 0;
          margin-top: 8px;
          color: $color-scale-gray-500;
        }
      }

      .upload-hidden {
        display: none;
      }
    }

  }


}

.heic-conversion-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}