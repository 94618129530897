@import "../../styles/main.scss";

// Calendar Overview
/* Remove default background and border from events */
.fc .fc-daygrid-event,
.fc .fc-daygrid-block-event .fc-event,
.fc .fc-timegrid-event {
  background: transparent !important;
  border: none !important;
}

.custom-event {
  background: transparent;
  border: none;
}

.fc-event-main-frame,
.fc-event-main {
  position: static !important;
  padding: 0 !important;
}

.fc-timegrid-slot,
.fc-timegrid-slot-label,
.fc-timegrid-slot-lane {
  border: none !important;
  padding: 0 !important;
}
.fc-scroller-harness {
  overflow: unset !important;
}
.fc-daygrid-event {
  z-index: unset !important;
}
.fc .fc-daygrid-body {
  .fc-daygrid-event-harness {
    padding: 4px;
    right: 0 !important;
    // position: relative !important;
    z-index: auto !important;
    top: 0 !important;
    left: 0 !important;
    margin-top: 0 !important;
  }

  tr:first-child {
    .calendar-event-detail {
      top: 100% !important;
    }
  }

  tr:last-child {
    .calendar-event-detail {
      top: 0 !important;
    }
  }

  tr:not(.fc-col-header) {
    td:first-child,
    td:nth-child(2) {
      .calendar-event-detail {
        left: 105%;
        transform: translate(0, -50%);
      }
    }

    td:last-child,
    td:nth-child(6) {
      .calendar-event-detail {
        left: auto;
        right: 105%;
        transform: translate(0, -50%);
      }
    }
  }
}
.fc-daygrid-day-bottom {
  padding: 2px 2px 4px 2px;

  .fc-daygrid-more-link.fc-more-link {
    width: 100%;
    padding: 4px 2px;
    font-weight: 600;
  }
}

.fc-popover.fc-more-popover {
  z-index: 49 !important;
}

.fc-popover-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .calendar-event-detail {
    width: 120% !important;
  }
}

.calendar-event-content-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  background: $color-scale-gray-50;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;

  .card-highlight {
    border-radius: 4px 0 0 4px;
    width: 4px;
    background-color: $color-scale-green-500;
  }

  .summarize-content {
    box-sizing: border-box;
    width: calc(100% - 8px);
    padding: 8px 2px 8px 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 6px;

    .event-title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      .text-wrapper {
        overflow: hidden;
        white-space: normal;
        > .event-title {
          font-size: 1.1em;
          color: $color-scale-gray-100;
          font-weight: 600;
          margin: 0;
          margin-bottom: 2px;
        }
        > .event-desc {
          font-size: 1em;
          color: $color-scale-gray-100;
          margin: 0;
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
    .event-time {
      font-size: 0.8em;
      color: $color-scale-gray-300;
      > span {
        text-transform: capitalize;
        margin-right: 2px;
        color: $color-scale-green-500;
        font-weight: 600;
        box-sizing: border-box;
        padding: 3px;
        border-radius: 6px;
        background-color: $color-scale-green-100;
      }
    }
  }

  &.failed {
    .card-highlight {
      background-color: $color-scale-red-500;
    }
    .event-time {
      > span {
        color: $color-scale-red-500;
        background-color: $color-scale-red-100;
      }
    }
  }
  &.scheduled {
    .card-highlight {
      background-color: $color-scale-primary-500;
    }
    .event-time {
      > span {
        color: $color-scale-primary-500;
        background-color: $color-scale-primary-100;
      }
    }
  }
  &.draft {
    .card-highlight {
      background-color: $color-scale-yellow-500;
    }
    .event-time {
      > span {
        color: $color-scale-yellow-700;
        background-color: $color-scale-yellow-100;
      }
    }
  }
  &.in-progress {
    .card-highlight {
      background-color: #ff6f00;
    }
    .event-time {
      > span {
        color: #ff6f00;
        background-color: #ffecb3;
      }
    }
  }

  .event-icons {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    justify-self: flex-end;
    .event-icon-wrapper {
      width: 20px;
      height: 20px;
      border-radius: 45px;
      border: 1px solid map-get($color-scale-gray, 150);
      display: flex;
      align-items: center;
      justify-content: center;
      &.facebook {
        background-color: map-get($color-scale-primary, 500);
      }
      &.instagram {
        background: linear-gradient(45deg, #ffdd55, #ff543e, #c837ab);
      }
      &.tiktok,
      &.x {
        background-color: map-get($color-scale-gray, 750);
      }
    }
  }
  .calendar-event-detail {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999 !important;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    // height: 200%;
    width: 150%;
    max-width: 300px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: $color-scale-gray-900;
    color: $color-scale-gray-1000 !important;

    .calendar-event-media {
      position: relative;
      width: 100%;
      height: 100px;
      max-height: 100px;
      overflow: hidden;
      border-radius: 10px 10px 0 0;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      // for play icon on video
      > div {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-size: 1.2em;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .detail-wrapper {
      width: 100%;
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .detail-title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      p {
        margin: 0;
      }
      .detail-bottom-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .event-icons {
          display: flex;
          gap: 4px;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: nowrap;
          margin: 0;
          overflow: hidden;
          .event-icon-wrapper {
            min-width: 20px;
          }
        }
        .post-creator-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    .calendar-event-detail {
      visibility: visible;
      opacity: 1;
    }
  }
}
.emp-brand-social-media-manager-page {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
  box-sizing: border-box;

  .page-header {
    color: $color-scale-gray-100;
    font-size: 1.6em !important;
  }

  .context-section {
    width: 100%;
  }

  .header-space-between {
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .connect-section {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    > h2 {
      font-size: 1.1em;
      font-weight: 600;
      margin: 0;
    }
    .expired-warning {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      padding: 8px;
      border-radius: 8px;
      background-color: map-get($color-scale-red, 100);
      font-size: 0.9em;
      font-weight: 600;
      > p {
        margin: 0;
      }
    }
    .connects-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      &.loading {
        width: 100%;
        height: 50px;
      }
    }
  }

  .profile-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 4px 8px 4px;
    border-radius: 8px;
    cursor: pointer;
    &.is-expired {
      background-color: map-get($color-scale-red, 100);
    }
    .picture-section {
      position: relative;
      width: 36px;
      height: 36px;
      img {
        max-width: 36px;
        max-height: 36px;
        border-radius: 45px;
        border: 1px solid map-get($color-scale-gray, 150);
      }

      .social-media-bubble {
        position: absolute;
        bottom: -1px;
        right: -1px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 0px 1px map-get($color-scale-primary, 150);
        &.facebook {
          background-color: map-get($color-scale-primary, 500);
        }
        &.instagram {
          background: linear-gradient(45deg, #ffdd55, #ff543e, #c837ab);
        }
        &.tiktok,
        &.x {
          background-color: map-get($color-scale-gray, 750);
        }
      }
    }

    .info-section {
      flex: 1;
      margin-left: 10px;

      .handle-name-lbl {
        display: block;
        white-space: nowrap;
        font-weight: 600;
        color: $color-scale-gray-300;
      }

      .metric-lbl {
        margin-top: 2px;
        display: block;
        white-space: nowrap;
        font-size: 0.9em;
        color: map-get($color-scale-gray, 500);
      }
    }
  }

  // modal
  .brand-social-media-manager-modal {
    .profile-card-section {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .profile-card-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid map-get($color-scale-gray, 50);
        background: map-get($color-scale-gray, 0);
        box-shadow: 0px 16px 40px 0px rgba(34, 40, 91, 0.08);
      }
    }
    .platform-icon-wrapper {
      width: 36px;
      height: 36px;
      border-radius: 45px;
      border: 1px solid map-get($color-scale-gray, 150);
      display: flex;
      align-items: center;
      justify-content: center;
      &.facebook {
        background-color: map-get($color-scale-primary, 500);
      }
      &.instagram {
        background: linear-gradient(45deg, #ffdd55, #ff543e, #c837ab);
      }
      &.tiktok,
      &.x {
        background-color: map-get($color-scale-gray, 750);
      }
    }
    .max-connected-card-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid map-get($color-scale-gray, 50);
      background: map-get($color-scale-gray, 0);
      box-shadow: 0px 16px 40px 0px rgba(34, 40, 91, 0.08);
      > img {
        width: 48px;
        height: 48px;
      }
      .header {
        margin-bottom: 0px;
        font-weight: 700;
        color: $color-scale-gray-100;
        font-size: 1.4em;
      }

      .description {
        text-align: center;
        line-height: 1.4em;
        color: map-get($color-scale-gray, 500);
        line-height: 150%;
        max-width: 30ch;
      }
    }
  }

  // tabs
  .brand-social-media-manager-calendar-tab {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    .calendar-sidebar-wrapper {
      min-width: 200px;
      position: relative;
    }
    .main-content {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      background-color: map-get($color-scale-gray, 900);
      border-radius: 12px;
      width: 100%;
      padding-top: 24px;
      box-sizing: border-box;
      box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
      .header-content-wrapper {
        padding: 0 16px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .left-side {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 16px;
          > h3 {
            font-weight: 600;
            font-size: 1.4em;
          }
          > span {
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
              transition: all 0.3s ease-in-out;
            }
          }
          .switch-view-wrapper {
            border-radius: 8px;
            padding: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            border: 1px solid map-get($color-scale-primary, 500);
            color: $color-scale-primary-500;
            > p {
              margin: 0;
            }
            > span {
              transition: all 0.3s ease-in-out;

              padding: 8px;
              border-radius: 8px;
              cursor: pointer;
              &.active {
                background-color: map-get($color-scale-primary, 500);
                color: #ffffff;
              }
            }
          }
        }
        .right-side {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
        }
      }

      .overview-calendar-wrapper {
        position: relative;
        width: 100%;
        min-height: 50vh;
        .emp-loader-overlay {
          backdrop-filter: blur(4px);
        }
      }

      // Post History Tab + Schedule Post Tab Style
      &.post-history-tab,
      &.scheduled-post-tab {
        overflow: auto;
        .refresh-icon-wrapper {
          > div {
            &:hover {
              animation: spinner 1s infinite;
              @keyframes spinner {
                to {
                  transform: rotate(1turn);
                }
              }
            }
            &:active {
              animation: none;
              transform: scale(0.9);
              transition: all 0.3s ease-in-out;
            }
          }
        }
        .creator-record-row {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          overflow: auto;

          .media-wrapper {
            width: 60px;
            min-width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 8px;
            border: 1px solid map-get($color-scale-gray, 100);
            position: relative;
            > div {
              position: absolute !important;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.5);
              color: #ffffff;
              font-size: 1.2em;
              font-weight: 600;
              text-align: center;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: rgba(0, 0, 0, 0.8);
              }
            }
            img,
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .post-wrapper {
            > p {
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }

        .scheduled-time,
        .post-type {
          width: 100%;
          text-align: center;
          p {
            margin: 0;
          }
        }

        .pagination-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
