.emp-pill-wrapper {
  display: inline-block;

  &.w-full {
    width: 100%;

    >.emp-pill {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .emp-pill {
    border-radius: 5px;
    font-size: 0.85em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    white-space: nowrap;
    line-height: initial;

    &.md {
      font-size: 0.85em;
      padding: 4px 5px;
      font-weight: 600;
    }

    &.sm {
      font-size: 0.75em;
      padding: 3px 4px;
      font-weight: 700;
    }

    .emp-pill-icon {
      margin-right: 8px;
    }

    .emp-pill-icon-left {
      margin-right: 2px;
    }

    .emp-pill-icon-right {
      margin-left: 2px;
    }
  }
}