// Margin utilities
// Margin unit size
$m-unit: 4px;

// Margin utilities for left, right, top, and bottom
@for $i from 0 through 10 {
    .ml-#{$i} {
        margin-left: $i * $m-unit !important;
    }

    .mr-#{$i} {
        margin-right: $i * $m-unit !important;
    }

    .mt-#{$i} {
        margin-top: $i * $m-unit !important;
    }

    .mb-#{$i} {
        margin-bottom: $i * $m-unit !important;
    }
}

// Margin utilities for vertical (top and bottom) and horizontal (left and right)
@for $i from 0 through 10 {
    .mv-#{$i} {
        margin: $i * $m-unit 0;
    }

    .mh-#{$i} {
        margin: 0 $i * $m-unit;
    }
}

// Margin utility for all sides
@for $i from 0 through 10 {
    .m-#{$i} {
        margin: $i * $m-unit;
    }
}


// Padding utilities
// Padding unit size
$p-unit: 4px;

// Padding utilities for left, right, top, and bottom
@for $i from 0 through 10 {
    .pl-#{$i} {
        padding-left: $i * $p-unit;
    }

    .pr-#{$i} {
        padding-right: $i * $p-unit;
    }

    .pt-#{$i} {
        padding-top: $i * $p-unit;
    }

    .pb-#{$i} {
        padding-bottom: $i * $p-unit;
    }
}

// Padding utilities for vertical (top and bottom) and horizontal (left and right)
@for $i from 0 through 10 {
    .pv-#{$i} {
        padding-top: $i * $p-unit;
        padding-bottom: $i * $p-unit;
    }

    .ph-#{$i} {
        padding-right: $i * $p-unit;
        padding-left: $i * $p-unit;
    }
}

// Padding utility for all sides
@for $i from 0 through 10 {
    .p-#{$i} {
        padding: $i * $p-unit;
    }
}