@import "../../styles/main.scss";

.emp-kyc-page {
    width: 100%;
    height: 100dvh;
    max-height: 100dvh;
    display: flex;

    @include tablet-view {
        height: fit-content;
        max-height: initial;
        flex-direction: column;
    }

    .info-section {
        flex: 0.4;
        min-width: 400px;
        background-color: $color-scale-primary-100;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include tablet-view {
            flex: 1;
            height: fit-content;
            justify-content: center;
            padding: 20px 0 0 0;
            background-color: $color-scale-gray-900;
        }

        @include mobile-view {
            width: 100%;
            min-width: initial;
            padding: 0;
        }

        .info-section-content {
            position: relative;
            height: 90vh;
            padding: 20px 35px;
            max-width: 400px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 20px 0 0 20px;

            @include tablet-view {
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                max-width: initial;
                width: 500px;
                height: fit-content;
                padding: 20px 25px;
            }

            @include mobile-view {
                border-radius: 0;
            }

            .back-btn-wrapper {
                display: none;

                @include tablet-view {
                    display: block;
                }
            }

            .logo-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                .logo-and-back-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    @include tablet-view {
                        display: none;
                    }
                }

                .logo {
                    width: 120px;
                    height: auto;

                }
            }

            .back-link-wrapper {
                display: block;

                @include tablet-view {
                    display: none;
                }
            }

            .mobile-header {
                margin: 0;
                font-size: 1.4em;
                color: $color-scale-gray-100;
                display: none;


                @include tablet-view {
                    display: block;
                }
            }

            .header {
                margin: 0;
                margin-top: 30px;
                font-size: 2em;
                color: $color-scale-gray-100;


                @include tablet-view {
                    display: none;
                }
            }

            .support-card-wrapper {
                margin-top: 20px;
                max-width: 600px;
                display: flex;

                @include tablet-view {
                    width: 100%;
                    display: none;
                }

                .support-card {
                    background-color: rgba(255, 255, 255, 0.7);
                    border: 1px solid $color-scale-gray-800;
                    padding: 10px 16px 10px 10px;
                    width: 100%;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .image-wrapper {

                        img,
                        .avatar {
                            width: 48px;
                            height: 48px;
                            border-radius: 8px;
                        }

                        img {

                            border: 1px solid map-get($color-scale-gray, 100)
                        }

                        .avatar {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .initials {
                                font-size: 1.8em;
                                color: map-get($color-scale-gray, 0);
                                font-weight: 700;
                            }
                        }
                    }

                    .details-wrapper {
                        flex: 1;
                        overflow: hidden;
                        width: 100%;

                        .name-lbl {
                            font-size: 1.1em;
                            font-weight: 600;
                            color: $color-scale-gray-100;
                        }

                        .email-lbl {
                            color: $color-scale-gray-300;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .action-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }

                }
            }

            .step-wrapper {
                @include tablet-view {
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;
                }

                .step {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 8px;
                    border-radius: 8px;
                    background-color: rgba(255, 255, 255, 0.7);
                    border: 1px solid map-get($color-scale-gray, 100);
                    cursor: pointer;
                    box-sizing: border-box;
                    transition: all 200ms;

                    @include tablet-view {
                        max-width: calc(50% - 6px);
                        overflow: hidden;
                        width: 100%;
                    }

                    @include mobile-view {
                        max-width: initial;
                        overflow: hidden;
                        min-width: 240px;
                        width: 100%;
                    }

                    &:hover {
                        transform: translateY(-3px);
                        background-color: map-get($color-scale-gray, 50);
                    }

                    &:not(:first-child) {
                        margin-top: 8px;

                        @include tablet-view {
                            margin-top: 0;
                        }
                    }

                    .unchecked,
                    .done,
                    .in-progress,
                    .skipped {
                        width: 20px;
                        height: 20px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .unchecked {
                        background-color: map-get($color-scale-gray, 100);
                    }

                    .skipped {
                        background-color: #ffc107;
                    }

                    .done {
                        background-color: map-get($color-scale-green, 500);
                    }

                    .in-progress {
                        border: 2px solid map-get($color-scale-primary, 400);
                        background-color: map-get($color-scale-primary, 200);
                        box-sizing: border-box;
                    }

                    .step-label {
                        font-weight: 600;
                        color: $color-scale-gray-300;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

    }

    .form-section {
        flex: 0.6;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        background-color: $color-scale-gray-900;
        align-items: center;

        @include tablet-view {
            flex: 1;
            justify-content: center;
            padding: 20px 0 80px 0;
        }

        @include mobile-view {
            padding: 0;
        }

        .form-section-content {
            position: relative;
            height: 90vh;
            max-width: 600px;
            width: 100%;
            overflow: hidden;
            background-color: $color-scale-gray-900;
            border-radius: 0 20px 20px 0;

            @include tablet-view {
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 500px;
                height: fit-content;
            }

            @include mobile-view {
                border-radius: 0;
                margin-top: 20px;
            }

            .scroll-up-indicator,
            .scroll-down-indicator {
                pointer-events: none;
                position: absolute;
                left: 0;
                width: 100%;
                height: 60px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                z-index: 10;


                .wrapper {
                    margin-bottom: 10px;
                    display: flex;
                    gap: 2px;
                    align-items: center;
                    justify-content: center;
                    animation: moveUpDown 2s infinite ease-in-out; // Adjust the duration (2s) as needed

                    .scroll-lbl {
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }
                }

                @keyframes moveUpDown {

                    0%,
                    100% {
                        transform: translateY(0);
                    }

                    50% {
                        transform: translateY(-5px);
                    }
                }
            }

            .scroll-down-indicator {
                bottom: 0;
                background: linear-gradient(0deg, rgba(240, 240, 240, 1) 30%, rgba(255, 255, 255, 0) 100%);
            }

            .scroll-up-indicator {
                top: 0;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, rgba(240, 240, 240, 1) 100%, );
            }

            .form-section-wrapper {
                height: 100%;
                overflow-y: auto;
                width: 100%;

                @include tablet-view {
                    overflow-y: hidden;
                }

                .form-header {
                    margin: 0;
                    font-size: 1.8em;
                    margin-top: 75px;
                    color: $color-scale-gray-100;

                    @include tablet-view {
                        font-size: 1.4em;
                        margin-top: 30px;
                    }


                }

                .form-header-card {
                    padding: 15px;
                    border-radius: 12px;
                    border: 1px solid $color-scale-primary-150;
                    background: #e9f2fe;
                    background: -webkit-linear-gradient(224deg, #e9f2fe 0%, #d9e8fa 100%);
                    background: linear-gradient(224deg, #e9f2fe 0%, #d9e8fa 100%);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                    &.rejected {
                        border: 1px solid map-get($color-scale-red, 100);
                        background: #e9f2fe;
                        background: -webkit-linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);
                        background: linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);

                        .form-header-card-title {
                            color: map-get($color-scale-red, 500);
                        }
                    }

                    &.approved {
                        border: 1px solid map-get($color-scale-green, 100);
                        background: #e9f2fe;
                        background: -webkit-linear-gradient(224deg, #e9feeb 0%, #d9fae1 100%);
                        background: linear-gradient(224deg, #e9feeb 0%, #d9fae1 100%);

                        .form-header-card-title {
                            color: map-get($color-scale-green, 500);
                        }
                    }

                    .form-header-card-title {
                        font-size: 1.1em;
                        font-weight: 600;
                        color: $color-scale-primary-700;
                    }
                }

                .kyc-form {
                    width: 100%;
                    padding-bottom: 30px;
                    border-radius: 0 0 20px 20px;

                    @include mobile-view {
                        padding-bottom: 160px;
                    }
                }

                .form-subsection {
                    border-top: 1px solid $color-scale-gray-800;
                    border-bottom: 1px solid $color-scale-gray-800;
                    padding-bottom: 15px;
                    margin-bottom: 10px;
                }

                .form-label-wrapper {
                    width: 100%;
                    background-color: $color-scale-gray-900;
                    padding: 6px 6px 6px 40px;
                    margin-bottom: 20px;

                    @include tablet-view {
                        padding: 6px 25px;
                        max-width: initial;
                        width: 100%;
                    }

                    span {
                        color: $color-scale-gray-300;
                        font-weight: 600;
                    }
                }

                .form-control-wrapper {
                    max-width: 450px;
                    padding: 0px 5px 0px 40px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    @include tablet-view {
                        padding: 0 25px;
                        max-width: initial;
                        width: 100%;
                    }

                    .split-forms {
                        display: flex;
                        gap: 8px;

                        .split-form-wrapper {
                            flex: 1;
                        }
                    }

                    .dispute-box {
                        padding: 6px 8px 6px 8px;
                        margin-bottom: 10px;
                        border-radius: 8px;
                        width: 100%;
                        border: 1px solid map-get($color-scale-red, 100);
                        background-color: #fef2f2;

                        .reviewer-comment {
                            display: block;
                            color: map-get($color-scale-red, 500);
                            font-weight: 700;
                            font-size: 0.8em;
                        }

                        .comment {
                            font-size: 0.9em;
                            margin: 0;
                            margin-top: 4px;
                            color: $color-scale-gray-100;
                        }
                    }
                }



                .section-wrapper {
                    padding: 0px 5px 0px 40px;
                    max-width: 450px;
                    display: flex;
                    flex-direction: column;

                    @include tablet-view {
                        padding: 0 25px;
                        max-width: initial;
                        width: 100%;
                    }
                }

                .upload-section-label {
                    display: flex;
                    align-items: center;
                    font-size: 1em;
                    font-weight: 500;
                    color: map-get($color-scale-gray, 750);
                    margin-bottom: 4px;

                    .required {
                        display: inline;
                        color: red;
                    }
                }
            
                .upload-description {
                    font-size: 0.95em;
                    color: map-get($color-scale-gray, 500);
                    line-height: 1.4em;
                    margin: 0;
                    margin-bottom: 8px;
                }

                .upload-section {
                    cursor: pointer;
                    border-radius: 8px;
                    padding: 25px 20px;
                    width: 100%;
                    border: 1px solid $color-scale-gray-800;
                    background-color: $color-scale-gray-900;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    flex-direction: column;
                    gap: 4px;
                    transition: all 200ms;

                    &:hover {
                        transform: translateY(-5px);
                        border: 1px solid $color-scale-gray-800;
                        background-color: $color-scale-gray-900;
                    }

                    .upload-icon {
                        height: 40px;
                        width: auto;
                    }

                    .title {
                        display: block;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }

                    .description {
                        font-size: 0.95em;
                        color: $color-scale-gray-300;
                    }
                }

                .btn-wrapper {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }
    }

    .review-box {
        border-radius: 12px;
        border: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 8px 0 15px 0;
        width: 100%;


        .title-section {
            padding: 0px 15px 8px 15px;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .title-wrapper {
                display: flex;
                gap: 4px;
                align-items: center;

                span {
                    color: $color-scale-gray-100;
                    font-weight: 600;
                }
            }


        }

        .review-info-section {
            padding: 0 15px 0 15px;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 14px;

            .content-wrapper {
                min-width: calc(50% - 5px);
            }

            .country-wrapper {
                display: flex;
                gap: 4px;
                overflow: hidden;

                .img-flag {
                    height: 16px;
                    border: solid 1px map-get($color-scale-gray, 150);
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }

        .review-subsection {
            padding: 6px 15px 6px 15px;
            background-color: $color-scale-gray-900;
            width: 100%;
            margin: 12px 0;

            span {
                color: $color-scale-gray-300;
                font-weight: 600;
            }
        }
    }

    .review-btn-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }




    .platform-card {
        display: flex;
        border-radius: 12px;
        border: 1px solid map-get($color-scale-gray, 100);
        background-color: map-get($color-scale-gray, 50);
        padding: 8px;


        .left-elem {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .platform-logo {
            background-color: map-get($color-scale-gray, 150);
            width: 45px;
            min-width: 45px;
            min-height: 45px;
            height: 45px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.empty-state {
                background-color: map-get($color-scale-gray, 800);
            }
        }



        .social-media-img-wrapper {
            position: relative;
            display: flex;

            .social-media-logo-placeholder {
                position: absolute;
                right: -2px;
                bottom: -2px;
                width: 20px;
                height: 20px;
                border: 1.5px solid map-get($color-scale-gray, 100);
                border-radius: 50px;
                background-color: #1778F2;
                display: flex;
                align-items: center;
                justify-content: center;


            }


            .social-media-image {
                object-fit: contain;
                width: 45px;
                height: 45px;
                border-radius: 8px;
            }

        }


        .content {
            margin-left: 12px;

            .status-lbl {
                display: block;
                font-weight: 600;
                color: $color-scale-gray-300;
            }

            .action-lbl {
                margin-top: 4px;
                display: block;
                color: map-get($color-scale-gray, 500);

            }
        }
    }

    .acknowledgement-wrapper {
        display: flex;

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            flex-direction: column;
            background-color: $color-scale-primary-100;
            padding: 15px;
            border-radius: 12px;
            width: 100%;

            .intro-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                span {
                    color: $color-scale-primary-600;
                    font-weight: 700;
                }
            }

            .control-wrapper {
                display: flex;

                .checkbox-text-wrapper {
                    cursor: pointer;
                    flex: 1;

                    span {
                        line-height: 1.4em;
                        font-weight: 400;
                        color: $color-scale-gray-100;

                        &.checked {
                            font-weight: 500;
                        }
                    }
                }
            }

        }
    }
}