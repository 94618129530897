@import "../../../styles/main.scss";
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&display=swap');

.emp-settings-brand-payment-view {
    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    @include mobile-view {
        padding-left: 0px;
    }

    .section-header {
        color: $color-scale-gray-100;
        font-size: 1.8em;
        margin: 0;
    }

    .view-header-wrapper {
        display: flex;
        gap: 10px;

        .text-wrapper {
            flex: 1;

            .text-status-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 10px;


            }

            .page-description {
                line-height: 1.4em;
                color: $color-scale-gray-300;
                margin: 0;
            }
        }


        h1 {
            font-size: 1.8em;
            color: $color-scale-gray-100;
            margin: 0 0 10px 0;
        }

    }

    .tabbed-option-wrapper {
        padding: 5px;
        width: fit-content;
        border-radius: 10px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        display: flex;
        gap: 4px;

        .tabbed-option {
            cursor: pointer;
            height: 24px;
            padding: 0 10px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            &.active {
                background-color: $color-scale-gray-850;
                cursor: not-allowed;
            }


            span {
                font-weight: 600;
                color: $color-scale-gray-400;
                transition: color 200ms;

                &:hover {
                    color: $color-scale-gray-300;
                }
            }

            &.active {
                span {
                    color: $color-scale-gray-300;
                }
            }

        }
    }

    .card-wrapper {
        margin-top: 20px;
        display: flex;

        .payout-wrapper {
            @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);
            position: relative;
            overflow: hidden;
            display: flex;
            padding: 20px;
            border: 1px solid $color-scale-gray-800;
            background: $cbz-card-gradient-md;
            border-radius: 12px;
            box-sizing: border-box;
            flex-direction: column;
            width: 100%;

            .svg-wrapper {
                opacity: 0.1;
                position: absolute;
                right: 0;
                top: -200px;
                width: 100%
            }


            .bank-name-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .bank-logo {
                    width: 30px;
                    height: 30px;
                    border-radius: 8px;
                }

                .bank-name-header {
                    margin: 0;
                    color: $color-scale-gray-100;
                    font-weight: 700;
                    font-size: 1.5em;
                }
            }



            .bank-account-number,
            .bank-details-text {
                font-size: 1.1em;
                color: $color-scale-gray-300;
                font-weight: 600;
            }

            .bank-account-number {
                font-family: 'Inconsolata', monospace;
                font-weight: 600;
                letter-spacing: 0.2em;
                font-size: 1.25em;
            }

            .loader-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }



            .trash-btn,
            .edit-btn {
                z-index: 1;
                position: absolute;
                cursor: pointer;
                right: -6px;
                width: 30px;
                height: 30px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 200ms;

                &:hover {
                    transform: scale(1.1);
                }
            }

            .edit-btn {
                top: -6px;
                background-color: map-get($color-scale-gray, 800);
            }

            .trash-btn {
                top: 30px;
                background-color: map-get($color-scale-red, 400);
            }

        }

        .campaign-payment-user {
            display: flex;
            gap: 10px;
            align-items: center;

            .profile-wrapper {
                position: relative;

                .profile {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    border: 1px solid $color-scale-gray-800;
                    object-fit: cover;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.profile-badge {
                        position: absolute;
                        bottom: 0;
                        right: -4px;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        object-fit: cover;
                    }

                    .initials {
                        color: $color-scale-gray-0;
                        font-size: 1.4em;
                        font-weight: 600;
                    }
                }

            }
        }

        .info-wrapper {
            .creator-name {
                display: block;
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .representative-lbl {

                display: block;
                color: $color-scale-gray-300;
            }
        }

        .table-overlay-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            img {
                height: 60px;

            }

            .header {
                margin-top: 8px;
                margin-bottom: 0px;
                font-weight: 700;
                color: $color-scale-gray-100;
                font-size: 1.4em;
            }

            .description {
                margin-top: 8px;
                line-height: 1.4em;
                color: $color-scale-gray-300;
            }
        }

    }


}