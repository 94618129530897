@import "../../../styles/main.scss";


.emp-link {
    // display: flex;
    // align-items: center;
    font-size: 1em;
    font-weight: 600;
    gap: 2px;
    text-decoration: none;
    transition: all 150ms;
    cursor: pointer;

    &.disabled {
        color: map-get($color-scale-gray, 500);
    }

    &.white {
        color: map-get($color-scale-gray, 0);
    }

    &.primary {
        color: $color-scale-primary-350;
        &:hover {
            color: $color-scale-primary-250;
        }
    }

    &.danger {
        color: map-get($color-scale-red, 600);

        &:hover {
            color: map-get($color-scale-red, 700);
        }
    }

    &.secondary {
        color: $color-scale-gray-300;

        &:hover {
            color: $color-scale-gray-300;
        }
    }

    &.primary-lighter {
        color: map-get($color-scale-primary, 400);

        &:hover {
            color: map-get($color-scale-primary, 500);
        }
    }

}