@import "../../styles/main.scss";


.emp-about-us-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .uploaded-content-section,
    .about-us-form-section {
        padding: 0 20px;
    }

    .uploaded-content-section {

        .header-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header {
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

        }


        .uploaded-files-section {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-direction: column;
            position: relative;

            .empty-state {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 8.77%, #FFFFFF 92.91%);

                .empty-state-lbl {
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }
            }

            .file-card {

                border: 1px solid map-get($color-scale-gray, 100);
                background-color: map-get($color-scale-gray, 50);
                padding: 6px 12px 6px 6px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: all 200ms;

                &:hover {
                    transform: translateY(-4px);
                }

                .left-wrapper {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    gap: 10px;

                    .image-section {
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        background-color: map-get($color-scale-primary, 500);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .text-section {
                        .file-title {
                            display: block;
                            font-weight: 600;
                            color: $color-scale-gray-300;
                        }

                        .file-name {
                            display: block;
                            margin-top: 3px;
                            color: map-get($color-scale-gray, 500);
                        }
                    }
                }


            }

            .file-card-skeleton {

                border: 1px solid map-get($color-scale-gray, 100);
                background-color: map-get($color-scale-gray, 50);
                padding: 6px 12px 6px 6px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                gap: 10px;


                .left-wrapper {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    gap: 10px;

                    .image-section {
                        width: 36px;
                        height: 36px;
                        border-radius: 6px;
                        background-color: map-get($color-scale-gray, 100);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .text-section {
                        .file-title-skeleton {
                            width: 80px;
                            border-radius: 4px;
                            height: 14px;
                            background-color: map-get($color-scale-gray, 100);
                        }

                        .file-name-skeleton {
                            margin-top: 5px;
                            width: 170px;
                            border-radius: 4px;
                            height: 12px;
                            background-color: map-get($color-scale-gray, 100);
                        }
                    }
                }


            }
        }
    }
}