@import "../../styles/main.scss";



.emp-coming-soon-page {
    display: flex;
    align-items: center;

    .coming-soon-wrapper {
        display: flex;
        background: linear-gradient(73.5deg, #121111 0%, #596075 80%);
        width: 100%;
        padding: 30px;
        box-sizing: border-box;
        mask-image: paint(squircle);
        --squircle-radius: 30px;
        --squircle-smooth: 4;

        @include tablet-view {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }


        .title-section {
            max-width: 400px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h2 {
                margin: 0;

                font-weight: 700;
                font-size: 2.4em;
                color: $color-scale-gray-0;
            }

            p {
                margin: 0;
                margin-top: 16px;
                font-size: 1.2em;
                font-weight: 500;
                color: $color-scale-gray-300;
                line-height: 1.4em;
            }
        }

        .image-section {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            height: fit-content;

            .campaign-teaser-img {
                max-height: 230px;

                @include tablet-view {
                    display: none;
                }
            }
        }

    }
}