@import "../../../styles/main.scss";

.emp-sm-recruitment-card {
    width: 100%;

    .skeletal-card,
    .loading-card,
    .recruitment-card {
        background: $cbz-card-gradient-lg;
        border: 1px solid $color-scale-gray-800;
        border-radius: 8px;
        padding: 12px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;

        .bio-section {
            display: flex;
            gap: 8px;

            .image-wrapper {
                position: relative;
                min-width: 42px;
                min-height: 42px;
                width: 42px;
                height: 42px;

                &.skeleton {
                    background-color: $color-scale-gray-800;
                    border-radius: 42px;
                }

                .sm-icon {
                    position: absolute;
                    right: -3px;
                    bottom: 0;
                    background-color: $color-scale-gray-800;
                    width: 18px;
                    height: 18px;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid map-get($color-scale-gray, 100);
                }

                .profile-img,
                .profile-avatar {

                    width: 100%;
                    height: 100%;

                    border: 1px solid map-get($color-scale-gray, 100);
                    border-radius: 84px;
                }

                .profile-img {
                    object-fit: cover;
                }

                .profile-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 1.1em;
                        font-weight: 700;
                        color: $color-scale-gray-0
                    }
                }
            }

            .account-details-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow: hidden;

                .email,
                .name {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                .name {
                    font-size: 1.05em;
                    font-weight: 600;
                    color: $color-scale-gray-100;

                    &.skeleton {
                        width: 80px;
                        height: 16px;
                        background-color: $color-scale-gray-100;
                        border-radius: 4px;
                    }
                }

                .email {
                    margin-top: 2px;
                    font-size: 0.9em;
                    font-weight: 500;
                    color: map-get($color-scale-gray, 500);

                    &.skeleton {
                        margin-top: 4px;
                        width: 120px;
                        height: 14px;
                        background-color: $color-scale-gray-100;
                        border-radius: 4px;
                    }
                }
            }
        }

        .metrics-section {
            display: flex;
            gap: 8px;
            overflow: hidden;

            .country-wrapper {
                display: flex;
                gap: 4px;
                overflow: hidden;

                .img-flag {
                    height: 16px;
                    border: solid 1px map-get($color-scale-gray, 150);
                }

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .agency-wrapper {
                display: flex;
                gap: 2px;


            }

            .metric-left {
                flex: 1;
            }

            .metric-right {
                flex: 1;
            }
        }

        .pill-section {
            width: 100%;

            .hidden-section {
                height: 0;
                visibility: hidden;
            }

            .pill-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }

        }

        .action-section {
            flex: 1;
            gap: 10px;
            display: flex;
            align-items: flex-end;

            .btn-wrapper {
                flex: 1
            }
        }
    }

    .recruitment-card {
        cursor: pointer;
        transition: all 200ms;

        &:hover {
            border: 1px solid $color-scale-primary-600;
            background-color: $color-scale-primary-100
        }
    }

    .skeletal-card {
        box-shadow: none;
        height: 100%;
    }


    .loading-card {
        height: 300px;
    }


}