@import "../../styles/main.scss";


.emp-upload-file-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .content-section {
        padding: 20px;


        .uploader-section {
            .upload-label {
                font-size: 1em;
                font-weight: 500;
                color: map-get($color-scale-gray, 750);
                margin-bottom: 8px;

                .required {
                    display: inline;
                    color: red;
                }
            }

            .file-upload-zone {
                text-align: center;
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid $color-scale-gray-800;
                box-sizing: border-box;
                flex-direction: column;
                cursor: pointer;
                transition: all 200ms;



                &:hover {
                    background-color: $color-scale-gray-900;

                    &>.title {
                        color: map-get($color-scale-primary, 600);
                    }
                }

                .title {
                    display: block;
                    margin-top: 4px;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                    transition: all 200ms;
                }

                .specs {
                    margin: 0;
                    margin-top: 8px;
                    color: $color-scale-gray-300;
                }
            }

            .upload-hidden {
                display: none;
            }
        }

    }

}