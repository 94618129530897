@import "../../styles/main.scss";

.emp-change-password-modal {
    position: relative;
    overflow: hidden;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 0px;
    }

  

    .content-body {
        position: relative;
        // height: 500px;
        display: flex;

        .swipe-slide {
            flex: 1;
            height: fit-content;

            .title-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .back-btn-wrapper {
                    cursor: pointer;
                }

                h3 {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }



            .description {
                color: $color-scale-gray-300;
                line-height: 1.4em;
                margin: 0;

                .highlighted {
                    color: map-get($color-scale-primary, 600);
                    font-weight: 500;
                }
            }


            &.second {
                .second-slide-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    .success-img {
                        margin-top: 10px;
                        height: 90px;
                        width: 90px;
                    }
                }
            }
        }
    }
}