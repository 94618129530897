@import "../../../styles/main.scss";

.emp-multi-filter {
    label {
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        color: $color-scale-gray-100;

    }

    .emp-multi-filter-wrapper {
        position: relative;
        box-sizing: border-box;
        width: fit-content;
        background: linear-gradient(360deg, #11111e -20%, #060610 40%);
        border: 1px solid $color-scale-gray-800;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 10px;


        &.active {
            background-color: $color-scale-gray-650;
        }



        .multi-filter-wrapper {
            display: flex;
            padding: 9px 12px;
            height: 38px;
            align-items: center;
            gap: 10px;

            @include mobile-view {
                height: 45px;
            }

            .multi-filter-label {
                display: block;
                flex: 1;
                color: $color-scale-gray-150;
                font-weight: 600;

                &.active {
                    color: $color-scale-primary-200;
                }
            }
        }

        .dropdown-menu {
            bottom: -10px;
            left: 0;
            transform: translateY(100%);
            position: absolute;
            min-width: 100%;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 8px;
            background: $cbz-card-gradient-md;
            border: 1px solid $color-scale-gray-800;
            padding: 5px 0;
            z-index: 10;
            

            .dropdown-item {
                height: 40px;
                padding: 0 12px;
                border-bottom: 1px solid $color-scale-gray-800;
                white-space: nowrap;
                display: flex;
                align-items: center;

                color: $color-scale-gray-300;
                font-weight: 500;
                transition: all 100ms;
                gap: 8px;
                cursor: pointer;

                &:hover {
                    .checkbox {
                        background-color: $color-scale-gray-150;

                        &.selected {
                            box-shadow: 0 0 0 2px $color-scale-primary-400;
                            background-color: $color-scale-primary-400;
                        }
                    }
                }

                .checkbox {
                    width: 12px;
                    height: 12px;
                    border-radius: 3px;
                    box-shadow: 0 0 0 2px $color-scale-gray-200;
                    background-color: $color-scale-gray-100;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.selected {
                        box-shadow: 0 0 0 2px $color-scale-primary-500;
                        background-color: $color-scale-primary-500;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background-color: $color-scale-gray-850;
                }
            }
        }
    }
}