@import "../../styles/main.scss";

.emp-white-bg-on-mobile {
    @include mobile-view {
        background-color: white !important;
    }
}

.emp-creator-home-page {
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
    box-sizing: border-box;


    .context-section {
        width: 100%;
        @include card-border-conic-gradient-mixin(95deg, 0 0 30px 30px, 49%, 51%, false);

        .colored-bg-section {
            height: 120px;
            background-color: #181030;

            @include mobile-view {
                height: 40px;
            }
        }

        .common-info-section {
            display: flex;
            padding: 10px 20px 20px 20px;
            background: $cbz-card-gradient-sm;
            border-radius: 0 0 20px 20px;

            .image-card {
                position: relative;
                margin-top: -50px;

                @include mobile-view {
                    margin-top: -30px;
                }

                .edit-btn {
                    position: absolute;
                    cursor: pointer;
                    bottom: 0;
                    right: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: $color-scale-gray-800;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 200ms;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                img,
                .avatar {
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;

                    @include mobile-view {
                        width: 80px;
                        height: 80px;
                    }
                }

                img {
                    border: 1px solid $color-scale-gray-800;
                }

                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 3em;
                        color: $color-scale-gray-0;
                        font-weight: 700;
                    }
                }
            }

            .info-wrapper {
                margin-left: 20px;
                flex: 1;
                overflow: hidden;

                .name-lbl {
                    display: block;
                    font-size: 1.4em;
                    font-weight: 600;
                    color: $color-scale-gray-150;
                }

                .email-lbl {
                    display: block;
                    color: $color-scale-gray-400;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }



    // Dashboard Section. To be copied over
    .dashboard-section {
        width: 100%;
        display: flex;
        gap: 12px;


        @include tablet-view {
            flex-direction: column-reverse;
        }

        .left-section {
            flex: 0.4
        }

        .right-section {
            flex: 0.6
        }

        .dashboard-card {
            padding: 20px;
            background: $cbz-card-gradient-lg;
            box-sizing: border-box;



            @include mobile-view {
                border: 1px solid transparent;
                border-radius: 0;
                border-bottom: 1px solid $color-scale-gray-800;
                padding: 20px 5px;
                background-color: transparent;
            }

            &.no-padding {
                padding: 20px 0;
            }

            .card-header {
                font-size: 1.2em;
                font-weight: 700;
                color: $color-scale-gray-100;
                margin: 0;
            }

            .card-description {
                margin: 0;
                color: $color-scale-gray-400;
                line-height: 1.4em;
            }
        }

        .copy-to-clipboard {
            @include card-border-conic-gradient-mixin(95deg, 10px, 49%, 52%);

            .wrapper {
                display: flex;
                align-items: flex-end;

                .button-wrapper {
                    position: relative;
                    top: -12px;
                    margin-left: 10px;
                }
            }
        }

    }
}