@import "../../../styles/main.scss";


.emp-checkbox-group-wrapper {
    width: 100%;
    margin-bottom: 20px;

    label {
        font-size: 14px;
        font-weight: 500;
        color: map-get($color-scale-gray, 300);
        margin-bottom: 8px;
    
        .required {
            display: inline;
            color: red;
        }
    }
    

    p {
        font-size: 0.8em;
        color: map-get($color-scale-gray, 300);
        line-height: 1.4em;
        margin: 0;
        margin-bottom: 12px;
    }
}