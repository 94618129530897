@import "../main.scss";

.emp-proof-of-work-view {
  padding-top: 15px;

  .task-details-section {
    .task-name-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid $color-scale-gray-800;

      .info-wrapper {
        flex: 1;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }

  .section-label {
    margin: 0;
    font-size: 1.4em;
    font-weight: 700;
    color: $color-scale-gray-100
  }

  .deliverable-cards-section {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .draft-not-required-card {
    border: 1px solid $color-scale-gray-150;
    padding: 15px !important;

    .header-lbl {
      font-size: 1.1em;
      font-weight: 600;
      color: $color-scale-gray-100;
    }
  }

  .deliverable-section {

    .deliverable-wrapper {
      padding-top: 20px;
      box-sizing: border-box;

      .deliverable-indicator {
        font-size: 0.9em;
        font-weight: 700;
        color: #7E8591;
      }

      .deliverable-name-lbl {
        margin: 0;
        display: block;
        padding-top: 8px;
        box-sizing: border-box;
        font-size: 1em;
        font-weight: 700;
        color: $color-scale-gray-300
      }

      .deliverable-description {
        display: block;
        padding-top: 8px;
        box-sizing: border-box;
        margin: 0;
        line-height: 1.4em;
        color: $color-scale-gray-300
      }
    }


  }


}