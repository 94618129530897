@import "../../styles/main.scss";

.emp-kanban {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .kanban-body-container {
        display: flex;
        gap: 16px;
        flex: 1;
        overflow: hidden;
        overflow: auto;
    }
    .test-kanban {
        position: fixed;
        left: 0;
        top: 0;
    }

    .kanban-header {
        width: 100%;
        height: fit-content;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        background-color: $color-scale-gray-900;



        .title-lbl {
            display: block;
            flex: 1;
            font-size: 1.05em;
            font-weight: 600;
            color: $color-scale-gray-100;
        }

        .plus-icon-hidden-placeholder {
            position: relative;
            width: 20px;
            height: 20px;


            .plus-icon-wrapper {
                position: absolute;
                top: -10px;
                left: -10px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 10;
                cursor: pointer;

                &:before {
                    position: absolute;
                    z-index: 0;
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-color: $color-scale-gray-800;
                    border-radius: 20px;
                    transition: all 300ms;
                }

                &:hover {
                    &:before {
                        width: 30px;
                        height: 30px;
                        background-color:  $color-scale-gray-700;
                    }

                }
            }

        }

    }

    .kanban-column {
        width: 260px;
        min-width: 260px;
        // height: fit-content;
        min-height: 400px;

        box-sizing: border-box;
        padding: 0 10px 10px 10px;
        border-radius: 8px;
        background-color: $color-scale-gray-900;
        border: $color-scale-gray-800 1px solid;



        .empty-state-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: 350px;

            img {
                width: 100px;
                height: 100px;

                &.done-img {
                    width: 80px;
                    height: auto;
                    margin-top: 12px;
                    margin-bottom: 16px;
                }
            }

            .empty-state-title {
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .empty-state-description {
                line-height: 1.4em;
                color: $color-scale-gray-300;
                font-weight: 500;
            }
        }


        // .vertical-spacer {
        //     height: 50px;
        //     width: 100%;
        // }

        .kanban-card {
            width: 100%;
            border-radius: 8px;
            background-color: $color-scale-gray-850;
            border: 1px solid $color-scale-gray-800;
            padding: 10px 10px 20px 10px;


            .profile-section {
                display: flex;
                gap: 10px;

                .image-wrapper {
                    position: relative;
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;

                    .absolute-wrapper {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        bottom: 0;
                        right: -4px;

                        .profile-photo {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .profile-photo {
                        width: 36px;
                        height: 36px;
                        border-radius: 100px;
                        object-fit: cover;
                        border: 1px solid $color-scale-gray-950;

                        &.org-photo {
                            border-radius: 12px;
                        }
                    }

                    .avatar {
                        display: flex;
                        width: 36px;
                        height: 36px;
                        border-radius: 120px;
                        justify-content: center;
                        align-items: center;

                        .initials {
                            font-size: 1.3em;
                            color: map-get($color-scale-gray, 0);
                            font-weight: 700;
                        }
                    }
                }
            }

            .details-wrapper {
                display: flex;
                flex-direction: column;
                gap: 2px;

                .name-lbl {
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .representative-wrapper {
                    font-size: 0.9em;
                    font-weight: 500;
                    color: $color-scale-gray-300;
                    display: flex;
                    gap: 4px;
                    align-items: center;
                }
            }

            .actions-wrapper {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
            }
        }

        .kanban-description {
            margin: 0;
            font-size: 1em;
            line-height: 1.4em;
            color: $color-scale-gray-300;
        }
    }
}