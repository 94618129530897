@import "../../../styles/main.scss";

.emp-seller-task-summary-view {
    padding-top: 20px;


    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }

    .brand-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .logo {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: 1px solid $color-scale-gray-800;
            object-fit: cover;
        }

        .company-name {
            color: $color-scale-gray-100;
            font-weight: 500;
        }
    }

    .payout-card-banner {
        padding: 20px;
        border-radius: 12px;
        background: $cbz-background-gradient;
        border: 1px solid $color-scale-gray-800;
        display: flex;
        align-items: center;

        .banner-info-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;

            .banner-title {
                font-size: 1.3em;
                color: $color-scale-gray-100;
                font-weight: 700;
            }

            .banner-description {
                margin: 0;
                margin-top: 8px;
                font-weight: 500;
                color: $color-scale-gray-300;
            }
        }

        img {
            width: 100px;
        }
    }

    .task-details-section {
        padding-bottom: 25px;
        border-bottom: 1px solid $color-scale-gray-800;

        .deliverable-section {
            padding: 25px 0;
            border-bottom: 1px solid $color-scale-gray-800;


            .title-wrapper {
                display: flex;
                gap: 10px;
                align-items: center;

                .link-wrapper {
                    position: relative;
                    top: -2px;
                }
            }

            .collapsible-section {
                overflow: hidden;
                transition: height 300ms;

                .deliverable-wrapper {

                    padding: 20px;
                    box-sizing: border-box;
                    background-color: $color-scale-gray-850;
                    border-radius: 12px;
                    margin-top: 10px;

                    .deliverable-indicator {
                        display: block;
                        font-size: 0.9em;
                        font-weight: 700;
                        color: #7E8591;
                    }

                    .deliverable-name-lbl {
                        margin: 0;
                        display: block;
                        padding-top: 8px;
                        box-sizing: border-box;
                        font-size: 1em;
                        font-weight: 700;
                        color: $color-scale-gray-100
                    }

                    .deliverable-description {
                        display: block;
                        padding-top: 8px;
                        box-sizing: border-box;
                        margin: 0;
                        line-height: 1.4em;
                        color: $color-scale-gray-300
                    }
                }

            }


        }

        .task-info-wrapper {
            display: flex;
            align-items: center;
            gap: 12px;

            @include mobile-view {
                flex-direction: column-reverse;
                align-items: flex-start;
            }

            .task-name-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;

                .info-wrapper {
                    flex: 1;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            }

            .task-status-button {
                background: $cbz-card-gradient-md;
                border-radius: 12px;
                padding: 10px 15px 10px 15px;
                outline: 1px solid $color-scale-gray-800;
                display: flex;
                align-items: center;
                gap: 10px;
                max-width: 260px;
                box-sizing: border-box;
                transition: all 200ms;

                @include mobile-view {
                    width: 100%;
                    max-width: initial;
                }

                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.05);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                &.clickable {
                    cursor: pointer;
                    padding-right: 8px;
                    animation: pulse 2s ease 0s infinite normal forwards;

                    &:hover {
                        background: $color-scale-gray-850;
                        outline: 1px solid $color-scale-gray-700;
                        transform: translateY(-2px);
                    }
                }

                .info-wrapper {
                    flex: 1;
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .metric-img {
                        width: 40px;
                        height: auto;

                        &.handshake-img {
                            width: 60px;
                        }

                        &.hourglass-img {
                            width: 23px;
                        }
                    }

                    .metric-title {
                        color: $color-scale-gray-100;
                        font-size: 1em;
                        display: block;
                        font-weight: 700;
                    }

                    .metric-description {
                        display: block;
                        margin-top: 4px;
                        font-size: 0.95em;
                        font-weight: 500;
                        color: $color-scale-gray-300
                    }
                }
            }
        }

        .task-description {
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300;
            word-break: break-word;
        }

        .task-details {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;


        }
    }

    .deliverable-section {
        padding: 25px 0;
        border-bottom: 1px solid $color-scale-gray-800;


        .title-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .link-wrapper {
                position: relative;
                top: -2px;
            }
        }

        .collapsible-section {
            overflow: hidden;
            transition: height 300ms;

            .deliverable-wrapper {

                padding: 20px;
                box-sizing: border-box;
                background-color: $color-scale-gray-850;
                border-radius: 12px;
                margin-top: 10px;

                .deliverable-indicator {
                    display: block;
                    font-size: 0.9em;
                    font-weight: 700;
                    color: #7E8591;
                }

                .deliverable-name-lbl {
                    margin: 0;
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    font-size: 1em;
                    font-weight: 700;
                    color: $color-scale-gray-100
                }

                .deliverable-description {
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    margin: 0;
                    line-height: 1.4em;
                    color: $color-scale-gray-300;
                }
            }

        }


    }

    .events-section {
        padding: 25px 0;

        .table-wrapper {
            border: 1px solid $color-scale-gray-800;
            border-top: none;
        }
    }

    .metrics-section {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .metric-card,
        .metric-card-skeleton {
            min-width: 250px;
            flex: 1;
            min-height: 50px;
        }

        .metric-card {
            position: relative;
            background: $cbz-card-gradient-md;
            @include card-border-conic-gradient-mixin(95deg, 10px, 46%, 52%);
            border-radius: 12px;
            padding: 15px 8px 15px 15px;
            display: flex;
            gap: 10px;
            transition: all 200ms;

            &.clickable {
                cursor: pointer;


                &:hover {
                    transform: translateY(-3px);
                }
            }

            &.disabled {
                cursor: default;
                background-color: $color-scale-gray-100;
            }


            .absolute-pill-wrapper {
                position: absolute;
                top: 15px;
                right: 8px;
            }

            .info-wrapper {
                flex: 1;
                height: fit-content;
                display: flex;
                align-items: center;
                gap: 14px;

                .metric-img {
                    width: 50px;
                    height: auto;
                    animation: verticle-pulse 12s ease 0s infinite normal forwards;

                    &.play-img {
                        width: 40px;
                    }

                    &.handshake-img {
                        width: 60px;
                    }
                }

                .creator-image-wrapper {
                    position: relative;

                    .creator-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        object-fit: cover;

                        .initials {
                            font-size: 2em;
                            color: $color-scale-gray-0;
                            font-weight: 700;
                        }
                    }

                    .badge {
                        position: absolute;
                        bottom: -2px;
                        right: -4px;
                        padding: 3px 6px;
                        border-radius: 8px;
                        background: linear-gradient(180deg, #FF6868 0%, rgba(202, 50, 50, 0.80) 100%);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .value {
                            color: $color-scale-gray-0;
                            font-weight: 700;
                        }
                    }
                }

                @keyframes verticle-pulse {

                    0%,
                    100% {
                        transform: translateY(0);
                    }

                    10%,
                    30%,
                    50%,
                    70% {
                        transform: translateY(-3px);
                    }

                    20%,
                    40%,
                    60% {
                        transform: translateY(3px);
                    }

                    80% {
                        transform: translateY(2.4px);
                    }

                    90% {
                        transform: translateY(-2.4px);
                    }
                }


                .metric-title {
                    color: $color-scale-gray-100;
                    font-size: 1.2em;
                    display: block;
                    font-weight: 700;
                }

                .metric-description {
                    display: block;
                    margin-top: 4px;
                    font-weight: 500;
                    color: $color-scale-gray-300
                }
            }

            .chevron-wrapper {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

    .profile-photo {
        width: 20px;
        height: 20px;
        border-radius: 100px;
        object-fit: cover;
        border: 1px solid $color-scale-gray-800;

        &.org-photo {
            border-radius: 12px;
        }
    }

    .avatar {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;

        .initials {
            font-size: 0.8em;
            color: map-get($color-scale-gray, 0);
            font-weight: 700;
        }
    }

    .country-wrapper {
        display: flex;
        gap: 2px;
    }

    .creator-wrapper {
        display: flex;
        align-items: center;
        gap: 2px;

        .amount {
            font-size: 0.95em;
            font-weight: 600;
            color: $color-scale-gray-300;
        }

        .pipe {
            margin: 0 4px;
            width: 2px;
            height: 16px;
            background-color: $color-scale-gray-800;

        }
    }

    .country {
        width: 18px;
        height: 18px;
        border: 1px solid $color-scale-gray-800;
        border-radius: 18px;
    }

    .extra {
        height: 18px;
        padding: 0 5px;
        border: 1px solid $color-scale-gray-700;
        border-radius: 18px;
        background-color: $color-scale-gray-800;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.9em;
        color: $color-scale-gray-300;
    }
}