@import "./colors";

.emp-error-message-wrapper {
    display: flex;
    margin-top: 10px;
    align-items: center;

    span {
        display: block;
        margin-left: 6px;
        color: map-get($color-scale-red, 600);
        font-size: 1em;
        line-height: 1.3em;
    }
}