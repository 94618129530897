@import "../../styles/main.scss";

.emp-plan-selection-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .section-title {
        font-size: 1.2em;
        font-weight: 600;
        color: $color-scale-gray-100;
        margin: 0;
    }

    .plan-selection-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 20px;

        .plan-selection-card-skeleton,
        .plan-selection-card {
            height: 64px;
            width: 100%;
            border-radius: 12px;
        }

        .plan-selection-card {
            padding: 0 15px;
            box-sizing: border-box;
            background-color: $color-scale-gray-900;
            border: 2px solid $color-scale-gray-100;
            transition: all 300ms;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background-color: $color-scale-gray-900;
                border: 2px solid $color-scale-gray-150;
            }

            &.selected {
                border: solid 2px rgba(59, 134, 247, 0.8);
                background-color: rgba(59, 134, 247, 0.1);
            }

            .plan-details-wrapper {
                width: 100%;
                display: flex;
                gap: 10px;
                align-items: center;

                .knob {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    border: 2px solid $color-scale-gray-100;

                    &.selected {
                        border: 2px solid $color-scale-gray-200;
                    }

                    .knob-ellipsis {
                        width: 14px;
                        height: 14px;
                        border-radius: 10px;
                        background-color: $color-scale-primary-500;
                        opacity: 0;

                        &.selected {
                            opacity: 1;
                        }
                    }
                }

                .plan-price-lbl,
                .plan-name-lbl {
                    display: block;
                }

                .plan-name-lbl {
                    color: $color-scale-gray-100;
                    font-weight: 600;
                }

                .plan-price-lbl {
                    color: $color-scale-gray-300;
                    font-weight: 500;
                }

                .free-trial-subtitle {
                    font-size: 0.9em;
                    color: $color-scale-gray-300;
                }

                .plan-name-wrapper {
                    flex: 1;
                }

            }
        }
    }

    .product-capability-section {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        gap: 12px;

        .capability-description,
        .capability-header {
            margin: 0;
            display: block;
        }

        .capability-header {
            text-transform: uppercase;
            font-weight: 700;
            color: $color-scale-primary-500;
        }

        .capability-description {
            margin-top: 4px;
            color: $color-scale-gray-300;
        }
    }

    .btn-wrapper {
        display: flex;
        flex: 1;
        gap: 10px;
        align-items: flex-end;
        justify-content: flex-end;
    }
}