@import "../../styles/main.scss";

.sm-pills-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  .pill-wrapper {
    position: relative;

    .pill {
      display: flex;
      gap: 6px;
      align-items: center;
      border-radius: 30px;
      background-color: $color-scale-gray-900;
      height: 30px;
      border: 1px solid $color-scale-gray-800;
      font-size: 0.95em;
      color: $color-scale-gray-300;
      white-space: nowrap;
      transition: 100ms all;

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: $color-scale-gray-800;
        }
      }

      &.has-dropdown {
        .status-section {
          padding: 4px 0px 4px 8px;
          display: flex;
          gap: 4px;
          align-items: center;
        }

        .dropdown-section {
          display: flex;
        }
      }

      .status-section {
        padding: 4px 8px;
        display: flex;
        gap: 4px;
        align-items: center;


        .status {
          width: 6px;
          height: 6px;
          border-radius: 10px;

          &.success {
            background-color: $color-scale-green-400;
          }

          &.warn {
            background-color: $color-scale-yellow-800;
          }
        }
      }

      .dropdown-section {
        height: 100%;
        padding-right: 6px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }


    }
  }

  .dropdown-menu {
    position: absolute;
    background-color: $color-scale-gray-900;
    width: 200px;
    bottom: -10px;
    right: 0;
    padding: 10px 0;
    transform: translateY(100%);
    border-radius: 8px;
    border: 1px solid $color-scale-gray-800;
    z-index: 10;

    .padded-section {
      position: relative;
      padding: 0 15px;

      &.bordered {
        border-bottom: 1px solid $color-scale-gray-800;
      }
    }


    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 35.5px;
      height: 35.5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-scale-gray-900;
      transition: all 100ms;
      border-radius: 8px;
      z-index: 2;

      &:hover {
        background-color: $color-scale-gray-800;
      }
    }

    h2 {
      font-size: 1em;
      font-weight: 600;
      color: $color-scale-gray-100;
    }

  }
}