@import "../../styles/main.scss";







.bg-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0.00) 100%);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f9 100%);
}

.emp-brand-task-recruitment-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 20px;
    box-sizing: border-box;

    .header-wrapper {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    .page-header {
        font-size: 1.6em !important;
        color: $color-scale-gray-100;
    }

    .resize-wrapper {
        width: 100%;
    }

    .filter-section {
        width: 100%;

        .search-control-section {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .search-control {
                flex: 1;
                max-width: 500px;
            }

            .sort-by-control {
                width: fit-content;
            }
        }

        .filter-control-section {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .control {
                width: fit-content;
            }
        }
    }

    .records-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;

        .records-title-wrapper {
            flex: 1;

            .records-title {
                display: block;
                font-size: 1.2em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .records-title-loader {
                width: 200px;
                height: 30px;
                border-radius: 4px;
                border: 1px solid $color-scale-gray-800;
            }
        }


    }

    .recruitment-list-section {
        width: 100%;

        .blank-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(7, 7, 47, 0.3) 10%, $color-scale-primary-1000 100%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 2;

            .info-wrapper {
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                img {
                    height: 120px;
                }

                .header {
                    margin-top: 8px;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                    font-size: 1.6em;
                }

                .description {
                    margin-top: 0px;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 500);
                }
            }
        }
    }

    .recruitment-card-section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 10px;
        position: relative;


    }




}