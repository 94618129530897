@import "../../../../styles/main.scss";


.brand-task-chat-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;


    .chat-card {
        flex: 1;
        display: flex;
        border: 1px solid $color-scale-gray-800;
        min-height: calc(100dvh - 130px);
        max-height: calc(100dvh - 130px);

        .contacts-section {
            min-width: 240px;
            width: 240px;
            padding: 0 0 15px 0;
            border-right: 1px solid $color-scale-gray-800;

            .title-section {
                padding: 0 15px;
                border-bottom: 1px solid $color-scale-gray-800;
                height: 60px;
                display: flex;
                align-items: center;

                .title-lbl {
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }
            }

            .deliverable-section {
                padding: 5px;

                .deliverable-card {
                    overflow: hidden;
                    height: 36px;
                    border-radius: 8px;
                    padding: 10px;
                    border: 1px solid $color-scale-gray-800;
                    background-color: $color-scale-gray-100;
                    transition: 200ms all;

                    &.expanded {
                        height: fit-content;
                    }


                    .deliverable-header-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        margin-bottom: 4px;

                        .deliverable-header {
                            display: block;
                            flex: 1;
                            font-weight: 700;
                            color: $color-scale-gray-100;
                        }
                    }

                }
            }

            .conversation-section {

                .conversation-item {
                    padding: 10px 15px;
                    display: flex;
                    gap: 12px;
                    border-bottom: 1px solid $color-scale-gray-800;
                    background: $color-scale-gray-900;
                    transition: all 200ms;
                    cursor: pointer;

                    &.selected {
                        background: $color-scale-primary-800;

                        &:hover {
                            background: $color-scale-primary-700;
                        }
                    }

                    &:hover {
                        background: $color-scale-gray-800;
                    }

                    .profile-wrapper {

                        position: relative;

                        .profile {
                            width: 42px;
                            height: 42px;
                            border-radius: 42px;
                            border: 1px solid $color-scale-gray-800;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            object-fit: cover;

                            &.profile-badge {
                                position: absolute;
                                bottom: 0;
                                right: -4px;
                                width: 20px;
                                height: 20px;
                                border-radius: 6px;
                            }

                            .initials {
                                color: $color-scale-gray-0;
                                font-size: 1.4em;
                                font-weight: 700;
                            }
                        }

                    }

                    .info-wrapper {
                        flex: 1;
                        overflow: hidden;

                        .person-name-lbl {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-weight: 600;
                            color: $color-scale-gray-100;
                            display: block;
                            font-size: 1.05em;
                            flex: 1;
                        }

                        .deliverable-state-wrapper,
                        .message-context-wrapper {
                            display: flex;
                            gap: 6px;
                            align-items: center;

                            .last-message-lbl {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                color: $color-scale-gray-300;
                                display: block;
                            }
                        }
                    }

                    .unread-badge {
                        padding: 2px 4px;
                        min-width: 20px;
                        width: fit-content;
                        height: fit-content;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        color: $color-scale-gray-0;
                        font-weight: 600;
                        font-size: 0.8em;
                        background-color: map-get($color-scale-red, 400);
                    }
                }



            }
        }

        .chat-section {
            flex: 1;
            overflow: hidden;
            background-color: #fafafa;
            display: flex;
            flex-direction: column;

            .chat-header {
                padding: 0 15px;
                height: 60px;
                background-color: $color-scale-gray-900;
                border-bottom: 1px solid $color-scale-gray-800;
                display: flex;
                gap: 8px;
                align-items: center;

                .chat-header-profile-wrapper {
                    flex: 1;
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    .profile-wrapper {
                        position: relative;
                        height: 36px;
                        width: 36px;

                        .profile {
                            width: 36px;
                            height: 36px;
                            border-radius: 36px;
                            border: 1px solid $color-scale-gray-800;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            object-fit: cover;

                            &.profile-badge {
                                position: absolute;
                                bottom: 0;
                                right: -4px;
                                width: 24px;
                                height: 24px;
                                border-radius: 4px;
                            }


                            .initials {
                                color: $color-scale-gray-0;
                                font-size: 1.2em;
                                font-weight: 700;
                            }
                        }

                    }

                    .info-wrapper {
                        .person-name-wrapper {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            .person-name-lbl {
                                font-weight: 600;
                                color: $color-scale-gray-100;
                                display: block;
                                flex: 1;
                            }

                            .activity-status {
                                height: 8px;
                                width: 8px;
                                border-radius: 16px;
                                background-color: $color-scale-gray-700;

                                &.online {
                                    background-color: map-get($color-scale-green, 400);
                                }
                            }
                        }

                        .last-updated-lbl {
                            font-weight: 600;
                            font-size: 0.9em;
                            color: $color-scale-gray-300;
                        }
                    }
                }


            }

            .chat-body {
                position: relative;
                flex: 1;
                flex-grow: 1;
                overflow-y: auto;
                padding: 10px;
                display: flex;
                flex-direction: column-reverse;
                background: $color-scale-gray-900;
                gap: 10px;


                .empty-wrapper {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    img {
                        width: 160px;
                        height: 160px;
                    }

                    h2 {
                        margin: 0;
                        font-size: 1.4em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }
                }

                .bubble-wrapper {
                    width: 100%;
                    display: flex;

                    &.my-bubble-wrapper {
                        justify-content: flex-end;
                    }

                    &.recipient-bubble-wrapper {
                        justify-content: flex-start
                    }
                }

                .my-bubble,
                .recipient-bubble {
                    width: fit-content;
                    max-width: 300px;
                    padding: 10px;
                    word-wrap: break-word;

                    .image-wrapper {
                        margin-bottom: 8px;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .image-inner-wrapper {
                            width: fit-content;

                            &.half-width {
                                flex: 0 0 calc(50% - 5px);
                            }

                            &.full-width {
                                flex: 1;
                            }
                        }

                        .single-img {
                            border-radius: 10px;
                            width: 100%;

                            height: auto;
                            object-fit: cover;
                            background-color: $color-scale-gray-900;

                            &.square-img {
                                aspect-ratio: 1;
                                object-fit: contain;
                            }
                        }
                    }

                    .video-wrapper {
                        width: 100%;
                        margin-bottom: 10px;

                        video {
                            border-radius: 8px;
                            width: 100%;
                            height: auto;
                        }
                    }

                    .file-wrapper {
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        margin-bottom: 12px;

                        .file-indicator {
                            min-width: 36px;
                            width: 36px;
                            height: 36px;
                            min-height: 36px;
                            border-radius: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.my-bubble-file {
                                background: $color-scale-primary-700;

                            }

                            &.recipient-bubble-file {
                                background: $color-scale-gray-200;
                            }
                        }


                        .file-info-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 3px;

                            &.my-bubble-file {
                                .filename-lbl {
                                    color: $color-scale-gray-50 !important;
                                }

                                .size-lbl {
                                    color: $color-scale-gray-150 !important;
                                }
                            }

                            &.recipient-bubble-file {
                                .filename-lbl {
                                    color: $color-scale-gray-850 !important;
                                }

                                .size-lbl {
                                    color: $color-scale-gray-400 !important;
                                }
                            }

                            .filename-lbl {
                                display: block;
                                font-size: 1.1em;
                                font-weight: 600;
                                color: $color-scale-gray-50;
                            }

                            .size-lbl {
                                display: block;
                                font-size: 1em;
                                color: $color-scale-gray-150;
                            }
                        }
                    }
                }

                .my-bubble {
                    background-color: $color-scale-primary-600;
                    border-radius: 12px 12px 4px 12px;
                    color: $color-scale-gray-100;
                    font-weight: 500;

                    .timestamp-wrapper {
                        width: 100%;
                        margin-top: 3px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 2px;

                        span {
                            font-size: 0.8em;
                            font-weight: 600;
                            color: $color-scale-gray-150;
                        }
                    }
                }

                .recipient-bubble {

                    background-color: $color-scale-gray-800;
                    border: 1px solid $color-scale-gray-700;
                    border-radius: 12px 12px 12px 4px;
                    color: $color-scale-gray-100;
                    font-weight: 500;

                    .timestamp-wrapper {
                        width: 100%;
                        margin-top: 3px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 2px;

                        span {
                            font-size: 0.8em;
                            font-weight: 600;
                            color: $color-scale-gray-200;
                        }
                    }
                }

            }

            .chat-form-wrapper {
                border-top: 1px solid $color-scale-gray-800;
                background-color: $color-scale-gray-900;
                padding: 10px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                .attachment-btn {
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border-radius: 8px;
                        background-color: $color-scale-gray-700;
                        transition: all 300ms;
                        opacity: 0;


                    }

                }

                textarea {
                    all: unset;
                    width: 100%;
                    height: 30px;
                    color: $color-scale-gray-100;

                    &::placeholder {
                        /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $color-scale-gray-300;
                        font-weight: 400;
                        /* Firefox */
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: $color-scale-gray-300;
                        font-weight: 400;
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: $color-scale-gray-300;
                        font-weight: 400;
                    }
                }
            }
        }


    }

}