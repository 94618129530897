@import "../../styles/main.scss";

.emp-responsive {
    @include mobile-view {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.settings-page {

    .header-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid $color-scale-gray-800;
        padding-bottom: 20px;

        h1 {
            color: $color-scale-gray-100;
            margin: 0;
            font-size: 1.8em;
        }
    }

    .content {
        width: 100%;
        display: flex;

        @include mobile-view {
            padding-bottom: 80px;
        }

        .menu-wrapper {
            margin-top: 20px;

            @include mobile-view {
                width: 100%;
            }

            .settings-menu-item {
                display: flex;
                padding: 10px;
                width: 100%;
                border-radius: 6px;
                cursor: pointer;
                color: $color-scale-gray-100;
                font-size: 1.1em;
                font-weight: 600;
                box-sizing: border-box;
                transition: all 200ms;

                @include mobile-view {
                    border-radius: 12px;
                    background-color: $color-scale-gray-900;
                    font-weight: 600;
                    color: $color-scale-gray-300;
                    border: $color-scale-gray-800 1px solid;
                    padding: 15px;

                    &:hover {
                        background-color: $color-scale-gray-0 !important;
                        transform: translateY(-4px);
                    }
                }

                &.active {
                    color: $color-scale-primary-350;
                    background-color: $color-scale-primary-850;
                }

                &:hover {
                    background-color: $color-scale-gray-850;
                }

                &.logout {
                    color: $color-scale-red-400;
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }

        .setting-view-wrapper {
            flex: 1;
            width: 100%;
            overflow: hidden;
        }
    }
}