@import "../../styles/main.scss";

.brand-task-status-card {
    .negotiation-panel {
        margin-bottom: 16px;
        padding: 15px;
        display: flex;
        align-items: center;

        @include mobile-view {
            flex-direction: column;
            align-items: flex-start;
        }

        .overlap-left {
            margin-left: -12px;
        }

        .profile-photo-wrapper {
            display: flex;

            .creator-profile {
                margin-left: -12px;
            }

            .profile-photo {
                width: 50px;
                height: 50px;
                border-radius: 100px;
                object-fit: cover;
                border: 1px solid map-get($color-scale-gray, 150);

                &.org-photo {
                    border-radius: 12px;
                }
            }


            .empty-div {
                width: 50px;
                height: 50px;
                border-radius: 100px;
                display: flex;
                background-color: map-get($color-scale-gray , 50);
                box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
                align-items: center;
                justify-content: center;
                color: map-get($color-scale-gray , 500);
                font-weight: 600;
            }


            .avatar {
                display: flex;
                width: 50px;
                height: 50px;
                border-radius: 120px;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 3em;
                    color: map-get($color-scale-gray, 0);
                    font-weight: 700;
                }
            }
        }

        .invitation-section {
            padding-left: 12px;
            flex: 1;

            @include mobile-view {
               padding-left: 0px;
            }

            .card-header {
                display: block;
                font-weight: 700;
                color: $color-scale-gray-100;
                font-size: 1.2em;
            }

            .card-description {
                display: block;
                margin-top: 6px;
                margin-bottom: 0;
                color: $color-scale-gray-300;
                font-weight: 500;
            }

            .metrics-section {
                display: flex;
                align-items: center;
                gap: 10px;

                .pipe {
                    height: 18px;
                    width: 1px;
                    background-color: $color-scale-gray-200;
                }

                .horizontal-content {
                    display: flex;
                    gap: 4px;

                    .content-header {
                        font-weight: 600;
                        color: $color-scale-gray-400;
                    }

                    .content-value {
                        font-weight: 500;
                        color: $color-scale-gray-100;
                    }
                }
            }

        }
    }
}