@import "../../../styles/main.scss";

@mixin mobile-form {
    @media only screen and (max-width:500px) {
        @content
    }
}

.emp-agency-interest-view {
    padding: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-wrapper {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;

        img {
            height: 24px;
        }

        h1 {
            font-size: 1.2em;
            color: $color-scale-gray-100;
            font-weight: 600;
        }

        p {
            color: $color-scale-gray-400;
            margin: 0;
        }
    }

    .back-btn-wrapper {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 4px;

        @include mobile-form {
            margin-bottom: 20px;
        }
    }

    .form-wrapper {
        @include card-border-conic-gradient-mixin(100deg, 20px, 47%, 53%);
        width: 500px;
        border-radius: 20px;
        padding: 30px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        position: relative;

        @include mobile-form {
            width: 100%;
            padding: 15px 15px;
        }

        .form-row {
            width: 100%;
            display: flex;

            .left-group {
                flex: 1;
                padding-right: 12px;

                @include mobile-form {
                    padding-right: 0px;
                }
            }

            .right-group {
                flex: 1;
                padding-left: 12px;

                @include mobile-form {
                    padding-left: 0px;
                }
            }

            @include mobile-form {
                flex-direction: column;
            }
        }

        .recaptcha-description {
            font-size: 1em;
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300;
        }


        .button-wrapper-row {
            margin-top: 8px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.centered {
                justify-content: center !important;
            }
        }


    }



}