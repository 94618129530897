@import "../../../styles/main.scss";

.animated-btn-wrapper {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        .bar {
            background-color: map-get($color-scale-gray, 500);
        }
    }

    .top {
        transform: rotate(45deg);
        transform-origin: 0% 0%;
    }

    .middle {
        transform: scaleX(0);
    }

    .bottom {
        transform: rotate(-45deg);
        transform-origin: 0% 50%;
    }


    .bar {
        height: 2px;
        width: 17.5px;
        background-color: $color-scale-gray-400;
        border-radius: 10px;
        transition: 300ms all;

        &:not(:first-child) {
            margin-top: 4px;
        }
    }
}



.side-nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 999;
}

.side-nav-menu {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 500px;
    width: 80%;
    height: 100dvh;
    padding: 10px;
    backdrop-filter: blur(2px);
    z-index: 1000;

    .side-nav-menu-wrapper {
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        width: 100%;
        height: 100%;
        border-radius: 12px;

        .side-nav-profile-wrapper {
            display: flex;
            align-self: center;
            padding: 15px;
            overflow: hidden;
            gap: 8px;
            border-bottom: 1px solid $color-scale-gray-800;

            .profile-picture {
                width: 38px;
                min-width: 38px;
                height: 38px;
                min-height: 38px;
                border-radius: 24px;
                border-radius: 1px solid $color-scale-gray-800;
                background-color: $color-scale-gray-800;
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;


                .initials {
                    color: $color-scale-gray-0;
                    font-weight: 700;
                    font-size: 1.2em;
                }
            }

            .profile-details-wrapper {
                flex: 1;
                overflow: hidden;

                .name-lbl {
                    display: block;
                    font-size: 1.1em;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                }

                .email-lbl {
                    margin-top: 2px;
                    display: block;
                    font-weight: 500;
                    color: $color-scale-gray-300;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
        }

        .side-nav-menu-item-wrapper {
            padding: 0 10px;
            display: flex;
            flex-direction: column;
            gap: 2px;



            .side-nav-menu-item {
                cursor: pointer;
                width: 100%;
                padding: 14px 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 12px;
                background-color: $color-scale-gray-900;
                transition: all 100ms ease-in;

                &:hover {
                    background-color: $color-scale-gray-850;
                    transform: scale(1)
                }

                @include mobile-view {
                    &:hover {
                        background-color: $color-scale-gray-900; // Reset to default background
                        transform: none; // Remove transformation
                    }

                    &:active {
                        background-color: $color-scale-gray-100;
                        transform: scale(0.95);
                    }

                }

                .icon-wrapper {
                    width: 22px;
                }

                .label {
                    display: block;
                    user-select: none;
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-300;

                    &.red {
                        color: map-get($color-scale-red, 600)
                    }
                }
            }
        }

    }
}