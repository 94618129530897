@import "../../../styles/main.scss";

.emp-brand-manage-team-view {

    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    .view-header-wrapper {
        display: flex;
        max-width: 800px;
        justify-content: space-between;
        align-items: flex-end;

        .text-wrapper {
            flex: 1;
            margin-right: 12px;
        }

        h1 {
            font-size: 1.8em;
            color: $color-scale-gray-100;
            margin: 0 0 10px 0;
        }

        .page-description {
            line-height: 1.4em;
            color: $color-scale-gray-300;
            margin-bottom: 0;
        }
    }

    .accounts-section {
        margin-top: 20px;

        .accounts-label {
            color: $color-scale-gray-300;
            font-weight: 600;
        }

        .account-card {
            margin-top: 12px;
            border-radius: 14px;
            padding: 14px;
            max-width: 800px;
            box-sizing: border-box;
            border: 1px solid $color-scale-gray-800;
            background-color: $color-scale-gray-900;

            &.empty-state {
                padding: 5px 10px;
                display: flex;

                .empty-img {
                    height: 90px;
                    position: relative;
                    top: -8px
                }

                .empty-cta {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        display: block;
                        font-size: 1.4em;
                        font-weight: 700;
                        color: $color-scale-gray-100
                    }

                    .description {
                        display: block;
                        margin-top: 5px;
                        color: $color-scale-gray-300
                    }
                }
            }

            .profile-image {
                display: flex;
                align-items: center;

                .profile-details {
                    margin-left: 10px;

                    .name-lbl {
                        font-size: 1em;
                        display: block;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }

                    .email-lbl {
                        font-size: 0.95em;
                        margin-top: 2px;
                        display: block;
                        font-weight: 400;
                        color: $color-scale-gray-400;
                    }


                    .invitation-status {
                        font-size: 0.95em;
                        margin-top: 2px;
                        font-weight: 600;
                        color: $color-scale-gray-400;
                    }
                }
            }
        }
    }
}