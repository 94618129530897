@import "../../styles/main.scss";

.pow-modal-listing {
  display: flex;
  gap: 8px;

  @include mobile-view {
    flex-direction: column;
  }

  .listing-pills-wrapper {
    margin-top: 4px;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .preview-image {
    height: 75px;
    aspect-ratio: 1;
    border-radius: 8px;
    object-fit: cover;
    border: $color-scale-gray-100 solid 1px;

    &.attachment {
      background-color: $color-scale-gray-100;
      object-fit: contain !important;
    }

    @include mobile-view {
      aspect-ratio: initial;
      width: 100%;
    }
  }

  .preview-file {
    height: 75px;
    width: 75px;
    overflow: hidden;
    border-radius: 8px;
    border: $color-scale-gray-100 solid 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    box-sizing: border-box;
    gap: 4px;

    .preview-file-name {
      display: block;
      width: 100%;
      font-size: 0.9em;
      color: $color-scale-gray-600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .preview-text {
    height: 75px;
    aspect-ratio: 1;
    border-radius: 8px;
    border: $color-scale-gray-100 solid 1px;
    background: #474bff;
    background: -webkit-linear-gradient(0deg, #f9f9ff 100%, #f0f0f0 0%);
    background: linear-gradient(0deg, #f9f9ff 100%, #f0f0f0 0%);
    display: flex;
    justify-content: center;
    padding: 5px;
    overflow: hidden;

    @include mobile-view {
      aspect-ratio: initial;
      width: 100%;
    }

    .text {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.6em;
      font-weight: 600;
      color: $color-scale-gray-500;
      line-height: 1.4em;

      @include mobile-view {
        color: $color-scale-gray-600;
        font-weight: 500;
        font-size: 1em;
      }
    }
  }
}