@import "../../../styles/main.scss";

.brand-event-post-modal {
  p,
  h1,
  h2,
  h3 {
    margin: 0;
  }
  p {
    font-size: 1em;
    color: map-get($color-scale-gray, 500);
  }
  h1 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 16px;
  }
  h2 {
    font-size: 1.1em;
    font-weight: 600;
  }
  label,
  h3 {
    font-size: 1em;
    font-weight: 600;
    .asterisk-mark {
      color: map-get($color-scale-red, 500);
    }
  }
  a {
    color: map-get($color-scale-primary, 400);
    font-weight: 600;
  }
  .content-wrapper {
    .emp-loader-overlay {
      position: fixed;
      inset: 0;
    }
    .brand-post-pill-wrapper {
      .emp-pill-wrapper {
        width: 100%;
        .emp-pill {
          padding: 10px;
          justify-content: flex-start;
          width: 100%;
          font-size: 0.9em;
          font-weight: 600;
        }
      }
    }
    label {
      font-weight: 500;
      font-size: 1em;
      margin-bottom: 8px;
    }
    .content-header {
      padding-bottom: 8px;
      width: 100%;
      border-bottom: 1px solid map-get($color-scale-gray, 200);
      > h3 {
        margin: 0;
      }
    }
    .connects-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;
      overflow-y: hidden;
      overflow-x: auto;
      .profile-wrapper {
        position: relative;
        transition: all 0.3s ease;
        opacity: 0.3;
        &.active {
          opacity: 1;
        }
        &.current {
          background-color: map-get($color-scale-primary, 100);
        }
        &.expired {
          opacity: 0.3;
          background-color: map-get($color-scale-red, 100) !important;
          padding: 8px 12px 8px 8px !important;
          cursor: not-allowed;
        }
        &:hover {
          background-color: map-get($color-scale-primary, 100);
        }

        .picture-section {
          .error-icon {
            display: none;
          }
          &.error {
            img {
              border: 1px solid map-get($color-scale-red, 500);
            }
            .error-icon {
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              background-color: map-get($color-scale-red, 500);
              color: white;
              border-radius: 50%;
              padding: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              width: 12px;
              height: 12px;
            }
          }
        }
        .x-button {
          position: absolute;
          top: -8px;
          right: -8px;
          background-color: map-get($color-scale-gray, 200);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
          z-index: 1;
          &:hover {
            background-color: map-get($color-scale-gray, 300);
          }
        }
      }
    }

    .multi-edit-section {
      width: 100%;
      background: linear-gradient(
        to right,
        #f6f6f6 0%,
        rgba(214, 230, 255, 0.01) 100%
      );
      margin-bottom: 12px;
      border: 1px solid map-get($color-scale-gray, 100);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 12px;
      .multi-edit-btn {
        width: fit-content;
      }
    }

    .enabled-multi-edit-section {
      width: 100%;
      background: linear-gradient(
        to right,
        #f2fff0 0%,
        rgba(255, 255, 255, 0.01) 100%
      );
      margin-bottom: 12px;
      border: 1px solid map-get($color-scale-gray, 100);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 12px;
      .close-btn {
        align-self: flex-start;
        cursor: pointer;
      }
    }
    .platform-error-message {
      margin: 12px 0;
      color: map-get($color-scale-danger, 500);
      font-size: 1em;
      span {
        font-weight: 600;
        color: map-get($color-scale-primary, 500);
      }
    }
    .post-tab-wrapper {
      &.disabled {
        pointer-events: none;
      }
    }
    .text-content-section {
      padding: 16px 0;
      border: 1px solid map-get($color-scale-gray, 200);
      border-radius: 8px;
      position: relative;

      > div {
        padding: 0 20px;
      }
      .top-section {
        margin-bottom: 12px;
        .thread-post-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          .emp-button {
            padding: 4px;
            box-sizing: border-box;
            border-radius: 6px;
          }
        }
        .poll-input-wrapper {
          .poll-option-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            margin-bottom: 8px;

            .poll-btn-wrapper {
              align-self: flex-start;
              margin-top: 32px;
              min-width: 60px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 8px;
            }
          }
          .poll-length {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            > div {
              width: 30%;
              position: relative;
              .emp-dropdown-menu {
                > div {
                  width: 100% !important;
                }
              }
              .emp-dropdown-wrapper {
                bottom: unset !important;
                overflow: auto;
              }
            }
          }
        }
        .suggested-hashtag {
          color: map-get($color-scale-gray, 400);
        }
        .hashtag-list {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
        }
        .x-close-btn {
          position: absolute;
          top: 8px;
          right: 8px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
          z-index: 1;
        }
      }
      .bottom-section {
        padding-top: 16px;
        border-top: 1px solid map-get($color-scale-gray, 200);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .left-side {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          margin-left: -4px;
          > div {
            border-radius: 8px;
            padding: 4px;
            transition: all 0.3s ease;
            position: relative;
            cursor: pointer;
            .upload-hidden {
              position: absolute;
              inset: 0;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
            }
            &:hover {
              background-color: map-get($color-scale-gray, 200);
            }
          }
        }
      }

      .preview-file-wrapper {
        padding: 12px 20px;
        border-top: 1px solid map-get($color-scale-gray, 200);
        overflow: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        .preview-file {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          > div {
            position: relative;

            .x-button {
              position: absolute;
              top: -8px;
              right: -8px;
              background-color: map-get($color-scale-gray, 200);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              cursor: pointer;
              transition: all 0.3s ease;
              z-index: 1;
              &:hover {
                background-color: map-get($color-scale-gray, 300);
              }
            }
          }
          .video-preview {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: map-get($color-scale-gray, 100);
            border-radius: 6px;
            border: 1px solid map-get($color-scale-gray, 200);
            .play-wrapper {
              position: absolute !important;
              border-radius: 6px;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, 0.5);
              color: #ffffff;
              font-size: 1.2em;
              font-weight: 600;
              text-align: center;
              transition: all 0.3s ease-in-out;
              &:hover {
                background-color: rgba(0, 0, 0, 0.8);
              }
            }
            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }
          .image-preview {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: map-get($color-scale-gray, 100);
            border-radius: 6px;
            border: 1px solid map-get($color-scale-gray, 200);
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }
        }
      }

      .event-post-bottom-section {
        width: 100%;
        padding-top: 16px;
        border-top: 1px solid map-get($color-scale-gray, 200);
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .file-input-wrapper {
          width: auto;
          position: relative;
          padding: 4px;
          transition: all 0.3s ease;

          .upload-hidden {
            position: absolute;
            inset: 0;
            opacity: 0;
            z-index: 1;
          }

          .upload-text {
            font-size: 0.9em;
            color: map-get($color-scale-primary, 500);
            font-weight: 600;
          }
        }
      }
    }

    .append-tweet-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }

    .post-modal-warning-wrapper {
      margin: 8px 0;
      width: 100%;
      > div {
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        background-color: map-get($color-scale-red, 100);
        border-radius: 6px;

        .warning-icon {
          min-width: 20px;
          min-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .warning-text {
          font-size: 0.9em;
          color: map-get($color-scale-red, 700);
          font-weight: 600;
          > p {
            margin: 0;
          }
        }
      }
    }

    .date-picker-wrapper {
      margin-top: 20px;
      max-width: 250px;
      h3 {
        .optional-text {
          color: map-get($color-scale-gray, 400);
        }
      }
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        > div {
          height: 40px;
          > input {
            height: 100%;
          }
        }
      }
    }
  }
  .footer-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
