@import "../../../styles/main.scss";

.emp-animated-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    width: 100vw;
    z-index: 100;
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.3) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms;

    &.visible {
        z-index: 200;
        opacity: 1;
    }

    &.hidden {
        z-index: -999;
        opacity: 0;
    }

    .canvas-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            margin: 0;
            margin-top: 4px;
            font-size: 1.4em;
            font-weight: 700;
            color: $color-scale-gray-0;
        }


    }
}