@import "../../../styles/main.scss";

@mixin modal-sm-view {
  @media only screen and (max-width: 540px) {
    @content;
  }
}

@mixin modal-md-view {
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin modal-xl-view {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin modal-lg-view {
  @media only screen and (max-width: 740px) {
    @content;
  }
}

@mixin modal-xs-view {
  @media only screen and (max-width: 460px) {
    @content;
  }
}

.emp-modal-overlay {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms;
  box-sizing: border-box;

  &.responsive-sheet {
    @include modal-sm-view {
      padding: 0px !important;
    }
  }

  &.xl {
    @include modal-xl-view {
      padding: 10px;
    }
  }

  &.lg {
    @include modal-lg-view {
      padding: 10px;
    }
  }

  &.md {
    @include modal-md-view {
      padding: 10px;
    }
  }

  &.sm {
    @include modal-sm-view {
      padding: 10px;
    }
  }

  &.xs {
    @include modal-xs-view {
      padding: 10px;
    }
  }
}

.emp-modal-overlay.visible {
  opacity: 1;
}

.emp-modal-card {
  position: relative;
  top: -40px;
  width: 300px;
  border-radius: 12px;

  &.responsive-sheet {
    @include modal-sm-view {
      position: absolute;
      width: 100%;
      max-height: 95dvh;
      height: 100%;
      top: unset;
      left: 0px;
      bottom: 0px;
      border-radius: 24px 24px 0 0;
    }
  }

  &.vertical-padding {
    padding: 20px 0;
  }

  .scroll-down-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 1px;

    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 100%);
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 10;
    border-radius: 0 0 12px 12px;

    .wrapper {
      margin-bottom: 10px;
      display: flex;
      gap: 2px;
      align-items: center;
      justify-content: center;
      animation: moveUpDown 2s infinite ease-in-out; // Adjust the duration (2s) as needed

      .scroll-lbl {
        font-weight: 700;
        color: $color-scale-gray-100;
      }
    }

    @keyframes moveUpDown {

      0%,
      100% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-5px);
      }
    }
  }
}

.emp-modal-card.sm {
  width: 500px;

  @include card-border-conic-gradient-mixin(90deg, 12px, 48%, 52%);

  @include modal-sm-view {
    width: 100%;
    margin: 0;
  }
}

.emp-modal-card.md {
  width: 600px;
  @include card-border-conic-gradient-mixin(90deg, 12px, 48%, 52%);

  @include modal-md-view {
    width: 100%;
  }
}

.emp-modal-card.lg {
  width: 700px;
  @include card-border-conic-gradient-mixin(90deg, 12px, 47%, 53%);

  @include modal-lg-view {
    width: 100%;
  }
}

.emp-modal-card.xl {
  width: 1000px;
  @include card-border-conic-gradient-mixin(90deg, 12px, 46%, 54%);

  @include modal-lg-view {
    width: 100%;
  }
}

.emp-dismiss-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
}

.emp-dismiss-icon {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-modal-footer,
.emp-modal-header,
.emp-modal-body {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.emp-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .emp-modal-header {
//     padding-bottom: 20px;
// }

.emp-modal-bottom-border {
  padding-bottom: 20px;
  border-bottom: $color-scale-gray-800 1px solid;
}

.emp-modal-footer {
  padding-top: 20px;
}

.emp-modal-body {
  display: flex;
  flex-direction: column;
  max-height: 70vh;

  &.responsive-sheet {
    @include modal-sm-view {
      max-height: unset;
      height: 100%;
    }
  }

  &.no-h-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.emp-modal-header-text {
  color: $color-scale-gray-100;
  margin: 0;
  font-weight: 600;
}



.emp-modal-common-header {
  padding: 0 20px 15px 20px;
  border-bottom: 1px solid $color-scale-gray-800;

  .emp-modal-hstack-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  h2 {
    margin: 0;
    color: $color-scale-gray-100;
    font-size: 1.3em;
  }
}

.emp-modal-x-wrapper {
  all: unset;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: 1;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms;
  border-radius: 12px;

  &:hover {
    background-color: $color-scale-gray-800;
    transform: scale(1)
  }

  &:active {
    transform: scale(0.98);
  }

  @include mobile-view {
    &:hover {
      background-color: $color-scale-gray-900; // Reset to default background
      transform: scale(0.98);
    }

    &:active {
      background-color: $color-scale-gray-800;
    }

  }
}