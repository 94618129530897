@import "../../../styles/main.scss";

.emp-seller-task-recruitment-view {
    padding-top: 20px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }

    .task-details-section {
        padding-bottom: 25px;

        .info-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    .description-lbl {
        margin: 0;
        line-height: 1.4em;
        color: $color-scale-gray-300;
    }

    .kanban-section {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

}