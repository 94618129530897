@import "../../styles/main.scss";

.emp-create-deliverable-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .custom-rate-card-section {
        display: flex;
        gap: 10px;

        @include mobile-view {
            gap: 4px;
            flex-direction: column;
        }

        .rate-name-section {
            flex: 1
        }

        .price-section {
            min-width: 100px;
            width: 120px;

            @include mobile-view {
                width: 100%;
            }
        }
    }

    .custom-rate-btn-wrapper {
        width: fit-content;

        @include mobile-view {
            width: auto;
            flex: 1;
        }
    }
}