/* The switch - the box around the slider */
.emp-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

/* Hide default HTML checkbox */
.emp-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.emp-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.emp-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.emp-slider {
    background-color: #9227ef;
}

input:focus+.emp-slider {
    box-shadow: 0 0 1px #9227ef;
}

input:checked+.emp-slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

/* Rounded sliders */
.emp-slider.round {
    border-radius: 34px;
}

.emp-slider.round:before {
    border-radius: 50%;
}