@import "../../styles/main.scss";

.emp-talent-entry-page {
    width: 100%;
    height: 100dvh;
    background: $cbz-background-gradient;

    .emp-talent-entry-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;

        @include mobile-view {
            padding: 0;
            align-items: flex-start;
        }


        .onboarding-wrapper {
            max-width: 1000px;
            background: $cbz-card-gradient-md;
            border: 1px solid map-get($color-scale-gray, 800);
            border-radius: 20px;
            display: flex;
            overflow: hidden;

            @include mobile-view {
                border-radius: 0;
                height: 100%;
                border: none;
                flex-direction: column;
                justify-content: flex-start;
            }

            .form-section {
                width: 450px;
                box-sizing: border-box;
                padding: 40px 55px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                @include mobile-view {
                    width: 100%;
                    padding: 60px 20px 120px 20px;
                }

                &.hidden {
                    position: absolute;
                    z-index: -999;
                }

                .header-wrapper {
                    display: flex;
                    align-items: center;

                    .header {
                        font-size: 1.2em;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }
                }

                .loader-wrapper {
                    display: flex;
                    align-items: center;
                }



                .loader-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0.75;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-scale-gray-900;

                }


                .inner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .intro-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 32px;
                }

                .emplifive-logo {
                    height: 24px;
                }

                .logo {
                    height: 40px;
                    width: 40px;
                    border-radius: 10px;
                }

                .description {
                    color: map-get($color-scale-gray, 300);
                    line-height: 1.4em;
                    margin: 0;
                }

                .highlight {
                    font-weight: 600;
                    color: map-get($color-scale-primary, 400);
                    cursor: pointer;
                }

                .form-wrapper {
                    width: 100%;
                }

                .password-card {
                    border-radius: 8px;
                    padding: 10px;

                }


                .disclaimer-section {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .picture-wrapper {

                        display: flex;
                        gap: 20px;
                        align-items: center;

                        .plus {
                            display: block;
                            position: relative;
                            font-weight: 700;
                            font-size: 2.5em;
                            color: $color-scale-gray-300;
                        }

                        .avatar,
                        .org-image {
                            width: 60px;
                            height: 60px;
                        }

                        .avatar {
                            border-radius: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .initials {
                                color: $color-scale-gray-0;
                                font-size: 1.8em;
                                font-weight: 700;
                            }
                        }

                        .org-image {
                            border-radius: 8px;
                        }
                    }

                    .heading {
                        margin-top: 40px;
                        text-align: center;
                        font-size: 1.4em;
                        line-height: 1.4em;
                        font-weight: 600;
                        color: $color-scale-gray-100;

                        .highlighted {
                            color: $color-scale-primary-400 !important;
                        }
                    }

                    .disclaimer-description {
                        margin-top: 30px;
                        text-align: center;
                        line-height: 1.4em;
                        font-weight: 500;
                        color: $color-scale-gray-300;
                    }

                    .btn-wrapper {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }

        }
    }
}