@import "../../../styles/main.scss";


.emp-editor-control {

    label {
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        color: $color-scale-gray-100;

        &.compressed {
            margin-bottom: 2px;
            font-weight: 700;
            font-size: 0.85em;
            color: $color-scale-gray-300;
        }


        .required {
            display: inline;
            color: red;
        }
    }

    .description {
        font-size: 0.95em;
        color: $color-scale-gray-400;
        line-height: 1.4em;
        margin: 0;
        margin-bottom: 8px;
    }

    .emp-editor-character-count {
        display: block;
        color: $color-scale-gray-400;
        font-size: 0.95em;
        font-weight: 500;
    }

    .emp-editor {
        border-radius: 8px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;

        &.focused {
            border: 1px solid $color-scale-primary-500;
        }


        .emp-editor-wrapper {
            padding: 9px 12px 9px 12px;
        }

        .slate-placeholder {
            pointer-events: none;
        }



        .toolbar {
            border-top: 1px solid $color-scale-gray-800;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 4px 12px 4px 8px;
            gap: 4px;

            .buttons-wrapper {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }

        .placeholder-attribute {
            color: $color-scale-gray-300 !important;
            opacity: 1 !important;
        }

        .elem-modifier-btn {
            cursor: pointer;
            width: 25px;
            height: 25px;
            border-radius: 4px;
            background-color: $color-scale-gray-900;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;
            border: 1px solid $color-scale-gray-800;

            &.selected {
                background-color: $color-scale-gray-800;

                &:hover {
                    background-color: $color-scale-gray-150;
                }
            }

            &:hover {
                background-color: $color-scale-gray-800;
            }
        }
    }
}

.slate-link {
    position: relative;
    top: -1px;
    cursor: pointer;
    display: inline-block;
    height: fit-content;
    padding: 3px 6px;
    vertical-align: middle;
    margin: 3px 4px;
    background-color: $color-scale-gray-800;
    border-radius: 4px;
    color: $color-scale-gray-200;
    font-weight: 500;

    &.selected {
        font-weight: 600;
        background-color: $color-scale-primary-150;
        color: $color-scale-primary-500;
    }

    .link-wrapper {
        display: flex;
        height: fit-content;
        align-items: center;
        pointer-events: none;
        gap: 4px;

        .icon {
            display: inline;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            object-fit: cover;
        }

        span {
            line-height: 1.2em;
        }
    }

    .hidden {
        display: none;
    }
}

.slate-paragraph {
    margin: 0;
    line-height: 1.4em;
    color: $color-scale-gray-300;
    vertical-align: middle;
    align-items: center;

    &.placeholder {
        color: $color-scale-gray-300;
    }
}



.slate-ul {
    padding-left: 20px;
    margin: 6px 0;

    li {
        line-height: 1.4em;
        color: $color-scale-gray-300;
    }
}