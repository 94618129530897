@import "../../../styles/main.scss";

.emp-rate-card-request-card {

    .card-base {
        padding: 12px 12px 20px 14px !important;
        max-width: 900px;
        border-radius: 12px;


        &.rate-card-skeleton {
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            height: 90px;
        }

        &.rate-card-request-card {
            background-color: $color-scale-gray-900;
            display: flex;
            gap: 12px;
            border: solid 1px $color-scale-gray-800;
        }

        .brand-img {
            width: 60px;
            height: 60px;
            border-radius: 8px;
        }

        .info-wrapper {
            flex: 1;

            .header {
                display: block;
                flex: 1;
                font-size: 1.1em;
                font-weight: 600;

            }

            .metrics-wrapper {
                // display: flex;
                // gap: 14px;

                .creator-content {
                    position: relative;
                    top: -4px;
                    display: flex;
                    gap: 6px;
                    align-items: center;

                    .user-profile {
                        width: 24px;
                        height: 24px;
                        border-radius: 24px;
                        border: 1px solid $color-scale-gray-800;

                        &img {
                            object-fit: contain;
                        }

                        &div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .initials {
                            font-size: 1em;
                            font-weight: 700;
                            color: $color-scale-gray-0;
                        }
                    }
                }

                .recruiting-task-wrapper {
                    max-width: 300px;

                    .link-span {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .btn-wrapper {
            display: flex;
            gap: 6px;
        }

    }
}