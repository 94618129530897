@import "../../styles/main.scss";

.emp-campaign-analytics-breakdown-table {
  width: 100%;
  overflow: hidden;
  background-color: $color-scale-gray-900;
  border-radius: 12px;
  position: relative;
  border: 1px solid $color-scale-gray-800;

  .creator-cell {
    display: flex;
    align-items: center;
    gap: 8px;

    .image-wrapper {
      position: relative;

      .display-picture {
        width: 45px;
        height: 45px;
        border-radius: 45px;
        border: 1px solid $color-scale-gray-100;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: cover;
        font-size: 1.4em;
        font-weight: 600;
        color: $color-scale-gray-0;
      }

      .agency-wrapper {
        position: absolute;
        width: 28px;
        height: 28px;
        border: 0.5px solid $color-scale-gray-100;
      }
    }

    .details-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .evidence-record-cell {
    position: relative;


    .attachment-display {
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-scale-primary-100;
      border-radius: 12px;
      ;
    }


    .text-container,
    video,
    img {
      width: 52px;
      height: 52px;
      border-radius: 12px;
      border: 1px solid $color-scale-gray-700;
      object-fit: cover;
    }

    .text-container {
      background-color: $color-scale-gray-900;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1.2em;
      font-weight: 600;
      color: $color-scale-gray-400;
    }

    .sm-icon-wrapper {
      position: absolute;
      background-color: $color-scale-gray-800;
      border: 0.5px solid $color-scale-gray-0;
      bottom: 0;
      right: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      width: 24px;
      height: 24px;

      &.Facebook {
        background-color: $facebook-color;
      }

      &.Instagram {
        background: $instagram-color;
      }

      &.TikTok,
      &.X {
        background-color: $tiktok-color;
      }
    }
  }

  .submission-details-cell {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}