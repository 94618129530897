@import "../../styles/main.scss";

.emp-delete-task-modal {
  position: relative;
  display: flex;
  flex-direction: column;

  .dismiss-icon-wrapper {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;
  }

  .modal-header-wrapper {
    padding: 0 20px;

    .intro-subtitle {
      display: block;
      font-weight: 700;
      color: #D50006;
    }

    .title {
      margin: 0;
      margin-top: 6px;
      display: block;
      font-weight: 600;
      font-size: 1.4em;
      color: $color-scale-gray-100;
    }
  }

  .participants-wrapper {
    .header-wrapper {
      padding: 5px 20px;
      background-color: $color-scale-gray-100;
      display: flex;

      .participants-header {
        flex: 1;
      }

      .amount-header {
        display: flex;
        justify-content: flex-end;
        width: 160px;

      }

      .header-text {
        font-weight: 600;
        color: $color-scale-gray-750
      }
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;

      .row-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .participants-wrapper {
          flex: 1;
          display: flex;
          align-items: center;
          gap: 10px;

          .profile-wrapper {
            position: relative;

            .profile {
              width: 45px;
              height: 45px;
              border-radius: 50px;
              border: 1px solid $color-scale-gray-800;
              object-fit: cover;
              display: flex;
              align-items: center;
              justify-content: center;

              &.org {
                border-radius: 8px;
              }

              .initials {
                color: $color-scale-gray-0;
                font-size: 1.4em;
                font-weight: 600;
              }
            }

          }



          .info-wrapper {
            .creator-name {
              display: block;
              font-size: 1.1em;
              font-weight: 600;
              color: $color-scale-gray-100;
            }

            .creator-name-skeleton {
              border: 1px solid $color-scale-gray-800;
              height: 18px;
              width: 140px;
              border-radius: 6px;
            }

            .representative-lbl {

              display: block;
              color: $color-scale-gray-300;
            }

            .representative-lbl-skeleton {
              border: 1px solid $color-scale-gray-800;
              margin-top: 4px;
              height: 18px;
              width: 70px;
              border-radius: 6px;
            }
          }

          .amount-lbl {

            display: block;
            font-size: 1em;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

        }



        .amount-wrapper {
          width: 160px;
          display: flex;
          justify-content: flex-end;

          .amount-lbl {
            display: block;
            font-size: 1em;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          .amount-lbl-skeleton {
            border: 1px solid $color-scale-gray-800;
            height: 20px;
            width: 65px;
            border-radius: 6px;
          }
        }
      }


      .header-text {
        font-weight: 600;
        color: $color-scale-gray-750
      }
    }
  }



  .acknowledgement-wrapper {
    display: flex;
    padding: 0 20px;

    .card {
      display: flex;
      flex-direction: column;
      background-color: map-get($color-scale-red, 100);
      padding: 15px;
      border-radius: 12px;
      width: 100%;

      border: 1px solid map-get($color-scale-red, 100);
      background: #e9f2fe;
      background: -webkit-linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);
      background: linear-gradient(224deg, #fee9ec 0%, #fad9de 100%);



      .intro-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        span {
          color: map-get($color-scale-red, 500);
          font-weight: 700;
        }
      }

      .control-wrapper {
        display: flex;

        .checkbox-text-wrapper {
          cursor: pointer;
          flex: 1;

          span {
            line-height: 1.4em;
            font-weight: 400;
            color: $color-scale-gray-100;

            &.checked {
              font-weight: 500;
            }
          }
        }
      }

    }
  }


  .button-wrapper {
    padding: 0 20px;
    display: flex;
    flex-direction: row-reverse;
  }

}