@import "../../styles/main.scss";


.emp-single-deliverable-modal {
    position: relative;
    display: flex;
    flex-direction: column;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 20px;
    }

    .tdm-content-section {
        padding: 0 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .task-name-wrapper {
            display: flex;
            gap: 6px;

            .task-name {
                color: $color-scale-gray-100;
                font-size: 1.4em;
                font-weight: 600;
                margin: 0;
            }
        }

        .tdm-description {
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }

        .deliverable-wrapper {
            padding: 20px 0;
            box-sizing: border-box;


            .deliverable-indicator {
                font-size: 0.9em;
                font-weight: 700;
                color: #7E8591;
            }

            .deliverable-name-lbl {
                margin: 0;
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-100;
            }

            .deliverable-description {
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300
            }
        }
    }

   


}