@import "../../styles/main.scss";


.emp-creator-has-exclusive-agency-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;


    .profile-photo {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        object-fit: cover;
        border: 1px solid map-get($color-scale-gray, 150)
    }

    .empty-div {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        display: flex;
        background-color: map-get($color-scale-gray , 50);
        box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
        align-items: center;
        justify-content: center;
        color: map-get($color-scale-gray , 500);
        font-weight: 600;
    }


    .avatar {
        display: flex;
        width: 95px;
        height: 95px;
        border-radius: 120px;
        justify-content: center;
        align-items: center;

        .initials {
            font-size: 3em;
            color: map-get($color-scale-gray, 0);
            font-weight: 700;
        }
    }

    .content-section {
        margin-top: 20px;

        .heading,
        .invitation-failed-lbl {
            display: block;

            font-weight: 600;
            text-align: center;
            line-height: 1.4em;
        }

        .invitation-failed-lbl {
            font-size: 1.3em;
            color: map-get($color-scale-red, 600);
        }

        .heading {
            font-size: 1em;
            color: $color-scale-gray-300;
            margin-top: 6px;
        }

        .description {
            text-align: center;
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }
    }

}