@import "../../../styles/main.scss";

.creator-extended-info {
    margin-top: 16px;
    @include card-border-conic-gradient-mixin(100deg, 10px, 48%, 52%);

    @include mobile-view {
        margin-top: 0;
    }


    .button-wrapper {
        display: flex;
        justify-content: flex-end;
    }


    .pills-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .header-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        justify-content: space-between;
    }
}