@import "../../styles/main.scss";


.emp-leave-agency-confirmation-modal {
    display: flex;
    flex-direction: column;
    align-items: center;


    .agency-content-section {
        display: flex;
        gap: 12px;
    }

    .profile-photo {
        margin-top: 16px;
        width: 60px;
        height: 60px;
        border-radius: 12px;
        object-fit: cover;
        border: 1px solid map-get($color-scale-gray, 150)
    }


    .content-section {
        .heading {
            margin-top: 12px;
            display: block;
            font-size: 1.3em;
            color: $color-scale-gray-100;
            font-weight: 600;
            line-height: 1.4em;
            text-align: center;
        }



        .description {
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
            text-align: center;

            .highlighted {
                font-weight: 600;
                color: $color-scale-primary-500;
            }
        }
    }

    .button-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
    }

}