@import "../../../styles/main.scss";

.emp-brand-ongoing-task-summary-view {
    padding-top: 20px;

    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }



    .country {
        width: 20px;
        height: 20px;
        border: 1px solid $color-scale-gray-800;
        border-radius: 18px;
    }

    .extra {
        height: 18px;
        padding: 0 5px;
        border: 1px solid $color-scale-gray-700;
        border-radius: 18px;
        background-color: $color-scale-gray-800;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.9em;
        color: $color-scale-gray-300;
    }

    .profile-photo {
        width: 20px;
        height: 20px;
        border-radius: 100px;
        object-fit: cover;
        border: 1px solid $color-scale-gray-800;

        &.org-photo {
            border-radius: 12px;
        }
    }

    .avatar {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;

        .initials {
            font-size: 0.8em;
            color: map-get($color-scale-gray, 0);
            font-weight: 700;
        }
    }

    .country-wrapper {
        display: flex;
        gap: 2px;
    }

    .task-details-section {
        padding-bottom: 25px;
        border-bottom: 1px solid $color-scale-gray-800;

        .task-name-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .info-wrapper {
                flex: 1;
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .task-description {
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300;
            word-break: break-word;
        }

        .task-details {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;


        }
    }

    .metrics-section {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .metric-card,
        .metric-card-skeleton {
            min-width: 200px;
            flex: 1;
            height: 100px;
        }

        .metric-card {
            position: relative;
            background: $cbz-card-gradient-md;
            @include card-border-conic-gradient-mixin(95deg, 10px, 46%, 52%);
            border-radius: 12px;
            padding: 15px 8px 15px 15px;
            display: flex;
            cursor: pointer;
            transition: all 200ms;

            &:hover {
                transform: translateY(-3px);
            }

            &:active {
                transform: scale(0.95);
            }

            .absolute-pill-wrapper {
                position: absolute;
                top: 15px;
                right: 8px;
            }

            .info-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;

                .profile-wrapper {
                    flex: 1;

                    .no-creators-lbl {
                        font-size: 0.85em;
                        font-weight: 700;
                        color: $color-scale-gray-400;
                    }
                }

                .metric-wrapper {
                    .metric-title {
                        color: $color-scale-gray-100;
                        display: block;
                        font-weight: 600;
                    }

                    .metric {
                        margin-top: 3px;
                        display: block;
                        font-size: 1.6em;
                        font-weight: 700;
                        color: $color-scale-gray-300
                    }
                }
            }

            .chevron-wrapper {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

    .deliverable-section {
        padding: 25px 0;
        border-bottom: 1px solid $color-scale-gray-800;


        .title-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .link-wrapper {
                position: relative;
                top: -2px;
            }
        }

        .collapsible-section {
            overflow: hidden;
            transition: height 300ms;

            .deliverable-wrapper {

                padding: 20px;
                box-sizing: border-box;
                border: 1px solid $color-scale-gray-800;
                background-color: $color-scale-gray-900;
                border-radius: 12px;
                margin-top: 10px;

                .deliverable-indicator {
                    display: block;
                    font-size: 0.9em;
                    font-weight: 700;
                    color: #7E8591;
                }

                .deliverable-name-lbl {
                    margin: 0;
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    font-size: 1em;
                    font-weight: 700;
                    color: $color-scale-gray-100
                }

                .deliverable-description {
                    display: block;
                    padding-top: 8px;
                    box-sizing: border-box;
                    margin: 0;
                    line-height: 1.4em;
                    color: $color-scale-gray-300
                }
            }

        }


    }

    .events-section {
        padding: 25px 0;

        .table-wrapper {
            border: 1px solid $color-scale-gray-800;
            border-top: none;
        }
    }
}