@import "../../styles/main.scss";

.emp-sign-up-page {
    width: 100%;
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;


    @include mobile-view {
        align-items: flex-start;
        padding: 0;
        padding-bottom: 80px;
    }
}