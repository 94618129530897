@import "../../../styles/main.scss";

.emp-draft-card {
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    transition: all 200ms;
    background: $cbz-card-gradient-md;
    border: $color-scale-gray-800 1px solid;

    &.skeleton-wrapper {
        .image-wrapper {
            width: 100%;
            aspect-ratio: 1.667;
            display: flex;
            flex-direction: column;

            .top-section {
                width: 100%;
                padding: 10px;
                display: flex;
                gap: 4px;

                .profile-image {
                    background-color: $color-scale-gray-850;
                    width: 28px;
                    height: 28px;
                    border-radius: 28px;
                }

                .name {
                    background-color: $color-scale-gray-850;
                    border-radius: 8px;
                    height: 24px;
                    width: 90px;
                }
            }

            .middle-section {
                background-color: $color-scale-gray-800;
                width: 100%;
                flex: 1;
            }
        }

        .details-wrapper {
            padding: 10px;

            .profile-image-2 {
                background-color: $color-scale-gray-100;
                min-width: 38px;
                width: 38px;
                min-height: 38px;
                height: 38px;
                border-radius: 28px;
            }

            .name-2 {
                background-color: $color-scale-gray-100;
                border-radius: 4px;
                height: 18px;
                width: 90px;
            }

            .status {
                margin-top: 4px;
                background-color: $color-scale-gray-100;
                border-radius: 3px;
                height: 14px;
                width: 60px;
            }
        }

        .fake-button {
            height: 30px;
            width: 100%;
            background-color: $color-scale-gray-100;
            border-radius: 8px;
        }

    }

    .image-wrapper {
        position: relative;
        aspect-ratio: 1.667;
        background-color: $color-scale-gray-950;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        &.padding {
            padding: 15px;
        }

        .remarks-section {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            padding: 20px 10px 10px 10px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 80%);

            p {
                text-align: left;
                margin: 0;
                color: $color-scale-gray-300;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .blank-bg {
            aspect-ratio: 1.667;
            width: 100%;
            background-color: $color-scale-gray-800;
        }

        .attachment-img {
            object-fit: cover;
            aspect-ratio: 1.667;
            width: 100%;
            background-color: $color-scale-gray-900;
        }

        .attachment-video {
            aspect-ratio: 1.667;
            width: 100%;
            object-fit: cover;
            background-color: $color-scale-gray-900;
        }

        .attachment-file-wrapper {
            aspect-ratio: 1.667;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $cbz-card-gradient-md;
            flex-direction: column;

            .title {
                margin-top: 3px;
                font-weight: 600;
                color: $color-scale-gray-100;
                text-align: center;
            }

            .specs {
                margin-top: 4px;
                font-weight: 600;
                color: $color-scale-gray-300;
                text-align: center;
            }
        }

        .empty-draft-img {
            object-fit: cover;
            aspect-ratio: 1;
            width: 20%;
        }

        .empty-draft-header {
            font-weight: 700;
            color: $color-scale-gray-100;
        }

        .empty-draft-description {
            margin: 0;
            font-weight: 500;
            color: $color-scale-gray-300;
            font-size: 0.9em;
        }


    }

    .details-wrapper {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 8px;
        border-top: 1px solid $color-scale-gray-800;

        .profile {
            width: 38px;
            height: 38px;
            border-radius: 36px;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            .initials {
                color: $color-scale-gray-0;
                font-size: 1.2em;
                font-weight: 600;
            }
        }

        .info-wrapper {
            .name-lbl {
                font-size: 1em;
                color: $color-scale-gray-100;
                font-weight: 700;
            }

            .pill-wrapper {
                display: flex;
                align-items: center;
                gap: 6px;
            }
        }
    }

    .revision-pill {
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        white-space: nowrap;
        line-height: initial;
        background-color: $color-scale-gray-700;
        color: $color-scale-gray-150;
        gap: 2px;
        font-size: 0.75em;
        padding: 3px 4px;
        font-weight: 700;
    }

    .button-wrapper {
        padding: 0 10px 10px 10px;
    }
}