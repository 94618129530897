@import "../../styles/main.scss";

.emp-agency-onboarding-page {
    width: 100%;
    background: $cbz-background-gradient;

    .emp-onboarding-info-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;

        .header-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                height: 24px;
            }

            .description {
                text-align: center;
                color: map-get($color-scale-gray, 300);
                line-height: 1.4em;
                margin: 0;
            }
        }

        .image-upload-group {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;

            img {
                width: 95px;
                height: 95px;
                border-radius: 100px;
                object-fit: cover;
                border: 1px solid map-get($color-scale-gray, 800)
            }

            .empty-div {
                width: 95px;
                height: 95px;
                border-radius: 100px;
                display: flex;
                background-color: map-get($color-scale-gray , 50);
                box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
                align-items: center;
                justify-content: center;
                color: map-get($color-scale-gray , 500);
                font-weight: 600;
            }

            .logo-upload {
                color: $color-scale-primary-300;
                font-weight: 600;
                cursor: pointer;
                margin-top: 8px;
                transition: color 200ms;

                &:hover {
                    color: $color-scale-primary-400;
                }
            }

            .upload-hidden {
                display: none;
            }
        }

        .form-wrapper {
            width: 400px;
            padding: 40px;
            border-radius: 20px;
            box-sizing: border-box;
            background: linear-gradient(360deg, #18182a -30%, #060610 20%);
            @include card-border-conic-gradient-mixin(90deg, 20px, 47%, 53%);
        }
    }
}