@import "../../../styles/main.scss";

.emp-coin-icon {
    position: relative;
    display: inline-flex;
    gap: 4px;
    align-items: center;

    img {
        border: 1px solid $color-scale-gray-800;
    }

    span {
        color: $color-scale-gray-300;
        font-weight: 500;
    }

    .no-icon {
        height: 16px;
        width: 16px;
        border-radius: 2px;
        background-color: $color-scale-gray-800;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: 600;
            color: $color-scale-gray-0;
        }
    }
}