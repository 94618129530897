@import "../../../styles/main.scss";


.emp-mobile-select-wrapper {
    width: 100%;
    margin-bottom: 10px;
    position: relative;

    .emp-multi-select-label {
        font-size: 14px;
        font-weight: 500;
        color: map-get($color-scale-gray, 750);
        margin-bottom: 8px;

        .required {
            display: inline;
            color: red;
        }
    }

    .emp-form-control {
        position: relative;
    }

    .emp-select-icon {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }



    .emp-text-input-wrapper {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid map-get($color-scale-gray , 100);
        background-color: map-get($color-scale-gray , 0);
        transition: border 200ms;

        &.disabled {
            background-color: map-get($color-scale-gray , 50);
        }

        &.focused {
            border: 1px solid map-get($color-scale-primary , 550);
        }

        .dial-code-wrapper {
            cursor: pointer;
            display: flex;
            gap: 4px;
            align-items: center;
            border-right: 1px solid $color-scale-gray-800;
            transition: all 200ms;
            border-radius: 7px 0 0 7px;

            &:hover {
                background-color: $color-scale-gray-900;
            }

            .emp-left-icon-wrapper {
                padding: 0 9px 0 9px;
                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: center;
                border-radius: 6px 0 0 6px;
                border-right: none;
                height: 37px;
                box-sizing: border-box;

                .img-flag {
                    height: 12px;
                    border: solid 1px map-get($color-scale-gray, 150);
                }

                .empty-flag {
                    height: 12px;
                    width: 16px;
                    border: solid 1px map-get($color-scale-gray, 150);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: map-get($color-scale-gray, 200);

                    span {
                        font-size: 0.8em;
                        font-weight: 800;
                        color: map-get($color-scale-gray, 350);
                    }
                }
            }

            .dial-code {
                font-weight: 600;
                color: $color-scale-gray-300;
            }
        }



        .emp-text-input {
            all: unset;
            border-radius: 6px;
            padding: 9px 12px;
            color: $color-scale-gray-100;
            font-weight: 500;
            font-size: 14px;
            width: 100%;
            transition: all 200ms;
            box-sizing: border-box;

            @include mobile-view {
                height: 45px;
            }

            &.left-icon {
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
            }
        }

    }



    .emp-multi-select {
        white-space: nowrap;
        overflow: hidden;
        background-color: map-get($color-scale-gray , 0);
        border-radius: 6px;
        padding: 10px 25px 10px 12px;
        min-height: 34px;
        color: $color-scale-gray-100;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        box-shadow: 0 0 0 1px map-get($color-scale-gray , 150);
        transition: all 200ms;
        box-sizing: border-box;
        cursor: pointer;

        .placeholder {
            color: map-get($color-scale-gray, 300);
            font-weight: 400;
            opacity: 1;
        }

        .multi-select-value,
        .hidden-value {
            color: $color-scale-gray-100;
            font-size: 14px;
        }

        .hidden-value {
            position: absolute;
            left: 0;
            visibility: hidden;
            z-index: -999;
        }

        &:focus {
            box-shadow: 0 0 0 1px map-get($color-scale-primary , 600);
        }

    }

    select:invalid,
    select option[value=""] {
        color: map-get($color-scale-gray, 550) !important;
        font-weight: 400 !important;
    }

    .emp-multi-select-section {
        position: absolute;
        z-index: 3;
        left: 0;
        top: calc(100% + 10px);
        width: 100%;
        box-sizing: border-box;
        padding: 5px 0;
        color: white;
        background-color: map-get($color-scale-gray , 0);
        border: 1px solid map-get($color-scale-gray , 150);
        border-radius: 6px;
        max-height: 210px;
        display: flex;
        flex-direction: column;

        .search-input-wrapper {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $color-scale-gray-800;
            padding: 6px 10px 8px 10px;
            gap: 6px;

            .search-input {
                all: unset;
                width: 100%;
                padding: 0;
                font-weight: 400;
                color: $color-scale-gray-100;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }
            }
        }


        .checkbox-section {
            flex: 1;
            overflow-y: auto;

            .checkbox-wrapper {
                padding: 8px 10px;
                width: 100%;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                display: flex;
                gap: 10px;

                &.selected {
                    background-color: map-get($color-scale-primary, 100);
                }

                &.hovered {
                    background-color: map-get($color-scale-gray , 100);
                }

                .country-option-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .img-flag {
                        height: 12px;
                        border: solid 1px map-get($color-scale-gray, 150);
                    }

                    .country-label {
                        color: map-get($color-scale-gray , 800);
                    }
                }

                .empty-option-section {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        display: block;
                        font-weight: 600;
                        color: $color-scale-gray-300;
                    }
                }

                &:hover {
                    background-color: map-get($color-scale-gray , 100);
                }

                .click-zone {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }


    }
}