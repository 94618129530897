@import "../../styles/main.scss";


.emp-rate-card-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .rate-section {
        border-top: 1px solid $color-scale-gray-800;

        .rate-item-wrapper {
            padding: 0px 20px 8px 20px;
            border-bottom: 1px solid $color-scale-gray-800;

            .header-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .rate-header {
                    display: block;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }
            }


            .rate-description {
                margin: 0;
                color: $color-scale-gray-300;
            }

            .info-section {
                flex: 1
            }

            .collapsible-section {
                width: 4px;
            }

            .input-section {
                min-width: 120px;
                width: 220px;
                display: flex;
                gap: 10px;
                align-items: flex-end;
                justify-content: space-between;

                @include mobile-view {
                    margin-top: 10px;
                    width: 100%;

                }

                .input-wrapper {
                    width: 140px;
                    min-width: 140px;

                    @include mobile-view {
                        flex: 1;
                        width: auto;

                    }

                }

                .button-icon-wrapper {
                    position: relative;
                    bottom: 15px;
                }
            }

            .content-wrapper {
                display: flex;
                flex: 1;
                align-items: center;


                @include mobile-view {
                    align-items: flex-start !important;
                    flex-direction: column;
                }
            }



            .rate-item {
                position: relative;
                gap: 10px;

                .line-wrapper {
                    position: absolute;
                    top: 0px;
                    left: 0px;

                    height: 100%;
                    display: flex;
                    align-items: center;

                    @include mobile-view {
                        align-items: flex-start;
                    }
                }

                .line {
                    width: 3px;
                    height: 70%;
                    border-radius: 50px;
                    background-color: map-get($color-scale-gray, 150);

                    @include mobile-view {
                        height: 90%;
                    }
                }

                // padding: 10px 0;
                display: flex;
                align-items: center;
            }
        }


    }

    .custom-rate-section {
        padding: 12px 20px;

        .header {
            font-size: 1.2em;
            font-weight: 600;
            color: $color-scale-gray-100;
        }

        .custom-rate-button-wrapper {
            width: fit-content;

            @include mobile-view {
                width: 100%;
            }
        }

    }
}