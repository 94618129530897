@import "../../../styles/main.scss";

.emp-dropdown-menu {
    position: relative;
    height: fit-content;
}


.emp-dropdown-wrapper {
    position: absolute;
    // right: 0;
    padding: 10px 0;
    background-color: $color-scale-gray-900;
    border-radius: 10px;
    border: 1px solid map-get($color-scale-gray, 800);
    z-index: 999;

    &.hidden {
        position: fixed;
        background-color: green;
        visibility: hidden;
        z-index: -100;
    }

    .emp-dropdown-menu-btn {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        cursor: pointer;
        color: $color-scale-gray-100;
        background-color: map-get($color-scale-gray, 900);
        font-size: 1em;
        font-weight: 600;
        display: flex;
        align-items: center;
        transition: all 200ms;
        white-space: nowrap;

        &:hover {
            background-color: map-get($color-scale-gray, 850);
        }

        &.danger {
            color: map-get($color-scale-red, 600) !important;
        }

        &.disabled {
            cursor: not-allowed;
            background-color: $color-scale-gray-900;
            color: $color-scale-gray-400;
        }
    }
}