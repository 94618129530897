@import "../../../styles/main.scss";

.emp-sidebar {
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  background-color: map-get($color-scale-gray, 900);
  border-radius: 12px;
  width: 100%;
  max-width: 200px;
  padding: 12px 0;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.collapsed {
    width: 60px;
    .header {
      display: none;
    }
    .menu {
      display: none;
    }
  }

  .header {
    font-size: 1.1em;
    font-weight: 600;
    padding: 0 12px;
  }
  .divider {
    margin: 10px 0;
    border-bottom: 1px solid $color-scale-gray-800;
    width: 100%;
    position: relative;
  }
  .menu {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    .menu-item {
      padding: 10px;
      border-radius: 8px;
      font-size: 1em;
      font-weight: 600;
      color: #333333;
      cursor: pointer;

      &:hover {
        background-color: $color-scale-primary-50;
      }

      &.active {
        color: $color-scale-primary-500;
        background-color: $color-scale-primary-100;
      }
    }
  }
}
