@import "../../../styles/main.scss";


.emp-multi-select-wrapper {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .emp-multi-select-label {
        font-size: 14px;
        font-weight: 500;
        color: $color-scale-gray-150;
        margin-bottom: 8px;

        .required {
            display: inline;
            color: red;
        }
    }

    .emp-form-control {
        position: relative;
    }

    .emp-select-icon {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .emp-multi-select {
        white-space: nowrap;
        overflow: hidden;
        background: linear-gradient(360deg, #11111e -20%, #060610 40%);
        border-radius: 6px;
        padding: 10px 25px 10px 12px;
        min-height: 34px;
        color: $color-scale-gray-100;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        box-shadow: 0 0 0 1px $color-scale-gray-800;
        transition: all 200ms;
        box-sizing: border-box;
        cursor: pointer;

        .placeholder {
            color: $color-scale-gray-100;
            font-weight: 400;
            opacity: 1;
        }

        .multi-select-value,
        .hidden-value {
            color: $color-scale-gray-100;
            font-size: 14px;
        }

        .hidden-value {
            position: absolute;
            left : 0;
            visibility: hidden;
            z-index: -999;
        }

        &:focus {
            box-shadow: 0 0 0 1px map-get($color-scale-primary , 600);
        }

    }

    select:invalid,
    select option[value=""] {
        color: map-get($color-scale-gray, 550) !important;
        font-weight: 400 !important;
    }

    .emp-multi-select-section {
        position: absolute;
        z-index: 3;
        left: 0;
        top: calc(100% + 10px);
        width: 100%;
        box-sizing: border-box;
        padding: 15px 0;
        background: linear-gradient(360deg, #11111e -20%, #060610 40%);
        border: 1px solid $color-scale-gray-800;
        border-radius: 6px;
        max-height: 200px;
        overflow-y: auto;

        .checkbox-wrapper {
            padding: 8px 10px;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;

            &:hover {
                background-color: linear-gradient(360deg, #141422 -20%, #0b0b1d 40%);
            }

            .click-zone {
                position: absolute;
                top : 0;
                left : 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}