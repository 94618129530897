@import "../../styles/main.scss";


.emp-readonly-rate-card-modal {
    position: relative;

    .rate-card-dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .rate-section {


        .rate-header-section {
            padding: 5px 20px;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;

            .rate-name-cell {
                flex: 1;
            }

            .rate-value-cell {
                min-width: 100px;
                display: flex;
                justify-content: flex-end;
            }

            .header-text {
                font-weight: 600;
                color: $color-scale-gray-300;
            }
        }

        .rate-content-section {
            padding: 5px 20px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 20px;

            .rate-row {
                display: flex;
                align-items: center;


                .rate-name-cell {
                    flex: 1;
                    display: flex;
                }

                .rate-value-cell {
                    min-width: 100px;
                    display: flex;
                    justify-content: flex-end;
                }


                .rate-header {
                    display: block;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .rate-description {
                    display: block;
                    margin: 0;
                    color: map-get($color-scale-gray, 500);
                }


                .nested-row-line {
                    width: 4px;
                    background-color: $color-scale-gray-200;
                }
            }


        }
    }

}