.emp-avatar {
    box-sizing: border-box;
    .display-img {
        border-radius: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 600;
        background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(255, 209, 67, 1) 0%, rgba(255, 145, 83, 1) 90%);
    }

    img {
        border-radius: 36px;
        object-fit: contain;
    }
}