@import "../../styles/main.scss";



.emp-brand-home-page {
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .emp-brand-home-page-intro-banner {
        @include card-border-conic-gradient-mixin(95deg, 0 0 12px 12px, 49%, 51%, false);
        position: relative;
        padding: 0 20px;
        background-color: #000000;
        background-size: contain;
        width: 100%;
        aspect-ratio: 9/1;
        background-image: url('https://static.vecteezy.com/system/resources/previews/023/851/577/original/colorful-dark-mesh-gradient-background-template-copy-space-suitable-for-poster-banner-landing-page-leaflet-pamphlet-cover-or-booklet-free-vector.jpg');
        background-size: cover;
        background-position: top;
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: flex-end;

        .banner-shading {
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, rgba(10, 10, 10, 0.7) 100%);
            height: 50px;
            border-radius: 0 0 12px 12px;

        }

        .company-info-wrapper {
            position: relative;
            bottom: -24px;
            display: flex;
            gap: 10px;

            .organistion-logo {
                border: 2px solid $color-scale-gray-100;
                border-radius: 12px;
                width: 80px;
                height: 80px;
            }

            .intro-lbl {
                display: block;
                font-size: 1.8em;
                font-weight: 700;
                color: $color-scale-gray-100;
            }
        }

    }

    .context-section {
        width: 100%;
    }

    .content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;

        .metrics-tile-wrapper {
            width: 100%;
            gap: 10px;
            display: flex;
            flex-wrap: wrap;

            .metric-tile {
                @include card-border-conic-gradient-mixin(95deg, 10px, 47%, 53%);
                flex: 1;
                min-width: 190px;
                min-height: 70px;
                display: flex;
                gap: 6px;
                flex-direction: column;
                padding: 10px 15px;
                border-radius: 10px;
                background: $cbz-card-gradient-md;
                align-items: flex-start;

                .details-wrapper {
                    flex: 1;
                    display: flex;
                    width: 100%;
                    gap: 10px;
                    justify-content: space-between;

                    .metric-value {
                        font-size: 2em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }
                }

                .metric-header {
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $color-scale-gray-300;
                }

            }
        }

        .split-columns {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            .card-header {
                font-weight: 600;
                font-size: 1.2em;
                color: $color-scale-gray-100;
                margin: 0;
            }

            .task-status-chart-card {
                @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
                background: $cbz-card-gradient-md;
                width: fit-content;

                .pie-wrapper {
                    min-width: 300px;
                    padding: 15px;
                    background-color: transparent !important;
                }

                .form-section {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    .form-control-wrapper {
                        width: 100%
                    }
                }

                .chart-wrapper {
                    position: relative;
                    height: 300px;

                    .blob-animation-wrapper {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .empty-chart-skeleton-wrapper {
                    position: relative;
                    height: 160px;
                    display: flex;
                    justify-content: center;

                    .empty-chart-overlay {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        height: 100%;
                        width: 100%;
                        background: linear-gradient(180deg, $color-scale-gray-900 10%, rgba(255, 255, 255, 0)100%);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        text-align: center;

                        h2 {
                            margin: 0;
                            font-size: 1.2em;
                            font-weight: 700;
                            color: $color-scale-gray-100;
                        }
                    }

                    .empty-pie-skeleton {
                        height: 100%;
                        aspect-ratio: 1;
                        display: flex;
                        justify-content: center;

                        .outer {
                            width: 80%;
                            height: 80%;
                            display: flex;
                            border-radius: 50%;
                            align-items: center;
                            justify-content: center;

                            .inner {
                                border-radius: 50%;
                                width: 60%;
                                height: 60%;
                                background-color: $color-scale-gray-800;
                            }
                        }
                    }


                }
            }

            .active-tasks-card {
                @include card-border-conic-gradient-mixin(100deg, 10px, 48%, 52%);
                flex: 1;
                height: 400px;
                overflow-y: auto;
                background: $cbz-card-gradient-md;

                .header-section {
                    padding: 15px;
                }

                .table-overlay-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    img {
                        height: 60px;
                        width: auto;
                    }

                    .header {
                        margin-top: 8px;
                        margin-bottom: 0px;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                        font-size: 1.4em;
                    }

                    .description {
                        margin-top: 8px;
                        line-height: 1.4em;
                        color: $color-scale-gray-300;
                    }
                }

                .task-name-cell {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .campaign-img {
                        height: 30px;
                        width: auto;
                        border-radius: 6px;
                        border: 1px solid $color-scale-gray-800;
                    }

                    .task-platform-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 2.5px;


                        .task-type-small {
                            font-size: 0.9em;
                            color: $color-scale-gray-400;
                            font-weight: 600;
                            display: block;
                        }
                    }

                }

                .raw-text {
                    color: $color-scale-gray-300;
                    font-weight: 500;
                }

                .creators-cell {
                    display: flex;
                    align-items: center;

                    .profile-img {
                        width: 30px;
                        margin-left: -10px;
                        height: 30px;
                        border-radius: 30px;
                        border: 1px solid $color-scale-gray-800;
                        object-fit: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .initials {
                            font-size: 0.9em;
                            font-weight: 600;
                            color: $color-scale-gray-0;
                        }
                    }
                }
            }
        }


    }

    img {
        width: 1200px;
        height: auto;
    }
}