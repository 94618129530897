@import "../../styles/main.scss";

.emp-invitation-page {
    width: 100%;
    height: 100dvh;
    background-color: $cbz-background-gradient;


    .emp-invitation-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;

        @include mobile-view {
            padding: 0;
            align-items: flex-start;
        }


        .onboarding-wrapper {
            @include card-border-conic-gradient-mixin(100deg, 20px, 47%, 53%);
            background: $cbz-card-gradient-md;
            max-width: 1000px;
            border-radius: 20px;
            display: flex;
            overflow: hidden;

            @include mobile-view {
                border-radius: 0;
                height: 100%;
                border: none;
                flex-direction: column;
                justify-content: center;
            }

            .form-section {
                width: 450px;
                box-sizing: border-box;
                padding: 40px 55px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                @include mobile-view {
                    width: 100%;
                    padding: 20px 35px;
                }

                &.hidden {
                    position: absolute;
                    z-index: -999;
                }

                .header-wrapper {
                    display: flex;
                    align-items: center;

                    .header {
                        font-size: 1.2em;
                        font-weight: 600;

                    }
                }

                .loader-wrapper {
                    display: flex;
                    align-items: center;
                }



                .loader-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0.75;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $cbz-neutral-color-2
                }


                .inner {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .intro-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 32px;
                }

                .emplifive-logo {
                    height: 24px;
                }

                .logo {
                    height: 40px;
                    width: 40px;
                    border-radius: 10px;

                }

                .description {
                    color: $color-scale-gray-300;
                    line-height: 1.4em;
                    margin: 0;
                }

                .highlight {
                    font-weight: 600;
                    color: $color-scale-primary-400;
                    cursor: pointer;
                }

                .form-wrapper {
                    width: 100%;
                }

                .password-card {
                    border-radius: 8px;
                    padding: 10px;

                }
            }

        }
    }
}