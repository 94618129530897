@import "../../../styles/main.scss";


.emp-secondary-tabbar {
    width: 100%;
    display: flex;
    justify-content: center;

    .emp-tabbar-wrapper {
        position: relative;
        max-width: 1240px;
        padding-bottom: 2px;
        width: 100%;
        display: flex;
        gap: 6px;
        overflow-x: auto;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .tabbar-underline-wrapper {
            position: relative;
            display: flex;
            gap : 6px;
            min-width: fit-content;
            width: 100%;

            .tabbar-underline {
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: $color-scale-gray-700;
            }

        }

        .tab {
            position: relative;
            cursor: pointer;
            height: 35px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            padding: 0 10px;

            .tab-content-wrapper {
                display: flex;
                align-items: center;
                gap: 6px;

                .tab-content {
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-300;
                    transition: all 100ms;
                    white-space: nowrap;

                    &:hover {
                        color: $color-scale-gray-100;
                    }
                }

                .toast-wrapper {
                    position: relative;
                    top: -1px;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
                    background-color: #FF4A4F;
                    color: $color-scale-gray-0;
                    font-size: 11px;
                    font-weight: 700;
                }
            }

            &.active {
                .tab-content-wrapper {
                    span {
                        color: $color-scale-primary-300;

                        &:hover {
                            color: $color-scale-primary-400;
                        }
                    }
                }
            }



            .underline {
                background-color: $color-scale-primary-400;
                position: absolute;
                bottom: 0;
                transform: translateY(100%);
                width: 100%;
                height: 2px;
                z-index: 2;
                border-radius: 3px;
            }
        }

        .blank-tab-space {
            width: 80px;
        }
    }
}