@import "../../../styles/main.scss";

.talent-agency {
    @include card-border-conic-gradient-mixin(90deg, 10px, 48%, 52%);
    margin-top: 16px;

    @include mobile-view {
        margin-top: 0;
    }

    .ta-section-label {
        display: block;
        font-size: 0.95em;
        font-weight: 700;
        color: $color-scale-gray-350;
    }

    .ta-card {
        display: flex;
        align-items: center;
        border-radius: 12px;
        border: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;
        padding: 8px;

        .ta-logo {
            border-radius: 8px;
            width: 45px;
            height: 45px;
            object-fit: cover;
            border: 1px solid $color-scale-gray-800;
        }

        .content {
            margin-left: 12px;
            flex: 1;

            .name-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .name-lbl {
                    flex: 1;
                    display: block;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

            }

            .pipe-lbl {
                display: inline-block;
                font-weight: 600;
                color: $color-scale-gray-300;
            }

            .invited-date {
                display: inline-block;
                font-weight: 500;
                color: $color-scale-gray-300;
            }

        }
    }
}