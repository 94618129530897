@import "../../styles/main.scss";


.brand-settings-page {

    .header-wrapper {
        border-bottom: 1px solid $color-scale-gray-800;
        display: flex;
        gap: 10px;

        h1 {
            color: $color-scale-gray-100;
            font-size: 1.8em;
            margin: 0 0 10px 0;
        }
    }

    .content {
        width: 100%;
        display: flex;

        .menu-wrapper {
            margin-top: 20px;

            .settings-menu-item {
                display: flex;
                padding: 10px;
                width: 100%;
                border-radius: 6px;
                cursor: pointer;
                color: $color-scale-gray-100;
                font-size: 1.1em;
                font-weight: 600;
                box-sizing: border-box;
                transition: all 200ms;

                &.active {
                    color: $color-scale-primary-350;
                    background-color: $color-scale-primary-850;
                }

                &:hover {
                    background-color: $color-scale-gray-850;
                }

                &.logout {
                    color: $color-scale-red-400;
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }

        .setting-view-wrapper {
            flex: 1;
            overflow: hidden;
        }
    }
}