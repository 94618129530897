@import "../../../styles/main.scss";

.brand-post-preview-modal {
  position: absolute;
  top: 0;
  right: -358px;
  width: 350px;
  height: 100%;
  border-radius: 12px;
  background-color: map-get($color-scale-gray, 50);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  overflow: auto;
  .post-header {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-weight: 600;
    padding: 20px 0;
    border-bottom: #e0e0e0 1px solid;
    > div {
      padding: 0 20px;
    }
  }
  .platform-dropdown-wrapper {
    padding: 0 20px;
    width: 100%;
    > div {
      width: 100%;
      > div {
        width: 100% !important;

        .emp-button {
          background-color: map-get($color-scale-gray, 0) !important;
          border: 1px solid map-get($color-scale-gray, 100) !important;
          .emp-button-content {
            > div:first-child {
              padding: 4px;
              border-radius: 100%;
              svg {
                path {
                  fill: map-get($color-scale-gray, 0);
                }
              }
            }
          }
        }
      }
    }

    .emp-dropdown-wrapper {
      > div {
        padding: 6px;
        justify-content: center;
      }
      .emp-dropdown-menu-btn {
        .profile-wrapper {
          .picture-section {
            width: auto;
            height: auto;
            > img {
              width: 20px;
              height: 20px;
            }
            .social-media-bubble {
              width: 10px;
              height: 10px;
            }
          }
          .info-section {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            .handle-name-lbl {
              font-size: 0.9em;
            }
            .metric-lbl {
              font-size: 0.8em;
            }
          }
        }
      }
    }
    &.facebook {
      .emp-button-content {
        > div:first-child {
          background-color: map-get($color-scale-primary, 500);
        }
      }
    }
    &.instagram {
      .emp-button-content {
        > div:first-child {
          background: linear-gradient(45deg, #ffdd55, #ff543e, #c837ab);
        }
      }
    }
    &.tiktok,
    &.x {
      .emp-button-content {
        > div:first-child {
          background-color: map-get($color-scale-gray, 750);
        }
      }
    }
  }
  .mocking-post-content-wrapper {
    padding: 0 20px;
    width: 100%;
    .post-content-wrapper {
      background-color: map-get($color-scale-gray, 900);
      border: 1px solid map-get($color-scale-gray, 100);
      border-radius: 6px;
      padding: 10px 0;
      > div {
        padding: 10px 20px;
      }

      .post-author-wrapper {
        display: flex;
        align-items: center;
        border-bottom: 1px solid map-get($color-scale-gray, 100);
        padding-bottom: 10px;
        .profile-pic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .profile-info {
          .profile-name {
            font-weight: bold;
          }
          .post-time {
            color: #65676b;
            font-size: 0.85em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            > p {
              margin: 0;
            }
          }
        }
      }

      .post-content {
        background-color: map-get($color-scale-gray, 900);
        border-bottom: 1px solid map-get($color-scale-gray, 100);
        font-size: 1em;
        text-wrap: wrap;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        &.empty-description {
          color: map-get($color-scale-gray, 300);
        }
        .file-preview-wrapper {
          display: grid;
          grid-gap: 2px; // Space between items
          margin-top: 8px;

          // Default Layout for a single item
          grid-template-columns: 1fr;

          // Two Items: Split into two columns
          &.two-items {
            grid-template-columns: repeat(2, 1fr);
          }

          // Three Items: First item larger, spans two rows
          &.three-items {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto auto;
            .file-preview:first-child {
              grid-row: span 2;
            }
          }

          // Four Items: First item significantly larger
          &.four-items {
            grid-template-columns: repeat(5, 1fr); // Adjusted to fit 5 columns
            grid-template-rows: auto auto;
            .file-preview {
              grid-column: span 2;
            }
            .file-preview:first-child {
              grid-column: span 3 !important;
              grid-row: span 3 !important; // Makes the first item larger
            }
          }

          // Many Items: Adapts to a 2-column layout with varying row spans for the first few items
          &.five-items,
          &.many-items {
            grid-template-columns: repeat(2, 1fr);

            .file-preview:nth-child(1) {
              grid-area: 1 / 1 / 4 / 2;
            }
            .file-preview:nth-child(2) {
              grid-area: 4 / 1 / 7 / 2;
            }

            .file-preview:nth-child(3),
            .file-preview:nth-child(4),
            .file-preview:nth-child(5) {
              grid-column: span 1;
              grid-row: span 2;
            }
          }

          .file-preview {
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            width: 100%;
            height: 100%;

            > img,
            > video {
              width: 100%;
              height: 100%;
            }
            img {
              object-fit: contain;
            }
            &.empty-image {
              opacity: 0.7;
              display: flex;
              justify-content: center;
              align-items: center;

              > img {
                width: 60%;
                height: auto;
                object-fit: contain;
              }
            }

            .overlay-text {
              position: absolute;
              inset: 0;
              background-color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              padding: 4px 8px;
              font-size: 16px;
              border-top-left-radius: 8px;
              > p {
                margin: 0;
              }
            }
          }
        }
      }

      .post-interactions {
        background-color: map-get($color-scale-gray, 900);
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid map-get($color-scale-gray, 100);

        color: #65676b;
        font-size: 0.85em;
        .reaction-stats {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > * {
            box-sizing: border-box;
          }
          > div {
            width: 16px;
            height: 16px;
            border-radius: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              fill: white;
              stroke: white;
            }
          }
          .like-icon {
            background-color: map-get($color-scale-primary, 500);
          }
          .heart-icon {
            background-color: map-get($color-scale-danger, 500);
            margin-right: 4px;
          }
        }
        .comments {
          margin-right: 15px;
        }
      }
      .post-actions {
        display: flex;
        padding: 10px 20px 0 20px;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        .action-button {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 0.9em;
          background: none;
          border: none;
          padding: 4px;
          border-radius: 5px;
          gap: 2px;
          cursor: pointer;

          &:hover {
            background-color: #ececec;
          }
        }
      }

      // for facebook post
      &.preview-facebook {
        // for facebook reels
        &.facebook-reels {
          position: relative;
          width: 100%;
          height: 500px;
          background-color: #000; // Assuming stories are viewed in a dark mode context
          padding: 0;
          .top-content {
            width: 100%;
            background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.4),
              rgba(0, 0, 0, 0.1) 52.5%,
              transparent
            );
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            padding: 8px 16px;
            height: 200px;

            .user-info {
              display: flex;
              align-items: center;
              .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px;
                padding: 2px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }

              .profile-info {
                .profile-name {
                  color: $color-scale-gray-0;
                  font-weight: 600;
                }
                .post-time {
                  color: $color-scale-gray-0;
                  font-weight: 500;
                  font-size: 0.85em;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 4px;
                  > p {
                    margin: 0;
                  }
                }
              }
            }
          }

          .bottom-content {
            width: 100%;
            position: absolute;
            max-height: 200px;
            left: 0;
            bottom: 0;
            z-index: 1;
            padding: 8px 16px;
            background-image: linear-gradient(
              180deg,
              transparent,
              rgba(0, 0, 0, 0.1) 12.5%,
              rgba(0, 0, 0, 0.4)
            );

            .post-content {
              background-color: transparent;
              border: none;
              color: map-get($color-scale-gray, 0) !important;
              margin-bottom: 10px;
            }

            .audio-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;

              .audio-name-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                max-width: 200px;
                color: map-get($color-scale-gray, 0) !important;

                mask-image: linear-gradient(
                  to right,
                  transparent,
                  rgb(0, 0, 0) 15%,
                  rgb(0, 0, 0) 85%,
                  transparent
                );
                width: 250px;
                overflow: hidden;
                white-space: nowrap;

                .audio-name {
                  font-size: 0.9em;
                  font-weight: 500;
                  white-space: nowrap;
                  animation: scroll 10s linear infinite;
                  display: inline-block;
                  width: 100%;
                  box-sizing: border-box;

                  @keyframes scroll {
                    from {
                      transform: translateX(0);
                    }
                    to {
                      transform: translateX(calc(-100% + 16px));
                    }
                  }
                }
              }
            }
          }

          .reels-content {
            padding: 0px 20px;
            width: 100%;
            height: 100%;
            position: relative;

            img,
            video {
              width: 100%;
              height: 100%;
            }

            img {
              object-fit: contain;
            }
          }
        }
      }

      // for instagram post
      &.preview-instagram {
        > div {
          border-bottom: none !important;
        }
        .post-author-wrapper {
          margin-bottom: 0;
        }
        .post-content {
          .file-preview {
            img,
            video {
              border-radius: 6px;
            }
          }
        }
        .post-interactions {
          .reaction-stats {
            gap: 20px;
            > div {
              width: 16px;
              height: 16px;
              border-radius: 100%;

              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                fill: none;
                stroke: none;
              }
            }
          }
        }
        .instagram-post-like {
          margin-top: 8px;
          font-weight: 600;
          font-size: 1em;
        }
        .instagram-post-description {
          font-size: 1em;
          text-wrap: wrap;
          overflow-wrap: break-word;
          overflow: auto;
          max-height: 5em;
          white-space: pre-wrap;
          > span:first-child {
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          &.empty-description {
            color: map-get($color-scale-gray, 300);
          }
        }

        // Instagram Story
        &.instagram-story {
          position: relative;
          width: 100%;
          height: 500px;
          background-color: #000; // Assuming stories are viewed in a dark mode context
          padding: 0;
          .top-content {
            width: 100%;
            background-image: linear-gradient(
              180deg,
              rgba(38, 38, 38, 0.8) 0,
              rgba(38, 38, 38, 0) 100%
            );
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            padding: 8px 16px;
            .progress-bars {
              display: flex;
              justify-content: space-between;
              width: 100%;

              .progress-bar {
                flex-grow: 1;
                height: 2px;
                background-color: rgba(255, 255, 255, 0.4);
                margin: 0 2px;
                position: relative;

                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 2px;
                  width: 100%; // This will be animated with JavaScript
                  background-color: #fff;
                  animation: progressAnimation 15s linear forwards; // Example duration
                }
              }
            }

            .user-info {
              display: flex;
              align-items: center;
              .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px;
                padding: 2px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }

              .username {
                color: $color-scale-gray-0;
                font-weight: 500;
                font-size: 14px;
                > span {
                  font-weight: 400;

                  color: $color-scale-gray-100;
                }
              }
            }
          }

          .story-content {
            padding: 0px 20px;
            width: 100%;
            height: 100%;
            position: relative;

            img,
            video {
              width: 100%;
              height: 100%;
            }
            img {
              object-fit: contain;
            }
          }

          @keyframes progressAnimation {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }
        }

        // Instagram Reels
        &.instagram-reels {
          position: relative;
          width: 100%;
          height: 500px;
          background-color: #000; // Assuming stories are viewed in a dark mode context
          padding: 0;
          .bottom-content {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            padding: 8px 16px;

            .user-info {
              display: flex;
              align-items: center;
              .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: 8px;
                padding: 2px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }

              .username {
                color: $color-scale-gray-0;
                font-weight: 500;
                font-size: 14px;
                > span {
                  font-weight: 400;

                  color: $color-scale-gray-100;
                }
              }
            }
          }

          .reels-content {
            padding: 0px 20px;
            width: 100%;
            height: 100%;
            position: relative;

            img,
            video {
              width: 100%;
              height: 100%;
            }
            img {
              object-fit: contain;
            }
          }
        }
      }

      &.preview-tiktok {
        position: relative;
        width: 100%;
        height: 500px;
        background-color: #000;
        padding: 0;
        overflow: hidden;
        .bottom-content {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
          padding: 8px 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left-content {
            padding: 0 16px 4px 16px;
            max-width: 240px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              bottom: -8px;
              left: 0;
              width: 500px;
              height: 130%;
              background-image: linear-gradient(
                180deg,
                transparent,
                rgba(0, 0, 0, 0.1) 12.5%,
                rgba(0, 0, 0, 0.4)
              );
              z-index: -11;
            }

            .user-info {
              display: flex;
              align-items: center;
              .username {
                color: $color-scale-gray-0;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 10px;
              }
            }
            .post-content {
              background-color: transparent;
              border: none;
              color: map-get($color-scale-gray, 0) !important;
              margin-bottom: 10px;
            }

            .audio-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;

              .audio-name-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                max-width: 200px;
                color: map-get($color-scale-gray, 0) !important;

                mask-image: linear-gradient(
                  to right,
                  transparent,
                  rgb(0, 0, 0) 15%,
                  rgb(0, 0, 0) 85%,
                  transparent
                );
                width: 250px;
                overflow: hidden;
                white-space: nowrap;

                .audio-name {
                  font-size: 0.9em;
                  font-weight: 500;
                  white-space: nowrap;
                  animation: scroll 10s linear infinite;
                  display: inline-block;
                  width: 100%;
                  box-sizing: border-box;

                  @keyframes scroll {
                    from {
                      transform: translateX(0);
                    }
                    to {
                      transform: translateX(calc(-100% + 16px));
                    }
                  }
                }
              }
            }
          }

          .right-content {
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            margin-bottom: 10px;
            .user-wrapper {
              .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                padding: 2px;

                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .tiktok-icon-wrapper {
              width: 40px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 4px;
              > div {
                svg {
                  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
                }
              }
              > span {
                color: map-get($color-scale-gray, 0);
                font-size: 0.9em;
              }
            }
          }
        }

        .reels-content {
          width: 100%;
          height: 100%;
          position: relative;
          padding: 0 !important;
          .custom-slider {
            height: 100% !important;
            .rs-carousel-toolbar {
              display: none;
            }
          }
          img,
          video {
            width: 100%;
            height: 100%;
          }
          img {
            object-fit: contain;
          }
        }
      }

      &.preview-x {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &.thread {
          flex-direction: column;
          gap: 10px;
        }

        > div {
          border-bottom: none !important;
        }

        .post-author-wrapper {
          display: flex;
          align-items: center;
          border-bottom: 1px solid map-get($color-scale-gray, 100);
          padding: 0 8px;
          .profile-pic {
            width: 32px;
            height: 32px;
            margin-right: 0;
          }
        }

        .x-post-main-content {
          flex: 1;
          padding: 0px;
          > div {
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            border-bottom: none !important;
          }
          .profile-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
            .profile-name {
              font-weight: 600;
            }
            .post-time {
              color: #65676b;
              font-size: 0.85em;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
              > p {
                margin: 0;
              }
            }
          }
          .post-content {
            max-width: 250px;

            .file-preview {
              img,
              video {
                border-radius: 6px;
              }
            }
          }
          .poll-options {
            display: flex;
            padding: 0 8px 0 0;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 4px;

            > li:first-child {
              .option-text,
              .option-percentage {
                font-weight: 600;
              }

              .option-text {
                color: map-get($color-scale-gray, 0);
              }

              .color-bar {
                background-color: map-get($color-scale-primary, 500);
              }
            }

            .poll-option {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 4px;
              padding: 6px 6px 6px 12px;
              border-radius: 6px;
              background-color: transparent;
              position: relative;

              .option-text,
              .option-percentage {
                font-size: 0.9em;
                position: relative;
                z-index: 0;
              }
              .color-bar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 6px;
                background-color: map-get($color-scale-gray, 100);
                z-index: 0;
              }
            }
          }

          .post-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 6px;
            padding: 0 8px 0 0;

            .left-content {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              gap: 6px;
            }
            .right-content {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 4px;
            }
          }
        }
      }
    }
    .preview-modal-empty-content {
      height: 100%;
      width: 100%;
      font-size: 1.2em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      > span {
        font-size: 1.1em;
        font-weight: 600;
        color: $color-scale-gray-300;
      }
    }
  }
  .preview-warning-wrapper {
    padding: 0 20px;
    width: 100%;
    > div {
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      background-color: map-get($color-scale-yellow, 100);
      border-radius: 6px;

      .warning-icon {
        min-width: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .warning-text {
        font-size: 1em;
        color: map-get($color-scale-yellow, 1000);
        font-weight: 600;
        > p {
          font-size: 0.9em;
          margin: 0;
        }
      }
    }
  }
}
