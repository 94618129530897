@import "../../../styles/main.scss";

.emp-profile-dropdown-menu {
    position: relative;

    .dropdown-profile-wrapper {
        margin-left: 12px;
        max-width: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 100ms;
        padding: 0 10px;
        height: 50px;
        border-radius: 12px;

        &:hover {
            background-color: $color-scale-gray-800;
        }

        .display-picture {
            width: 28px;
            height: 28px;
            border-radius: 30px;
        }

        .avatar-profile-img {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            .initials {
                font-weight: 700;
                color: $color-scale-gray-0;
                font-size: 1em;
            }
        }

        .dropdown-profile-name-wrapper {
            overflow: hidden;
            margin-left: 4px;

            .name-lbl {
                font-weight: 600;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $color-scale-gray-300;
            }
        }

    }

    .emp-dropdown-menu-wrapper {

        @include card-border-conic-gradient-mixin(110deg, 12px, 45%, 55%);
        background: $cbz-card-gradient-lg;
        position: absolute;
        right: 0;
        bottom: 0;
        width: fit-content;
        padding: 10px 0;
        border-radius: 10px;
        z-index: 3;

        &.hidden {
            background-color: green;
            visibility: hidden;
            z-index: -100;
        }

        .emp-dropdown-menu-btn {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
            min-width: 150px;
            cursor: pointer;
            color: $color-scale-gray-100;
            font-size: 1em;
            font-weight: 600;
            display: flex;
            align-items: center;
            transition: all 200ms;
            white-space: nowrap;

            &:hover {
                background-color: $color-scale-gray-800;
            }
        }
    }
}