@import "../../../styles/main.scss";

.emp-brand-basic-info-step {

    .header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            height: 30px;
        }

        .description {
            text-align: center;
            color: map-get($color-scale-gray, 300);
            line-height: 1.4em;
            margin: 0;
        }
    }

    .image-upload-group {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;

        img {
            width: 95px;
            height: 95px;
            border-radius: 100px;
            object-fit: cover;
            border: 1px solid map-get($color-scale-gray, 150)
        }

        .empty-div {
            width: 95px;
            height: 95px;
            border-radius: 100px;
            display: flex;
            background-color: map-get($color-scale-gray , 50);
            box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
            align-items: center;
            justify-content: center;
            color: map-get($color-scale-gray , 300);
            font-weight: 600;
        }

        .logo-upload {
            color: $color-scale-primary-300;
            font-weight: 600;
            cursor: pointer;
            margin-top: 8px;
            transition: color 200ms;

            &:hover {
                color: $color-scale-primary-400;
            }
        }

        .avatar {
            display: flex;
            width: 95px;
            height: 95px;
            border-radius: 120px;
            justify-content: center;
            align-items: center;

            .initials {
                font-size: 3em;
                color: map-get($color-scale-gray, 0);
                font-weight: 700;
            }
        }

        .upload-hidden {
            display: none;
        }


    }

    .form-wrapper {
        width: 400px;
        padding: 20px 0;
        border-radius: 20px;
        box-sizing: border-box;

        @include tablet-view {
            width: 100%;
        }

        .split-form-group-wrapper {
            display: flex;
            gap: 12px;

            .gender-wrapper {
                flex: 0.7
            }

            .age-wrapper {
                flex: 0.3
            }
        }

        .body-section {
            padding: 20px 20px 0px 20px;
        }

        .header-section {
            padding: 0 20px 20px 20px;
            border-bottom: 1px solid $color-scale-gray-800;

            .step-lbl {
                font-weight: 600;
                font-size: 1.1em;
                color: $color-scale-gray-100;
                display: block;
            }
        }

        .btn-section {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btn-wrapper {
                display: flex;
                width: fit-content;
                justify-content: flex-end;
                gap: 10px;

                @include mobile-view {
                    width: 100%;
                }
            }
        }
    }
}