@import "../../../styles/main.scss";

.emp-campaign-card {
    cursor: pointer;
    aspect-ratio: 1.667;
    position: relative;
    border-radius: 12px;
    background-size: cover;
    overflow: hidden;
    transition: all 200ms;
    width: 100%;

    &:hover {
        transform: translateY(-3px);

        .top-bar {
            .organisation-wrapper {
                opacity: 1;
                width: 100%;
            }
        }
    }

    .card-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1 !important;
        width: 100%;
        height: 70%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    }

    .campaign-details-wrapper {
        position: relative;
        z-index: 5;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 15px;

        .campaign-name-wrapper {
            display: flex;

            .campaign-name-lbl {
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }
        }

        .campaign-info-wrapper {
            display: flex;
            margin-top: 8px;
            gap: 10px;

            .light-content {
                display: flex;
                flex-direction: column;
                gap: 1px;

                .header {
                    font-size: 0.8em;
                    font-weight: 800;
                    color: $color-scale-gray-300;
                }

                .content {
                    font-size: 0.9em;
                    color: $color-scale-gray-100;
                }
            }
        }
    }

    .top-bar {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        padding: 10px;
        gap: 8px;
        z-index: 1;
        justify-content: space-between;

        .organisation-img-abs {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 38px;
            height: 38px;
            border-radius: 45px;
            aspect-ratio: 1;
            object-fit: cover;
            border: 1px solid $color-scale-gray-800;
            z-index: 3;
        }

        .organisation-wrapper {
            position: relative;
            padding: 5px;
            border-radius: 8px;
            align-items: center;
            display: flex;
            gap: 6px;
            background-color: rgba(0, 0, 0, 0.6);
            width: 48px;
            overflow: hidden;
            opacity: 0;
            transition: all 300ms;
        }

        .organisation-img-placeholder {
            min-width: 38px;
            min-height: 38px;
        }


        .organisation-name-lbl {
            white-space: nowrap;
            font-weight: 600;
            display: block;
            color: $color-scale-gray-100;
        }

        .objective-lbl {
            white-space: nowrap;
            display: block;
            font-size: 0.9em;
            font-weight: 500;
            color: $color-scale-gray-200;
        }
    }
}