@import "../../../styles/main.scss";

.emp-brand-review-draft-view {
    padding-top: 20px;


    .task-details-section {

        .task-name-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .info-wrapper {
                flex: 1;
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }



    }

    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }



    .deliverable-cards-section {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .draft-not-required-card {
        border: 1px solid $color-scale-gray-800;
        padding: 15px !important;

        .header-lbl {
            font-size: 1.1em;
            font-weight: 600;
            color: $color-scale-gray-100;
        }
    }

    .deliverable-section {

        .deliverable-wrapper {
            padding-top: 20px;
            box-sizing: border-box;

            .deliverable-indicator {
                font-size: 0.9em;
                font-weight: 700;
                color: #7E8591;
            }

            .deliverable-name-lbl {
                margin: 0;
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-100
            }

            .deliverable-description {
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300
            }
        }
    }
}