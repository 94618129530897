@import "../../styles/main.scss";

.emp-signin-page {
    width: 100vw;
    height: 100dvh;
    overflow-y: scroll;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile-view {
        min-height: 100dvh;
        height: fit-content;
        padding: 0;
        justify-content: flex-start;
    }


    .emp-signin-content {
        width: 100%;
        height: 100%;
        max-width: 1400px;
        max-height: 800px;
        display: flex;
        border-radius: 55px 20px 55px 20px;
        border: 1px solid $color-scale-gray-800;
        background: $cbz-card-gradient-md;

        @include tablet-view {
            width: fit-content;
        }

        @include mobile-view {
            min-height: fit-content;
            height: fit-content;
            max-height: 100%;
            width: 100%;
            border-radius: 0px;
        }
    }

    .emp-form-area {
        position: relative;
        width: 420px;
        display: flex;
        align-items: center;
        border-radius: 20px 0 0 20px;

        @include tablet-view {
            border-radius: 20px;
        }


        @include mobile-view {

            width: 100%;
            align-items: flex-start;
            height: fit-content;
        }

        .language-wrapper {
            position: absolute;
            top: 30px;
            left: 0;
            width: 100%;
            padding: 0 40px;

            @include mobile-view {
                display: none;
            }
        }

        .emp-form-wrapper {
            padding: 0 50px;
            width: 100%;

            @include mobile-view {
                padding: 60px 20px 50px 20px;
                min-height: 100dvh;
                box-sizing: border-box;
            }

            .logo-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: space-between;

                .logo {
                    position: relative;
                    left: 3px;
                    height: 24px;
                }

                .language-picker-mobile {
                    display: none;

                    @include mobile-view {
                        display: block;
                    }
                }
            }


            .title {
                margin-top: 16px;
                font-size: 2em;
                font-weight: 800;
                color: $color-scale-gray-100;
                display: block;

                @include mobile-view {
                    font-size: 1.6em;
                }
            }

            .description {
                color: $color-scale-gray-400;
                line-height: 1.4em;
            }

        }

        .sign-in-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-scale-gray-300;
        }
    }



    .emp-gallery-area {
        position: relative;
        flex: 1;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        @include tablet-view {
            display: none;
        }


        .gallery-background-mask {
            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
            background: linear-gradient(360deg, #18182a -20%, #060610 30%);
            border-radius: 50px 15px 50px 15px;
            border: 1px solid $color-scale-gray-800;
            background-position: center;
            background-size: cover;


            .hidden-div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .slide-wrapper {
                position: relative;

                .slide {
                    position: absolute;
                    top: 0;
                    right: 0;
                    overflow: hidden;

                    .slide-anchor {
                        background: linear-gradient(360deg, #18182a -20%, #060610 30%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }



                    .slide-image {
                        object-fit: cover;
                    }


                    .slide-hidden-div {
                        width: 100%;
                        height: 100%;
                        background-color: black;
                    }
                }
            }


            .info-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 40px 30px;
                box-sizing: border-box;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);

                .testimony-line {
                    font-weight: 600;
                    color: map-get($color-scale-gray, 0);
                    line-height: 1.4em;
                    font-size: 1.6em;
                    transition: all 200ms;
                }

                .name-lbl {
                    display: block;
                    font-weight: 700;
                    font-size: 1.3em;
                    color: map-get($color-scale-gray, 0);
                    transition: all 200ms;
                }

                .representative-lbl {

                    display: block;
                    margin-top: 6px;
                    font-weight: 500;
                    font-size: 1em;
                    color: map-get($color-scale-gray, 200);
                    transition: all 200ms;
                }
            }

            .button-wrapper {
                position: absolute;
                bottom: 40px;
                right: 30px;
                display: flex;
            }

            .loader-wrapper {
                position: absolute;
                bottom: 0px;
                left: 0px;
                height: 6px;
                width: 100%;
                background-color: map-get($color-scale-gray, 800);

                .loader-bar {
                    height: 100%;
                    background-color: map-get($color-scale-primary, 500);
                }
            }

        }
    }
}