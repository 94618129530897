@import "../../styles/main.scss";


.emp-subscription-cancellation-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }
    }

    .cancellation-content-section {
        padding: 0 20px;

        .skeletal-card,
        .cancellation-card {
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            border-radius: 12px;
            padding: 15px 15px 20px 15px;
        }
        .skeletal-card {
            height: 135px;
        }
        .cancellation-card {
            h2 {
                margin: 0;
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-100;
            }

            .info-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .acknowledgement-wrapper {
            cursor: pointer;
            display: flex;


            .selected-text {
                font-weight: 600 !important;
            }
        }
    }


}