@import "../../../styles/main.scss";

.emp-submit-evidence-select-sm-modal {
  .modal-shine-border {
    border: 1px solid $color-scale-gray-800;
    border-radius: 12px;
    margin: 6px;
    background: linear-gradient(180deg, $color-scale-gray-850 0%, $color-scale-gray-900 30%);
  }

  .header-section,
  .social-media-section {
    padding: 0 16px;
  }


  .header-section {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
      font-weight: 600;
      font-size: 1.4em;
      color: $color-scale-gray-100;
      text-align: center;
      margin: 0;
    }

    p {
      text-align: center;
    }
  }

  .social-media-section {

    .platform-card {
      position: relative;
      overflow: hidden;
      display: flex;
      border-radius: 12px;
      border: 1px solid $color-scale-gray-800;
      background-color: $color-scale-gray-900;
      padding: 8px;
      box-sizing: border-box;

      .loader {
        position: absolute;
        width: 100%;
        top: -1px;
        left: 0;
      }

      .left-elem {
        display: flex;
        align-items: center;
        flex: 1;


        .platform-logo {
          background-color: $color-scale-gray-900;
          border: $color-scale-gray-800 solid 1px;
          width: 45px;
          min-width: 45px;
          min-height: 45px;
          height: 45px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.empty-state {
            background-color: map-get($color-scale-gray, 800);
          }
        }

        .social-media-img-wrapper {
          position: relative;
          display: flex;

          .social-media-logo-placeholder {
            position: absolute;
            right: -2px;
            bottom: -2px;
            width: 20px;
            height: 20px;
            border: 1.5px solid map-get($color-scale-gray, 100);
            border-radius: 50px;
            background-color: #1778F2;
            display: flex;
            align-items: center;
            justify-content: center;
          }


          .social-media-image {
            object-fit: contain;
            width: 45px;
            height: 45px;
            border-radius: 8px;
          }

        }


        .content {
          margin-left: 12px;

          .top-section {
            display: flex;
            align-items: center;
            gap: 4px;

            .unlink-btn-wrapper {
              position: relative;
              top: -2px;
            }
          }

          .status-lbl {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          .action-lbl {
            margin-top: 4px;
            display: block;
            color: $color-scale-gray-400;

          }
        }
      }

      .right-elem {
        display: flex;
        align-items: center;
      }
    }
  }

  .footer-section {
    margin-top: 20px;
    border-top: 1px solid $color-scale-gray-800;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 16px;

    .flex-full {
      flex: 1;
    }
  }
}