@import "../../../styles/main.scss";

.emp-toast {
  border-radius: 100px;
  padding: 10px;
  padding-right: 18px;
  color: $color-scale-gray-100;
  background: $cbz-card-gradient-sm;
  border: 1px solid $color-scale-gray-800;
  margin-top: 10px;
  opacity: 0;
  transform: translateX(100%);
  transition: 300ms all;

  &.appear {
    opacity: 1;
    transform: translateX(0%);
  }

  .toast-wrapper {
    display: flex;
    align-items: center;
    // background-color: red;

    .icon-wrapper {
      flex-shrink: 0;
      width: 38px;
      height: 38px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.success {
        background-color: map-get($color-scale-green, 400);
      }

      &.error {
        background-color: map-get($color-scale-red, 400);
      }
    }

    .title {
      position: relative;
      top: 2px;
      margin: 0;
      margin-top: 2px;
      font-weight: 600;
      font-size: 1em;
    }

    .description {
      margin: 0;
      margin-top: 4px;
      color: $color-scale-gray-300;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 1.4em;
      white-space: pre-line;
    }
  }
}