@import "../../styles/main.scss";


.exclusive-partnership-confirmation-modal {
    display: flex;
    flex-direction: column;
    align-items: center;


    .content-section {
        margin-top: 20px;


        .disclaimer-section {
            display: flex;
            flex-direction: column;
            flex: 1;

            .picture-wrapper {
                justify-content: center;
                display: flex;
                gap: 20px;
                align-items: center;

                .plus {
                    display: block;
                    position: relative;
                    font-weight: 700;
                    font-size: 2.5em;
                    color: $color-scale-gray-300;
                }

                .profile,
                .avatar,
                .org-image {
                    width: 60px;
                    height: 60px;
                }

                .profile,
                .avatar {
                    border-radius: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .initials {
                        color: $color-scale-gray-0;
                        font-size: 1.8em;
                        font-weight: 700;
                    }
                }

                .org-image {
                    border-radius: 8px;
                }
            }

            .heading {
                margin-top: 40px;
                text-align: center;
                font-size: 1.4em;
                line-height: 1.4em;
                font-weight: 600;
                color: $color-scale-gray-100;

                .highlighted {
                    color: $color-scale-primary-400 !important;
                }
            }

            .disclaimer-description {
                margin-top: 30px;
                text-align: center;
                line-height: 1.4em;
                font-weight: 500;
                color: $color-scale-gray-300;
            }

            .btn-wrapper {
                flex: 1;
                width: 100%;
                display: flex;
                align-items: flex-end;
            }
        }

        .button-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
        }
    }

}