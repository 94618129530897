@import "../../styles/main.scss";


.emp-agency-invite-creator-to-task-modal {
    position: relative;
    display: flex;
    flex-direction: column;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .header {
        font-size: 1.1em;
        font-weight: 600;
        color: $color-scale-gray-100;
    }


    .loading-view {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        .emp-loader {
            padding: 12px 12px;
            background-color: $color-scale-gray-900;
            border-radius: 8px;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            flex-direction: column;
            align-items: center;

            .label {
                font-weight: 600;
                font-size: 1.2em;
            }
        }
    }


    .content-section {
        margin-top: 20px;

        .talent-card-wrapper {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 8px;
        }

        .talent-card {
            padding: 8px 8px 12px 8px;
            box-sizing: border-box;
            border-radius: 8px;
            border: $color-scale-gray-800 solid 1px;
            display: flex;
            gap: 8px;
            cursor: pointer;
            transition: 200ms all;
            background-color: $color-scale-gray-900;

            &:hover {
                transform: translateY(-3px);
            }

            .profile-img,
            .profile-avatar {
                width: 42px;
                height: 42px;
                min-width: 42px;
                min-height: 42px;
                border: 1px solid map-get($color-scale-gray, 800);
                border-radius: 80px;
            }

            .profile-img {
                object-fit: cover;
            }

            .profile-avatar {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-size: 1.4em;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                }
            }

            .bio-wrapper {
                flex: 1;

                .email,
                .name {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                }

                .name-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

                .icon-wrapper {
                    flex: 1;
                    display: flex;
                    justify-content: flex-end;
                    min-width: 20px;
                }

                .name {
                    font-size: 1.05em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .email {
                    width: 100%;
                    margin-top: 2px;
                    font-size: 0.9em;
                    font-weight: 500;
                    color: $color-scale-gray-300;
                }

                .metrics-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }

        }
    }

}