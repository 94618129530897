@import "../../../styles/main.scss";

.emp-breadcrumb {
    display: flex;
    align-items: center;

    .crumb-wrapper {
        display: flex;

        &:first-child {
            .crumb {
                padding-left: 0;
            }

        }
    }

    .skeletal-crumb {
        height: 26.5px;
        width: 80px;
        border-radius: 4px;
    }

    .crumb {
        padding: 5px 8px;
        font-size: 1em;
        font-weight: 500;
        color: $color-scale-gray-300;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            color: $color-scale-gray-300;
        }

        &.active {
            font-weight: 600;
            color: $color-scale-primary-400;
            background-color: rgba(122, 59, 247, 0.1);

            &:hover {
                color: $color-scale-primary-500;
            }
        }
    }
}