@import "../../styles/main.scss";

@mixin mobile-view {
    @media only screen and (max-width:500px) {
        @content
    }
}




.emp-404-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    width: 100vw;
    background-color: $color-scale-gray-900;
    padding: 20px;
    box-sizing: border-box;

    @include mobile-view {
        padding: 10px;
    }

    .pattern-bg {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        svg {
            width: 100%;
            opacity: 0.5;
        }
    }



    .emplifive-logo {
        height: 30px;
        position: relative;
        top: -20px;
    }

    .emp-content-wrapper {
        z-index: 1;
        max-width: 600px;
        width: 100%;
        background-color: $color-scale-gray-900;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 30px;
        align-items: center;
        box-sizing: border-box;
        border-radius: 15px;

        @include mobile-view {
            padding: 20px 15px;
        }


        .title {
            margin: 0;
            font-weight: 700;
            font-size: 2.4em;
            color: $color-scale-gray-100;

            @include mobile-view {
                font-size: 1.8em;
            }
        }

        .description {
            margin: 0;
            font-weight: 500;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }

        .button-wrapper {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;


            .button {
                width: fit-content;

                @include mobile-view {
                    flex: 1
                }
            }

        }


    }
}