@import "../../../styles/main.scss";

.emp-subscription-access-control-view {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    background: $cbz-background-gradient;



    .loading-wrapper {
        position: relative;
        top: -80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            margin-bottom: 6px;
            height: 26px;
            width: auto;
        }

        .loading-lbl {
            font-size: 1em;
            font-weight: 800;
            color: $color-scale-gray-300;
        }
    }

    .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        width: 100%;
        max-width: 500px;

        img {
            width: 140px;
            height: auto;
        }

        .title-lbl {
            text-align: center;
            font-size: 1.4em;
            line-height: 1.4em;
            font-weight: 700;
            color: $color-scale-gray-100;
        }

        p {
            text-align: center;
        }
    }
}