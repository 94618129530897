.rs-picker-menu {
    z-index: 100 !important;
}

.rs-picker-toggle-textbox {
    background: $cbz-card-gradient-sm !important;

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: map-get($color-scale-gray, 300);
        font-weight: 400;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: map-get($color-scale-gray, 300);
        font-weight: 400;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: map-get($color-scale-gray, 300);
        font-weight: 400;
    }
}

.rs-picker-toggle {
    // padding: 9px 12px !important;
    height: 40px !important;
}


.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    box-shadow: none;
}


.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #eff0f1 !important;
    font-weight: 500;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
    background: $cbz-card-gradient-sm !important;
}

.rs-picker-default .rs-picker-toggle {
    border: 1px solid $color-scale-gray-800;
}