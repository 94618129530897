@import "../../styles/main.scss";


.subscription-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 200;
}

.emp-subscription-alert-modal {
    z-index: 201;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;



    .content-section {
        background: linear-gradient(137deg, #073764 0%, #000 100%);
        position: relative;
        padding: 20px;
        border-radius: 20px;
        display: flex;
        width: 100%;
        max-width: 900px;
        max-height: 75vh;
        box-sizing: border-box;

        .left-section {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 15px;
            padding-bottom: 15px;
            padding-top: 10px;

            .logo {
                height: 30px;
            }

            .pattern-wrapper {
                position: relative;
                overflow: hidden;
                width: 100%;
                flex: 1;
                display: flex;
                align-items: center;

                .image-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .megaphone-img {
                        width: 60%;
                        height: auto;
                        animation: verticle-pulse-lg 12s ease 0s infinite normal forwards;
                    }
                }
            }

            .pattern {
                aspect-ratio: 1;
                width: 100%;
            }

            .cta-header {
                color: $color-scale-gray-0;
                font-size: 2.2em;
                line-height: 1.4em;
                margin: 0;

                .highlighted {
                    color: $color-scale-primary-400;
                }
            }

            .cta-description {
                margin: 0;
                margin-top: 12px;
                font-size: 1.1em;
                line-height: 1.4em;
                color: $color-scale-gray-300;
            }
        }

        .right-section {
            background-color: white;
            padding: 20px 0;
            width: 450px;
            border-radius: 12px;
            position: relative;
            overflow-y: auto;

            .dismiss-icon-wrapper {
                z-index: 1;
                cursor: pointer;
                position: absolute;
                top: 16px;
                right: 20px;
            }
        }
    }
}