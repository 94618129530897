@import "../../../styles/main.scss";

.emp-toggle-view-switch {
    padding: 5px;
    width: fit-content;
    border-radius: 10px;
    background-color: $color-scale-gray-950;
    border: 1px solid $color-scale-gray-800;
    display: flex;
    gap: 4px;

    .tabbed-option {
        cursor: pointer;
        height: 24px;
        padding: 3px 4px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            background-color: $color-scale-gray-700;
            cursor: not-allowed;
        }


        span {
            font-weight: 600;
            color: $color-scale-gray-400;
            transition: color 200ms;

            &:hover {
                color: $color-scale-gray-300;
            }
        }

        &.active {
            span {
                color: $color-scale-gray-300;
            }
        }

    }
}