@keyframes verticle-pulse {

    0%,
    100% {
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateY(-3px);
    }

    20%,
    40%,
    60% {
        transform: translateY(3px);
    }

    80% {
        transform: translateY(2.4px);
    }

    90% {
        transform: translateY(-2.4px);
    }
}

@keyframes verticle-pulse-lg {

    0%,
    100% {
        transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
        transform: translateY(-8px);
    }

    20%,
    40%,
    60% {
        transform: translateY(8px);
    }

    80% {
        transform: translateY(6px);
    }

    90% {
        transform: translateY(-6px);
    }
}