@import "../../../styles/main.scss";

.emp-table {
  width: 100%;
  position: relative;

  .empty-overlay {
    z-index: 1;
    position: absolute;
    background: linear-gradient(180deg,
        rgb(33, 35, 39, 0) 10%,
        rgb(0, 0, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 47.5px;
    width: 100%;
    height: calc(100% - 47.5px);
  }

  table {
    width: 100%;
    display: block;
    overflow-x: auto;
    position: relative;

    th {
      text-align: left;
      border-top: solid 1px $color-scale-gray-800;
      border-bottom: solid 1px $color-scale-gray-800;
      transition: all 200ms;
      color: $color-scale-gray-100;
      font-size: 1em;

      &.table-in-card {
        border-top: none;
      }

      .emp-table-header,
      .emp-table-sortable-header {
        display: flex;
        padding: 14px 12px;
      }

      &:first-child {

        .emp-table-sortable-header,
        .emp-table-header {
          padding-left: 0;
        }
      }

      &:last-child:not(:first-child) {

        .emp-table-sortable-header,
        .emp-table-header {
          justify-content: end;
          padding-right: 0;
        }
      }

      .emp-table-sortable-header {
        cursor: pointer;
        align-items: center;

        &:hover {
          background-color: $color-scale-gray-800;
        }
      }
    }

    td {
      padding: 12px 12px;
      font-size: 0.9em;
      border-bottom: solid 1px $color-scale-gray-800;
      color: $color-scale-gray-300;

      &.separator {
        padding: 3px 12px;
        border-left: 1px solid $color-scale-gray-800;
        border-right: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-850 !important;
        font-weight: 600;
        color: $color-scale-gray-400;
      }

      &.collapsible {
        padding: 0 !important;
        border-left: 1px solid $color-scale-gray-800;
        border-right: 1px solid $color-scale-gray-800;
      }

      .collapsible-wrapper {
        transition: all 200ms;
        overflow: hidden;
      }

      .emp-table-content {
        display: flex;
      }

      &:last-child:not(:first-child) {
        .emp-table-content {
          justify-content: flex-end;
        }
      }

      .empty-shimmer {
        margin: 12px 0;
        height: 20px;
        border-radius: 3px;
        width: 80%;
      }
    }

    th,
    td {
      width: 0.1%;
      font-size: 1em;
      line-height: 1.4em;

      &:first-child {
        padding-left: 32px;

        &.sm-padding {
          padding-left: 15px !important;
        }

        &.shadow {
          box-shadow: rgba(149, 157, 165, 0.4) 0px 0px 12px;
          clip-path: inset(0px -12px 0px 0px);

          &.first-fixed {
            position: sticky;
            left: 0;
            z-index: 2;

            &::before {
              content: "";
              box-shadow: inset 10px 0 8px -8px rgba(149, 157, 165, 0.4);
              position: absolute;
              top: 0;
              right: 0;
              bottom: -1px;
              width: 30px;
              transform: translate(100%);
              transition: box-shadow 0.3s;
            }
          }
        }
      }

      &:last-child {
        width: 100% !important;
        padding-right: 32px;

        &.sm-padding {
          padding-right: 15px !important;
        }

        &.shadow {
          &.last-fixed {
            position: sticky;
            right: 0;
            z-index: 2;

            &::before {
              content: "";
              box-shadow: inset -10px 0 8px -8px rgba(149, 157, 165, 0.4);
              position: absolute;
              top: 0;
              left: -30px;
              bottom: -1px;
              width: 30px;
              transition: box-shadow 0.3s;
            }
          }
        }
      }

      .shimmer-block {
        height: 20px;
        border-radius: 4px;
      }
    }
  }

  .pagination-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .emp-empty-table {
    @include card-border-conic-gradient-mixin(90deg, 0px, 47%, 53%);

    width: 100%;
    height: 250px;
    background: $cbz-card-gradient-lg;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .emp-table-title {
      display: block;
      font-size: 1em;
      font-weight: 600;
      color: $color-scale-gray-100;
    }

    .emp-table-description {
      margin: 0;
      margin-top: 8px;
      font-size: 1em;
      color: $color-scale-gray-300;
    }
  }
}