@import "../../../styles/main.scss";


.emp-toggle-btn {
    all: unset;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;

    .btn-wrapper {
        position: relative;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-scale-gray-850;
        border: 1px solid $color-scale-gray-800;
        width: 25px;
        height: 25px;
        border-radius: 6px;

        .horizontal-line {
            height: 2px;
            width: 10px;
            background-color: $color-scale-gray-300;
        }

        .rotatable-line {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .line {
                height: 10px;
                width: 2px;
                background-color: $color-scale-gray-300;
            }

        }
    }


    .bg-hover {
        position: absolute;
        top: -12.5px;
        left: -12.5px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        transition: all 200ms;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .anim-circle {
            background-color: $color-scale-gray-850;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            opacity: 0;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            .anim-circle {
                background-color: $color-scale-gray-850;
                width: 50px;
                height: 50px;
                opacity: 1;
            }
        }
    }
}