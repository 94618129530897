@import "./colors.scss";

.emp-spinner {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid;
    border-color: $color-scale-gray-900;
    border-right-color: $color-scale-primary-200;
    animation: spinner 1s infinite;

    &.small {
        width: 10px;
        height: 10px;
        border: 1px solid;
        border-color: $color-scale-gray-800;
        border-right-color: $color-scale-primary-500;
    }

    &.medium {
        width: 13px;
        height: 13px;
        border: 2px solid;
        border-color: $color-scale-gray-800;
        border-right-color: $color-scale-primary-500;
    }

    &.large {
        width: 30px;
        height: 30px;
        // border: 6px solid;
        border: 6px solid;
        border-color: $color-scale-gray-800;
        border-right-color: $color-scale-primary-500;
        border-radius: 50%;
    }

    @keyframes spinner {
        to {
            transform: rotate(1turn);
        }
    }
}


.emp-loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

    &.transparent {
        background-color: transparent;
    }

    &.light {
        background: rgba(255, 255, 255, 0.25);
    }

    &.default {
        background: rgba(0, 0, 0, 0.25);
    }

    &.dark {
        background: rgba(0, 0, 0, 0.25);
    }

    .emp-loader {
        padding: 12px 12px;
        background-color: $color-scale-gray-900;
        border-radius: 8px;
        border: 1px solid $color-scale-gray-750;
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            font-weight: 600;
            font-size: 1.2em;
            color: $color-scale-gray-100;
        }
    }
}


/* Progress Bar */
.emp-progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #36065f;
    border-radius: 2px;
    background-clip: padding-box;
    overflow: hidden;
}

.emp-progress .indeterminate {
    background-color: #860fed;
}

.emp-progress .indeterminate:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.emp-progress .indeterminate:after {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@-webkit-keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}