@import "../../../styles/main.scss";

.emp-google-button {
  all: unset;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 0 14px;
  color: $color-scale-gray-100;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid $color-scale-gray-800;
  background: radial-gradient(circle at 50% 600%, #1D1D26, #171720);
  transition: all 200ms ease;
  text-align: center;
  border-radius: 10px;
  mask-image: paint(squircle);
  --squircle-radius: 30px;
  --squircle-smooth: 4;


  &.height-sm {
    height: 30px;

    @include mobile-view {
      height: 35px;
    }
  }

  &.height-md {
    height: 35px;

    @include mobile-view {
      height: 40px;
    }
  }

  &.height-lg {
    height: 40px;

    @include mobile-view {
      height: 45px;
    }
  }

  &:hover {
    background: radial-gradient(circle at 50% 600%, #272733, #1e1e2a);
  }

  .emp-button-content {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .google-icon {
      height: 20px;
      width: 20px;
      position: relative;
      top: -1px;
    }
  }

  .emp-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.full-width {
  width: 100%;
}

.fit-content {
  width: fit-content;
}