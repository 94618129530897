.emp-tiktok-loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .emp-loader {
        padding: 12px 12px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 1px solid #EFF0F1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .label {
            font-weight: 700;
            font-size: 1.2em;
        }
    }
}