@import "../../styles/main.scss";




.emp-creator-page {
    .header-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 10px;


        h1 {
            font-size: 1.8em;
            color: $color-scale-gray-100;
            margin: 0;
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .menu-wrapper {
            margin-top: 20px;

            .creator-menu-item {
                display: flex;
                padding: 10px;
                width: 100%;
                border-radius: 6px;
                cursor: pointer;
                background-color: map-get($color-scale-primary, 50);
                color: map-get($color-scale-gray, 500);
                font-size: 1.1em;
                font-weight: 600;
                box-sizing: border-box;
                transition: all 200ms;

                span {
                    white-space: nowrap;
                }


                &.active {
                    color: map-get($color-scale-primary, 500);
                    background-color: map-get($color-scale-primary, 100);
                }

                &:hover {
                    background-color: map-get($color-scale-gray, 100);
                }

                &.logout {
                    color: map-get($color-scale-red, 600);
                }

                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }

        .creator-view-wrapper {
            flex: 1;
            overflow: hidden;
        }
    }
}