@import "../../../styles/main.scss";

.emp-support-view {

    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    .view-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
        }

        .page-description {
            line-height: 1.4em;
            color: $color-scale-gray-300;
            margin-bottom: 0;
        }
    }

    .card-wrapper {
        margin-top: 20px;
        max-width: 600px;
        display: flex;

        .support-card {
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            padding: 10px 16px 10px 10px;
            width: 100%;
            border-radius: 12px;
            display: flex;
            align-items: center;
            gap: 12px;

            .image-wrapper {

                img,
                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                }

                img {

                    border: 1px solid map-get($color-scale-gray, 100)
                }

                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 1.8em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }
            }

            .details-wrapper {
                flex: 1;

                .name-lbl {
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .email-lbl {
                    color: $color-scale-gray-300;
                }
            }

            .action-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;
            }

        }
    }

}