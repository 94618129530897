// Color scales




$gray-card-alt: #282b31;
$color-scale-primary: (
  50: #f3e7fd,
  100: #e7cffb,
  150: #dbb7fa,
  200: #cf9ff8,
  250: #c387f6,
  300: #b66ff4,
  350: #aa57f2,
  400: #9e3ff1,
  450: #9227ef,
  500: #860fed,
  550: #790ed5,
  600: #6b0cbe,
  650: #29282b,
  700: #50098e,
  750: #430877,
  800: #36065f,
  850: #280447,
  900: #1b032f,
  950: #0d0118,
);

$color-scale-red: (
  50: #ffeeee,
  100: #ffd2d3,
  200: #ffa5a7,
  300: #ff777b,
  400: #ff4a4f,
  500: #ff1d23,
  600: #d50006,
  700: #8e0004,
  800: #470002,
);

$color-scale-green: (
  100: #c2f4d2,
  200: #84eaa6,
  300: #47df79,
  400: #21bd54,
  500: #168039,
  600: #11602b,
  700: #0b401d,
  800: #06200e,
);

$color-scale-gray: (
  0: #ffffff,
  50: #f8f8f9,
  100: #eff0f1,
  150: #dfe0e3,
  200: #cfd1d6,
  250: #bfc2c8,
  300: #aeb3ba,
  350: #9ea3ac,
  400: #8e949f,
  450: #7e8591,
  500: #6f7682,
  550: #595e68,
  600: #4e535b,
  650: #43474e,
  700: #383b41,
  750: #2c2f34,
  800: #212327,
  850: #16181a,
  900: #0b0c0d,
  950: #000000,
  1000: #000000,
);

$color-scale-yellow: (
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #ffee58,
  500: #ffeb3b,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  1000: #9f8e54,
);

$color-scale-red-100: map-get($color-scale-red, 100);
$color-scale-red-200: map-get($color-scale-red, 200);
$color-scale-red-300: map-get($color-scale-red, 300);
$color-scale-red-400: map-get($color-scale-red, 400);
$color-scale-red-500: map-get($color-scale-red, 500);
$color-scale-red-600: map-get($color-scale-red, 600);
$color-scale-red-700: map-get($color-scale-red, 700);
$color-scale-red-800: map-get($color-scale-red, 800);

$color-scale-green-100: map-get($color-scale-green, 100);
$color-scale-green-200: map-get($color-scale-green, 200);
$color-scale-green-300: map-get($color-scale-green, 300);
$color-scale-green-400: map-get($color-scale-green, 400);
$color-scale-green-500: map-get($color-scale-green, 500);
$color-scale-green-600: map-get($color-scale-green, 600);
$color-scale-green-700: map-get($color-scale-green, 700);
$color-scale-green-800: map-get($color-scale-green, 800);

$color-scale-yellow-100: map-get($color-scale-yellow, 100);
$color-scale-yellow-200: map-get($color-scale-yellow, 200);
$color-scale-yellow-300: map-get($color-scale-yellow, 300);
$color-scale-yellow-400: map-get($color-scale-yellow, 400);
$color-scale-yellow-500: map-get($color-scale-yellow, 500);
$color-scale-yellow-600: map-get($color-scale-yellow, 600);
$color-scale-yellow-700: map-get($color-scale-yellow, 700);
$color-scale-yellow-800: map-get($color-scale-yellow, 800);
$color-scale-yellow-900: map-get($color-scale-yellow, 900);

$color-scale-gray-0: map-get($color-scale-gray, 0);
$color-scale-gray-50: map-get($color-scale-gray, 50);
$color-scale-gray-100: map-get($color-scale-gray, 100);
$color-scale-gray-150: map-get($color-scale-gray, 150);
$color-scale-gray-200: map-get($color-scale-gray, 200);
$color-scale-gray-250: map-get($color-scale-gray, 250);
$color-scale-gray-300: map-get($color-scale-gray, 300);
$color-scale-gray-350: map-get($color-scale-gray, 350);
$color-scale-gray-400: map-get($color-scale-gray, 400);
$color-scale-gray-450: map-get($color-scale-gray, 450);
$color-scale-gray-500: map-get($color-scale-gray, 500);
$color-scale-gray-550: map-get($color-scale-gray, 550);
$color-scale-gray-600: map-get($color-scale-gray, 600);
$color-scale-gray-650: map-get($color-scale-gray, 650);
$color-scale-gray-700: map-get($color-scale-gray, 700);
$color-scale-gray-750: map-get($color-scale-gray, 750);
$color-scale-gray-800: map-get($color-scale-gray, 800);
$color-scale-gray-850: map-get($color-scale-gray, 850);
$color-scale-gray-900: map-get($color-scale-gray, 900);
$color-scale-gray-950: map-get($color-scale-gray, 950);
$color-scale-gray-1000: map-get($color-scale-gray, 1000);

$color-scale-primary-0: map-get($color-scale-primary, 0);
$color-scale-primary-50: map-get($color-scale-primary, 50);
$color-scale-primary-100: map-get($color-scale-primary, 100);
$color-scale-primary-150: map-get($color-scale-primary, 150);
$color-scale-primary-200: map-get($color-scale-primary, 200);
$color-scale-primary-250: map-get($color-scale-primary, 250);
$color-scale-primary-300: map-get($color-scale-primary, 300);
$color-scale-primary-350: map-get($color-scale-primary, 350);
$color-scale-primary-400: map-get($color-scale-primary, 400);
$color-scale-primary-450: map-get($color-scale-primary, 450);
$color-scale-primary-500: map-get($color-scale-primary, 500);
$color-scale-primary-550: map-get($color-scale-primary, 550);
$color-scale-primary-600: map-get($color-scale-primary, 600);
$color-scale-primary-650: map-get($color-scale-primary, 650);
$color-scale-primary-700: map-get($color-scale-primary, 700);
$color-scale-primary-750: map-get($color-scale-primary, 750);
$color-scale-primary-800: map-get($color-scale-primary, 800);
$color-scale-primary-850: map-get($color-scale-primary, 850);
$color-scale-primary-900: map-get($color-scale-primary, 900);
$color-scale-primary-950: map-get($color-scale-primary, 950);
$color-scale-primary-1000: map-get($color-scale-primary, 1000);

$color-scale-danger: (
  0: #fdd9d7,
  250: #f88e86,
  500: #f44336,
  750: #922820,
  1000: #310d0b,
);

$chart-color: (
  "purple": #a158ff,
  "purple-light": rgba(160, 88, 255, 0.2),
  "orange": #ff9458,
  "orange-light": rgba(255, 149, 88, 0.2),
  "teal": #58b7ff,
  "teal-light": rgba(88, 183, 255, 0.2),
);

// Social Media Color
$instagram-color: linear-gradient(45deg, #ffdd55, #ff543e, #c837ab);
$facebook-color: $color-scale-primary-500;
$tiktok-color: $color-scale-gray-750;
$x-color: $color-scale-gray-750;

@each $name,
$scale in (primary: $color-scale-primary,
  "gray": $color-scale-gray,
  danger: $color-scale-danger,
  chart: $chart-color ) {
  @each $shade, $color in $scale {
    .bg-#{$name}-#{$shade} {
      background-color: $color;
    }

    .color-#{$name}-#{$shade} {
      color: $color;
    }
  }
}

$main-color: map-get($color-scale-primary, 950);

$cbz-neutral-color-1: #060610;
$cbz-neutral-color-2: #100b1d;
$cbz-card-gradient-sm: linear-gradient(360deg, #18182a -40%, #060610 10%);
$cbz-card-gradient-md: linear-gradient(360deg, #18182a -30%, #060610 20%);
$cbz-card-gradient-lg: linear-gradient(360deg, #18182a -20%, #060610 30%);
$cbz-primary-gradient: linear-gradient(90deg, #6502F6 0%, #E600AE 100%);
$cbz-background-gradient: linear-gradient(180deg, #070711 0%, #100420 100%);

.cbz-background-gradient {
  background: $cbz-background-gradient;
}

.cbz-primary-button-color {
  background: radial-gradient(circle at 50% 300%, #865BFF, #4B23BA);
  border: 1px solid #764CE7;
}



@mixin card-border-conic-gradient-mixin($deg : 110deg, $radius, $start, $end, $border-top: true, $border-right: true, $border-bottom: true, $border-left: true) {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    border-radius: $radius;

    inset: if($border-top, 0, 1px) if($border-right, 0, 1px) if($border-bottom, 0, 1px) if($border-left, 0, 1px);

    // Dynamically set padding for each side
    padding-top: if($border-top, 1px, 0);
    padding-right: if($border-right, 1px, 0);
    padding-bottom: if($border-bottom, 1px, 0);
    padding-left: if($border-left, 1px, 0);

    background: conic-gradient(from $deg, $color-scale-gray-800 #{$start}, #6262a1 50%, $color-scale-gray-800 #{$end});
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}


.cbz-card-border-conic-gradient-sm {
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-radius: 12px;
    padding: 1px;
    background: conic-gradient(from 110deg, $color-scale-gray-800 46%, $color-scale-gray-650 50%, $color-scale-gray-800 54%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.cbz-card-border-conic-gradient {
  position: relative;
  pointer-events: none;

  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 40px;
    padding: 1px;
    background: conic-gradient(from 110deg, $color-scale-gray-800 49%, #9D9DFE 50%, $color-scale-gray-800 51%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.cbz-card-background-gradient {
  background: linear-gradient(360deg, #18182a -20%, #060610 30%);
}

.cbz-card-border-gradient {
  // border: 1px solid #14141c;
  border: 1px solid white;
  border-image: conic-gradient(red, orange, red, red, red) 1;
  background: conic-gradient(from 90deg at 50% 50%, #000000, rgba(255, 38, 38, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))
}