@import "../../styles/main.scss";


.emp-apply-task-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .profile-photo-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .agency-photo-wrapper {
            position: relative;

            .agency-badge-wrapper {
                position: absolute;
                bottom: -8px;
                right: -12px;

                .profile-photo {
                    border-radius: 10px;
                    ;
                    width: 36px !important;
                    height: 36px !important;
                }
            }
        }

        .profile-photo {
            width: 60px;
            height: 60px;
            border-radius: 100px;
            object-fit: cover;
            border: 1px solid map-get($color-scale-gray, 150);

            &.org-photo {
                border-radius: 12px;
            }
        }

        .plus-label {
            top: -6px;
            position: relative;
            display: block;
            color: $color-scale-gray-200;
            font-weight: 500;
            font-size: 4em;
        }

        .empty-div {
            width: 60px;
            height: 60px;
            border-radius: 100px;
            display: flex;
            background-color: map-get($color-scale-gray , 50);
            box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
            align-items: center;
            justify-content: center;
            color: map-get($color-scale-gray , 500);
            font-weight: 600;
        }


        .avatar {
            display: flex;
            width: 60px;
            height: 60px;
            border-radius: 120px;
            justify-content: center;
            align-items: center;

            .initials {
                font-size: 1.8em;
                color: map-get($color-scale-gray, 0);
                font-weight: 700;
            }
        }
    }



    .content-section {
        margin-top: 20px;

        .heading,
        .task-name-lbl {
            display: block;

            font-weight: 600;
            text-align: center;
            line-height: 1.4em;
        }

        .task-name-lbl {
            font-size: 1.3em;
            color: $color-scale-gray-100;
        }

        .heading {
            font-size: 1em;
            color: $color-scale-gray-300;
            margin-top: 6px;
        }

        .description {
            text-align: center;
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }

        .modal-setting-item {
            width: 100%;
            display: flex;
            gap: 12px;
            align-items: center;
            margin: 30px 0 10px 0;

            .switch-info {
                flex: 1;

                .switch-label {
                    display: block;
                    font-weight: 500;
                    color: $color-scale-gray-100;
                }

                .switch-description {
                    font-size: 0.95em;
                    margin: 0;
                    color: $color-scale-gray-300;
                    margin-top: 4px;
                }
            }

            .switch-wrapper {
                min-width: fit-content;
                min-height: fit-content;
            }
        }

        .fence-card {
            width: 100%;
            display: flex;
            background-color: $color-scale-primary-100;
            padding: 12px 15px 12px 10px;
            gap: 4px;
            border-radius: 12px;
            width: 100%;

            img {
                width: 30px;
                height: 30px;
            }

            .info-wrapper {
                .title {
                    font-weight: 600;
                    color: $color-scale-primary-500;
                }
            }

        }
    }

}