@import "../../styles/main.scss";

.emp-profile-pic-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.1em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 6px;
            display: block;
            color: $color-scale-gray-300;
        }

        .main-pic {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }


    }

    .content-section {
        display: flex;
        align-items: center;
        flex-direction: column;

        .main-picture {
            width: 120px;
            height: 120px;
            border-radius: 120px;


            img {
                width: 100%;
                height: 100%;
                border-radius: 120px;
                border: 1px solid $color-scale-gray-800;
            }

            .avatar {
                display: flex;
                width: 100%;
                height: 100%;
                border-radius: 120px;
                justify-content: center;
                align-items: center;

                .initials {
                    font-size: 4em;
                    color: map-get($color-scale-gray, 0);
                    font-weight: 700;
                }
            }
        }

        .display-pics-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            .platform-logo {
                background-color: $color-scale-gray-300;
                width: 50px;
                min-width: 50px;
                min-height: 50px;
                height: 50px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .camera-btn {
                cursor: pointer;
                background-color: $color-scale-gray-800;
                width: 50px;
                min-width: 50px;
                min-height: 50px;
                height: 50px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 200ms;

                &:hover {
                    background-color: $color-scale-gray-800;
                }
            }

            .upload-hidden {
                display: none;
            }

            .profile-image {
                cursor: pointer;
                width: 50px;
                min-width: 50px;
                min-height: 50px;
                height: 50px;
                border-radius: 50px;
                object-fit: cover;
                border: 1px solid $color-scale-gray-800;
                box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;


                &:hover {
                    box-shadow: 0px 0px 0px 10px rgba(255,255,255, 0.15);
                }

                &.selected {
                    border: 3px solid $color-scale-primary-400;
                }
            }
        }
    }




    .footer-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}