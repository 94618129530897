@import "../../styles/main.scss";

.emp-creator-onboarding-page {
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    background: $cbz-background-gradient;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;

    @include mobile-view {
        padding: 0px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .overall-content-wrapper {}

    .content-wrapper {
        @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
        display: flex;
        background: $cbz-card-gradient-md;
        border-radius: 20px;

        @include tablet-view {
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 0;
        }

        @include mobile-view {
            padding-bottom: 100px;
        }

        .progress-section {
            width: 340px;
            border-right: 1px solid $color-scale-gray-800;


            @include tablet-view {
                width: 100%;
            }

            .stepper-section {
                padding: 20px;
                border-bottom: 1px solid $color-scale-gray-800;


                .logo-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;

                    .logo {
                        height: 20px;
                    }

                    .control-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                }

                .title-lbl {
                    display: block;
                    font-weight: 600;
                    color: $color-scale-gray-100;

                    @include tablet-view {
                        display: none;
                    }
                }

                .progress-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;


                    .step {
                        height: 4px;
                        flex: 1;
                        background-color: map-get($color-scale-primary, 200);
                        border-radius: 8px;

                        &.completed {
                            background-color: map-get($color-scale-primary, 600);
                        }
                    }
                }
            }

            .step-item-section {
                padding: 20px;

                @include tablet-view {
                    display: none;
                }

                .step {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid $color-scale-gray-800;
                    cursor: pointer;
                    transition: all 200ms;

                    &:hover {
                        transform: translateY(-3px);
                        background-color: map-get($color-scale-gray, 800);
                    }

                    &:not(:first-child) {
                        margin-top: 8px;
                    }


                    .unchecked,
                    .done,
                    .in-progress,
                    .skipped {
                        width: 20px;
                        height: 20px;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .unchecked {
                        background-color: map-get($color-scale-gray, 100);
                    }

                    .skipped {
                        background-color: #ffc107;
                    }

                    .done {
                        background-color: map-get($color-scale-green, 500);
                    }

                    .in-progress {
                        border: 2px solid map-get($color-scale-primary, 400);
                        background-color: map-get($color-scale-primary, 200);
                        box-sizing: border-box;
                    }

                    .step-label {
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }
                }
            }
        }
    }
}