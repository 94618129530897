@import "../../../styles/main.scss";

.emp-brand-task-payment-view {
    padding-top: 20px;

    .task-details-section {
        .task-name-wrapper {
            display: flex;
            gap: 10px;
            align-items: center;

            .info-wrapper {
                flex: 1;
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }

    .section-label {
        margin: 0;
        font-size: 1.4em;
        font-weight: 700;
        color: $color-scale-gray-100
    }

    .payment-section {
        .metrics-tile-wrapper {
            width: 100%;
            gap: 10px;
            display: flex;
            flex-wrap: wrap;

            .metric-tile {
                flex: 1;
                min-width: 190px;
                min-height: 70px;
                display: flex;
                gap: 6px;
                flex-direction: column;
                padding: 10px 15px;
                border-radius: 10px;
                border: 1px solid $color-scale-gray-800;
                background: $cbz-card-gradient-md;
                align-items: flex-start;

                .details-wrapper {
                    flex: 1;
                    display: flex;
                    width: 100%;
                    gap: 10px;
                    justify-content: space-between;

                    .metric-wrapper {
                        display: flex;
                        gap: 4px;
                    }

                    .metric-value {
                        font-size: 2em;
                        font-weight: 700;
                        color: $color-scale-gray-100;
                    }
                }

                .metric-header {
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $color-scale-gray-300;
                }

            }
        }
    }

    .payouts-table-section {
        h2 {
            font-size: 1.5em;
            font-weight: 700;
            color: $color-scale-gray-100;
            margin: 0;
        }

        .payouts-table {

            .representative-wrapper {
                display: flex;
                align-items: center;

                .profile-wrapper {
                    position: relative;

                    .profile {
                        width: 36px;
                        height: 36px;
                        border-radius: 50px;
                        border: 1px solid $color-scale-gray-800;
                        object-fit: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.profile-badge {
                            position: absolute;
                            bottom: -3px;
                            right: -6px;
                            width: 20px;
                            height: 20px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            object-fit: cover;
                        }

                        .initials {
                            color: $color-scale-gray-0;
                            font-size: 1.4em;
                            font-weight: 600;
                        }
                    }

                }

                .info-wrapper {
                    .subtitle-lbl {
                        font-size: 0.95em;
                        color: $color-scale-gray-400;
                        font-weight: 500;
                    }
                }
            }

            .completion-wrapper {
                .task-completion-label {
                    color: $color-scale-gray-400;
                    font-weight: 600;
                    font-size: 0.9;
                    display: block;
                }

                .bar-wrapper {
                    margin-top: 6px;
                    height: 6px;
                    width: 160px;
                    display: flex;
                    gap: 2px;
                }

                .bar {
                    height: 100%;
                    border-radius: 1px;

                    &.completed {
                        background-color: #21BD54;
                    }

                    &.pending {
                        background-color: $color-scale-gray-800;
                    }

                    &:first-child {
                        border-top-left-radius: 6px;
                        border-bottom-left-radius: 6px;
                    }

                    &:last-child {
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }
                }
            }
        }
    }

}