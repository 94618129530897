@import "../../../styles/main.scss";

.unlink-btn {
    display: flex;
    cursor: pointer;
    position: relative;
    top: 2px;
    transition: all 200ms;

    .unlink-lbl {
        display: inline-block;
        margin-left: 3px;
        font-weight: 600;
        color: map-get($color-scale-gray, 400);

        &.hovered {
            color: $color-scale-gray-300;
        }
    }
}