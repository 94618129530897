@import "../../../styles/main.scss";


.emp-creator-template-wrapper {
    width: 100vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $cbz-background-gradient;
    overflow: hidden;

    /** emp-service-provider-navbar **/
    .emp-horizontal-navbar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;

        .top-bar-wrapper {
            padding: 4px 30px;
            box-sizing: border-box;
            background-color: $cbz-neutral-color-1;
            border-bottom: 1px solid $color-scale-gray-800;
            width: 100%;
            display: flex;
            justify-content: center;

            @include mobile-view {
                padding: 12px 15px;
                border-bottom: 1px solid $color-scale-gray-800;
            }


            .top-bar-content {
                width: 100%;
                max-width: 1200px;
                display: flex;
                align-items: center;

                .left-elems {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .logo {
                        height: 20px;
                        position: relative;

                        @include mobile-view {
                            top: 0;
                        }
                    }

                    .search-wrapper {
                        width: 500px;
                    }
                }



                .right-elems {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;

                    .profile-wrapper {
                        margin-left: 12px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all 100ms;
                        padding: 0 10px;
                        height: 50px;
                        border-radius: 12px;

                        &:hover {
                            background-color: $color-scale-gray-800;
                        }

                        .display-picture {
                            width: 28px;
                            height: 28px;
                            border-radius: 30px;
                        }

                        .name-lbl {
                            font-weight: 600;
                            display: block;
                            margin-left: 8px;
                            white-space: nowrap;
                            color: $color-scale-gray-300;
                        }
                    }
                }
            }



        }


        .menu-items-wrapper {
            padding: 0px 30px;
            box-sizing: border-box;
            background-color: $cbz-neutral-color-1;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            justify-content: center;
            width: 100%;

            @include mobile-view {
                display: none !important;
            }

            .menu-items-content {
                width: 100%;
                max-width: 1200px;
                height: fit-content;
                display: flex;
                align-items: center;
                position: relative;
                left: -15px;


                .underline {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 4px;
                    background: $cbz-primary-gradient;
                    transition: all 200ms;

                }

                .menu-item {
                    height: 50px;
                    padding: 0 15px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1em;
                    font-weight: 500;
                    color: $color-scale-gray-400;
                    transition: all 100ms;
                    border-bottom: 1px solid $color-scale-gray-800;
                    white-space: nowrap;
                    gap: 8px;

                    .icon-wrapper {
                        width: 14px;

                        .icon {
                            height: 12px;

                        }
                    }


                    &.active {
                        color: $color-scale-gray-0;

                        &:hover {
                            color: $color-scale-gray-0 ;
                        }
                    }

                    &:hover {
                        color: $color-scale-gray-200;
                        background-color: $cbz-neutral-color-2;
                    }
                }
            }
        }

    }

    .emp-mobile-bar {
        background-color: $color-scale-gray-900;
        border-top: 1px solid $color-scale-gray-800;
        padding: 4px 12px 12px 12px;
        display: none;
        gap: 6px;

        @include mobile-view {
            display: flex;
        }

        .icon-btn {
            flex: 1;
            padding: 10px 6px;
            height: 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transition: all 150ms ease-out;
            border-radius: 12px;

            &:active {
                background-color: $color-scale-gray-850;
                transform: scale(0.9);
            }

            &.active {
                .icon-text {
                    user-select: none;
                    color: $color-scale-primary-500 !important;
                }
            }

            .icon {
                opacity: 0.7;
                width: 19px;

                &.active {
                    opacity: 1;
                }
            }

            .icon-text {
                font-size: 0.9em;
                font-weight: 600;
                color: $color-scale-gray-300;
            }
        }

    }
}