@import "../../styles/main.scss";




.emp-seller-campaign-listing-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;

    .bg-tint {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0) 100%);
    }


    .page-header {
        font-size: 1.6em !important;
        color: $color-scale-gray-100;
    }

    .campaign-view-wrapper {
        padding-bottom: 30px;
    }

    .header-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
    }
}