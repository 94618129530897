.emp-info-alert {
  padding: 8px 10px;
  border-radius: 6px;
  background: $color-scale-primary-100;
  color: $color-scale-primary-500;
  font-weight: 600;
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
  line-height: 1.4em;

  p {
    margin: 0;
  }
}