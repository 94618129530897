@import "../../styles/main.scss";

.negotiation-log-table {

    .initiator-col {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .profile-wrapper {
        position: relative;
    }

    .profile {
        width: 36px;
        height: 36px;
        border-radius: 40px;
        border: 1px solid $color-scale-gray-800;

        &.profile-badge {
            position: absolute;
            bottom: 0px;
            right: -3px;
            width: 24px;
            height: 24px;
            border-radius: 24px;

            .initials {
                font-size: 0.5em;
                font-weight: 700;
                color: $color-scale-gray-0;
            }
        }

        &.org {
            border-radius: 8px !important;
        }

        &img {
            object-fit: contain;
        }

        &div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .initials {
            font-size: 1em;
            font-weight: 700;
            color: $color-scale-gray-0;
        }
    }

    .negotiation-text {
        width: 400px;
        white-space: break-spaces;
    }
}