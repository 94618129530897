@import "../../../styles/main.scss";

.emp-brand-smm-account-modal {
  .text-content-section {
    .title {
      display: block;
      font-weight: 600;
      color: map-get($color-scale-primary, 500);
      font-size: 1.1em;
      margin: 0;
    }
  }

  .pages-wrapper {
    margin-top: 20px;
    width: 100%;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid map-get($color-scale-gray, 200);
    border-bottom: 1px solid map-get($color-scale-gray, 200);
    box-sizing: border-box;

    .page-name-wrapper {
      display: flex;
      gap: 4px;
      align-items: center;
      .flag-icon-wrapper {
        padding: 4px;
        border-radius: 100%;
        background-color: rgba(255, 140, 87, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .page-name-lbl {
        font-size: 0.95em;
        font-weight: 600;
        color: $color-scale-gray-300;
      }
    }

    .page-item {
      background-color: map-get($color-scale-gray, 900);
      border-radius: 4px;
      display: flex;
      padding: 8px;

      .left-elem {
        display: flex;
        flex: 1;

        .picture {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          object-fit: cover;
        }

        .text-section {
          margin-left: 12px;

          .name {
            display: block;
            font-size: 1em;
            font-weight: 600;
            color: $color-scale-gray-100;
          }

          .about {
            display: block;
            margin-top: 3px;
            font-size: 1em;
            color: map-get($color-scale-gray, 500);
          }
        }
      }

      .right-elem {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
