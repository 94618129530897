@import "../../styles/main.scss";



.emp-creator-details-page {
    flex: 1;
    background: linear-gradient(132.49deg, rgba(232, 237, 255, 0.192)7.07%, #FFFFFF 100.39%);
    display: flex;
    align-items: center;

    .content {
        width: 100%;
        max-width: 1240px;
        padding: 30px 20px;
        box-sizing: border-box;
        display: flex;
        gap: 16px;

        @include tablet-view {
            padding: 20px;
            flex-direction: column;
        }

        .profile-section {
            width: 240px;

            @include tablet-view {
                width: 100%;
            }

            .banner {
                height: 80px;
                width: 100%;
                background-color: #212121;
            }

            .pic-section {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: -30px;
                padding: 0 20px 30px 20px;
                box-sizing: border-box;
                text-align: center;

                @include mobile-view {
                    margin-top: -42.5px;
                }

                .avatar,
                .profile-pic {
                    width: 65px;
                    height: 65px;
                    border-radius: 70px;
                    border: 1px solid map-get($color-scale-gray, 150);

                    @include mobile-view {
                        width: 85px;
                        height: 85px;
                        border-radius: 85px;
                    }
                }

                .avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.8em;
                    font-weight: 700;
                    color: map-get($color-scale-gray, 0);

                    @include mobile-view {
                        font-size: 2.2em;
                    }
                }

                .profile-img {
                    object-fit: cover;
                }

                .creator-name-lbl {
                    display: block;
                    font-size: 1.2em;
                    font-weight: 700;
                    color: $color-scale-gray-300;
                }

                .creator-email-lbl {
                    display: block;
                    font-size: 1em;
                    font-weight: 500;
                    color: map-get($color-scale-gray, 500);
                }
            }

            .info-section {
                width: 100%;
                padding: 0 20px;
                box-sizing: border-box;

                .creator-info-wrapper {
                    width: 100%;
                    display: flex;
                    gap: 8px;

                    .creator-gender,
                    .creator-age {
                        flex: 1;
                    }
                }

                .country-wrapper {
                    display: flex;
                    gap: 4px;

                    .img-flag {
                        height: 16px;
                        border: solid 1px map-get($color-scale-gray, 150);
                    }
                }

                .platform-wrapper {
                    display: flex;
                    gap: 8px;
                }

                .agency-wrapper {
                    margin-top: 12px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .agency-card {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .agency-logo {
                        border-radius: 8px;
                        width: 32px;
                        height: 32px;
                        object-fit: cover;
                        border: 1px solid map-get($color-scale-gray, 100);
                    }

                    .agency-content {
                        flex: 1;

                        .name-wrapper {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .name-lbl {
                                flex: 1;
                                display: block;
                                font-weight: 600;
                                color: $color-scale-gray-300;
                            }
                        }
                    }
                }
            }

            .pills-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
            }
        }




        .social-media-section {
            flex: 1;


            // choose social media section
            .social-media-selector-card {
                display: flex;
                overflow-x: auto;
                align-items: center;
                padding: 8px;


                .social-media-option {
                    display: flex;
                    align-items: center;
                    padding: 8px 14px 8px 10px;
                    border-radius: 8px;
                    cursor: pointer;

                    &.active {
                        background-color: $color-scale-gray-850;
                    }

                    .picture-section {
                        position: relative;
                        width: 42px;
                        height: 42px;

                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 45px;
                            border: 1px solid $color-scale-gray-800;
                        }

                        .social-media-bubble {
                            position: absolute;
                            bottom: -1px;
                            right: -1px;
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background-color: $color-scale-gray-750;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0px 0px 0px 1px $color-scale-gray-800;
                        }
                    }

                    .info-section {
                        flex: 1;
                        margin-left: 10px;

                        .handle-name-lbl {
                            display: block;
                            white-space: nowrap;
                            font-weight: 600;
                            color: $color-scale-gray-100;
                        }

                        .metric-lbl {
                            margin-top: 2px;
                            display: block;
                            white-space: nowrap;
                            font-size: 0.9em;
                            color: $color-scale-gray-300;
                        }
                    }
                }

                .empty-option {
                    display: flex;
                    align-items: center;
                    padding: 8px 14px 8px 10px;
                    border-radius: 8px;

                    .picture-section {
                        position: relative;
                        width: 42px;
                        height: 42px;

                        .placeholder {
                            width: 42px;
                            height: 42px;
                            border-radius: 45px;
                            background-color: map-get($color-scale-gray, 150);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                    }

                    .info-section {
                        flex: 1;
                        margin-left: 10px;

                        .handle-name-lbl {
                            display: block;
                            font-weight: 600;
                            color: map-get($color-scale-gray, 250);
                        }

                        .metric-lbl {
                            margin-top: 2px;
                            display: block;
                            white-space: nowrap;
                            font-size: 0.9em;
                            color: map-get($color-scale-gray, 250);
                        }
                    }
                }
            }
        }
    }

    // External
    .agency-invitation-card {
        margin-bottom : 16px;
        padding-bottom: 20px;
    }

}