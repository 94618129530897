@import "../../../../styles/main.scss";

.emp-seller-chat-modal {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;


    .dismiss-modal-sheet-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        background-color: $color-scale-gray-100;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .seller-chat-header {
        padding: 20px 15px 15px 15px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $color-scale-gray-800;
        gap: 8px;

        .profile-pic-wrapper {
            position: relative;

            .profile-pic {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                border: 1px solid $color-scale-gray-800;
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;

                &.badge {
                    position: absolute;
                    bottom: -2px;
                    right: -2px;
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                }

                &.org {
                    border-radius: 8px;
                }

                .avatar {
                    font-size: 1.2em;
                    font-weight: 700;
                    color: $color-scale-gray-0;
                }
            }


        }

        .name-section {
            flex: 1;

            .chat-name-wrapper {
                display: flex;
                gap: 8px;
                align-items: center;

                .chat-name-lbl {
                    display: block;
                    font-size: 1.2em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .activity-status {
                    height: 8px;
                    width: 8px;
                    border-radius: 16px;
                    background-color: $color-scale-gray-600;

                    &.online {
                        background-color: map-get($color-scale-green, 400);
                    }
                }
            }

            .activity-lbl {
                display: block;
                font-size: 1em;
                font-weight: 500;
                color: $color-scale-gray-300;
            }
        }
    }

    .me-chat-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &.floating {
            visibility: hidden;
            z-index: -999;
            position: absolute;
            top: 0;
            left: 0;
        }

        .me-chat-bubble {
            max-width: 80%;
            width: fit-content;
            padding: 10px;
            border-radius: 12px 12px 12px 12px;
            background-color: $color-scale-primary-600;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 4px;


            p {
                margin: 0;
                text-align: end;
                word-wrap: break-word;
                word-break: break-all;
                color: $color-scale-gray-100;
                font-weight: 500;
            }

            .timestamp {
                display: block;
                font-size: 0.9em;
                font-weight: 500;
                color: $color-scale-gray-150;
            }
        }
    }

    .sender-chat-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &.floating {
            visibility: hidden;
            z-index: -999;
            position: absolute;
            top: 0;
            left: 0;
        }

        .sender-chat-bubble {
            max-width: 80%;
            width: fit-content;
            padding: 10px;
            border-radius: 12px 12px 12px 12px;
            background-color: $color-scale-gray-800;
            border: 1px solid $color-scale-gray-700;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            p {

                margin: 0;
                word-wrap: break-word;
                word-break: break-all;
                color: $color-scale-gray-100;
                font-weight: 500;
            }

            .timestamp {
                display: block;
                font-size: 0.9em;
                font-weight: 500;
                color: $color-scale-gray-150;
            }
        }
    }


    .image-wrapper {
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .image-inner-wrapper {
            width: fit-content;

            &.half-width {
                flex: 0 0 calc(50% - 5px);
            }

            &.full-width {
                flex: 1;
            }
        }

        .single-img {
            border-radius: 10px;
            width: 100%;

            height: auto;
            object-fit: cover;
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;


            &.square-img {
                aspect-ratio: 1;
                object-fit: contain;
            }
        }
    }

    .video-wrapper {
        width: 100%;
        margin-bottom: 10px;

        video {
            border-radius: 8px;
            width: 100%;
            height: auto;
        }
    }

    .file-wrapper {
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 12px;
        cursor: pointer;

        .file-indicator {
            min-width: 36px;
            width: 36px;
            height: 36px;
            min-height: 36px;
            border-radius: 36px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.my-bubble-file {
                background: $color-scale-primary-700;

            }

            &.recipient-bubble-file {
                background: $color-scale-gray-200;
            }
        }


        .file-info-wrapper {
            display: flex;
            flex-direction: column;
            gap: 3px;

            &.my-bubble-file {
                .filename-lbl {
                    color: $color-scale-gray-50 !important;
                }

                .size-lbl {
                    color: $color-scale-gray-150 !important;
                }
            }

            &.recipient-bubble-file {
                .filename-lbl {
                    color: $color-scale-gray-850 !important;
                }

                .size-lbl {
                    color: $color-scale-gray-400 !important;
                }
            }

            .filename-lbl {
                display: block;
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-50;
            }

            .size-lbl {
                display: block;
                font-size: 1em;
                color: $color-scale-gray-150;
            }
        }
    }


    .seller-chat-body {
        flex: 1;
        background-color: $color-scale-gray-900;
        overflow-y: scroll;
        display: flex;
        gap: 6px;
        flex-direction: column-reverse;
        padding: 0 15px 15px 15px;

    }

    .send-message-section {
        padding: 10px 15px 20px 15px;
        border-top: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;
        display: flex;
        align-items: center;
        gap: 10px;

        .send-message-input {
            flex: 1;
            min-height: 45px;
            height: fit-content;
            display: flex;
            align-items: center;
            padding: 0 20px;
            background-color: $color-scale-gray-900;
            border: 1.5px solid $color-scale-gray-800;
            border-radius: 50px;

            transition: all 100ms;

            &.focus {
                border: 1.5px solid $color-scale-primary-500;
            }

            textarea {
                all: unset;
                color: $color-scale-gray-300;
                font-weight: 500;
                padding: 8px 0;
                box-sizing: border-box;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }
            }
        }

        .attachment-btn,
        .send-btn {
            height: 45px;
            padding: 0 15px;
            font-weight: 600;
            border-radius: 50px;
        }

        .send-btn {
            background-color: $color-scale-primary-500;
            color: $color-scale-gray-0;
        }

        .attachment-btn {
            background-color: $color-scale-gray-800;
            color: $color-scale-gray-300;
        }
    }
}