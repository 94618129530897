@import "../../../styles/main.scss";

.emp-rate-card-request-view {
    margin-top: 20px;
    box-sizing: border-box;

    .header-wrapper {
        max-width: 900px;
        width: 100%;
        align-items: center;
        display: flex;

        .right-elem {
            flex: 1;
        }

        h1 {
            font-size: 1.2em;
            margin: 0 0 10px 0;
        }

        .page-description {
            line-height: 1.4em;
            color: map-get($color-scale-gray, 300);
            margin-bottom: 0;
        }
    }


    .privacy-intro-card {
        position: relative;
        max-width: 900px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: linear-gradient(268.9deg, rgba(0, 0, 0, 0) 4%, $color-scale-gray-800 67.72%);
        border: 1px solid $color-scale-gray-800;
        border-radius: 12px;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        @include mobile-view {
            padding: 18px;
        }

        .grid-h {
            position: absolute;
            right: 0;
            width: 400px;
            height: 2px;
            background: rgba(0, 0, 0, 0.2);

            &.one {
                top: 25%;
            }

            &.two {
                top: 50%;
            }

            &.three {
                top: 75%;
            }
        }

        .grid-v {
            position: absolute;
            top: 0px;
            width: 2px;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);

            &.one {
                right: 80px;
            }

            &.two {
                right: 160px;
            }

            &.three {
                right: 240px;
            }
        }


        .image-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 10px;

            img {
                position: relative;
                height: 100%;

                @include tablet-view {
                    display: none;
                }
            }
        }


        .title-lbl {
            margin: 0;
            font-size: 1.4em;
            color: $color-scale-gray-100;
            font-weight: 700;

            @include mobile-view {
                font-size: 1.4em;
            }
        }

        .description-lbl {
            max-width: 600px;
            color: $color-scale-gray-300;
            line-height: 1.4em;
            margin: 0;
        }

        .form-wrapper {
            max-width: 700px;
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    .setting-item {
        width: 100%;
        max-width: 900px;
        display: flex;
        gap: 12px;
        padding: 0px 0 20px 0px;
        align-items: center;
        border-bottom: 1px solid $color-scale-gray-800;



        .info-wrapper {
            .info-header {
                font-size: 1.1em;
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .info-description {
                display: block;

                margin: 0;
                margin-top: 4px;
                font-weight: 500;
                color: $color-scale-gray-300;
                line-height: 1.4em;
            }
        }

        .switch-wrapper {
            min-width: fit-content;
            min-height: fit-content;
        }

    }

    .rate-cards-request-section {
        position: relative;
        width: 100%;
        max-width: 900px;

        .campaign-empty-state-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #100420 100%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .info-wrapper {
                max-width: 400px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                img {
                    height: 80px;
                }

                .header {
                    margin-top: 8px;
                    margin-bottom: 0px;
                    font-weight: 700;
                    color: map-get($color-scale-gray, 100);
                    font-size: 1.4em;
                }

                .description {
                    margin-top: 8px;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 300);
                }
            }
        }

        .emp-rate-card-request-card {
            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }

}