@import "../../../styles/main.scss";

.emp-search-bar-wrapper {
    flex: 1;

    .emp-search-bar {
        max-width: 500px;
        width: 100%;
        display: block;

        @include tablet-view {
            display: none;
        }
    }

    .emp-search-button {
        display: none;

        @include tablet-view {
            display: block;
        }
    }
}