@mixin mobile-view {
    @media only screen and (max-width:500px) {
        @content
    }
}

.emp-card {
    border-radius: 12px;
    background: $cbz-card-gradient-md;
    width: 100%;
    max-width: 1200px;
    padding: 20px 20px 30px 20px;
    box-sizing: border-box;
    overflow: hidden;


    @include mobile-view {
        padding: 18px;
    }

    &.no-h-padding {
        padding: 20px 0 30px 0;
    }

    &.no-padding {
        padding: 0;
    }

    .emp-card-padded-section {
        padding: 0 30px;
    }

    .emp-card-header {
        color: $color-scale-gray-100;
        font-size: 1.2em;
        font-weight: 700;
        margin: 0;
    }

    .emp-card-description {
        color:  $color-scale-gray-300;
        line-height: 1.4em;
        margin: 8px 0 15px 0;
    }

    .emp-card-header-section {
        padding: 20px 30px;

        @include mobile-view {
            padding: 18px;
        }

    }
}