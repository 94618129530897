@import "../../styles/main.scss";


.emp-select-x-tweet-modal {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .text-content-section {
        padding: 0 20px 20px 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }


    .post-section {
        padding: 20px;
        width: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .empty-card {
            border: 1px solid $color-scale-gray-800;
            border-radius: 12px;
            height: 120px;
            width: 100%;
        }

        .x-card {
            background-color: map-get($color-scale-gray, 900);
            border: 1px solid map-get($color-scale-gray, 100);
            border-radius: 12px;
            box-sizing: border-box;
            overflow: hidden;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            &:not(:first-child) {
                margin-top: 10px;
            }

            .profile-wrapper {
                padding: 15px;
                display: flex;
                gap: 10px;

                .picture {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                }

                .tweet-details-wrapper {
                    flex: 1;
                    overflow: hidden;

                    .profile-name-wrapper {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .profile-name-wrapper-2 {
                            display: flex;
                            gap: 3px;
                            align-items: center;

                            .verified-mark {
                                width: 14px;
                                height: auto;
                            }

                            .profile-name {
                                color: $color-scale-gray-100;
                                font-weight: 700;
                            }
                        }




                        .profile-handle {
                            font-weight: 500;
                            color: $color-scale-gray-300;
                        }

                        .date-wrapper {
                            flex: 1;
                            display: flex;
                            justify-content: flex-end;

                            span {
                                font-weight: 500;
                                color: $color-scale-gray-300;
                            }
                        }
                    }

                    .caption {
                        margin: 0;
                        margin-top: 10px;
                        color: $color-scale-gray-300;
                        line-height: 1.4em;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                    }

                    .image-grid {
                        margin-top: 12px;
                        display: grid;
                        grid-gap: 4px;
                        width: 100%;
                        aspect-ratio: 2;
                        border-radius: 12px;
                        overflow: hidden;
                        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
                        border: 1px solid $color-scale-gray-800;

                        .media-wrapper {
                            position: relative;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            .play-video-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .play-circle {
                                    cursor: pointer;
                                    background-color: rgba(255, 255, 255, 0.1);
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            &.items-1 {
                                &:nth-child(1) {
                                    grid-column: span 2;
                                    grid-row: span 2;
                                }
                            }

                            &.items-2 {

                                &:nth-child(1),
                                &:nth-child(2) {
                                    grid-row: span 2;
                                }
                            }

                            &.items-3 {
                                &:nth-child(1) {
                                    grid-row: span 2;
                                }
                            }
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                    }


                }
            }

            .info-section {
                padding: 10px 12px 14px 60px;
                width: 100%;
                box-sizing: border-box;
                border-top: 1px solid $color-scale-gray-800;
                background-color: map-get($color-scale-gray, 900);

                .metrics-wrapper {
                    width: 100%;
                    display: flex;
                    gap: 20px;

                    .metric {
                        display: flex;
                        gap: 4px;

                        .metric-value {
                            color: $color-scale-gray-300;
                        }
                    }

                    .link-to-post {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;

                        .link {
                            cursor: pointer;
                            display: flex;
                            gap: 6px;
                            align-items: center;

                        }
                    }

                }
            }
        }
    }



    .empty-card {
        height: 240px;
        border-radius: 12px;
        box-sizing: border-box;
    }
}