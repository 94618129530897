@import "../../../../styles/main.scss";

.emp-seller-mobile {
    min-height: 100%;
    flex-direction: column;

    .conversation-header {
        border-bottom: 1px solid $color-scale-gray-800;
        padding: 15px;

        h2 {
            margin: 0;
            font-size: 1.3em;
            font-weight: 700;
            color: $color-scale-gray-100;
        }
    }

    .chat-item-section {
        display: flex;
        flex-direction: column;

        .skeletal-chat-item {
            padding: 15px 15px;
            display: flex;
            gap: 10px;

            .skeletal-profile-pic {
                width: 50px;
                height: 50px;
                border-radius: 50px;
            }

            .skeletal-msg-wrapper {
                flex: 1;

                .skeletal-name {
                    height: 16px;
                    width: 80px;
                    border-radius: 6px;
                }

                .skeletal-message-wrapper {
                    display: flex;
                    gap: 6px;
                    align-items: center;

                    .skeletal-message-div {
                        flex: 1;
                    }

                    .skeletal-message-2,
                    .skeletal-message {
                        margin-top: 6px;
                        height: 14px;
                        border-radius: 6px;
                    }

                    .skeletal-message {
                        width: 100%;
                    }

                    .skeletal-message-2 {
                        width: 80%;
                    }

                    .skeletal-badge {
                        width: 20px;
                        height: 20px;
                        border-radius: 20px;
                    }
                }
            }
        }

        .chat-item {
            display: flex;
            gap: 10px;
            padding: 15px 15px;
            border-bottom: 1px solid $color-scale-gray-800;

            .profile-pic-wrapper {
                position: relative;
                width: 45px;
                height: 45px;

                .profile-pic {
                    width: 45px;
                    height: 45px;
                    border-radius: 50px;
                    border: 1px solid $color-scale-gray-800;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    object-fit: cover;

                    &.badge {
                        position: absolute;
                        bottom: -2px;
                        right: -2px;
                        width: 24px;
                        height: 24px;
                        border-radius: 8px;
                    }

                    &.org {
                        border-radius: 8px;
                    }

                    .avatar {
                        font-size: 1.2em;
                        font-weight: 700;
                        color: $color-scale-gray-0;
                    }
                }

            }


            .msg-wrapper {
                flex: 1;
                overflow: hidden;

                .name-lbl {
                    font-size: 1.2em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .message-wrapper {
                    display: flex;
                    flex: 1;
                    overflow: hidden;
                    gap: 6px;
                    align-items: center;

                    .message-div {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        gap: 4px;
                    }

                    .last-message-lbl {
                        font-size: 1.1em;
                        font-weight: 500;
                        overflow: hidden;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        // white-space: nowrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                    }



                }
            }

            .unread-badge {
                padding: 2px 4px;
                min-width: 20px;
                width: fit-content;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                color: $color-scale-gray-0;
                font-weight: 600;
                font-size: 0.8em;
                background-color: map-get($color-scale-red, 400);
            }
        }
    }

}