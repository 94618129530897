@import "../../../styles/main.scss";

.finder-tip {
    position: absolute;
    top: -45px;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    border-radius: 12px;
    background-color: $color-scale-gray-900;
    color: $color-scale-gray-300;
    font-weight: 500;

    code {
        padding: 3px 5px;
        background-color: $color-scale-gray-300;
        font-weight: 600;
        border-radius: 3px;
    }
}

.emp-search-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 16px;

    .searchbar-wrapper {
        display: flex;
        border-bottom: 1px solid $color-scale-gray-800;
        transition: 100ms all;

        &.focused {
            background-color: $color-scale-gray-900;
            border-bottom: 1px solid $color-scale-gray-700;
        }

        .search-icon-wrapper {
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 6px;
        }

        input {
            all: unset;
            padding: 12px 20px 12px 0;
            flex: 1;
            color: $color-scale-gray-100;
            font-weight: 500;
            caret-color: $color-scale-primary-600;
            font-size: 1.1em;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: map-get($color-scale-gray, 400);
                font-weight: 400;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: map-get($color-scale-gray, 400);
                font-weight: 400;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: map-get($color-scale-gray, 400);
                font-weight: 400;
            }
        }

        .dismiss-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid $color-scale-gray-800;
            aspect-ratio: 1;
            min-width: 48px;
            cursor: pointer;
            transition: 200ms all;

            &.focused {
                border-left: 1px solid $color-scale-gray-800;
            }

            &:hover {
                background-color: $color-scale-gray-100;
            }
        }

    }


    .category-section {
        padding: 10px 20px 4px 20px;

        span {
            display: block;
            font-size: 0.9em;
            color: $color-scale-gray-300;
            font-weight: 500;
            margin-bottom: 6px;
        }
    }

    .empty-loading-placeholder {
        height: 4px;
        width: 100%;
    }

    .results-section {
        flex: 1;
        max-height: 50vh;
        overflow-y: auto;

        .subsection {
            padding: 5px 20px;
            color: $color-scale-gray-300;
            font-size: 0.95em;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            gap: 8px;

            &.bordered {
                border-top: 1px solid $color-scale-gray-800;
            }
        }


        .record {
            padding: 8px 20px;
            // border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            gap: 8px;
            transition: all 150ms;
            cursor: pointer;

            &:hover {
                background-color: $color-scale-gray-800;
            }

            .name-lbl {
                font-size: 0.95em;
                font-weight: 500;
                color: $color-scale-gray-100;
            }

            .profile-img {
                height: 30px;
                width: 30px;
                border-radius: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                border: 1px solid $color-scale-gray-800;

                &.org {
                    border-radius: 8px !important;
                }

                .initials {
                    color: $color-scale-gray-0;
                    font-weight: 600;
                }
            }

            .campaign-img {
                height: 30px;
                aspect-ratio: 6/4;
                object-fit: cover;
                width: auto;
                border-radius: 6px;
                border: 1px solid $color-scale-gray-800;
            }

            .task-platform-wrapper {
                display: flex;
                align-items: center;
                gap: 2.5px;


                .task-type-small {
                    font-size: 0.9em;
                    color: $color-scale-gray-300;
                    font-weight: 500;
                    display: block;
                }
            }

        }

    }
}