@import "colors";
@import "spacing";
@import "font";
@import "display";
@import "table";
@import "card";
@import "breakpoint";
@import "form";
@import "loader";
@import "rs-override";
@import "toggle-control";
@import "typography";
@import "anim";
@import "alert";

* {
  -webkit-tap-highlight-color: transparent;
}

@mixin tablet-view {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}

@mixin mobile-view {
  @media only screen and (max-width: 520px) {
    @content;
  }
}

.hide-on-mobile {
  @include mobile-view {
    display: none !important;
  }
}

.show-on-mobile-flex {
  display: none !important;

  @include mobile-view {
    display: flex !important;
  }
}

.show-on-mobile {
  display: none !important;

  @include mobile-view {
    display: block !important;
  }
}

.emp-button-reset,
.emp-anchor-reset {
  all: unset;
}

.emp-highlighted {
  color: $color-scale-primary-400;
  font-weight: 600;
}

.emp-highlighted-neutral {
  color: $color-scale-primary-200;
  font-weight: 600;
}

hr {
  height: 1px;
  border: none;
  background-color: $color-scale-gray-800;
}

.emp-page-wrapper {
  position: relative;
  flex: 1;
  max-height: 100dvh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #070711 0%, #100420 100%);

  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &.emp-responsive {
    @include mobile-view {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  &.emp-responsive-no-padding {
    @include mobile-view {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.emp-page-content {
  position: relative;
  flex: 1;
  padding: 20px 20px 60px 20px;
  background: $cbz-background-gradient;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;

  &.no-padding {
    max-width: 1200px;
    width: 100%;
    padding: 0;
    padding-bottom: 30px;
  }
}

.emp-tooltip {
  cursor: pointer;
}

/* FF, IE7+, chrome (except button) */
input[type=file],
/* chromes and blink button */
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.emp-tooltip-wrapper {
  display: inline-block;
  width: fit-content;
  margin-left: 4px;
  cursor: pointer;
}

.emp-shimmer {
  background: $color-scale-gray-800;
  background-image: linear-gradient(to right,
      $color-scale-gray-750 0%,
      $color-scale-gray-800 20%,
      $color-scale-gray-750 40%,
      $color-scale-gray-800 100%);
  background-repeat: no-repeat;
  background-size: 800px 800px;
  position: relative;

  -webkit-animation: placeholderShimmer 1s linear infinite forwards;
  animation: placeholderShimmer 1s linear infinite forwards;
}

.emp-shimmer-translucent {
  background: rgba(246, 247, 248, 0.6);
  background-image: linear-gradient(to right,
      $color-scale-gray-750 0%,
      $color-scale-gray-800 20%,
      $color-scale-gray-750 40%,
      $color-scale-gray-800 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;
  -webkit-animation: placeholderShimmer 1s linear infinite forwards;
  animation: placeholderShimmer 1s linear infinite forwards;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.emp-disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


