@import "../../../styles/main.scss";

.emp-add-member-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.1em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 6px;
            display: block;
            color: $color-scale-gray-300;
        }
    }

    .invitation-form-section {
        margin-top: 24px;
        // display: flex;

        .text-input-wrapper {
            display: flex;
            flex: 1;
        }

        .button-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 10px;
        }
    }

    .invited-member-section {
        margin-top: 15px;
        border-radius: 10px;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        padding: 20px 15px;

        .member {
            display: flex;
            align-items: center;
            width: 100%;

            &:not(:first-child) {
                margin-top: 20px;
            }

            .member-left-elem {
                flex: 1;
                display: flex;

                .member-info-wrapper {
                    margin-left: 10px;

                    .member-name-lbl {
                        font-size: 1em;
                        display: block;
                        font-weight: 600;
                        color: $color-scale-gray-100;
                    }

                    .member-email-lbl {
                        font-size: 0.95em;
                        margin-top: 2px;
                        display: block;
                        font-weight: 400;
                        color: $color-scale-gray-300;
                    }
                }
            }
        }
    }
}