@import "../../styles/main.scss";



.bg-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0.00) 100%);
}

.emp-brand-creators-listing-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .page-header {
        font-size: 1.6em !important;
        color: $color-scale-gray-100;
    }

    .listing-view-wrapper {
        margin-top: 20px;
        padding-bottom: 30px;


        .creator-listing-view {
            .resize-wrapper {
                width: 100%;
            }

            .filter-section {
                width: 100%;

                .filter-control-section {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;

                    .search-control {
                        flex: 1;
                        max-width: 400px;
                        min-width: 250px;
                    }

                    .control {
                        width: fit-content;
                    }

                    .age-control {
                        width: 140px;
                    }
                }
            }

            .records-wrapper {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

                .records-title {
                    display: block;
                    flex: 1;
                    font-size: 1.2em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .records-title-loader {
                    width: 200px;
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid $color-scale-gray-800;
                }
            }


            .recruitment-card-section {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                gap: 10px;

                .blank-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(7, 7, 47, 0.3) 10%, $color-scale-primary-1000 100%);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 2;

                    .info-wrapper {
                        width: 400px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;

                        img {
                            height: 120px;
                        }

                        .header {
                            margin-top: 8px;
                            font-weight: 700;
                            color: $color-scale-gray-100;
                            font-size: 1.6em;
                        }

                        .description {
                            margin-top: 0px;
                            line-height: 1.4em;
                            color: map-get($color-scale-gray, 500);
                        }
                    }
                }
            }
        }
    }
}