@import "../../../styles/main.scss";

.emp-explore-creator-view {
    margin-top: 20px;
    box-sizing: border-box;

    .explore-header-wrapper {
        align-items: center;
        display: flex;

        .right-elem {
            flex: 1;
        }

        h1 {
            font-size: 1.2em;
            margin: 0 0 10px 0;
            color: $color-scale-gray-100;
        }

        .page-description {
            line-height: 1.4em;
            color: map-get($color-scale-gray, 300);
            margin-bottom: 0;
        }
    }

    .search-form-section {
        display: flex;
        gap: 10px;

        .search-input {
            width: 300px;
        }

        .search-options-input {
            width: 200px;
        }

        .search-button-wrapper {
            display: flex;
            align-items: flex-end;
            padding-bottom: 20px;
        }
    }

    .cards-section-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 14px;

        .records-title {
            flex: 1;
            font-size: 1.2em;
            font-weight: 600;
            color: $color-scale-gray-100;
        }
    }

    .footer-section-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 0;
    }

    .cards-section {
        display: flex;
        flex-wrap: wrap;
        gap: 11px;


        .creator-card {
            background-color: $color-scale-gray-900;
            border: 1px solid $color-scale-gray-800;
            border-radius: 8px;
            padding: 8px 0 12px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .bio-section {
                display: flex;
                padding: 0 12px 5px 12px;
                gap: 8px;
                border-bottom: 1px solid $color-scale-gray-800;

                .profile-img,
                .profile-avatar {
                    width: 34px;
                    height: 34px;
                    min-width: 34px;
                    min-height: 34px;
                    border: 1px solid map-get($color-scale-gray, 100);
                    border-radius: 80px;
                }

                .profile-img {
                    object-fit: cover;
                }

                .profile-avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-size: 1.1em;
                        font-weight: 700;
                        color: $color-scale-gray-0;
                    }
                }


                .account-details-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;

                    .email,
                    .name {
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                    }

                    .name {
                        font-size: 1.05em;
                        font-weight: 600;
                        color: map-get($color-scale-gray, 100);
                    }

                    .email {
                        margin-top: 2px;
                        font-size: 0.9em;
                        font-weight: 500;
                        color: map-get($color-scale-gray, 300);
                    }
                }
            }

            .metrics-section {
                padding: 0 12px;
                display: flex;
                gap: 8px;

                .country-wrapper {
                    display: flex;
                    gap: 4px;

                    .img-flag {
                        height: 16px;
                        border: solid 1px map-get($color-scale-gray, 800);
                    }
                }

                .agency-wrapper {
                    display: flex;
                    gap: 2px;


                }

                .metric-left {
                    flex: 1;
                }

                .metric-right {
                    flex: 1;
                }
            }

            .social-media-section {
                padding: 0 12px;
                display: flex;
                row-gap: 12px;
                column-gap: 8px;
                flex-wrap: wrap;

                .social-media-empty-state {
                    span {
                        font-weight: 600;
                        color: $color-scale-gray-300;
                    }
                }

                .social-media {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    flex: 1;

                    .image-wrapper {
                        position: relative;
                        width: 32px;
                        height: 32px;
                        min-width: 32px;
                        min-height: 32px;
                    }

                    .social-img {

                        width: 32px;
                        height: 32px;
                        min-width: 32px;
                        min-height: 32px;
                        border-radius: 50px;
                        object-fit: cover;
                    }

                    .platform-badge {
                        position: absolute;
                        bottom: -3px;
                        right: -3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-scale-gray-900;
                        width: 16px;
                        height: 16px;
                        border-radius: 100px;
                    }
                }

                .social-details-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;


                    .handle,
                    .platform {
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                    }

                    .handle {
                        font-weight: 500;
                        color: $color-scale-gray-300;
                    }

                    .platform {
                        margin-top: 2px;
                        font-size: 0.9em;
                        font-weight: 500;
                        color: map-get($color-scale-gray, 500);
                    }
                }

            }

            .social-media-heading {
                padding: 0 12px;
                font-size: 0.9em;
                font-weight: 700;
                color: #7E8591;
                display: block;
            }

            .action-section {
                padding: 0 12px;
                flex: 1;
                gap: 10px;
                display: flex;
                align-items: flex-end;

                .btn-wrapper-section {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    flex-wrap: wrap;

                    .btn-wrapper {
                        flex: 1;
                        height: fit-content;
                    }
                }
            }
        }
    }

    .cards-empty-state {
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        display: flex;
        gap: 11px;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .info-wrapper {
                margin-top: 100px;
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                img {
                    height: 120px;
                }

                .header {
                    margin-top: 8px;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                    font-size: 1.6em;
                }

                .description {
                    margin-top: 0px;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 500);
                }
            }
        }

        .talent-placeholder-card {
            background-color: map-get($color-scale-gray, 100);
            border-radius: 10px;
            padding: 16px;
            box-sizing: border-box;

            .details-wrapper {
                display: flex;

                .profile {
                    width: 36px;
                    height: 36px;
                    border-radius: 50px;
                }

                .account-details-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;


                    .name {
                        height: 14px;
                        border-radius: 4px;
                        width: 80px;
                    }

                    .email {
                        width: 120px;
                        margin-top: 6px;
                        height: 10px;
                        border-radius: 4px;
                    }
                }
            }

            .metrics-wrapper {
                display: flex;

                .metric {
                    flex: 1;

                    .title {
                        height: 10px;
                        border-radius: 4px;
                        width: 40px;
                    }

                    .value {
                        width: 80px;
                        margin-top: 4px;
                        height: 14px;
                        border-radius: 4px;
                    }
                }

            }

            .social-media-wrapper {
                display: flex;

                .icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 20px;

                    &:not(:first-child) {
                        margin-left: 6px;
                    }
                }
            }
        }

        .shimmer-color {
            background-color: $color-scale-gray-850;
        }
    }
}