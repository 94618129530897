@import "../../../styles/main.scss";

.emp-icon-button {
    all: unset;
    position: relative;
    box-sizing: border-box;
    height: 30px;
    min-height: 30px;
    width: 30px;
    min-width: 30px;

    color: map-get($color-scale-gray, 0);
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    background-color: map-get($color-scale-primary, 500);
    transition: all 200ms ease;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $color-scale-gray-800;
    display: flex;
    align-items: center;
    justify-content: center;

    &.mobile-view {
        @include mobile-view {
            height: 45px;
            min-height: 45px;
            width: 45px;
            min-width: 45px;
            border-radius: 8px;
        }
    }


    &.secondary {
        background: radial-gradient(circle at 50% 600%, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
        color: $color-scale-gray-100;

        &:hover {
            color: $color-scale-gray-100 !important;
            background: radial-gradient(circle at 50% 600%, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05));
        }
    }

    &.translucent {
        background-color: rgba(255, 255, 255, 0.3) !important;
        color: $color-scale-gray-100;

        &:hover {
            color: $color-scale-gray-100 !important;
            background-color: rgba(255, 255, 255, 0.5) !important;
        }
    }

    &:not(:first-child) {
        @include breakpoint(mobile) {
            margin-top: 12px;
        }
    }

    &.loading {
        background-color: map-get($color-scale-gray, 200);

        &:hover {
            background-color: map-get($color-scale-gray, 200);
        }
    }


    &:hover {
        background-color: map-get($color-scale-primary, 700);
    }


    .emp-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}