@import "../../../styles/main.scss";

.emp-creator-info-step {

    .header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            height: 30px;
        }

        .description {
            text-align: center;
            color: $color-scale-gray-300;
            line-height: 1.4em;
            margin: 0;
        }
    }

    .intro-para {
        margin: 0;
        color: $color-scale-gray-300;
    }

    .description {
        margin: 0;
        margin-top: 6px;
        display: block;
        color: $color-scale-gray-300;

        .highlighted {
            font-weight: 600;
            color: $color-scale-primary-400;
        }
    }
    .error-message {
        color: $color-scale-red-400;
    }

    .pill-wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 6px;
        column-gap: 6px;

        .pill-btn {
            cursor: pointer;
        }
    }


    .form-wrapper {
        width: 600px;
        padding: 12px 0 20px 0;
        border-radius: 20px;
        box-sizing: border-box;

        @include tablet-view {
            width: 100%;
        }


        .body-section {
            padding: 20px 20px 0px 20px;

            &.no-top-pad {
                padding-top: 0;
            }
        }

        .divider {
            margin: 20px 0;
        }

        .header-section {
            padding: 0 20px 8px 20px;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            gap: 12px;
            align-items: center;

            .step-lbl {
                font-weight: 600;
                font-size: 1.1em;
                color: $color-scale-gray-100;
                display: block;
            }
        }

        .btn-section {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .btn-wrapper {
                display: flex;
                width: fit-content;
                justify-content: flex-end;
                gap: 10px;

                @include mobile-view {
                    width: 100%;
                }
            }
        }
    }
}