@import "../../../styles/main.scss";

.emp-language-picker-menu {
    position: relative;

    .emp-language-button {
        all: unset;
        display: flex;
        gap: 6px;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        padding: 6px 16px 6px 8px;
        border-radius: 8px;
        z-index: 1;
        transition: all 300ms;

        @include mobile-view {
            padding: 6px;
        }

        &.bordered {
            padding: 6px 16px 6px 8px;
            border: 1px solid $color-scale-gray-800;
        }

        img {
            height: 20px;
            width: auto;
            border-radius: 100px;
        }

        .language-lbl {
            white-space: nowrap;
            color: $color-scale-gray-100;
            font-weight: 600;

            &.responsive {
                @include mobile-view {
                    display: none;
                }
            }

        }

        &:hover {
            background-color: $color-scale-gray-100;
        }
    }
}


.emp-language-picker-wrapper {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    padding: 10px 0;
    background-color: map-get($color-scale-gray, 50);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    border: 1px solid map-get($color-scale-gray, 100);
    z-index: 999;

    &.hidden {
        background-color: green;
        visibility: hidden;
        z-index: -100;
    }

    .emp-language-btn {
        width: 100%;
        box-sizing: border-box;
        padding: 8px 20px 8px 10px;
        cursor: pointer;
        color: 1px solid map-get($color-scale-gray, 800);
        background-color: map-get($color-scale-gray, 50);
        font-size: 1em;
        font-weight: 600;
        display: flex;
        align-items: center;
        transition: all 200ms;
        gap: 6px;

        img {
            height: 16px;
            width: auto;
            border-radius: 100px;
        }

        .language-lbl {
            white-space: nowrap;
            color: $color-scale-gray-100;
            font-weight: 600;

        }

        &:hover {
            background-color: map-get($color-scale-gray, 100);
        }
    }
}