@import "../../../styles/main.scss";

.emp-pagination {
    display: flex;
    align-items: center;

    .directional-btn {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: $color-scale-gray-100;
        border-radius: 6px;
        font-size: 1em;

        &.disabled {
            color: map-get($color-scale-gray, 400);
        }

        &:hover {
            background-color: map-get($color-scale-gray, 100);
        }
    }

    .pagination-btn {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        font-weight: 600;
        width: 32px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: map-get($color-scale-gray, 400);
        border-radius: 6px;
        font-size: 1em;


        &:hover {
            background-color: map-get($color-scale-gray, 100);
        }

        &:not(:first-child) {
            margin-left: 4px;
        }

        &.active {
            background-color: map-get($color-scale-primary, 500);
            color: map-get($color-scale-gray, 100);

            &:hover {
                background-color: map-get($color-scale-primary, 600);
            }
        }
    }
}