@import "../../styles/main.scss";


.emp-review-deliverable-changelog-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .list-view {
    .response-section {
      display: flex;
      flex-direction: column;

      .response-form-row {
        padding: 10px 20px;
        position: relative;
        cursor: pointer;

        display: flex;
        align-items: center;
        transition: all 200ms;
        gap: 12px;

        &:not(:last-child) {
          border-bottom: 1px solid $color-scale-gray-800;
        }


        &:hover {
          background-color: $color-scale-gray-900;
        }

        &.selected {
          background-color: $color-scale-gray-900;

          &:hover {
            background-color: $color-scale-gray-100;
          }
        }

        .relative-wrapper {
          position: relative;
          width: 45px;
          height: 45px;

          .absolute-wrapper {
            position: absolute;
            bottom: 0px;
            right: -4px;
            width: 25px;
            height: 25px;

            .profile-photo {
              width: 25px;
              height: 25px;
            }
          }
        }

        .profile-photo {
          width: 45px;
          height: 45px;
          border-radius: 100px;
          object-fit: cover;
          border: 1px solid map-get($color-scale-gray, 150);
          box-shadow: 0 0 0 0px transparent;
          transition: 400ms all;

          &.org-photo {
            border-radius: 8px;
          }

          &.selected {
            box-shadow: 0 0 0 8px $color-scale-primary-100;
          }
        }

        .avatar {
          display: flex;
          width: 45px;
          height: 45px;
          border-radius: 120px;
          justify-content: center;
          align-items: center;

          .initials {
            font-size: 1.8em;
            color: map-get($color-scale-gray, 0);
            font-weight: 700;
          }
        }

        .details-section {
          flex: 1;

          .rep-name-section {
            display: flex;
            align-items: center;
            gap: 6px;

            .rep-name-lbl {
              font-weight: 600;
              color: $color-scale-gray-100;
              display: block;
            }
          }

          .rate-section {
            display: flex;

            .rate-lbl {
              font-weight: 600;
              color: $color-scale-gray-300;
              display: block;
            }
          }
        }

        .form-section {
          display: flex;
          flex-direction: row-reverse;
          width: fit-content;
          position: relative;
          top: -4px;
        }
      }
    }
  }

  .review-deliverable-view {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;


    .details-section {
      padding: 10px 20px;
      border-bottom: 1px solid $color-scale-gray-800;

      .creator-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        .name-lbl {
          font-size: 1.2em;
          font-weight: 600;
          color: $color-scale-gray-100;
        }

        .relative-wrapper {
          position: relative;
          width: 36px;
          height: 36px;
        }

        .profile-photo {
          width: 36px;
          height: 36px;
          border-radius: 100px;
          object-fit: cover;
          border: 1px solid map-get($color-scale-gray, 150);
          box-shadow: 0 0 0 0px transparent;
          transition: 400ms all;

          &.org-photo {
            border-radius: 8px;
          }

          &.selected {
            box-shadow: 0 0 0 8px $color-scale-primary-100;
          }
        }

        .avatar {
          display: flex;
          width: 36px;
          height: 36px;
          border-radius: 120px;
          justify-content: center;
          align-items: center;

          .initials {
            font-size: 1.8em;
            color: map-get($color-scale-gray, 0);
            font-weight: 700;
          }
        }
      }

      .details-wrapper {
        display: flex;
        margin-top: 12px;
        gap: 20px;
      }
    }

    .deliverable-changelog-section {
      display: flex;
      overflow-y: auto;
      flex-direction: column;
      flex: 1;

      .deliverable-changelog-section-header {
        margin: 0;
        padding: 10px 20px;
        font-size: 1.2em;
        font-weight: 700;
        color: $color-scale-gray-100;
      }

      .comparison-section {
        flex: 1;
        display: flex;

        .comparison-column {
          flex: 1;
          background-color: #FAFAFA;

          &:first-child {
            border-right: 1px solid $color-scale-gray-800;
          }

          .column-header-wrapper {
            padding: 6px 20px 6px 10px;
            background-color: $color-scale-gray-100;
            font-weight: 600;
            color: $color-scale-gray-300;

            &.first {
              padding: 6px 10px 6px 20px;
            }
          }

          .column-body-wrapper {

            &.first {

              .deliverable-card-shimmer,
              .deliverable-card {
                padding: 10px 10px 10px 20px;
              }
            }

            .deliverable-card-shimmer {
              background-color: $color-scale-gray-900;
              padding: 10px 20px 10px 10px;
              display: flex;
              flex-direction: column;
              gap: 6px;

              .deliverable-name-shimmer,
              .deliverable-number-shimmer,
              .deliverable-description-shimmer {
                border-radius: 6px;
              }

              .deliverable-name-shimmer {
                width: 60%;
                height: 20px;
              }

              .deliverable-number-shimmer {
                width: 40%;
                height: 20px;
              }

              .deliverable-description-shimmer {
                width: 100%;
                height: 60px;
              }
            }

            .deliverable-card {
              padding: 10px 20px 10px 10px;
              background-color: $color-scale-gray-900;
              border-bottom: 1px solid $color-scale-gray-800;
              word-break: break-word;

              &:last-child {
                border-bottom: none;
              }

              .deliverable-indicator {
                font-size: 0.9em;
                font-weight: 700;
                color: #7E8591;
              }

              .deliverable-name-lbl {
                margin: 0;
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-100;
              }

              .deliverable-description {
                display: block;
                padding-top: 8px;
                box-sizing: border-box;
                margin: 0;
                line-height: 1.4em;
                color: $color-scale-gray-300
              }
            }

          }
        }
      }
    }




    .counteroffer-section {
      padding: 10px 20px;
      flex: 1;
      overflow-y: scroll;

      .profile-photo-section {
        padding-top: 20px;
        display: flex;
        justify-content: center;

        .relative-wrapper {
          width: 60px;
          height: 60px;
          position: relative;

          .absolute-wrapper {
            position: absolute;
            bottom: -4px;
            right: -4px;
            width: 30px;
            height: 30px;

            .profile-photo {
              width: 30px;
              height: 30px;
              border: 1px solid map-get($color-scale-gray, 150);
            }
          }
        }

        .creator-rep-wrapper {
          display: flex;
          align-items: center;
        }

        .profile-photo-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;

          .overlap {
            margin-left: -20px;
          }

          .profile-photo {
            width: 60px;
            height: 60px;
            border-radius: 100px;
            object-fit: cover;
            border: 2px solid map-get($color-scale-gray, 150);

            &.org-photo {
              border-radius: 12px;
            }
          }

          .plus-label {
            top: -6px;
            position: relative;
            display: block;
            color: $color-scale-gray-200;
            font-weight: 500;
            font-size: 4em;
          }

          .empty-div {
            width: 60px;
            height: 60px;
            border-radius: 100px;
            display: flex;
            background-color: map-get($color-scale-gray , 50);
            box-shadow: 0 0 0 1px map-get($color-scale-gray , 200);
            align-items: center;
            justify-content: center;
            color: map-get($color-scale-gray , 500);
            font-weight: 600;
          }


          .avatar {
            display: flex;
            width: 60px;
            height: 60px;
            border-radius: 120px;
            justify-content: center;
            align-items: center;

            .initials {
              font-size: 1.8em;
              color: map-get($color-scale-gray, 0);
              font-weight: 700;
            }
          }
        }
      }

      .content-section {
        margin-top: 20px;

        .heading,
        .task-name-lbl {
          display: block;

          font-weight: 600;
          text-align: center;
          line-height: 1.4em;
        }

        .task-name-lbl {
          font-size: 1.3em;
          color: $color-scale-gray-100;
        }

        .heading {
          font-size: 1em;
          color: $color-scale-gray-300;
          margin-top: 6px;
        }

        .description {
          text-align: center;
          margin: 0;
          color: $color-scale-gray-300;
          line-height: 1.4em;
        }
      }

    }
  }

  .button-wrapper-section {
    padding: 15px 20px 0 20px;
    display: flex;
    gap: 10px;
    width: 100%;
    border-top: 1px solid $color-scale-gray-800;
    justify-content: flex-end;
    align-items: center;
  }
}