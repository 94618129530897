@import "../../../../styles/main.scss";


.global-notifications-wrapper {

    .global-notification {
        width: 90vw;
        max-width: 460px;
        position: fixed;
        left: 50%;
        padding: 10px;
        border-radius: 10px;
        background-color: $color-scale-gray-800;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        display: flex;
        align-items: center;
        gap: 8px;

        &.foreground {
            z-index: 800;
        }

        &.background {
            z-index: 799;
        }

        .profile-pic {
            width: 32px;
            height: 32px;
            border-radius: 50px;
            border: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            justify-content: center;
            object-fit: cover;

            &.badge {
                position: absolute;
                bottom: -2px;
                right: -2px;
                width: 20px;
                height: 20px;
                border-radius: 4px;
            }

            &.org {
                border-radius: 8px;
            }

            .avatar {
                font-size: 1.2em;
                font-weight: 700;
                color: $color-scale-gray-0;
            }
        }

        .notification-content-wrapper {
            flex: 1;
            overflow: hidden;


            .notification-title-lbl {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 600;
                color: $color-scale-gray-0;
            }

            .notification-message-lbl {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 3px;
                font-weight: 500;
                color: $color-scale-gray-300;
            }
        }
    }
}