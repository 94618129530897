@import "../../styles/main.scss";


.emp-view-deliverable-comparison-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .comparison-section {
    flex: 1;
    display: flex;
    overflow-y: auto;

    .comparison-column {
      flex: 1;
      background-color: #FAFAFA;

      &:first-child {
        border-right: 1px solid $color-scale-gray-800;
      }

      .column-header-wrapper {
        padding: 6px 20px 6px 10px;
        background-color: $color-scale-gray-100;
        font-weight: 600;
        color: $color-scale-gray-300;

        &.first {
          padding: 6px 10px 6px 20px;
        }
      }

      .column-body-wrapper {

        &.first {

          .deliverable-card-shimmer,
          .deliverable-card {
            padding: 10px 10px 10px 20px;
          }
        }

        .deliverable-card-shimmer {
          background-color: $color-scale-gray-900;
          padding: 10px 20px 10px 10px;
          display: flex;
          flex-direction: column;
          gap: 6px;

          .deliverable-name-shimmer,
          .deliverable-number-shimmer,
          .deliverable-description-shimmer {
            border-radius: 6px;
          }

          .deliverable-name-shimmer {
            width: 60%;
            height: 20px;
          }

          .deliverable-number-shimmer {
            width: 40%;
            height: 20px;
          }

          .deliverable-description-shimmer {
            width: 100%;
            height: 60px;
          }
        }

        .deliverable-card {
          padding: 10px 20px 10px 10px;
          background-color: $color-scale-gray-900;
          border-bottom: 1px solid $color-scale-gray-800;
          word-break: break-word;

          &:last-child {
            border-bottom: none;
          }

          .deliverable-indicator {
            font-size: 0.9em;
            font-weight: 700;
            color: #7E8591;
          }

          .deliverable-name-lbl {
            margin: 0;
            display: block;
            padding-top: 8px;
            box-sizing: border-box;
            font-size: 1em;
            font-weight: 700;
            color: $color-scale-gray-100
          }

          .deliverable-description {
            display: block;
            padding-top: 8px;
            box-sizing: border-box;
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300
          }
        }

      }
    }
  }

  .button-wrapper-section {
    padding: 15px 20px 0 20px;
    display: flex;
    gap: 10px;
    width: 100%;
    border-top: 1px solid $color-scale-gray-800;
    justify-content: flex-end;
    align-items: center;
  }
}