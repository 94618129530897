@import "../../styles/main.scss";

.emp-creator-listing-table {
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    background: $cbz-card-gradient-lg;
    position: relative;
    border: 1px solid $color-scale-gray-800;


    .restriction-overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        left: 0px;
        flex-direction: column;

        .title {
            font-size: 1.6em;
            margin: 0;
        }
    }

    .initiator-col {
        display: flex;
        align-items: center;
        gap: 10px;

        .profile-wrapper {
            position: relative;
        }

        .profile {
            width: 36px;
            height: 36px;
            border-radius: 40px;
            border: 1px solid $color-scale-gray-800;
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;

            &.profile-badge {
                position: absolute;
                bottom: 0px;
                right: -3px;
                width: 24px;
                height: 24px;
                border-radius: 24px;

                .initials {
                    font-size: 0.5em;
                    font-weight: 700;
                    color: $color-scale-gray-0;
                }
            }

            &.org {
                border-radius: 8px !important;
            }



            .initials {
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-0;
            }
        }


        .name-wrapper {
            display: flex;
            align-items: center;
            gap: 4px;

            .img-flag {
                height: 16px;
                border: solid 1px map-get($color-scale-gray, 150);
            }
        }
    }

    .connected-accounts-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;

        .account-wrapper {
            flex: 0.5;
            display: flex;
            align-items: center;
            gap: 4px;

            .platform-icon-wrapper {
                width: 10px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            .picture {
                width: 20px;
                height: 20px;
                border-radius: 20px;
                border: 1px solid $color-scale-gray-800;
            }

            .account-name {
                color: $color-scale-gray-100;
            }
        }
    }

    .pill-wrapper {
        display: flex !important;
        gap: 4px !important;
        flex-wrap: wrap;

        .pill-h-wrapper {
            display: flex;
            gap: 4px !important;
        }


    }
}