@import "../../../styles/main.scss";

label {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    color: $color-scale-gray-150;
    margin-bottom: 8px;

    &.compressed {
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 0.85em;
        color: $color-scale-gray-300;
    }


    .required {
        display: inline;
        color: red;
    }
}

.emp-multi-select-v2 {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    background: linear-gradient(360deg, #11111e -20%, #060610 40%);
    box-shadow: 0 0 0 1px $color-scale-gray-800;
    border-radius: 8px;
    padding: 9px 15px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        background-color: map-get($color-scale-gray , 850);
    }

    &.active {
        background-color: map-get($color-scale-gray , 850);
        font-weight: 600;
    }

    .outer-wrapper {
        display: flex;
        align-items: center;
        flex: 1;

        .multi-select-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;

            .multi-filter-label {
                display: block;
                color: $color-scale-gray-300;

                &.active {
                    color: $color-scale-gray-100;
                    font-weight: 500;
                }
            }
        }
    }

    .top-bar {
        border-bottom: 1px solid $color-scale-gray-800;

        .emp-text-input-wrapper {
            display: flex;
            align-items: center;
            background-color: $color-scale-gray-900;
            transition: border 200ms;



            .emp-left-icon-wrapper {
                padding: 0 6px 0 9px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px 0 0 6px;
                border-right: none;
                height: 37px;
                box-sizing: border-box;
            }

            .emp-text-input {
                all: unset;
                border-radius: 6px;
                padding: 9px 12px;
                color: $color-scale-gray-100;
                font-weight: 500;
                font-size: 14px;
                width: 100%;
                transition: all 200ms;
                box-sizing: border-box;

                @include mobile-view {
                    height: 45px;
                    font-size: 15px;
                }

                &.left-icon {
                    padding-left: 0;
                    border-left: none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                    opacity: 1;
                    /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: map-get($color-scale-gray, 300);
                    font-weight: 400;
                }
            }
        }
    }

    .bottom-bar {
        padding: 12px;
        transition: 200ms all;
        display: flex;
        align-items: center;
        border-top: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;

        .indicator,
        .clear-selection {
            color: $color-scale-gray-200;

        }

        .indicator {
            font-weight: 600;
        }

        .clear-selection {
            font-weight: 500;
            flex: 1;
        }

        &:hover {
            background-color: $color-scale-gray-850;
        }
    }


    .dropdown-menu {
        left: 0;
        position: absolute;

        border: 1px solid $color-scale-gray-800;
        border-radius: 8px;
        background-color: $cbz-card-gradient-md;
        padding: 5px 0;
        z-index: 10;

        .option-wrapper {
            height: fit-content;
            max-height: 200px;
            overflow-y: auto;
        }

        .dropdown-item {
            height: 40px;
            padding: 0 12px;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            align-items: center;
            background-color: $color-scale-gray-900;
            color: $color-scale-gray-300;
            font-weight: 600;
            transition: all 100ms;
            gap: 8px;
            white-space: nowrap;
            cursor: pointer;


            &:hover {
                .checkbox {
                    background-color: $color-scale-gray-850;

                    &.selected {
                        box-shadow: 0 0 0 2px $color-scale-primary-400;
                        background-color: $color-scale-primary-400;
                    }
                }
            }

            .checkbox {
                width: 12px;
                min-width: 12px;
                height: 12px;
                min-height: 12px;
                border-radius: 3px;
                box-shadow: 0 0 0 2px $color-scale-gray-600;
                background-color: $color-scale-gray-800;
                display: flex;
                align-items: center;
                justify-content: center;

                &.selected {
                    box-shadow: 0 0 0 2px $color-scale-primary-500;
                    background-color: $color-scale-primary-500;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: $color-scale-gray-800;
            }
        }
    }
}