@import "../../../styles/main.scss";

@mixin mobile-view {
    @media only screen and (max-width:620px) {
        @content
    }
}


.emp-brand-template-wrapper {
    width: 100vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: $cbz-background-gradient;
    overflow: hidden;

    /** emp-service-provider-navbar **/
    .emp-horizontal-navbar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;

        .top-bar-wrapper {
            padding: 4px 30px;
            box-sizing: border-box;
            background-color: $cbz-neutral-color-1;
            border-bottom: 1px solid $color-scale-gray-800;
            width: 100%;
            display: flex;
            justify-content: center;

            .top-bar-content {
                width: 100%;
                max-width: 1200px;
                display: flex;
                align-items: center;

                .left-elems {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    gap: 10px;

                    .logo {
                        height: 20px;
                        position: relative;
                        top: -3px;
                    }

                    .search-wrapper {
                        width: 100%;
                        max-width: 400px;
                    }
                }

                .right-elems {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 100%;

                    .profile-wrapper {
                        margin-left: 12px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all 100ms;
                        padding: 0 10px;
                        height: 50px;
                        border-radius: 12px;

                        &:hover {
                            background-color: $color-scale-gray-800;
                        }

                        .display-picture {
                            width: 28px;
                            height: 28px;
                            border-radius: 30px;
                        }

                        .name-lbl {
                            font-weight: 600;
                            display: block;
                            margin-left: 8px;
                            white-space: nowrap;
                            color: $color-scale-gray-300;
                        }
                    }
                }
            }



        }


        .menu-items-wrapper {
            padding: 0px 30px;
            box-sizing: border-box;
            background-color: $cbz-neutral-color-1;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            justify-content: center;
            width: 100%;

            .menu-items-content {
                width: 100%;
                max-width: 1200px;
                height: fit-content;
                display: flex;
                align-items: center;
                position: relative;
                left: -15px;


                .underline {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 4px;
                    background: $cbz-primary-gradient;
                    transition: all 200ms;

                }

                .menu-item {
                    height: 50px;
                    padding: 0 15px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1em;
                    font-weight: 500;
                    color: $color-scale-gray-400;
                    transition: all 100ms;
                    border-bottom: 1px solid $color-scale-gray-800;
                    white-space: nowrap;
                    gap: 8px;

                    .icon-wrapper {
                        width: 16px;

                        .icon {
                            height: 12px;

                        }

                        .sm-icon {
                            height: 14px;

                        }
                    }


                    &.active {
                        color: $color-scale-gray-0;

                        &:hover {
                            color: $color-scale-gray-0 ;
                        }
                    }

                    &:hover {
                        color: $color-scale-gray-200;
                        background-color: $cbz-neutral-color-2;
                    }
                }
            }
        }

    }

    .emp-template-access-control-wrapper {
        position: relative;
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}