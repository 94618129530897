@import "../../../styles/main.scss";

.emp-brand-unbind-modal {
  .text-content-section {
    .title {
      display: block;
      font-weight: 600;
      color: map-get($color-scale-primary, 500);
      font-size: 1.1em;
      margin: 0;
    }
    .description {
      margin: 0;
      margin-top: 6px;
      display: block;
      color: $color-scale-gray-300;
    }
  }

  .profile-wrapper {
    pointer-events: none;
    margin: 10px 0;
    padding: 10px;
    background-color: $color-scale-gray-900;
    width: fit-content;
    .picture-section {
      > img {
        background-color: $color-scale-gray-900;
        border-radius: 8px !important;
        border: none;
      }
    }
  }
  .footer-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
}
