@import "../../../styles/main.scss";

.emp-notification {
    position: relative;


    .bell-icon-wrapper {
        cursor: pointer;

        padding: 8px;
        position: relative;
        background-color: transparent;
        border-radius: 8px;
        transition: all 200ms;


        &:hover {
            background-color: $color-scale-gray-800;
        }

        .unread-count-wrapper {
            position: absolute;
            top: -2px;
            left: 24px;
            padding: 3px 6px;
            border-radius: 30px;
            background: $cbz-primary-gradient;
            color: $color-scale-gray-0;
            font-size: 11px;
            font-weight: 800;
        }
    }

    .notification-box {
        z-index: 999;
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        padding: 15px 0;
        border-radius: 8px;
        width: 360px;
        border: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-900;
        display: flex;
        flex-direction: column;

        @include breakpoint(mobile) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100dvh;
        }

        .title-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 15px 10px 15px;
            border-bottom: 1px solid $color-scale-gray-800;


            .notification-loader {
                position: absolute;
                z-index: 2;
                bottom: -4px;
                left: 0;
                width: 100%;
                border-radius: 0;
            }

            .title {
                display: block;
                flex: 1;
                font-size: 1em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }
        }

        .footer-wrapper {
            border-top: 1px solid $color-scale-gray-800;
            padding: 10px;
            display: none;

            @include breakpoint(mobile) {
                display: block;
            }
        }


    }

    .notification-wrapper {
        max-height: 360px;
        overflow-y: auto;
        background-color: $color-scale-gray-900;

        @include breakpoint(mobile) {
            max-height: none;
            flex: 1;
        }
    }

    .notification-empty-wrapper {
        height: 200px;
        background-color: $color-scale-gray-900;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include breakpoint(mobile) {
            height: auto;
            flex: 1
        }

        .empty-state-bell-img {
            width: 80px;
        }

        .empty-state-header {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-300;
        }
    }
}