@mixin breakpoint($value) {

    @if $value ==mobile-sm {
        @media (max-width: 500px) {
            @content;
        }
    }

    @if $value ==mobile {
        @media (max-width: 600px) {
            @content;
        }
    }

    @if $value ==mobile_med {
        @media (max-width: 800px) {
            @content;
        }
    }

    @else if $value ==small {
        @media (max-width: 1400px) {
            @content;
        }
    }

    @else if $value ==medium {
        @media (min-width: 1400px) {
            @content;
        }
    }
}