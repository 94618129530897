@import "../../styles/main.scss";


.emp-rate-privacy-setting-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px 10px 20px;


        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: map-get($color-scale-gray, 300);
        }
    }

    .demo-wrapper {
        padding: 10px 0;
        display: flex;

        .slide {
            flex: 1;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            filter: grayscale(100%);
            transition: 200ms all;
            background-image: url('../../assets/images/light-mesh-bg.png');
            background-size: cover;

            img {
                width: 60%;
                filter: grayscale(100%);
                transition: 200ms all;
            }

            &.selected {
                filter: grayscale(0%);

                img {
                    width: 60%;
                    filter: grayscale(0%);
                }
            }
        }
    }

    .info-wrapper {
        border-top: 1px solid $color-scale-gray-800;
        padding: 10px 20px;
        border-bottom: 1px solid $color-scale-gray-800;

        .modal-setting-item {
            display: flex;
            gap: 12px;
            align-items: center;

            .info-header {
                flex: 1;
                font-size: 1.1em;
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .switch-wrapper {
                min-width: fit-content;
                min-height: fit-content;
            }

        }

    }


    .acknowledgement-wrapper {
        display: flex;
        padding: 0 20px;

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: $color-scale-primary-100;
            padding: 10px 15px;
            border-radius: 12px;
            width: 100%;

            .intro-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                span {
                    color: $color-scale-primary-600;
                    font-weight: 600;
                }
            }
        }
    }
}