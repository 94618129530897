@import "../../../../styles/main.scss";

.emp-insert-link-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 0px;
    }

    .text-content-section {

        .title-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;

            .title {
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
                font-size: 1.1em;
                margin: 0;
            }
        }

        .description {
            margin: 0;
            margin-top: 12px;
            display: block;
            color: $color-scale-gray-300;
        }

        .highlighted {
            font-weight: 500;
            color: map-get($color-scale-primary, 600);
        }
    }

}