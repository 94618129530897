@import "../../styles/main.scss";

.emp-edit-payment-modal {

    .form-section {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        gap: 6px;

        .split-wrapper {
            display: flex;
            gap: 10px;

            .split-control {
                flex: 1;

                small {
                    color: $color-scale-gray-300;
                    font-weight: 600;
                }
            }
        }
    }

    .subsection {
        padding: 6px 20px;
        background-color: $color-scale-gray-100;
        font-weight: 600;
        color: $color-scale-gray-300;
    }

    .button-wrapper {
        padding : 0 20px;
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: flex-end;
    }
}