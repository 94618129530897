@import "../../../styles/main.scss";



.emp-my-creator-view {
    margin-top: 20px;
    box-sizing: border-box;

    .my-creator-header-wrapper {
        align-items: flex-start;
        display: flex;
        gap: 10px;

        @include mobile-view() {
            flex-direction: column;
        }

        .right-elem {
            flex: 1;
        }

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
        }
    }

    .creator-record-row {
        display: flex;
        align-items: center;
        gap: 6px;

        .profile {
            width: 28px;
            height: 28px;
            border-radius: 40px;
            border: 1px solid $color-scale-gray-800;

            &img {
                object-fit: cover;
            }

            &.avatar {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .initials {
                font-size: 1em;
                font-weight: 700;
                color: $color-scale-gray-0;
            }
        }
    }


    .talent-empty-state {
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        display: flex;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 10%, map-get($color-scale-gray, 900) 100%);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .info-wrapper {
                margin-top: 100px;
                width: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;

                img {
                    height: 120px;
                }

                .header {
                    margin-top: 8px;
                    font-weight: 700;
                    color: $color-scale-gray-100;
                    font-size: 1.6em;
                }

                .description {
                    margin-top: 0px;
                    line-height: 1.4em;
                    color: map-get($color-scale-gray, 500);
                }
            }
        }

        .talent-placeholder-card {
            background-color: map-get($color-scale-gray, 900);
            border-radius: 10px;
            padding: 16px;
            box-sizing: border-box;

            .details-wrapper {
                display: flex;

                .profile {
                    width: 36px;
                    height: 36px;
                    border-radius: 50px;
                }

                .account-details-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;


                    .name {
                        height: 14px;
                        border-radius: 4px;
                        width: 80px;
                    }

                    .email {
                        width: 120px;
                        margin-top: 6px;
                        height: 10px;
                        border-radius: 4px;
                    }
                }
            }

            .metrics-wrapper {
                display: flex;

                .metric {
                    flex: 1;

                    .title {
                        height: 10px;
                        border-radius: 4px;
                        width: 40px;
                    }

                    .value {
                        width: 80px;
                        margin-top: 4px;
                        height: 14px;
                        border-radius: 4px;
                    }
                }

            }

            .social-media-wrapper {
                display: flex;
                align-items: center;



                .icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 20px;

                    &:not(:first-child) {
                        margin-left: 6px;
                    }
                }


            }
        }

        .shimmer-color {
            background-color: $color-scale-gray-850;
        }
    }

    .table-card {
        @include card-border-conic-gradient-mixin(90deg, 12px, 48%, 52%);
    }

    .onboarded-talents {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        padding-bottom: 100px;

        .talent-card {
            background-color: map-get($color-scale-gray, 900);
            border: 1px solid map-get($color-scale-gray, 800);
            border-radius: 10px;
            padding: 16px 0 12px 0;
            box-sizing: border-box;
            cursor: pointer;
            transition: all 200ms;

            &:hover {
                transform: translateY(-5px);
            }

            .details-wrapper {
                display: flex;
                padding: 0 16px;

                .profile-img {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 50px;
                    object-fit: cover;
                }

                .profile-avatar {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    min-height: 36px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-weight: 700;
                        color: map-get($color-scale-gray, 0);
                    }
                }

                .account-details-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;


                    .email,
                    .name {
                        display: block;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }



                    .name {
                        font-weight: 600;
                        color: map-get($color-scale-gray, 100);
                    }

                    .email {
                        font-size: 0.9em;
                        font-weight: 500;
                        color: map-get($color-scale-gray, 300);
                    }
                }
            }

            .metrics-wrapper {
                display: flex;
                flex-wrap: wrap;
                padding: 0 16px;
                gap: 4px;

                .metric-left {
                    flex: 0.8;
                }

                .metric-right {
                    flex: 1.2;
                    overflow: hidden;

                    .country-wrapper {
                        display: flex;
                        gap: 4px;
                        overflow: hidden;

                        .img-flag {
                            height: 16px;
                            border: solid 1px map-get($color-scale-gray, 800);
                        }

                        span {
                            color: $color-scale-gray-300;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

            }

            .social-media-wrapper {
                border-top: 1px solid map-get($color-scale-gray, 800);
                padding: 0 10px 10px 16px;
                display: flex;
                gap: 8px;

                .content-wrapper {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    gap: 4px;
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 20px;

                    &:not(:first-child) {
                        margin-left: 6px;
                    }
                }

                span {
                    color: $color-scale-gray-300;
                }
            }
        }

    }

    .talent-table {
        .social-media-wrapper {
            display: flex;
            align-items: center;
        }

        .action-button-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

}