@import "../../styles/main.scss";







.bg-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0.00) 100%);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f9 100%);
}

.emp-task-details-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 80px;
    box-sizing: border-box;

    .page-header-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .page-header {
        font-size: 1.6em !important;
        color: $color-scale-gray-100;
    }

    .task-view-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}