@import "../../styles/main.scss";

.emp-edit-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 0px;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .back-btn-wrapper {
            cursor: pointer;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }


    .text-content-section {

        .description {
            margin: 0;
            display: block;
            color: $color-scale-gray-300;
        }

        .highlighted {
            font-weight: 500;
            color: map-get($color-scale-primary, 600);
        }
    }

}