@import "../../styles/main.scss";



.bg-tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba(51, 124, 253, 0.15) 0%, rgba(51, 124, 253, 0.00) 100%);
}

.emp-brand-campaign-page {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .page-header {
        font-size: 1.6em !important;
        color: $color-scale-gray-100;
    }

    .context-section {
        width: 100%;
    }

    .header-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    .controls-section {
        width: 100%;
        display: flex;
        align-items: flex-end;
        column-gap: 10px;
        row-gap: 0px;
        flex-wrap: wrap;

        .form-control-section {
            flex: 1;
            display: flex;
            align-items: flex-end;
            gap: 10px;

            .search-textbox {
                width: 230px;
            }

            .sort-by-textbox {
                width: 230px;
            }
        }

        .action-button {
            position: relative;
            top: -10px;
        }
    }

    .cards-section-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 14px;

        .records-title {
            flex: 1;
            font-size: 1.2em;
            font-weight: 600;
            color: $color-scale-gray-100;
        }
    }


    .campaign-card-section {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 10px;
        position: relative;
    }

    .task-card-section {
        padding: 10px 0 20px 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        grid-gap: 10px;
        position: relative;

        @include tablet-view {
            grid-template-columns: 100%;
        }

        @include mobile-view {
            grid-template-columns: calc(50% - 5px) calc(50% - 5px)
        }
    }

    .blank-overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(7, 7, 47, 0.3) 10%, $color-scale-primary-1000 100%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 2;

        .info-wrapper {
            margin-top: 20px;
            width: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            padding-bottom: 100px;

            img {
                height: 120px;
            }

            .header {
                margin-top: 8px;
                font-weight: 700;
                color: $color-scale-gray-100;
                font-size: 1.6em;
            }

            .description {
                margin-top: 0px;
                line-height: 1.4em;
                color: $color-scale-gray-300;
            }
        }
    }

    .loading-card {
        aspect-ratio: 1.667;
        border-radius: 12px;
    }

    .blank-card {
        aspect-ratio: 1.667;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        padding: 15px;
        box-sizing: border-box;

        .blank-card-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;

            .top-section {
                flex: 1;

                .shimmer-block {
                    height: 20px;
                    border-radius: 6px;
                    width: 100px;
                }
            }

            .bottom-section {
                .name-block {
                    height: 20px;
                    border-radius: 6px;
                    width: 200px;
                }

                .metrics {
                    display: flex;
                    margin-top: 12px;
                    gap: 10px;

                    .metric-block-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        .metric-title-block-1 {
                            height: 14px;
                            border-radius: 6px;
                            width: 40px;
                        }

                        .metric-value-block-1 {
                            height: 16px;
                            border-radius: 6px;
                            width: 80px;
                        }

                        .metric-title-block-2 {
                            height: 14px;
                            border-radius: 6px;
                            width: 55px;
                        }

                        .metric-value-block-2 {
                            height: 16px;
                            border-radius: 6px;
                            width: 80px;
                        }
                    }
                }
            }
        }

        .shimmer-color {
            background-color: $color-scale-gray-850;
        }
    }

}