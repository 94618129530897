@import "../../../styles/main.scss";

@mixin mobile-form {
    @media only screen and (max-width:500px) {
        @content
    }
}

.emp-brand-signup-view {
    padding: 30px 0;
    min-height: 100dvh;
    display: flex;
    align-items: center;

    @include mobile-view {
        height: 100%;
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
            height: 24px;
        }

        h1 {
            font-size: 1.2em;
            color: $color-scale-gray-100;
            font-weight: 600;
        }

        p {
            color: $color-scale-gray-300;
            margin: 0;
            line-height: 1.4em;
        }
    }

    .form-wrapper {
        background: $cbz-card-gradient-md;
        @include card-border-conic-gradient-mixin(100deg, 12px, 47%, 53%);
        max-width: 400px;
        width: 100%;
        border-radius: 20px;
        padding: 40px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        box-sizing: border-box;

        @include mobile-view {
            padding: 60px 20px 120px 20px;
            max-width: 100%;
            border-radius: 0;
            box-shadow: none;
            height: 100%;
        }

        .back-btn-wrapper {
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .form-row {
            width: 100%;
            display: flex;

            .left-group {
                flex: 1;
                padding-right: 12px;

            }

            .right-group {
                flex: 1;
                padding-left: 12px;


            }

        }

        .recaptcha-description {
            font-size: 1em;
            margin: 0;
            line-height: 1.4em;
            color: $color-scale-gray-300;
        }


        .button-wrapper-row {
            margin-top: 8px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.centered {
                justify-content: center !important;
            }
        }

        .header-wrapper {
            display: flex;
            align-items: center;

            .header {
                font-size: 1.1em;
                font-weight: 600;
                color: $color-scale-gray-100;
            }
        }

        .description {
            color: $color-scale-gray-300;

            .highlight {
                font-weight: 600;
                color: map-get($color-scale-primary, 400);
            }
        }

        .loader-wrapper {
            display: flex;
        }
    }



}