@import "../../styles/main.scss";

.emp-create-task-modal {

    .task-modal-wrapper {
        padding: 0 20px;
        margin-top: 20px;

        .stepper-section {
            width: 100%;
            display: flex;
            gap: 12px;

            .step {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 6px;

                .step-header {
                    display: block;
                    font-size: 1.2em;
                    font-weight: 700;
                }

                .step-description {
                    display: block;
                    font-weight: 500;
                }

                .bar {
                    width: 100%;
                    height: 4px;
                    border-radius: 20px;
                }

                .stepper-elem {
                    background-color: $color-scale-gray-200;

                    &.active {
                        background-color: $color-scale-primary-500;
                    }
                }

                .stepper-text-elem {
                    color: $color-scale-gray-200;

                    &.active {
                        color: $color-scale-primary-500;
                    }
                }
            }
        }

        .form-section {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .split-wrapper {
                display: flex;
                gap: 10px;

                .split-control {
                    flex: 1;

                    small {
                        color: $color-scale-gray-300;
                        font-weight: 600;
                    }
                }

            }
        }

        .step-2 {
            .deliverable-content {
                padding-bottom: 10px;

                &:not(:last-child) {
                    border-bottom: 1px solid $color-scale-gray-800;
                }

                .deliverable-indicator-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }


                .deliverable-number {
                    flex: 1;
                    display: block;
                    font-size: 0.9em;
                    font-weight: 700;
                    color: $color-scale-gray-300;
                }


                .deliverable-name {
                    margin-top: 6px;
                    font-weight: 700;
                    font-size: 1.1em;
                    margin-bottom: 6px;
                    color: $color-scale-gray-100;
                }

                .deliverable-description {
                    margin-top: 2px;
                    font-weight: 500;
                    line-height: 1.4em;
                    color: $color-scale-gray-300;
                }
            }

            .acknowledgement-wrapper {
                display: flex;

                .card {
                    display: flex;
                    flex-direction: column;
                    background-color: $color-scale-primary-800;
                    padding: 15px;
                    border-radius: 12px;
                    width: 100%;

                    .intro-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        span {
                            color: $color-scale-primary-600;
                            font-weight: 700;
                        }
                    }

                    .control-wrapper {
                        display: flex;

                        .checkbox-text-wrapper {
                            cursor: pointer;
                            flex: 1;

                            span {
                                line-height: 1.4em;
                                font-weight: 400;
                                color: $color-scale-gray-100;

                                &.checked {
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                }
            }
        }

        .button-wrapper {
            display: flex;
            width: 100%;
            gap: 10px;
            justify-content: flex-end;
        }
    }


}

.picker-label {
    font-size: 1em;
    font-weight: 500;
    color: map-get($color-scale-gray, 750);
    margin-bottom: 8px;

    .required {
        display: inline;
        color: red;
    }
}