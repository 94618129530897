@import "../../../styles/main.scss";


.emp-task-chat-event-notification {

    .chat-bubble {
        border-radius: 12px 12px 12px 4px;
        border: 1px solid $color-scale-gray-800;
        background-color: $color-scale-gray-100;
        padding: 5px 10px;

        .chat-content {
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* Number of lines you want to display */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            color: $color-scale-gray-300;
            font-weight: 500;
            line-height: 1.4em;
        }
    }
}