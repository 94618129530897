@import "../../../styles/main.scss";

.emp-brand-deleted-task-view {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;

  img {
    width: 180px;
    animation: verticle-pulse 12s ease 0s infinite normal forwards;
  }

  @keyframes verticle-pulse {

    0%,
    100% {
      transform: translateY(0);
    }

    10%,
    30%,
    50%,
    70% {
      transform: translateY(-3px);
    }

    20%,
    40%,
    60% {
      transform: translateY(3px);
    }

    80% {
      transform: translateY(2.4px);
    }

    90% {
      transform: translateY(-2.4px);
    }
  }

  h3 {
    font-weight: 700;
    font-size: 1.6em;
    color: $color-scale-gray-300;
  }

  p {
    text-align: center;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}