@import "../../styles/main.scss";


.emp-subscription-change-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            line-height: 1.4em;
            display: block;
            color: $color-scale-gray-300;

            .highlighted {
                color: map-get($color-scale-primary, 600);
                font-weight: 600;
            }
        }

        .error-message-wrapper {
            border-radius: 8px;
            background-color: #fff1f1;
            border: 1px solid map-get($color-scale-red, 200);
            color: $color-scale-gray-100;
            padding: 12px;
        }
    }

    .plan-selection-card-skeleton,
    .plan-selection-card {
        height: 64px;
        width: 100%;
        border-radius: 12px;
    }

    .plan-selection-card {
        padding: 0 15px;
        box-sizing: border-box;
        background-color: $color-scale-gray-900;
        border: 2px solid $color-scale-gray-100;
        transition: all 300ms;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
            background-color: $color-scale-gray-900;
            border: 2px solid $color-scale-gray-150;
        }

        &.selected {
            border: solid 2px rgba(59, 134, 247, 0.8);
            background-color: rgba(59, 134, 247, 0.1);
        }

        .plan-details-wrapper {
            width: 100%;
            display: flex;
            gap: 10px;
            align-items: center;

            .knob {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                border: 2px solid $color-scale-gray-100;

                &.selected {
                    border: 2px solid $color-scale-gray-200;
                }

                .knob-ellipsis {
                    width: 14px;
                    height: 14px;
                    border-radius: 10px;
                    background-color: $color-scale-primary-500;
                    opacity: 0;

                    &.selected {
                        opacity: 1;
                    }
                }
            }

            .plan-price-lbl,
            .plan-name-lbl {
                display: block;
            }

            .plan-name-lbl {
                color: $color-scale-gray-100;
                font-weight: 600;
            }

            .plan-price-lbl {
                color: $color-scale-gray-300;
                font-weight: 500;
            }

            .free-trial-subtitle {
                font-size: 0.9em;
                color: $color-scale-gray-300;
            }

            .plan-name-wrapper {
                flex: 1;
            }
        }
    }
}