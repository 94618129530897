@import "../../styles/main.scss";

@mixin agency-mobile-view {
    @media only screen and (max-width:800px) {
        @content
    }
}



.view-agency-profile-page {
    width: 100%;
    max-width: 1200px;
    padding-bottom: 40px;

    .context-section {
        width: 100%;

        .mobile-back-btn {
            display: none;

            @include agency-mobile-view {
                margin-top: 12px;
                display: block;
            }
        }

        .colored-bg-section {
            position: relative;
            aspect-ratio: 9/1;
            width: 100%;
            background-color: #181030;
            background-size: cover;

            @include agency-mobile-view {
                border-radius: 20px 20px 0 0;
                margin-top: 8px;
            }

            .anchored-back-btn {
                position: absolute;
                top: 10px;
                left: 20px;

                @include agency-mobile-view {
                    display: none;
                }
            }
        }

        .common-info-section {
            display: flex;
            padding: 10px 20px 20px 20px;
            background-color: map-get($color-scale-gray, 900);
            border-radius: 0 0 20px 20px;
            border: 1px solid map-get($color-scale-gray, 100);
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            .image-card {
                position: relative;
                margin-top: -50px;

                @include mobile-view {
                    margin-top: -30px;
                }

                .edit-btn {
                    position: absolute;
                    cursor: pointer;
                    bottom: 0;
                    right: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: map-get($color-scale-gray, 100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 200ms;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                img,
                .avatar {
                    width: 100px;
                    height: 100px;
                    border-radius: 100px;

                    @include mobile-view {
                        width: 80px;
                        height: 80px;
                    }
                }

                img {

                    border: 1px solid map-get($color-scale-gray, 100)
                }

                .avatar {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .initials {
                        font-size: 4em;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 700;
                    }
                }
            }

            .info-wrapper {
                margin-left: 20px;
                flex: 1;
                overflow: hidden;

                .name-lbl {
                    display: block;
                    font-size: 1.4em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .email-lbl {
                    display: block;
                    color: map-get($color-scale-gray, 500);
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }



    // Dashboard Section. To be copied over
    .dashboard-section {
        width: 100%;
        display: flex;
        gap: 12px;

        @include tablet-view {
            flex-direction: column;
        }

        .left-section {
            min-width: 300px;
            width: 300px;

            @include tablet-view {
                width: 100%;
                min-width: auto;
            }

            .details-wrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .country-wrapper {
                display: flex;
                gap: 4px;

                .img-flag {
                    height: 16px;
                    border: solid 1px map-get($color-scale-gray, 150);
                }
            }
        }

        .right-section {
            flex: 1;

            .truncated {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .about-us-card {

                .header-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 12px;
                }

                .uploaded-file-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .uploaded-file-card {
                        border: 1px solid map-get($color-scale-gray, 800);
                        background-color: map-get($color-scale-gray, 900);
                        padding: 6px 30px 6px 6px;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        width: fit-content;
                        cursor: pointer;
                        transition: all 200ms;

                        &:hover {
                            transform: translateY(-4px);
                        }

                        .image-section {
                            width: 36px;
                            height: 36px;
                            border-radius: 6px;
                            background-color: map-get($color-scale-primary, 500);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .text-section {
                            .file-title {
                                display: block;
                                font-weight: 600;
                                color: $color-scale-gray-300;
                            }

                            .file-name {
                                display: block;
                                margin-top: 3px;
                                color: map-get($color-scale-gray, 500);
                            }
                        }
                    }
                }
            }

            .about-us-card-empty {
                display: flex;

                .header-wrapper {
                    padding: 20px;
                    flex: 1;
                }

                .about-us-section-wrapper {
                    @include tablet-view {
                        display: none
                    }

                    padding: 15px 20px;
                    background: linear-gradient(-89deg, #d1e0ff 17.07%, #FFFFFF 100.39%);

                    .about-us-empty-img {
                        height: 120px;
                    }
                }

            }

            .social-media-label {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 4px;

                h1 {
                    font-size: 1.4em;
                    color: $color-scale-gray-300;
                }
            }

            .onboarded-talents {
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                position: relative;
                gap: 12px;

                .talent-card {
                    background-color: map-get($color-scale-gray, 900);
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    border: 1px solid map-get($color-scale-gray, 100);
                    border-radius: 10px;
                    padding: 8px 0 12px 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    transition: all 200ms;

                    &:hover {
                        transform: translateY(-5px);
                    }

                    .details-wrapper {
                        display: flex;
                        padding: 0 12px 5px 12px;
                        border-bottom: 1px solid $color-scale-gray-800;

                        .profile-img {
                            width: 34px;
                            height: 34px;
                            min-width: 34px;
                            min-height: 34px;
                            border-radius: 50px;
                            object-fit: cover;
                        }

                        .profile-avatar {
                            width: 34px;
                            height: 34px;
                            min-width: 34px;
                            min-height: 34px;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            span {
                                font-weight: 700;
                                color: map-get($color-scale-gray, 0);
                            }
                        }

                        .account-details-wrapper {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            overflow: hidden;


                            .email,
                            .name {
                                display: block;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                            }

                            .name {
                                font-weight: 600;
                                color: $color-scale-gray-100;
                            }

                            .email {
                                font-size: 0.9em;
                                font-weight: 500;
                                color: map-get($color-scale-gray, 500);
                            }
                        }
                    }

                    .metrics-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0 16px;
                        gap: 4px;

                        .metric-left {
                            flex: 0.8;
                        }

                        .metric-right {
                            flex: 1.2;
                            overflow: hidden;

                            .country-wrapper {
                                display: flex;
                                gap: 4px;
                                overflow: hidden;

                                .img-flag {
                                    height: 16px;
                                    border: solid 1px map-get($color-scale-gray, 150);
                                }

                                span {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }

                    }

                    .social-media-wrapper {
                        border-top: 1px solid map-get($color-scale-gray, 100);
                        padding: 0 16px;
                        padding-top: 10px;
                        display: flex;
                        gap: 8px;

                        .icon {
                            width: 16px;
                            height: 16px;
                            border-radius: 20px;

                            &:not(:first-child) {
                                margin-left: 6px;
                            }
                        }
                    }
                }

            }


            .talent-empty-state {
                width: 100%;
                position: relative;
                flex-wrap: wrap;
                display: flex;
                gap: 12px;

                .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .info-wrapper {
                        max-width: 400px;
                        width: 100%;
                        padding: 20px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;

                        img {
                            height: 120px;
                        }

                        .header {
                            margin-top: 8px;
                            font-weight: 700;
                            color: $color-scale-gray-100;
                            font-size: 1.6em;
                        }

                        .description {
                            margin-top: 0px;
                            line-height: 1.4em;
                            color: map-get($color-scale-gray, 500);
                        }
                    }
                }


            }

            .talent-placeholder-card {
                background-color: map-get($color-scale-gray, 100);
                border-radius: 10px;
                padding: 16px;
                box-sizing: border-box;

                .details-wrapper {
                    display: flex;

                    .profile {
                        width: 36px;
                        height: 36px;
                        border-radius: 50px;
                    }

                    .account-details-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex: 1;


                        .name {
                            height: 14px;
                            border-radius: 4px;
                            width: 100%;
                            max-width: 80px;
                        }

                        .email {
                            max-width: 120px;
                            width: 100%;
                            margin-top: 6px;
                            height: 10px;
                            border-radius: 4px;
                        }
                    }
                }

                .metrics-wrapper {
                    display: flex;

                    .metric {
                        flex: 1;

                        .title {
                            height: 10px;
                            border-radius: 4px;
                            width: 100%;
                            max-width: 40px;
                        }

                        .value {
                            width: 100%;
                            max-width: 80px;
                            margin-top: 4px;
                            height: 14px;
                            border-radius: 4px;
                        }
                    }

                }

                .social-media-wrapper {
                    display: flex;

                    .icon {
                        width: 16px;
                        height: 16px;
                        border-radius: 20px;

                        &:not(:first-child) {
                            margin-left: 6px;
                        }
                    }
                }
            }

            .shimmer-color {
                background-color: $color-scale-gray-850;
            }
        }
    }
}