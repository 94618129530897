@import "../../../styles/main.scss";

.emp-ongoing-kanban {

    .revision-pill {
        border-radius: 5px;
        font-size: 0.85em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        white-space: nowrap;
        line-height: initial;
        background-color: $color-scale-gray-700;
        color: $color-scale-gray-150;
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 0.75em;
        padding: 3px 4px;
        font-weight: 700;
    }

    .draft-content-wrapper {
        margin-top: 8px;
        border-radius: 8px;
        width: 100%;
        aspect-ratio: 3/1.6;
        background-color: $color-scale-gray-900;
        border: 1px solid $color-scale-gray-800;
        overflow: hidden;

        .draft-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 200ms all;

            &:hover {
                transform: scale(1.1);
            }
        }

        .draft-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: $color-scale-gray-900;
        }

        .draft-file-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
            flex-direction: column;

            .title {
                margin-top: 3px;
                font-weight: 600;
                color: $color-scale-gray-100;
                text-align: center;
            }

            .specs {
                margin-top: 4px;
                font-weight: 600;
                color: $color-scale-gray-300;
                text-align: center;
            }
        }
    }
}