@import "../../styles/main.scss";


.emp-payment-fail-modal {

    .payment-modal-graphic-section {
        border-radius: 12px 12px 0 0;
        width: 100%;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(103deg, #f7fbff 7.61%, #bfc8f5 94.02%);

        .credit-card-img {
            width: 140px;
        }
    }

    .payment-modal-content-section {
        padding: 15px;
        text-align: center;

        .payment-title {
            font-size: 1.2em;
            font-weight: 700;
            color: $color-scale-gray-100;
        }

        .payment-fail-button-wrapper {
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
        }
    }

}