@import "../../styles/main.scss";


.emp-invite-talents-modal {
    position: relative;
    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .text-content-section {

        .title {
            display: block;
            font-weight: 600;
            color: map-get($color-scale-gray, 100);
            font-size: 1.1em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 6px;
            display: block;
            color: $color-scale-gray-300;
        }
    }



    .invitation-form-section {
        margin-top: 24px;
        // display: flex;


        .text-input-row-wrapper {
            display: flex;
            gap: 12px;
        }

        .text-input-wrapper {
            display: flex;
            flex: 1;
        }

        .button-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-bottom: 10px;
        }
    }

    .onboarding-link-section {
        width: 100%;
        background-color: $color-scale-gray-900;
        padding: 15px;
        border-radius: 12px;
        box-sizing: border-box;
        margin-bottom: 16px;

        p {
            margin: 0;
            color: $color-scale-gray-300;
            line-height: 1.4em;
        }
    }
}