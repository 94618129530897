@import "../../../styles/main.scss";

.emp-agency-privacy-settings-view {

    margin-top: 20px;
    padding-left: 60px;
    box-sizing: border-box;

    @include mobile-view {
        padding-left: 0px;
    }

    .view-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h1 {
            font-size: 1.8em;
            margin: 0 0 10px 0;
            color: $color-scale-gray-100;

            @include mobile-view {
                font-size: 1.4em;
                font-weight: 600;
                margin: 0;
            }
        }

        .page-description {
            line-height: 1.4em;
            color: $color-scale-gray-300;
            margin-bottom: 0;

            @include mobile-view {
                margin-top: 6px;
            }
        }
    }

    .setting-item {
        display: flex;
        gap : 12px;
        padding: 20px 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-scale-gray-800;

        .info-wrapper {
            .info-header {
                font-size: 1.1em;
                display: block;
                font-weight: 600;
                color: $color-scale-gray-100;
            }

            .info-description {
                display: block;

                margin: 0;
                margin-top: 4px;
                font-weight: 500;
                color: $color-scale-gray-300;
                line-height: 1.4em;
            }
        }
        .switch-wrapper {
            min-width: fit-content;
            min-height: fit-content;
        }

    }
    .save-btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

}