@import "../../styles/main.scss";


.emp-review-draft-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: map-get($color-scale-gray, 600);
        }
    }

    .content-section {
        padding: 0 20px;

        .slider-wrapper {
            position: relative;
            overflow: hidden;
            border-radius: 12px;
            height: 360px;
            display: flex;
            flex-direction: column;
            border: 1px solid $color-scale-gray-800;

            .sm-right-arrow-wrapper,
            .sm-left-arrow-wrapper {
                cursor: pointer;
                z-index: 1;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                background-color: $color-scale-gray-750;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 200ms;

                &:hover {
                    background-color: $color-scale-gray-700;
                }
            }

            .sm-left-arrow-wrapper {
                left: 10px;
            }

            .sm-right-arrow-wrapper {
                right: 10px;
            }


        }



        .details-wrapper {
            height: 60px;
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            background-color: $color-scale-gray-900;
            border-bottom: 1px solid $color-scale-gray-800;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .inner-wrapper {
                width: 75%;

                .file-header {
                    font-size: 1.1em;
                    font-weight: 600;
                    color: $color-scale-gray-100;
                }

                .bottom-row {
                    margin-top: 2px;
                    display: flex;
                    width: fit-content;
                    gap: 4px;
                    align-items: center;

                    span {
                        color: $color-scale-gray-300;
                        font-weight: 500;

                        &.filename {
                            max-width: 40%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .attachment-wrapper {
            width: 100%;
            background-color: $color-scale-gray-900;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 300px;

            .attachment-video,
            .attachment-img {
                max-height: 300px;
                height: auto;
                width: auto;
                max-width: 100%;
            }

            .attachment-file {
                width: 100%;
                height: 100%;
                padding: 20px;
                background-color: $color-scale-gray-100;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;

                .file-extension {
                    font-size: 2.4em;
                    text-transform: uppercase;
                    font-weight: 800;
                    color: $color-scale-gray-400;
                }

                .file-name {
                    color: $color-scale-gray-700;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                }
            }
        }

        .sm-pagination-dots-wrapper {
            padding: 5px 10px 8px 5px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            gap: 0;

            .sm-dot-clickable-area {
                width: 18px;
                height: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    .sm-dot {
                        background-color: $color-scale-gray-400;

                        &.active {
                            background-color: $color-scale-primary-600;
                        }
                    }
                }

                .sm-dot {
                    border-radius: 50%;
                    background-color: $color-scale-gray-200;
                    width: 8px;
                    height: 8px;
                    transition: 100ms all;

                    &.active {
                        background-color: $color-scale-primary-500;
                    }
                }
            }
        }

    }

    .acknowledgement-wrapper {
        display: flex;
        padding: 0 20px;

        .card {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            background-color: $color-scale-primary-100;
            padding: 10px 15px;
            border-radius: 12px;
            width: 100%;

            &.danger {
                background-color: #fef2f2;
                border: 1px solid map-get($color-scale-red, 100);
            }

            .intro-wrapper {
                display: flex;
                gap: 8px;

                .description-text {
                    color: $color-scale-primary-500;
                    font-weight: 500;

                    &.danger {
                        color: map-get($color-scale-red, 500);
                    }
                }
            }
        }
    }
}