@import "../../styles/main.scss";


.emp-profile-pictures-modal {
    position: relative;

    .dismiss-icon-wrapper {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        top: -2px;
        right: 20px;
    }

    .text-content-section {
        padding: 0 20px;

        .title {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
            font-size: 1.2em;
            margin: 0;
        }

        .description {
            margin: 0;
            margin-top: 8px;
            display: block;
            color: $color-scale-gray-300;
        }
    }


    .edit-picture-section {
        padding: 20px;

        .picture-label {
            display: block;
            font-weight: 600;
            color: $color-scale-gray-100;
        }

        .description {
            margin: 0;
            color: $color-scale-gray-300;
        }

        .profile-pic-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid map-get($color-scale-gray, 150);

            &:not(:first-child) {
                border-top: none;
            }

            .logo-wrapper {
                margin-top: 8px;
                width: 80px;
                height: 80px;
                border-radius: 100px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                .hover-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition: opacity 300ms;
                }

                .logo-img {
                    width: 100%;
                    height: 100%;
                    border: 1px solid map-get($color-scale-gray, 150);
                    border-radius: 100px;
                }
            }

            .banner-wrapper {
                margin-top: 8px;
                width: 100%;
                aspect-ratio: 9/1;
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                .hover-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    transition: opacity 300ms;

                    .hover-info-wrapper {
                        display: flex;
                        align-items: center;
                        color: map-get($color-scale-gray, 0);
                        font-weight: 600;
                        gap: 6px;
                    }
                }

                .banner-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid map-get($color-scale-gray, 150);
                    border-radius: 4px;
                }
            }
        }
    }

    .upload-hidden {
        display: none;
    }

}